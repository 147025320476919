import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import Header from "Components/headers/PageHeader";
import MainFeature1 from "Components/Features/TwoColWithButton.js"; // Reused component
import Features from "Components/Features/ThreeColumnPages"; // Reused component
import {
  FaDollarSign,
  FaServer,
  FaShieldAlt,
  FaUsers,
  FaHandshake,
  FaAward,
} from "react-icons/fa";

// Styled Components
const Subheading = tw.span`uppercase tracking-wider text-sm`;
const Heading = tw.h1`text-2xl md:text-4xl lg:text-5xl font-bold`;
const Description = tw.p`text-base md:text-lg lg:text-xl`;

// Affordable Web Hosting Services in Al Barsha Page Component
export const AffordableWebHostingServicesInAlBarsha = () => {
  return (
    <AnimationRevealPage>
      {/* Header Section */}
      <Header from="Affordable Web Hosting Services in Al Barsha" />

      {/* Hero Section */}
      <MainFeature1
        subheading={
          <Subheading>Al Barsha’s Most Affordable Hosting Provider</Subheading>
        }
        heading={
          <Heading>
            Elevate Your Online Presence with{" "}
            <span tw="text-primary-500">
              Affordable Web Hosting in Al Barsha
            </span>
          </Heading>
        }
        description={
          <Description>
            At MilaajBrandset, we specialize in delivering expert yet affordable
            web hosting solutions tailored for businesses and individuals in Al
            Barsha. Our team combines cutting-edge technology with unparalleled
            customer service to ensure your website remains fast, secure, and
            reliable without breaking the bank.
          </Description>
        }
        imageSrcs={[
          "https://images.unsplash.com/photo-1607799279861-4dd421887fb3?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2340&q=80", // Budget-friendly technology concept
          "https://images.unsplash.com/photo-1563986768494-4dee2763ff3f?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2340&q=80", // Server cables/infrastructure
          "https://images.unsplash.com/photo-1553877522-43269d4ea984?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2340&q=80", // Person working on affordable website solution
        ]}
        widths={["55%", "60%", "70%"]}
        heights={["45%", "60%", "35%"]}
        imageColumnStyle="height: 400px;"
        imageStyle="width: 45%; height: 60%;"
        imagePositions={[
          "left: 35%; top: 20%;",
          "right: 30%; top: 10%;",
          "left: 32%; bottom: 20%;",
        ]}
        primaryButtonText="Get Started Today"
        buttonRounded={true}
        textOnLeft={false}
      />

      {/* Key Benefits Section */}
      <Features
        subheading={<Subheading>Why Choose Us?</Subheading>}
        heading="Your Trusted Affordable Hosting Provider in Al Barsha"
        description="We combine affordability, performance, and innovation to deliver unmatched value."
        cards={[
          {
            icon: <FaDollarSign color="#ff5464" size={40} />,
            title: "Budget-Friendly Plans",
            description:
              "Affordable hosting packages designed to meet the needs of small businesses and startups.",
          },
          {
            icon: <FaServer color="#ff5464" size={40} />,
            title: "High-Performance Servers",
            description:
              "Guarantee 99.9% uptime with lightning-fast load times for your website.",
          },
          {
            icon: <FaShieldAlt color="#ff5464" size={40} />,
            title: "Enhanced Security",
            description:
              "Protect your data with advanced encryption and proactive threat detection.",
          },
          {
            icon: <FaUsers color="#ff5464" size={40} />,
            title: "Personalized Support",
            description:
              "24/7 assistance tailored specifically to Al Barsha businesses and individuals.",
          },
          {
            icon: <FaHandshake color="#ff5464" size={40} />,
            title: "Client-Centric Solutions",
            description:
              "Tailored strategies designed to meet your unique goals.",
          },
          {
            icon: <FaAward color="#ff5464" size={40} />,
            title: "Proven Track Record",
            description:
              "Delivering exceptional results for Al Barsha clients since [Year].",
          },
        ]}
      />

      {/* Our Process Section */}
      <MainFeature1
        subheading={<Subheading>How We Work</Subheading>}
        heading="Our Streamlined Hosting Process for Al Barsha Businesses"
        description={
          <Description>
            Our Al Barsha-focused process ensures quality, efficiency, and
            measurable results:
            <ul tw="mt-4 list-disc pl-6">
              <li>
                Discovery & Research: Understand your business and technical
                requirements.
              </li>
              <li>
                Planning & Strategy: Match you with the most affordable hosting
                plan for your needs.
              </li>
              <li>
                Implementation: Deploy your website on secure, high-performance
                servers.
              </li>
              <li>
                Monitoring & Optimization: Continuously improve speed and
                resource allocation.
              </li>
              <li>
                Ongoing Support: Provide 24/7 assistance with a local touch.
              </li>
            </ul>
          </Description>
        }
        imageSrcs={[
          "https://images.unsplash.com/photo-1579389083046-e3df9c2b3325?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2340&q=80", // Server room/data center
          "https://images.unsplash.com/photo-1600880292089-90a7e086ee0c?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2340&q=80", // Dubai business district
          "https://images.unsplash.com/photo-1484840676255-5d67c64e7bc0?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2340&q=80", // Analytics dashboard/performance monitoring
        ]}
        widths={["60%", "55%", "70%"]}
        heights={["60%", "45%", "35%"]}
        imageColumnStyle="height: 400px;"
        imageStyle="width: 45%; height: 60%;"
        imagePositions={[
          "right: 30%; top: 10%;",
          "left: 35%; bottom: 20%;",
          "right: 32%; bottom: 10%;",
        ]}
        primaryButtonText="Learn More"
        buttonRounded={true}
        textOnLeft={true}
      />

      {/* Success Stories Section */}
      <Features
        subheading={<Subheading>Success Stories</Subheading>}
        heading="Results That Speak for Themselves"
        description="Here’s how our affordable hosting solutions have empowered Al Barsha businesses."
        cards={[
          {
            icon: <FaAward color="#ff5464" size={40} />,
            title: "Business Growth",
            description:
              "Helped a local Al Barsha startup launch their website affordably and scale seamlessly.",
          },
          {
            icon: <FaHandshake color="#ff5464" size={40} />,
            title: "Client Satisfaction",
            description:
              "98% retention rate from Al Barsha clients through consistent excellence.",
          },
          {
            icon: <FaDollarSign color="#ff5464" size={40} />,
            title: "Cost Savings",
            description:
              "Enabled over 100 Al Barsha businesses to thrive with budget-friendly hosting plans.",
          },
        ]}
      />

      {/* Call-to-Action Section */}
      <MainFeature1
        subheading={<Subheading>Ready for Affordable Hosting?</Subheading>}
        heading="Let’s Grow Your Al Barsha Business Together"
        description={
          <Description>
            Partner with Al Barsha’s leading affordable web hosting provider to
            unlock your website’s full potential. Whether you’re launching a new
            site, migrating, or optimizing performance, we’re here to help.
          </Description>
        }
        imageSrcs={[
          "https://images.unsplash.com/photo-1577401239170-897942555fb3?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2340&q=80", // Cost-savings concept with coins/money
          "https://images.unsplash.com/photo-1601597111158-2fceff292cdc?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2340&q=80", // Business person using laptop
          "https://images.unsplash.com/photo-1600087626014-e652e18bbff2?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2340&q=80", // Dubai skyline emphasizing local services
        ]}
        widths={["55%", "60%", "70%"]}
        heights={["45%", "60%", "35%"]}
        imageColumnStyle="height: 400px;"
        imageStyle="width: 45%; height: 60%;"
        imagePositions={[
          "left: 35%; top: 20%;",
          "right: 30%; top: 10%;",
          "left: 32%; bottom: 20%;",
        ]}
        primaryButtonText="Contact Us Today"
        buttonRounded={true}
        textOnLeft={false}
      />
    </AnimationRevealPage>
  );
};
