import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import Header from "Components/headers/PageHeader";
import MainFeature1 from "Components/Features/TwoColWithButton.js"; // Reused component
import Features from "Components/Features/ThreeColumnPages"; // Reused component
import {
  FaSearch,
  FaChartBar,
  FaUsers,
  FaHandshake,
  FaAward,
  FaLightbulb,
} from "react-icons/fa";

// Styled Components
const Subheading = tw.span`uppercase tracking-wider text-sm`;
const Heading = tw.h1`text-2xl md:text-4xl lg:text-5xl font-bold`;
const Description = tw.p`text-base md:text-lg lg:text-xl`;

// Best Local SEO Agency in Dubai Page Component
export const BestLocalSEOAgencyInDubai = () => {
  return (
    <AnimationRevealPage>
      {/* Header Section */}
      <Header from="Best Local SEO Agency in Dubai" />

      {/* Hero Section */}
      <MainFeature1
        subheading={<Subheading>Your Trusted Partner for Local SEO</Subheading>}
        heading={
          <Heading>
            Elevate Your Local Presence with{" "}
            <span tw="text-primary-500">Best Local SEO Agency in Dubai</span>
          </Heading>
        }
        description={
          <Description>
            At MilaajBrandset, we specialize in delivering expert local SEO services across Dubai that help businesses improve their online visibility, attract nearby customers, and achieve measurable results. Our team of seasoned SEO specialists brings data-driven strategies, fresh ideas, and a client-centric approach to every project.
          </Description>
        }
        imageSrcs={[
            "https://images.unsplash.com/photo-1577962917302-cd874c4e31d2?w=1000", // Local business map and analytics
            "https://images.unsplash.com/photo-1497366216548-37526070297c?w=1000", // Team working on local SEO strategy
            "https://images.unsplash.com/photo-1568992687947-868a62a9f521?w=1000", // Digital marketing team collaboration
          ]}
        widths={["55%", "60%", "70%"]}
        heights={["45%", "60%", "35%"]}
        imageColumnStyle="height: 400px;"
        imageStyle="width: 45%; height: 60%;"
        imagePositions={[
          "left: 35%; top: 20%;",
          "right: 30%; top: 10%;",
          "left: 32%; bottom: 20%;",
        ]}
        primaryButtonText="Get Started Today"
        buttonRounded={true}
        textOnLeft={false}
      />

      {/* Key Benefits Section */}
      <Features
        subheading={<Subheading>Why Choose Us?</Subheading>}
        heading="Your Trusted Local SEO Experts in Dubai"
        description="We combine specialization, innovation, and measurable results to deliver exceptional value."
        cards={[
          {
            icon: <FaSearch color="#ff5464" size={40} />,
            title: "Proven Local SEO Strategies",
            description:
              "Our data-driven approach ensures maximum visibility in local search results.",
          },
          {
            icon: <FaChartBar color="#ff5464" size={40} />,
            title: "Data-Driven Insights",
            description:
              "Backed by research to ensure maximum impact and growth.",
          },
          {
            icon: <FaUsers color="#ff5464" size={40} />,
            title: "Audience-Centric Approach",
            description:
              "We prioritize the needs and behaviors of your target audience.",
          },
          {
            icon: <FaHandshake color="#ff5464" size={40} />,
            title: "Client-Centric Solutions",
            description:
              "Tailored strategies designed to meet your unique goals.",
          },
          {
            icon: <FaAward color="#ff5464" size={40} />,
            title: "Proven Expertise",
            description:
              "Years of experience helping businesses rank higher in local searches.",
          },
          {
            icon: <FaLightbulb color="#ff5464" size={40} />,
            title: "Creative Excellence",
            description:
              "Fresh perspectives and innovative ideas for every project.",
          },
        ]}
      />

      {/* Our Process Section */}
      <MainFeature1
        subheading={<Subheading>How We Work</Subheading>}
        heading="Our Streamlined Local SEO Process"
        description={
          <Description>
            Our process ensures quality, efficiency, and measurable results:
            <ul tw="mt-4 list-disc pl-6">
              <li>Discovery & Research: Understand your business and local audience.</li>
              <li>Keyword Analysis: Identify high-value keywords for local searches.</li>
              <li>Strategy Development: Create tailored local SEO plans for your goals.</li>
              <li>Implementation: Optimize your website and Google My Business profile.</li>
              <li>Monitoring & Reporting: Track performance and adjust strategies for maximum impact.</li>
            </ul>
          </Description>
        }
        imageSrcs={[
            "https://images.unsplash.com/photo-1553877522-43269d4ea984?w=1000", // Local search results and mapping
            "https://images.unsplash.com/photo-1483389127117-b6a2102724ae?w=1000", // Local business optimization
            "https://images.unsplash.com/photo-1542744173-8e7e53415bb0?w=1000", // Team analyzing local metrics
          ]}
        widths={["60%", "55%", "70%"]}
        heights={["60%", "45%", "35%"]}
        imageColumnStyle="height: 400px;"
        imageStyle="width: 45%; height: 60%;"
        imagePositions={[
          "right: 30%; top: 10%;",
          "left: 35%; bottom: 20%;",
          "right: 32%; bottom: 10%;",
        ]}
        primaryButtonText="Learn More"
        buttonRounded={true}
        textOnLeft={true}
      />

      {/* Success Stories Section */}
      <Features
        subheading={<Subheading>Success Stories</Subheading>}
        heading="Results That Speak for Themselves"
        description="Here’s how our local SEO services have helped businesses grow across Dubai."
        cards={[
          {
            icon: <FaAward color="#ff5464" size={40} />,
            title: "Improved Local Rankings",
            description:
              "A local restaurant ranked #1 on Google Maps for their primary keyword within three months.",
          },
          {
            icon: <FaHandshake color="#ff5464" size={40} />,
            title: "Client Satisfaction",
            description:
              "Achieved a 98% client retention rate through consistent results.",
          },
          {
            icon: <FaLightbulb color="#ff5464" size={40} />,
            title: "Increased Foot Traffic",
            description:
              "Boosted a retail client’s foot traffic by 150% in six months.",
          },
        ]}
      />

      {/* Call-to-Action Section */}
      <MainFeature1
        subheading={<Subheading>Ready to Boost Your Local Presence?</Subheading>}
        heading="Let’s Optimize Your Local SEO Together"
        description={
          <Description>
            Partner with our expert local SEO agency in Dubai to unlock the full potential of your online presence. Whether you’re looking to improve your local rankings, increase foot traffic, or enhance your brand’s visibility, we’re here to help.
          </Description>
        }
        imageSrcs={[
            "https://images.unsplash.com/photo-1517245386807-bb43f82c33c4?w=1000", // Team celebrating local SEO success
            "https://images.unsplash.com/photo-1460925895917-afdab827c52f?w=1000", // Local business analytics dashboard
            "https://images.unsplash.com/photo-1515187029135-18ee286d815b?w=1000", // Local business partnership meeting
          ]}
        widths={["55%", "60%", "70%"]}
        heights={["45%", "60%", "35%"]}
        imageColumnStyle="height: 400px;"
        imageStyle="width: 45%; height: 60%;"
        imagePositions={[
          "left: 35%; top: 20%;",
          "right: 30%; top: 10%;",
          "left: 32%; bottom: 20%;",
        ]}
        primaryButtonText="Contact Us Today"
        buttonRounded={true}
        textOnLeft={false}
      />
    </AnimationRevealPage>
  );
};

