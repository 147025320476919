import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import Header from "Components/headers/PageHeader";
import MainFeature1 from "Components/Features/TwoColWithButton.js"; // Reused component
import Features from "Components/Features/ThreeColumnPages"; // Reused component
import {
  FaChartLine,
  FaUsers,
  FaHandshake,
  FaAward,
  FaMapMarkerAlt,
  FaBullseye,
} from "react-icons/fa";

// Styled Components
const Subheading = tw.span`uppercase tracking-wider text-sm`;
const Heading = tw.h1`text-2xl md:text-4xl lg:text-5xl font-bold`;
const Description = tw.p`text-base md:text-lg lg:text-xl`;

// Best-Performing Social Ads in DIFC Page Component
export const BestPerformingSocialAdsInDIFC = () => {
  return (
    <AnimationRevealPage>
      {/* Header Section */}
      <Header from="Best-Performing Social Ads in DIFC" />

      {/* Hero Section */}
      <MainFeature1
        subheading={
          <Subheading>
            Maximize Your Impact with High-Performance Social Ads
          </Subheading>
        }
        heading={
          <Heading>
            Unlock Your Potential with{" "}
            <span tw="text-primary-500">
              The Best-Performing Social Ads in DIFC
            </span>
          </Heading>
        }
        description={
          <Description>
            At MilaajBrandset, we specialize in delivering professional social
            ad services tailored for businesses in DIFC, Dubai. Our team
            combines creativity, strategy, and local insights to help you grow
            your audience, engage your community, and achieve measurable results
            through targeted campaigns.
          </Description>
        }
        imageSrcs={[
          "https://images.unsplash.com/photo-1611162616475-46b635cb6868?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80", // Social media marketing analytics
          "https://images.unsplash.com/photo-1558403194-611308249627?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80", // DIFC financial district
          "https://images.unsplash.com/photo-1519389950473-47ba0277781c?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80", // Team working on digital campaign
        ]}
        widths={["55%", "60%", "70%"]}
        heights={["45%", "60%", "35%"]}
        imageColumnStyle="height: 400px;"
        imageStyle="width: 45%; height: 60%;"
        imagePositions={[
          "left: 35%; top: 20%;",
          "right: 30%; top: 10%;",
          "left: 32%; bottom: 20%;",
        ]}
        primaryButtonText="Get Started Today"
        buttonRounded={true}
        textOnLeft={false}
      />

      {/* Key Benefits Section */}
      <Features
        subheading={<Subheading>Why Choose Us?</Subheading>}
        heading="Your Trusted Partner for Best-Performing Social Ads in DIFC"
        description="We combine creativity, strategy, and innovation to deliver unmatched value."
        cards={[
          {
            icon: <FaBullseye color="#ff5464" size={40} />,
            title: "Targeted Campaigns",
            description:
              "Reach your ideal customers in DIFC with precision-targeted social ads.",
          },
          {
            icon: <FaChartLine color="#ff5464" size={40} />,
            title: "Data-Driven Strategies",
            description:
              "Leverage advanced analytics to optimize performance and maximize ROI.",
          },
          {
            icon: <FaMapMarkerAlt color="#ff5464" size={40} />,
            title: "Localized Expertise",
            description:
              "Understand DIFC's unique market needs and professional landscape.",
          },
          {
            icon: <FaUsers color="#ff5464" size={40} />,
            title: "Audience Engagement",
            description:
              "Build meaningful connections with your target audience through engaging ads.",
          },
          {
            icon: <FaHandshake color="#ff5464" size={40} />,
            title: "Client-Centric Approach",
            description:
              "Collaborate closely with you to ensure alignment with your vision.",
          },
          {
            icon: <FaAward color="#ff5464" size={40} />,
            title: "Proven Track Record",
            description:
              "Delivering exceptional results for DIFC clients since [Year].",
          },
        ]}
      />

      {/* Our Process Section */}
      <MainFeature1
        subheading={<Subheading>How We Work</Subheading>}
        heading="Our Streamlined Process for Best-Performing Social Ads in DIFC"
        description={
          <Description>
            Our DIFC-focused process ensures quality, efficiency, and measurable
            results:
            <ul tw="mt-4 list-disc pl-6">
              <li>
                Discovery & Research: Understand your business, audience, and
                goals.
              </li>
              <li>
                Audience Analysis: Identify your target demographic and their
                preferences.
              </li>
              <li>
                Strategy Development: Create a customized roadmap for growth and
                engagement.
              </li>
              <li>
                Creative Execution: Design visually stunning ads (Posts,
                Stories, Videos).
              </li>
              <li>
                Campaign Launch: Promote your brand across platforms like
                Facebook, Instagram, LinkedIn, and TikTok targeting DIFC
                audiences.
              </li>
              <li>
                Optimization & Analysis: Continuously refine strategies based on
                performance metrics and feedback.
              </li>
            </ul>
          </Description>
        }
        imageSrcs={[
          "https://images.unsplash.com/photo-1559136555-9303baea8ebd?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80", // Social media campaign planning
          "https://images.unsplash.com/photo-1551288049-bebda4e38f71?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80", // Data analytics dashboard
          "https://images.unsplash.com/photo-1600880292203-757bb62b4baf?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80", // Creative team collaboration
        ]}
        widths={["60%", "55%", "70%"]}
        heights={["60%", "45%", "35%"]}
        imageColumnStyle="height: 400px;"
        imageStyle="width: 45%; height: 60%;"
        imagePositions={[
          "right: 30%; top: 10%;",
          "left: 35%; bottom: 20%;",
          "right: 32%; bottom: 10%;",
        ]}
        primaryButtonText="Learn More"
        buttonRounded={true}
        textOnLeft={true}
      />

      {/* Success Stories Section */}
      <Features
        subheading={<Subheading>Success Stories</Subheading>}
        heading="Results That Speak for Themselves"
        description="Here’s how our best-performing social ads have empowered DIFC businesses."
        cards={[
          {
            icon: <FaAward color="#ff5464" size={40} />,
            title: "Campaign ROI",
            description:
              "Helped a financial consultancy in DIFC achieve a 15x ROI on their LinkedIn PPC campaign within six months.",
          },
          {
            icon: <FaHandshake color="#ff5464" size={40} />,
            title: "Client Satisfaction",
            description:
              "98% client retention rate through consistent excellence and measurable results.",
          },
          {
            icon: <FaBullseye color="#ff5464" size={40} />,
            title: "Conversion Optimization",
            description:
              "Increased lead generation by 250% for a luxury real estate firm in DIFC using Facebook Lead Ads.",
          },
        ]}
      />

      {/* Call-to-Action Section */}
      <MainFeature1
        subheading={<Subheading>Ready to Amplify Your Brand?</Subheading>}
        heading="Let’s Elevate Your Business Together in DIFC"
        description={
          <Description>
            Partner with DIFC’s leading social ad experts to unlock your brand’s
            full potential. Whether you’re launching a campaign, driving
            traffic, or boosting engagement, we’re here to help.
          </Description>
        }
        imageSrcs={[
          "https://images.unsplash.com/photo-1582213782179-e0d53f98f2ca?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80", // Dubai business district at night
          "https://images.unsplash.com/photo-1552581234-26160f608093?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80", // Social media marketing results
          "https://images.unsplash.com/photo-1600880292089-90a7e086ee0c?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80", // Professional business meeting
        ]}
        widths={["55%", "60%", "70%"]}
        heights={["45%", "60%", "35%"]}
        imageColumnStyle="height: 400px;"
        imageStyle="width: 45%; height: 60%;"
        imagePositions={[
          "left: 35%; top: 20%;",
          "right: 30%; top: 10%;",
          "left: 32%; bottom: 20%;",
        ]}
        primaryButtonText="Contact Us Today"
        buttonRounded={true}
        textOnLeft={false}
      />
    </AnimationRevealPage>
  );
};
