import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import Header from "Components/headers/PageHeader";
import MainFeature1 from "Components/Features/TwoColWithButton.js"; // Reused component
import Features from "Components/Features/ThreeColumnPages"; // Reused component
import {
  FaTrophy,
  FaServer,
  FaShieldAlt,
  FaUsers,
  FaHandshake,
  FaAward,
} from "react-icons/fa";

// Styled Components
const Subheading = tw.span`uppercase tracking-wider text-sm`;
const Heading = tw.h1`text-2xl md:text-4xl lg:text-5xl font-bold`;
const Description = tw.p`text-base md:text-lg lg:text-xl`;

// Best Web Hosting Solutions in Dubai Page Component
export const BestWebHostingSolutionsInDubai = () => {
  return (
    <AnimationRevealPage>
      {/* Header Section */}
      <Header from="Best Web Hosting Solutions in Dubai" />

      {/* Hero Section */}
      <MainFeature1
        subheading={
          <Subheading>Your Trusted Partner for Dubai’s Best Hosting</Subheading>
        }
        heading={
          <Heading>
            Elevate Your Online Presence with{" "}
            <span tw="text-primary-500">
              Best Web Hosting Solutions in Dubai
            </span>
          </Heading>
        }
        description={
          <Description>
            At MilaajBrandset, we specialize in delivering expert web hosting
            solutions tailored for businesses and individuals across Dubai. Our
            team of seasoned professionals ensures your website remains secure,
            fast, and scalable, providing exceptional hosting services that meet
            the unique demands of Dubai’s dynamic market while driving growth
            and success.
          </Description>
        }
        imageSrcs={[
          "https://images.unsplash.com/photo-1591439657848-9f4b9ce436b9?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2340&q=80", // Server racks in data center
          "https://images.unsplash.com/photo-1573164713988-8665fc963095?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2340&q=80", // Professional web team looking at screens
          "https://images.unsplash.com/photo-1593642532744-d377ab507dc8?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2340&q=80", // Modern laptop/computing
        ]}
        widths={["55%", "60%", "70%"]}
        heights={["45%", "60%", "35%"]}
        imageColumnStyle="height: 400px;"
        imageStyle="width: 45%; height: 60%;"
        imagePositions={[
          "left: 35%; top: 20%;",
          "right: 30%; top: 10%;",
          "left: 32%; bottom: 20%;",
        ]}
        primaryButtonText="Get Started Today"
        buttonRounded={true}
        textOnLeft={false}
      />

      {/* Key Benefits Section */}
      <Features
        subheading={<Subheading>Why Choose Us?</Subheading>}
        heading="Dubai’s Best Web Hosting Solutions"
        description="We combine performance, security, and innovation to deliver unmatched value."
        cards={[
          {
            icon: <FaTrophy color="#ff5464" size={40} />,
            title: "Award-Winning Services",
            description:
              "Recognized as Dubai’s top web hosting provider for reliability and performance.",
          },
          {
            icon: <FaServer color="#ff5464" size={40} />,
            title: "High-Performance Servers",
            description:
              "Guarantee 99.9% uptime with lightning-fast load times.",
          },
          {
            icon: <FaShieldAlt color="#ff5464" size={40} />,
            title: "Enhanced Security",
            description:
              "Protect your website and data with advanced threat mitigation.",
          },
          {
            icon: <FaUsers color="#ff5464" size={40} />,
            title: "Dubai-Centric Support",
            description:
              "Localized expertise to meet Dubai’s unique business needs.",
          },
          {
            icon: <FaHandshake color="#ff5464" size={40} />,
            title: "Client-Centric Solutions",
            description: "Tailored strategies designed to exceed your goals.",
          },
          {
            icon: <FaAward color="#ff5464" size={40} />,
            title: "Proven Track Record",
            description:
              "Delivering exceptional results for Dubai businesses of all sizes.",
          },
        ]}
      />

      {/* Our Process Section */}
      <MainFeature1
        subheading={<Subheading>How We Work</Subheading>}
        heading="Our Streamlined Hosting Process for Dubai Businesses"
        description={
          <Description>
            Our Dubai-focused process ensures quality, efficiency, and
            measurable results:
            <ul tw="mt-4 list-disc pl-6">
              <li>
                Discovery & Research: Understand your business and technical
                requirements.
              </li>
              <li>
                Planning & Strategy: Define hosting plans optimized for Dubai’s
                market.
              </li>
              <li>
                Implementation: Deploy your website on secure, scalable servers.
              </li>
              <li>
                Monitoring & Optimization: Continuously improve speed and
                performance.
              </li>
              <li>
                Ongoing Support: Provide 24/7 assistance with a local touch.
              </li>
            </ul>
          </Description>
        }
        imageSrcs={[
          "https://images.unsplash.com/photo-1563986768494-4dee2763ff3f?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2340&q=80", // Server infrastructure/network cables
          "https://images.unsplash.com/photo-1531973576160-7125cd663d86?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2340&q=80", // Team planning meeting
          "https://images.unsplash.com/photo-1581092921461-39b9e6872447?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2340&q=80", // Monitoring dashboards/analytics
        ]}
        widths={["60%", "55%", "70%"]}
        heights={["60%", "45%", "35%"]}
        imageColumnStyle="height: 400px;"
        imageStyle="width: 45%; height: 60%;"
        imagePositions={[
          "right: 30%; top: 10%;",
          "left: 35%; bottom: 20%;",
          "right: 32%; bottom: 10%;",
        ]}
        primaryButtonText="Learn More"
        buttonRounded={true}
        textOnLeft={true}
      />

      {/* Success Stories Section */}
      <Features
        subheading={<Subheading>Success Stories</Subheading>}
        heading="Results That Speak for Themselves"
        description="Here’s how our hosting solutions have empowered Dubai businesses."
        cards={[
          {
            icon: <FaAward color="#ff5464" size={40} />,
            title: "Dubai Tourism Startup",
            description:
              "Achieved 99.9% uptime during peak holiday seasons with our scalable hosting.",
          },
          {
            icon: <FaHandshake color="#ff5464" size={40} />,
            title: "Client Satisfaction",
            description:
              "98% retention rate from Dubai clients through consistent excellence.",
          },
          {
            icon: <FaTrophy color="#ff5464" size={40} />,
            title: "E-Commerce Growth",
            description:
              "Helped a Dubai-based online store reduce load times by 50%, boosting sales.",
          },
        ]}
      />

      {/* Call-to-Action Section */}
      <MainFeature1
        subheading={<Subheading>Ready for Dubai’s Best Hosting?</Subheading>}
        heading="Let’s Grow Your Dubai Business Together"
        description={
          <Description>
            Partner with Dubai’s leading web hosting experts to unlock your
            website’s full potential. Whether you’re launching a new site,
            migrating, or scaling, we’re here to help.
          </Description>
        }
        imageSrcs={[
          "https://images.unsplash.com/photo-1552581234-26160f608093?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2340&q=80", // Business handshake/partnership
          "https://images.unsplash.com/photo-1600880292203-757bb62b4baf?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2340&q=80", // UAE/Dubai cityscape
          "https://images.unsplash.com/photo-1486312338219-ce68d2c6f44d?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2340&q=80", // Person using laptop for business
        ]}
        widths={["55%", "60%", "70%"]}
        heights={["45%", "60%", "35%"]}
        imageColumnStyle="height: 400px;"
        imageStyle="width: 45%; height: 60%;"
        imagePositions={[
          "left: 35%; top: 20%;",
          "right: 30%; top: 10%;",
          "left: 32%; bottom: 20%;",
        ]}
        primaryButtonText="Contact Us Today"
        buttonRounded={true}
        textOnLeft={false}
      />
    </AnimationRevealPage>
  );
};
