import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import Header from "Components/headers/PageHeader";
import MainFeature1 from "Components/Features/TwoColWithButton.js"; // Reused component
import Features from "Components/Features/ThreeColumnPages"; // Reused component
import {
  FaChartLine,
  FaUsers,
  FaHandshake,
  FaAward,
  FaMapMarkerAlt,
  FaSearch,
  FaBullhorn,
} from "react-icons/fa";

// Styled Components
const Subheading = tw.span`uppercase tracking-wider text-sm`;
const Heading = tw.h1`text-2xl md:text-4xl lg:text-5xl font-bold`;
const Description = tw.p`text-base md:text-lg lg:text-xl`;

// Google and Social Media Marketing in Bur Dubai Page Component
export const GoogleAndSocialMediaMarketingInBurDubai = () => {
  return (
    <AnimationRevealPage>
      {/* Header Section */}
      <Header from="Google and Social Media Marketing in Bur Dubai" />

      {/* Hero Section */}
      <MainFeature1
        subheading={
          <Subheading>
            Maximize Your Reach with Integrated Marketing Solutions
          </Subheading>
        }
        heading={
          <Heading>
            Unlock Growth with{" "}
            <span tw="text-primary-500">
              The Best Google and Social Media Marketing in Bur Dubai
            </span>
          </Heading>
        }
        description={
          <Description>
            At MilaajBrandset, we specialize in delivering professional Google
            Ads and social media marketing services tailored for businesses in
            Bur Dubai. Our team combines creativity, strategy, and local
            insights to help you grow your audience, engage your community, and
            achieve measurable results through integrated campaigns.
          </Description>
        }
        imageSrcs={[
          "https://images.unsplash.com/photo-1460925895917-afdab827c52f?ixlib=rb-4.0.3&auto=format&fit=crop&w=1000&q=80", // Google Ads dashboard/analytics
          "https://images.unsplash.com/photo-1611926653458-09294b3142bf?ixlib=rb-4.0.3&auto=format&fit=crop&w=1000&q=80", // Social media platforms and marketing
          "https://images.unsplash.com/photo-1533750516457-a7f992034fec?ixlib=rb-4.0.3&auto=format&fit=crop&w=1000&q=80", // Digital marketing team collaboration
        ]}
        widths={["55%", "60%", "70%"]}
        heights={["45%", "60%", "35%"]}
        imageColumnStyle="height: 400px;"
        imageStyle="width: 45%; height: 60%;"
        imagePositions={[
          "left: 35%; top: 20%;",
          "right: 30%; top: 10%;",
          "left: 32%; bottom: 20%;",
        ]}
        primaryButtonText="Get Started Today"
        buttonRounded={true}
        textOnLeft={false}
      />

      {/* Key Benefits Section */}
      <Features
        subheading={<Subheading>Why Choose Us?</Subheading>}
        heading="Your Trusted Partner for Google and Social Media Marketing in Bur Dubai"
        description="We combine creativity, strategy, and innovation to deliver unmatched value."
        cards={[
          {
            icon: <FaSearch color="#ff5464" size={40} />,
            title: "Google Ads Expertise",
            description:
              "Drive targeted traffic and conversions with precision-targeted Google Ads campaigns.",
          },
          {
            icon: <FaBullhorn color="#ff5464" size={40} />,
            title: "Social Media Mastery",
            description:
              "Engage your audience and build brand awareness across platforms like Facebook, Instagram, LinkedIn, and TikTok.",
          },
          {
            icon: <FaChartLine color="#ff5464" size={40} />,
            title: "Data-Driven Strategies",
            description:
              "Leverage advanced analytics to optimize performance and maximize ROI.",
          },
          {
            icon: <FaMapMarkerAlt color="#ff5464" size={40} />,
            title: "Localized Expertise",
            description:
              "Understand Bur Dubai's unique market needs and cultural nuances.",
          },
          {
            icon: <FaUsers color="#ff5464" size={40} />,
            title: "Audience Engagement",
            description:
              "Build meaningful connections with your target audience through storytelling and creative content.",
          },
          {
            icon: <FaAward color="#ff5464" size={40} />,
            title: "Proven Track Record",
            description:
              "Delivering exceptional results for Bur Dubai clients since [Year].",
          },
        ]}
      />

      {/* Our Process Section */}
      <MainFeature1
        subheading={<Subheading>How We Work</Subheading>}
        heading="Our Streamlined Process for Google and Social Media Marketing in Bur Dubai"
        description={
          <Description>
            Our Bur Dubai-focused process ensures quality, efficiency, and
            measurable results:
            <ul tw="mt-4 list-disc pl-6">
              <li>
                Discovery & Research: Understand your business, audience, and
                goals.
              </li>
              <li>
                Audience Analysis: Identify your target demographic and their
                preferences.
              </li>
              <li>
                Strategy Development: Create a customized roadmap combining
                Google Ads and social media tactics.
              </li>
              <li>
                Campaign Setup: Design visually stunning ads and set up
                targeting parameters for both Google and social media platforms.
              </li>
              <li>
                Campaign Launch: Promote your brand across Google Search,
                Display Network, YouTube, Facebook, Instagram, LinkedIn, and
                TikTok targeting Bur Dubai audiences.
              </li>
              <li>
                Optimization & Analysis: Continuously refine strategies based on
                performance metrics and feedback.
              </li>
            </ul>
          </Description>
        }
        imageSrcs={[
          "https://images.unsplash.com/photo-1563986768609-322da13575f3?ixlib=rb-4.0.3&auto=format&fit=crop&w=1000&q=80", // Google search/ads concept
          "https://images.unsplash.com/photo-1611162618071-b39a2ec055fb?ixlib=rb-4.0.3&auto=format&fit=crop&w=1000&q=80", // Social media campaign strategy
          "https://images.unsplash.com/photo-1551288049-bebda4e38f71?ixlib=rb-4.0.3&auto=format&fit=crop&w=1000&q=80", // Marketing analytics and reporting
        ]}
        widths={["60%", "55%", "70%"]}
        heights={["60%", "45%", "35%"]}
        imageColumnStyle="height: 400px;"
        imageStyle="width: 45%; height: 60%;"
        imagePositions={[
          "right: 30%; top: 10%;",
          "left: 35%; bottom: 20%;",
          "right: 32%; bottom: 10%;",
        ]}
        primaryButtonText="Learn More"
        buttonRounded={true}
        textOnLeft={true}
      />

      {/* Success Stories Section */}
      <Features
        subheading={<Subheading>Success Stories</Subheading>}
        heading="Results That Speak for Themselves"
        description="Here’s how our Google and social media marketing services have empowered Bur Dubai businesses."
        cards={[
          {
            icon: <FaAward color="#ff5464" size={40} />,
            title: "Campaign ROI",
            description:
              "Helped an e-commerce store in Bur Dubai increase sales by 250% through Google Shopping Ads and Instagram promotions.",
          },
          {
            icon: <FaHandshake color="#ff5464" size={40} />,
            title: "Client Satisfaction",
            description:
              "98% client retention rate through consistent excellence and measurable results.",
          },
          {
            icon: <FaSearch color="#ff5464" size={40} />,
            title: "Google Ads Success",
            description:
              "Generated 1,000+ leads for a startup in Bur Dubai using targeted Google Search Ads.",
          },
        ]}
      />

      {/* Call-to-Action Section */}
      <MainFeature1
        subheading={<Subheading>Ready to Amplify Your Brand?</Subheading>}
        heading="Let’s Elevate Your Business Together in Bur Dubai"
        description={
          <Description>
            Partner with Bur Dubai’s leading Google and social media marketing
            experts to unlock your business’s full potential. Whether you’re
            launching a campaign, driving traffic, or boosting engagement, we’re
            here to help.
          </Description>
        }
        imageSrcs={[
          "https://images.unsplash.com/photo-1534224039826-c7a0eda0e6b3?ixlib=rb-4.0.3&auto=format&fit=crop&w=1000&q=80", // Dubai business skyline
          "https://images.unsplash.com/photo-1562577309-4932fdd64cd1?ixlib=rb-4.0.3&auto=format&fit=crop&w=1000&q=80", // Digital marketing success concept
          "https://images.unsplash.com/photo-1600880292089-90a7e086ee0c?ixlib=rb-4.0.3&auto=format&fit=crop&w=1000&q=80", // Business partnership/client meeting
        ]}
        widths={["55%", "60%", "70%"]}
        heights={["45%", "60%", "35%"]}
        imageColumnStyle="height: 400px;"
        imageStyle="width: 45%; height: 60%;"
        imagePositions={[
          "left: 35%; top: 20%;",
          "right: 30%; top: 10%;",
          "left: 32%; bottom: 20%;",
        ]}
        primaryButtonText="Contact Us Today"
        buttonRounded={true}
        textOnLeft={false}
      />
    </AnimationRevealPage>
  );
};
