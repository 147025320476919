import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import Header from "Components/headers/PageHeader";
import MainFeature1 from "Components/Features/TwoColWithButton.js"; // Reused component
import Features from "Components/Features/ThreeColumnPages"; // Reused component
import {
  FaBullhorn,
  FaChartBar,
  FaUsers,
  FaHandshake,
  FaAward,
  FaLightbulb,
} from "react-icons/fa";

// Styled Components
const Subheading = tw.span`uppercase tracking-wider text-sm`;
const Heading = tw.h1`text-2xl md:text-4xl lg:text-5xl font-bold`;
const Description = tw.p`text-base md:text-lg lg:text-xl`;

// Social Media Marketers Page Component
export const SocialMediaMarketersInBurDubai = () => {
  return (
    <AnimationRevealPage>
      {/* Header Section */}
      <Header from="Social Media Marketers in Bur Dubai" />

      {/* Hero Section */}
      <MainFeature1
        subheading={
          <Subheading>Your Trusted Social Media Marketers</Subheading>
        }
        heading={
          <Heading>
            Elevate Your Brand with{" "}
            <span tw="text-primary-500">
              Social Media Marketers in Bur Dubai
            </span>
          </Heading>
        }
        description={
          <Description>
            At MilaajBrandset, our skilled social media marketers craft tailored
            strategies to grow your brand, engage your audience, and drive
            measurable results. As a startup, we bring fresh ideas, data-driven
            insights, and a client-centric approach to every project.
          </Description>
        }
        imageSrcs={[
          "https://images.unsplash.com/photo-1460925895917-afdab827c52f?ixlib=rb-4.0.3", // Modern analytics dashboard
          "https://images.unsplash.com/photo-1542744173-8e7e53415bb0?ixlib=rb-4.0.3", // Professional marketing team meeting
          "https://images.unsplash.com/photo-1432888622747-4eb9a8efeb07?ixlib=rb-4.0.3", // Creative workspace
        ]}
        widths={["55%", "60%", "70%"]}
        heights={["45%", "60%", "35%"]}
        imageColumnStyle="height: 400px;"
        imageStyle="width: 45%; height: 60%;"
        imagePositions={[
          "left: 35%; top: 20%;",
          "right: 30%; top: 10%;",
          "left: 32%; bottom: 20%;",
        ]}
        primaryButtonText="Get Started Today"
        buttonRounded={true}
        textOnLeft={false}
      />

      {/* Key Benefits Section */}
      <Features
        subheading={
          <Subheading>Why Choose Our Social Media Marketers?</Subheading>
        }
        heading="Your Go-To Social Media Experts"
        description="Our marketers combine innovation, expertise, and measurable results to deliver exceptional value."
        cards={[
          {
            icon: <FaBullhorn color="#ff5464" size={40} />,
            title: "Targeted Campaigns",
            description:
              "Our marketers create campaigns that reach your ideal audience across platforms.",
          },
          {
            icon: <FaChartBar color="#ff5464" size={40} />,
            title: "Data-Driven Strategies",
            description:
              "Backed by analytics to ensure maximum ROI and campaign performance.",
          },
          {
            icon: <FaUsers color="#ff5464" size={40} />,
            title: "Audience Engagement",
            description:
              "We foster meaningful connections with your audience through engaging content.",
          },
          {
            icon: <FaHandshake color="#ff5464" size={40} />,
            title: "Client-Centric Approach",
            description:
              "Tailored strategies designed to meet your unique goals.",
          },
          {
            icon: <FaAward color="#ff5464" size={40} />,
            title: "Proven Expertise",
            description:
              "Years of experience managing successful social media campaigns.",
          },
          {
            icon: <FaLightbulb color="#ff5464" size={40} />,
            title: "Creative Excellence",
            description:
              "Fresh perspectives and innovative ideas for every campaign.",
          },
        ]}
      />

      {/* Our Process Section */}
      <MainFeature1
        subheading={<Subheading>How Our Marketers Work</Subheading>}
        heading="Our Streamlined Social Media Marketing Process"
        description={
          <Description>
            Our process ensures quality, efficiency, and measurable results:
            <ul tw="mt-4 list-disc pl-6">
              <li>
                Discovery & Research: Understand your business and target
                audience.
              </li>
              <li>
                Strategy Development: Create tailored plans for your goals.
              </li>
              <li>
                Content Creation: Design engaging, high-quality posts and
                campaigns.
              </li>
              <li>
                Performance Tracking: Monitor results and optimize strategies.
              </li>
            </ul>
          </Description>
        }
        imageSrcs={[
          "https://images.unsplash.com/photo-1551288049-bebda4e38f71?ixlib=rb-4.0.3", // Research and analytics
          "https://images.unsplash.com/photo-1558403194-611308249627?ixlib=rb-4.0.3", // Strategy planning
          "https://images.unsplash.com/photo-1553877522-43269d4ea984?ixlib=rb-4.0.3", // Content creation team
        ]}
        widths={["60%", "55%", "70%"]}
        heights={["60%", "45%", "35%"]}
        imageColumnStyle="height: 400px;"
        imageStyle="width: 45%; height: 60%;"
        imagePositions={[
          "right: 30%; top: 10%;",
          "left: 35%; bottom: 20%;",
          "right: 32%; bottom: 10%;",
        ]}
        primaryButtonText="Learn More"
        buttonRounded={true}
        textOnLeft={true}
      />

      {/* Success Stories Section */}
      <Features
        subheading={<Subheading>Success Stories</Subheading>}
        heading="Results That Speak for Themselves"
        description="Here’s how our social media marketers have helped businesses grow."
        cards={[
          {
            icon: <FaAward color="#ff5464" size={40} />,
            title: "Startup Growth",
            description:
              "A local startup saw a 500% increase in social media engagement within six months.",
          },
          {
            icon: <FaHandshake color="#ff5464" size={40} />,
            title: "Client Satisfaction",
            description:
              "Achieved a 95% client retention rate through consistent results.",
          },
          {
            icon: <FaLightbulb color="#ff5464" size={40} />,
            title: "Creative Solutions",
            description:
              "Boosted a retail client’s sales by 70% with targeted social media campaigns.",
          },
        ]}
      />

      {/* Call-to-Action Section */}
      <MainFeature1
        subheading={<Subheading>Ready to Elevate Your Brand?</Subheading>}
        heading="Let’s Drive Results Together"
        description={
          <Description>
            Partner with our expert social media marketers in Bur Dubai to
            unlock the full potential of your strategy. Whether you’re looking
            to boost your online presence or engage your audience effectively,
            we’re here to help.
          </Description>
        }
        imageSrcs={[
          "https://images.unsplash.com/photo-1600880292203-757bb62b4baf?ixlib=rb-4.0.3", // Successful team collaboration
          "https://images.unsplash.com/photo-1512486130939-2c4f79935e4f?ixlib=rb-4.0.3", // Modern workspace
          "https://images.unsplash.com/photo-1557425493-6f90ae4659fc?ixlib=rb-4.0.3", // Handshake closeup
        ]}
        widths={["55%", "60%", "70%"]}
        heights={["45%", "60%", "35%"]}
        imageColumnStyle="height: 400px;"
        imageStyle="width: 45%; height: 60%;"
        imagePositions={[
          "left: 35%; top: 20%;",
          "right: 30%; top: 10%;",
          "left: 32%; bottom: 20%;",
        ]}
        primaryButtonText="Contact Us Today"
        buttonRounded={true}
        textOnLeft={false}
      />
    </AnimationRevealPage>
  );
};
