import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import Header from "Components/headers/PageHeader";
import MainFeature1 from "Components/Features/TwoColWithButton.js"; // Reused component
import Features from "Components/Features/ThreeColumnPages"; // Reused component
import {
  FaChartLine,
  FaUsers,
  FaHandshake,
  FaAward,
  FaMapMarkerAlt,
  FaBullhorn,
} from "react-icons/fa";

// Styled Components
const Subheading = tw.span`uppercase tracking-wider text-sm`;
const Heading = tw.h1`text-2xl md:text-4xl lg:text-5xl font-bold`;
const Description = tw.p`text-base md:text-lg lg:text-xl`;

// Digital Marketing Services in Al Barsha Page Component
export const DigitalMarketingServicesInAlBarsha = () => {
  return (
    <AnimationRevealPage>
      {/* Header Section */}
      <Header from="Digital Marketing Services in Al Barsha" />

      {/* Hero Section */}
      <MainFeature1
        subheading={<Subheading>Maximize Your Reach with Digital Marketing</Subheading>}
        heading={
          <Heading>
            Unlock Your Potential with{" "}
            <span tw="text-primary-500">The Best Digital Marketing Services in Al Barsha</span>
          </Heading>
        }
        description={
          <Description>
            At MilaajBrandset, we specialize in delivering professional digital marketing services tailored for businesses in Al Barsha, Dubai. Our team combines creativity, strategy, and local insights to help you grow your audience, engage your community, and achieve measurable results through targeted campaigns.
          </Description>
        }
        imageSrcs={[
            "https://images.unsplash.com/photo-1611926653458-09294b3142bf?auto=format&fit=crop&q=80", // Digital marketing dashboard
            "https://images.unsplash.com/photo-1557838923-2985c318be48?auto=format&fit=crop&q=80", // Social media marketing
            "https://images.unsplash.com/photo-1533750516457-a7f992034fec?auto=format&fit=crop&q=80", // Dubai cityscape
          ]}
        widths={["55%", "60%", "70%"]}
        heights={["45%", "60%", "35%"]}
        imageColumnStyle="height: 400px;"
        imageStyle="width: 45%; height: 60%;"
        imagePositions={[
          "left: 35%; top: 20%;",
          "right: 30%; top: 10%;",
          "left: 32%; bottom: 20%;",
        ]}
        primaryButtonText="Get Started Today"
        buttonRounded={true}
        textOnLeft={false}
      />

      {/* Key Benefits Section */}
      <Features
        subheading={<Subheading>Why Choose Us?</Subheading>}
        heading="Your Trusted Partner for Digital Marketing in Al Barsha"
        description="We combine creativity, strategy, and innovation to deliver unmatched value."
        cards={[
          {
            icon: <FaBullhorn color="#ff5464" size={40} />,
            title: "Comprehensive Campaigns",
            description:
              "From SEO to PPC and social media ads, we cover all aspects of digital marketing.",
          },
          {
            icon: <FaChartLine color="#ff5464" size={40} />,
            title: "Data-Driven Strategies",
            description:
              "Leverage advanced analytics to optimize performance and maximize ROI.",
          },
          {
            icon: <FaMapMarkerAlt color="#ff5464" size={40} />,
            title: "Localized Expertise",
            description:
              "Understand Al Barsha's unique market needs and cultural nuances.",
          },
          {
            icon: <FaUsers color="#ff5464" size={40} />,
            title: "Audience Engagement",
            description:
              "Create meaningful connections with your target audience through engaging content.",
          },
          {
            icon: <FaHandshake color="#ff5464" size={40} />,
            title: "Client-Centric Approach",
            description:
              "Collaborate closely with you to ensure alignment with your vision.",
          },
          {
            icon: <FaAward color="#ff5464" size={40} />,
            title: "Proven Track Record",
            description:
              "Delivering exceptional results for Al Barsha clients since [Year].",
          },
        ]}
      />

      {/* Our Process Section */}
      <MainFeature1
        subheading={<Subheading>How We Work</Subheading>}
        heading="Our Streamlined Process for Digital Marketing in Al Barsha"
        description={
          <Description>
            Our Al Barsha-focused process ensures quality, efficiency, and measurable results:
            <ul tw="mt-4 list-disc pl-6">
              <li>Discovery & Research: Understand your business, audience, and goals.</li>
              <li>Audience Analysis: Identify your target demographic and their preferences.</li>
              <li>Strategy Development: Create a customized roadmap for growth.</li>
              <li>Creative Execution: Design visually stunning and engaging campaigns (SEO, PPC, Social Media Ads).</li>
              <li>Campaign Launch: Promote your brand across platforms like Google, Facebook, Instagram, LinkedIn, and TikTok.</li>
              <li>Optimization & Analysis: Continuously refine strategies based on performance metrics and feedback.</li>
            </ul>
          </Description>
        }
        imageSrcs={[
            "https://images.unsplash.com/photo-1460925895917-afdab827c52f?auto=format&fit=crop&q=80", // Marketing strategy planning
            "https://images.unsplash.com/photo-1432888622747-4eb9a8efeb07?auto=format&fit=crop&q=80", // Data analysis
            "https://images.unsplash.com/photo-1552581234-26160f608093?auto=format&fit=crop&q=80", // Team collaboration
          ]}
        widths={["60%", "55%", "70%"]}
        heights={["60%", "45%", "35%"]}
        imageColumnStyle="height: 400px;"
        imageStyle="width: 45%; height: 60%;"
        imagePositions={[
          "right: 30%; top: 10%;",
          "left: 35%; bottom: 20%;",
          "right: 32%; bottom: 10%;",
        ]}
        primaryButtonText="Learn More"
        buttonRounded={true}
        textOnLeft={true}
      />

      {/* Success Stories Section */}
      <Features
        subheading={<Subheading>Success Stories</Subheading>}
        heading="Results That Speak for Themselves"
        description="Here’s how our digital marketing services have empowered Al Barsha businesses."
        cards={[
          {
            icon: <FaAward color="#ff5464" size={40} />,
            title: "Ad Performance",
            description:
              "Helped a luxury spa in Al Barsha increase bookings by 250% through Instagram influencer collaborations and Facebook ads.",
          },
          {
            icon: <FaHandshake color="#ff5464" size={40} />,
            title: "Client Satisfaction",
            description:
              "98% client retention rate through consistent excellence and measurable results.",
          },
          {
            icon: <FaBullhorn color="#ff5464" size={40} />,
            title: "Creative Excellence",
            description:
              "Produced over 300 high-performing creatives for Al Barsha businesses in [Year].",
          },
        ]}
      />

      {/* Call-to-Action Section */}
      <MainFeature1
        subheading={<Subheading>Ready to Amplify Your Brand?</Subheading>}
        heading="Let’s Elevate Your Business Together in Al Barsha"
        description={
          <Description>
            Partner with Al Barsha’s leading digital marketing experts to unlock your brand’s full potential. Whether you’re launching a campaign, driving traffic, or boosting engagement, we’re here to help.
          </Description>
        }
        imageSrcs={[
            "https://images.unsplash.com/photo-1551288049-bebda4e38f71?auto=format&fit=crop&q=80", // Business handshake
            "https://images.unsplash.com/photo-1512428559087-560fa5ceab42?auto=format&fit=crop&q=80", // Growth chart
            "https://images.unsplash.com/photo-1534972195531-d756b9bfa9f2?auto=format&fit=crop&q=80", // Dubai business district
          ]}
        widths={["55%", "60%", "70%"]}
        heights={["45%", "60%", "35%"]}
        imageColumnStyle="height: 400px;"
        imageStyle="width: 45%; height: 60%;"
        imagePositions={[
          "left: 35%; top: 20%;",
          "right: 30%; top: 10%;",
          "left: 32%; bottom: 20%;",
        ]}
        primaryButtonText="Contact Us Today"
        buttonRounded={true}
        textOnLeft={false}
      />
    </AnimationRevealPage>
  );
};

