import { GoogleAdsManagementDifc } from "Pages/SeoPages/Advertising/GoogleAdsManagementDifc";
import { PPCAdvertisingServicesInBurDubai } from "Pages/SeoPages/Advertising/PPCAdvertisingServicesInBurDubai";
import { AffordableContentMarketingInAlBarsha } from "Pages/SeoPages/ContentMarketing/AffordableContentMarketingInAlBarsha";
import { BlogWritingServicesInAlBarsha } from "Pages/SeoPages/ContentMarketing/BlogWritingServicesInAlBarsha";
import { ContentCreationServicesInBurDubai } from "Pages/SeoPages/ContentMarketing/ContentCreationServicesInBurDubai";
import { ContentMarketingAgencyInDIFC } from "Pages/SeoPages/ContentMarketing/ContentMarketingAgencyInDIFC";
import { Helmet } from "react-helmet";

export const AffordableContentMarketingSEO = () => (
    <>
      <Helmet>
        <title>Affordable Content Marketing Companies in Al Barsha | MilaajBrandset</title>
        <meta
          name="description"
          content="MilaajBrandset is one of the most affordable content marketing companies in Al Barsha, offering blog writing, video production, and social media content. Elevate your business with our innovative strategies."
        />
        <meta
          name="keywords"
          content="affordable content marketing Al Barsha, blog writing Al Barsha, video production Al Barsha, social media content Al Barsha"
        />
        <meta
          property="og:title"
          content="Affordable Content Marketing Companies in Al Barsha | MilaajBrandset"
        />
        <meta
          property="og:description"
          content="MilaajBrandset is one of the most affordable content marketing companies in Al Barsha, offering blog writing, video production, and social media content. Elevate your business with our innovative strategies."
        />
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "ProfessionalService",
              "name": "MilaajBrandset",
              "image": "https://milaajbrandset.com/favicon.ico",
              "description": "MilaajBrandset provides affordable content marketing services in Al Barsha, helping businesses grow with engaging and cost-effective content strategies.",
              "address": {
                "@type": "PostalAddress",
                "streetAddress": "Al Barsha",
                "addressLocality": "Dubai",
                "postalCode": "XXXXX",
                "addressCountry": "AE"
              },
              "geo": {
                "@type": "GeoCoordinates",
                "latitude": 25.2631,
                "longitude": 55.2975
              }
            }
          `}
        </script>
      </Helmet>
      <AffordableContentMarketingInAlBarsha />
    </>
  );
  
  export const ContentCreationServicesSEO = () => (
    <>
      <Helmet>
        <title>Content Creation Services in Bur Dubai | MilaajBrandset</title>
        <meta
          name="description"
          content="MilaajBrandset offers professional content creation services in Bur Dubai, including blog writing, video production, and social media content. Elevate your business with our innovative strategies."
        />
        <meta
          name="keywords"
          content="content creation services Bur Dubai, blog writing Bur Dubai, video production Bur Dubai, social media content Bur Dubai"
        />
        <meta
          property="og:title"
          content="Content Creation Services in Bur Dubai | MilaajBrandset"
        />
        <meta
          property="og:description"
          content="MilaajBrandset offers professional content creation services in Bur Dubai, including blog writing, video production, and social media content. Elevate your business with our innovative strategies."
        />
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "ProfessionalService",
              "name": "MilaajBrandset",
              "image": "https://milaajbrandset.com/favicon.ico",
              "description": "MilaajBrandset provides content creation services in Bur Dubai, helping businesses grow with engaging and impactful content strategies.",
              "address": {
                "@type": "PostalAddress",
                "streetAddress": "Bur Dubai",
                "addressLocality": "Dubai",
                "postalCode": "XXXXX",
                "addressCountry": "AE"
              },
              "geo": {
                "@type": "GeoCoordinates",
                "latitude": 25.2631,
                "longitude": 55.2975
              }
            }
          `}
        </script>
      </Helmet>
      <ContentCreationServicesInBurDubai />
    </>
  );
  
  export const ContentMarketingAgencySEO = () => (
    <>
      <Helmet>
        <title>Content Marketing Agency in DIFC | MilaajBrandset</title>
        <meta
          name="description"
          content="MilaajBrandset is a leading content marketing agency in DIFC, offering blog writing, video production, and social media storytelling. Elevate your brand with our innovative strategies."
        />
        <meta
          name="keywords"
          content="content marketing agency DIFC, blog writing DIFC, video production DIFC, social media content DIFC"
        />
        <meta
          property="og:title"
          content="Content Marketing Agency in DIFC | MilaajBrandset"
        />
        <meta
          property="og:description"
          content="MilaajBrandset is a leading content marketing agency in DIFC, offering blog writing, video production, and social media storytelling. Elevate your brand with our innovative strategies."
        />
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "ProfessionalService",
              "name": "MilaajBrandset",
              "image": "https://milaajbrandset.com/favicon.ico",
              "description": "MilaajBrandset provides content marketing services in DIFC, helping businesses grow with engaging and impactful content strategies.",
              "address": {
                "@type": "PostalAddress",
                "streetAddress": "DIFC",
                "addressLocality": "Dubai",
                "postalCode": "XXXXX",
                "addressCountry": "AE"
              },
              "geo": {
                "@type": "GeoCoordinates",
                "latitude": 25.2631,
                "longitude": 55.2975
              }
            }
          `}
        </script>
      </Helmet>
      <ContentMarketingAgencyInDIFC />
    </>
  );
  

  export const ContentMarketingAgencyDIFCSEO = () => (
    <>
      <Helmet>
        <title>Content Marketing Agency in DIFC | MilaajBrandset</title>
        <meta
          name="description"
          content="MilaajBrandset is a leading content marketing agency in DIFC, offering blog writing, video production, and social media storytelling. Elevate your brand with our innovative strategies."
        />
        <meta
          name="keywords"
          content="content marketing agency DIFC, blog writing DIFC, video production DIFC, social media content DIFC"
        />
        <meta
          property="og:title"
          content="Content Marketing Agency in DIFC | MilaajBrandset"
        />
        <meta
          property="og:description"
          content="MilaajBrandset is a leading content marketing agency in DIFC, offering blog writing, video production, and social media storytelling. Elevate your brand with our innovative strategies."
        />
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "ProfessionalService",
              "name": "MilaajBrandset",
              "image": "https://milaajbrandset.com/favicon.ico",
              "description": "MilaajBrandset provides content marketing services in DIFC, helping businesses grow with engaging and impactful content strategies.",
              "address": {
                "@type": "PostalAddress",
                "streetAddress": "DIFC",
                "addressLocality": "Dubai",
                "postalCode": "XXXXX",
                "addressCountry": "AE"
              },
              "geo": {
                "@type": "GeoCoordinates",
                "latitude": 25.2631,
                "longitude": 55.2975
              }
            }
          `}
        </script>
      </Helmet>
      <ContentMarketingAgencyInDIFC />
    </>
  );
  
  export const BlogWritingServicesSEO = () => (
    <>
      <Helmet>
        <title>Blog Writing Services in Al Barsha | MilaajBrandset</title>
        <meta
          name="description"
          content="MilaajBrandset offers professional blog writing services in Al Barsha, including SEO-optimized content, thought leadership articles, and engaging storytelling. Elevate your brand with our innovative strategies."
        />
        <meta
          name="keywords"
          content="blog writing services Al Barsha, SEO blog writing Al Barsha, content creation Al Barsha, thought leadership blogs Al Barsha"
        />
        <meta
          property="og:title"
          content="Blog Writing Services in Al Barsha | MilaajBrandset"
        />
        <meta
          property="og:description"
          content="MilaajBrandset offers professional blog writing services in Al Barsha, including SEO-optimized content, thought leadership articles, and engaging storytelling. Elevate your brand with our innovative strategies."
        />
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "ProfessionalService",
              "name": "MilaajBrandset",
              "image": "https://milaajbrandset.com/favicon.ico",
              "description": "MilaajBrandset provides blog writing services in Al Barsha, helping businesses grow with engaging and SEO-optimized content strategies.",
              "address": {
                "@type": "PostalAddress",
                "streetAddress": "Al Barsha",
                "addressLocality": "Dubai",
                "postalCode": "XXXXX",
                "addressCountry": "AE"
              },
              "geo": {
                "@type": "GeoCoordinates",
                "latitude": 25.2631,
                "longitude": 55.2975
              }
            }
          `}
        </script>
      </Helmet>
      <BlogWritingServicesInAlBarsha />
    </>
  );
  
  
  export const PPCAdvertisingServicesSEO = () => (
    <>
      <Helmet>
        <title>PPC Advertising Services in Bur Dubai | MilaajBrandset</title>
        <meta
          name="description"
          content="MilaajBrandset offers professional PPC advertising services in Bur Dubai, including Google Ads, social media ads, and retargeting campaigns. Drive traffic, generate leads, and boost conversions with our data-driven strategies."
        />
        <meta
          name="keywords"
          content="PPC advertising Bur Dubai, Google Ads Bur Dubai, social media ads Bur Dubai, retargeting campaigns Bur Dubai"
        />
        <meta
          property="og:title"
          content="PPC Advertising Services in Bur Dubai | MilaajBrandset"
        />
        <meta
          property="og:description"
          content="MilaajBrandset offers professional PPC advertising services in Bur Dubai, including Google Ads, social media ads, and retargeting campaigns. Drive traffic, generate leads, and boost conversions with our data-driven strategies."
        />
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "ProfessionalService",
              "name": "MilaajBrandset",
              "image": "https://milaajbrandset.com/favicon.ico",
              "description": "MilaajBrandset provides PPC advertising services in Bur Dubai, helping businesses grow with targeted and data-driven ad campaigns.",
              "address": {
                "@type": "PostalAddress",
                "streetAddress": "Bur Dubai",
                "addressLocality": "Dubai",
                "postalCode": "XXXXX",
                "addressCountry": "AE"
              },
              "geo": {
                "@type": "GeoCoordinates",
                "latitude": 25.2631,
                "longitude": 55.2975
              }
            }
          `}
        </script>
      </Helmet>
      <PPCAdvertisingServicesInBurDubai />
    </>
  );
  

  
export const GoogleAdsManagementDifcPage = () => (
  <>
    <Helmet>
      <title>Google Ads Management in DIFC | MilaajBrandset</title>
      <meta
        name="description"
        content="MilaajBrandset is a Google Ads management agency in DIFC, dedicated to helping businesses grow and succeed online. From campaign setup to ongoing optimization, we provide expert Google Ads management services tailored to your specific needs in DIFC."
      />
      <meta
        name="keywords"
        content="google ads management in DIFC, DIFC google ads, google ads setup DIFC, google ads optimization DIFC"
      />
      <meta
        property="og:title"
        content="Google Ads Management in DIFC | MilaajBrandset"
      />
      <meta
        property="og:description"
        content="MilaajBrandset is a Google Ads management agency in DIFC, dedicated to helping businesses grow and succeed online. From campaign setup to ongoing optimization, we provide expert Google Ads management services tailored to your specific needs in DIFC."
      />
      <script type="application/ld+json">
        {`
          {
            "@context": "https://schema.org",
            "@type": "ProfessionalService",
            "name": "MilaajBrandset",
            "image": "https://milaajbrandset.com/favicon.ico",
            "description": "MilaajBrandset is a Google Ads management agency in DIFC, dedicated to helping businesses grow and succeed online.",
            "address": {
              "@type": "PostalAddress",
              "streetAddress": "DIFC",
              "addressLocality": "Dubai",
              "postalCode": "XXXXX",
              "addressCountry": "AE"
            },
            "geo": {
              "@type": "GeoCoordinates",
              "latitude": 25.2631,
              "longitude": 55.2975
            }
          }
        `}
      </script>
    </Helmet>
    <GoogleAdsManagementDifc />
  </>
);

