import React, { useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Helmet } from "react-helmet";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import "swiper/swiper-bundle.css"; // Ensure you have the right CSS for Swiper
import "./App.css";

import Hero from "./Components/Hero/Hero.js";
import Features from "./Components/Features/DashedBorderSixFeatures.js";
import MainFeature from "./Components/Features/TwoColSingleFeatureWithStats2.js";
import MainFeature2 from "./Components/Features/TwoColWithTwoFeaturesAndButtons.js";
import FAQ from "./Components/Faqs/SingleCol.js";
import ContactUsForm from "./Components/Forms/TwoColContactUsWithIllustration.js";
import ContactUs from "Pages/ContactUs.js";
import Footer from "./Components/Footer/MiniCenteredFooter.js";
import Aboutus from "Pages/Aboutus.js";
import customerSupportIllustrationSrc from "images/customer-support-illustration.webp";
import BlogIndex from "Pages/BlogIndex.js";
import DigitalMarketing from "Pages/DigitalMarketing.js";
import WebDevelopment from "Pages/WebDevelopment.js";
import ECommerce from "Pages/E_Commerce.js";
import Services from "Pages/Services.js";
import Faq from "Pages/Faq.js";
import SingleBlogPost from "Pages/BlogPostSingle.js";
import SeoPage from "Pages/seoPage.js";
import SocialMediaMarketing from "Pages/SocialMediaMarketing.js";
import SocialMediaBranding from "Pages/SocialMediaBranding.js";

import CustomWebDevelopment from "Pages/CustomWebDevelopment.js";

import UI_UX from "Pages/UI_UX.js";
import MultiStepForm from "Components/MultiStepForm/MultiStepForm";
import BoostYourBrand from "Pages/BoostYourBrand";
import TwoColumnList from "Components/Features/TwoColumnList";
import CustomCursor from "Components/Cursor/CustomCursor";
import Modal from "Components/Modal/Modal";
import BrandIdentityDevelopment from "Pages/BrandIdentityDevelopment";
import PaidMediaCampaigns from "Pages/PaidMediaCampaign";
import VideoAdProduction from "Pages/VideoAdProduction";
import {
  AdvertisingAgencyPage,
  AffordableAdvertisingPage,
  BestAdvertisingAgencyPage,
  CreativeAdvertisingAgencyPage,
  DigitalAdvertisingAgencyPage,
  ExcellentAdvertisingAgencyPage,
  ExpertAdvertisingAgencyPage,
  FullServiceAdvertisingAgencyPage,
  InnovativeAdvertisingAgencyPage,
  LeadingAdvertisingAgencyPage,
  LocalAdvertisingAgencyPage,
  No1AdvertisingAgencyPage,
  ProfessionalAdvertisingAgencyPage,
  ReliableAdvertisingAgencyPage,
  TopAdvertisingAgencyPage,
  TopRatedAdvertisingAgencyPage,
} from "helmets/AdvertisingAgency/bur_dubai";
import {
  AdvertisingAgencyDubaiPage,
  AffordableAdvertisingAgencyDubaiPage,
  BestAdvertisingAgencyDubaiPage,
  CreativeAdvertisingAgencyDubaiPage,
  ExcellentAdvertisingAgencyDubaiPage,
  ExpertAdvertisingAgencyDubaiPage,
  No1AdvertisingAgencyDubaiPage,
  ReliableAdvertisingAgencyDubaiPage,
  TopAdvertisingAgencyDubaiPage,
} from "helmets/AdvertisingAgency/dubai";
import {
  BrandingAgencyBurDubaiPage,
  BrandingAgencyDubaiPage,
} from "helmets/Branding/dubai";
import {
  AffordableSeoServicesBurDubaiPage,
  DifcSeoExpertsSmallBusinessesPage,
  ExpertSEOSEO,
  LocalSeoServicesAlBarshaPage,
  SeoAgencyBurDubaiPage,
  SeoAgencyDubaiPage,
} from "helmets/SEO";
import {
  BestWebManagementServicesDifcPage,
  BestWebsiteDesignCompanyBurDubaiPage,
  CustomWebsiteDevelopmentAlBarshaPage,
  DifcProfessionalWebDesignersPage,
  ResponsiveWebsiteDesignBurDubaiPage,
  WebDevelopmentAgencyBurDubaiPage,
  WebDevelopmentAgencyDubaiPage,
  WebHostingSolutionsBurDubaiPage,
  WebManagementServicesDifcPage,
  WebsiteDesignAndDevelopmentSEO,
  WebsiteMaintenanceAlBarshaPage,
} from "helmets/WebDevelopment";
import {
  AffordableMarketingServicesSEO,
  CreativeBrandingAgencyNearDifcPage,
  DigitalMarketingAgencyNearDifcPage,
  FullServiceDigitalAgencySEO,
  MarketingCompanyAlBarshaPage,
  ReliableMarketingAgenciesSEO,
  TopDigitalMarketingAgencyAlBarshaPage,
} from "helmets/DigitalMarketing";
import {
  AffordableBrandingAgencyDubaiSEO,
  AffordableBrandingAgencySEO,
  AffordableBrandingServicesSEO,
  BestBrandingAgencySEO,
  BestBrandingServicesBurDubaiPage,
  BestCreativeBrandingAgencyDubaiSEO,
  BestCreativeBrandingAgencySEO,
  BrandDevelopmentAgencyDubaiSEO,
  BrandDevelopmentAgencySEO,
  BrandIdentityAgencyDubaiSEO,
  BrandIdentityAgencySEO,
  BrandingAndDesignAgencyDubaiSEO,
  BrandingAndDesignAgencySEO,
  BrandingAndDigitalSolutionsSEO,
  BusinessBrandingAgencyDubaiSEO,
  BusinessBrandingAgencySEO,
  CorporateBrandingAgencyDubaiSEO,
  CorporateBrandingAgencySEO,
  CreativeBrandingAgencyBurDubaiPage,
  CreativeBrandingAgencyDubaiSEO,
  CreativeBrandingAgencySEO,
  DigitalBrandingAgencyDubaiSEO,
  DigitalBrandingAgencySEO,
  DubaiBasedBrandingAgencySEO,
  FullServiceBrandingAgencyDubaiSEO,
  FullServiceBrandingAgencySEO,
  InnovativeBrandingAgencyDubaiSEO,
  InnovativeBrandingAgencySEO,
  LeadingBrandingAgencyDubaiSEO,
  LeadingBrandingAgencySEO,
  LogoAndBrandingAgencyDubaiSEO,
  LogoAndBrandingAgencySEO,
  LogoDesignAndBrandingDubaiSEO,
  LogoDesignAndBrandingSEO,
  LuxuryBrandingAgencyDubaiSEO,
  LuxuryBrandingAgencySEO,
  MarketingAndBrandingAgencyDubaiSEO,
  MarketingAndBrandingAgencySEO,
  ProfessionalBrandingAgenciesSEO,
  ProfessionalBrandingAgencyDubaiSEO,
  ProfessionalBrandingAgencySEO,
  TopBrandingAgencyBurDubaiPage,
  TopBrandingAgencySEO,
  TopRatedBrandingAgencyDubaiSEO,
  TopRatedBrandingAgencySEO,
} from "helmets/Branding";
import { CreativeAgencyAlBarshaPage } from "helmets/VideoAdProduction";
import { BestAdvertisingAgencyBurDubaiPage } from "helmets/AdvertisingAgency";
import {
  BestSocialMediaMarketingTeamSEO,
  DIFCInstagramFacebookAdsSEO,
  ProfessionalSocialMediaManagementSEO,
  SocialMediaAdvertisingServicesSEO,
  SocialMediaManagersSEO,
  SocialMediaMarketersSEO,
  SocialMediaMarketingSEO,
  SocialMediaMarketingServicesSEO,
  SocialMediaMarketingTeamSEO,
} from "helmets/PaidAdvertising";
import {
  AffordableContentMarketingSEO,
  BlogWritingServicesSEO,
  ContentCreationServicesSEO,
  ContentMarketingAgencyDIFCSEO,
  GoogleAdsManagementDifcPage,
  PPCAdvertisingServicesSEO,
} from "helmets/ContentMarketing";
import { UXDesignCompanySEO } from "helmets/UIUXDesign";
import {
  AffordableBrandingAgencyInAlBarshaSEO,
  AffordableBrandingServicesInAlBarshaSEO,
  AlBarshaBasedBrandingAgencySEO,
  BestBrandingAgencyInAlBarshaSEO,
  BestCreativeBrandingAgencyInAlBarshaSEO,
  BrandDevelopmentAgencyInAlBarshaSEO,
  BrandIdentityAgencyInAlBarshaSEO,
  BrandingAndDesignAgencyInAlBarshaSEO,
  BusinessBrandingAgencyInAlBarshaSEO,
  CorporateBrandingAgencyInAlBarshaSEO,
  CreativeBrandingAgencyInAlBarshaSEO,
  DigitalBrandingAgencyInAlBarshaSEO,
  FullServiceBrandingAgencyInAlBarshaSEO,
  InnovativeBrandingAgencyInAlBarshaSEO,
  LeadingBrandingAgencyInAlBarshaSEO,
  LogoAndBrandingAgencyInAlBarshaSEO,
  LogoDesignAndBrandingInAlBarshaSEO,
  LuxuryBrandingAgencyInAlBarshaSEO,
  MarketingAndBrandingAgencyInAlBarshaSEO,
  ProfessionalBrandingAgencyInAlBarshaSEO,
  TopBrandingAgencyInAlBarshaSEO,
  TopRatedBrandingAgencyInAlBarshaSEO,
} from "helmets/Branding/albarsha";
import {
  AffordableBrandingAgencyInDIFCSEO,
  AffordableBrandingServicesInDIFCSEO,
  BestBrandingAgencyInDIFCSEO,
  BestCreativeBrandingAgencyInDIFCSEO,
  BrandDevelopmentAgencyInDIFCSEO,
  BrandIdentityAgencyInDIFCSEO,
  BrandingAndDesignAgencyInDIFCSEO,
  BusinessBrandingAgencyInDIFCSEO,
  CorporateBrandingAgencyInDIFCSEO,
  CreativeBrandingAgencyInDIFCSEO,
  DIFCBasedBrandingAgencySEO,
  DigitalBrandingAgencyInDIFCSEO,
  FullServiceBrandingAgencyInDIFCSEO,
  InnovativeBrandingAgencyInDIFCSEO,
  LeadingBrandingAgencyInDIFCSEO,
  LogoAndBrandingAgencyInDIFCSEO,
  LogoDesignAndBrandingInDIFCSEO,
  LuxuryBrandingAgencyInDIFCSEO,
  MarketingAndBrandingAgencyInDIFCSEO,
  ProfessionalBrandingAgencyInDIFCSEO,
  TopBrandingAgencyInDIFCSEO,
  TopRatedBrandingAgencyInDIFCSEO,
} from "helmets/Branding/difc";
import {
  AffordableSEOCompanyInBurDubaiSEO,
  BestDigitalMarketingAndSEOInBurDubaiSEO,
  BestLocalSEOAgencyInBurDubaiSEO,
  BestSEOCompanyInBurDubaiSEO,
  DigitalMarketingAndSEOCompanyInBurDubaiSEO,
  EcommerceSEOCompanyInBurDubaiSEO,
  ExpertSEOConsultantsInBurDubaiSEO,
  GoogleRankingSEOCompanyInBurDubaiSEO,
  LeadingSEOCompanyInBurDubaiSEO,
  LocalSEOCompanyInBurDubaiSEO,
  OnPageAndOffPageSEOInBurDubaiSEO,
  ProfessionalSEOCompanyInBurDubaiSEO,
  SearchEngineOptimizationCompanyInBurDubaiSEO,
  SEOAndPPCAgencyInBurDubaiSEO,
  SEOAndSocialMediaMarketingInBurDubaiSEO,
  SEOContentWritingServicesInBurDubaiSEO,
  SEOOptimizationServicesInBurDubaiSEO,
  SEOServicesInBurDubaiSEO,
  SEOSpecialistsInBurDubaiSEO,
  SmallBusinessSEOCompanyInBurDubaiSEO,
  TopSEOCompanyInBurDubaiSEO,
  WebsiteSEOCompanyInBurDubaiSEO,
  WhiteHatSEOCompanyInBurDubaiSEO,
} from "helmets/SEO/bur_dubai";
import {
  AffordableSEOCompanyInDubaiSEO,
  BestSEOCompanyInDubaiSEO,
  DigitalMarketingAndSEOCompanyInDubaiSEO,
  LeadingSEOCompanyInDubaiSEO,
  LocalSEOCompanyInDubaiSEO,
  ProfessionalSEOCompanyInDubaiSEO,
  SEOAgencyInDubaiSEO,
  SEOServicesInDubaiSEO,
  TopSEOCompanyInDubaiSEO,
  ExpertSEOConsultantsInDubaiSEO,
  SEOSpecialistsInDubaiSEO,
  WebsiteSEOCompanyInDubaiSEO,
  EcommerceSEOCompanyInDubaiSEO,
  SEOOptimizationServicesInDubaiSEO,
  GoogleRankingSEOCompanyInDubaiSEO,
  SEOAndSocialMediaMarketingInDubaiSEO,
  BestLocalSEOAgencyInDubaiSEO,
  GuaranteedSEOResultsInDubaiSEO,
  SearchEngineOptimizationCompanyInDubaiSEO,
  SEOContentWritingServicesInDubaiSEO,
  SEOAndPPCAgencyInDubaiSEO,
  WhiteHatSEOCompanyInDubaiSEO,
  BestDigitalMarketingAndSEOInDubaiSEO,
  OnPageAndOffPageSEOInDubaiSEO,
  SmallBusinessSEOCompanyInDubaiSEO,
} from "helmets/SEO/dubai";
import {
  AffordableSEOCompanyInAlBarshaSEO,
  BestDigitalMarketingAndSEOInAlBarshaSEO,
  BestLocalSEOAgencyInAlBarshaSEO,
  BestSEOCompanyInAlBarshaSEO,
  DigitalMarketingAndSEOCompanyInAlBarshaSEO,
  EcommerceSEOCompanyInAlBarshaSEO,
  ExpertSEOConsultantsInAlBarshaSEO,
  GoogleRankingSEOCompanyInAlBarshaSEO,
  GuaranteedSEOResultsInAlBarshaSEO,
  LeadingSEOCompanyInAlBarshaSEO,
  LocalSEOCompanyInAlBarshaSEO,
  OnPageAndOffPageSEOInAlBarshaSEO,
  ProfessionalSEOCompanyInAlBarshaSEO,
  SearchEngineOptimizationCompanyInAlBarshaSEO,
  SEOAgencyInAlBarshaSEO,
  SEOAndPPCAgencyInAlBarshaSEO,
  SEOAndSocialMediaMarketingInAlBarshaSEO,
  SEOContentWritingServicesInAlBarshaSEO,
  SEOOptimizationServicesInAlBarshaSEO,
  SEOServicesInAlBarshaSEO,
  SEOSpecialistsInAlBarshaSEO,
  SmallBusinessSEOCompanyInAlBarshaSEO,
  TopSEOCompanyInAlBarshaSEO,
  WebsiteSEOCompanyInAlBarshaSEO,
  WhiteHatSEOCompanyInAlBarshaSEO,
} from "helmets/SEO/al_barsha";
import {
  AffordableSEOCompanyInDIFCSEO,
  BestDigitalMarketingAndSEOInDIFCSEO,
  BestLocalSEOAgencyInDIFCSEO,
  BestSEOCompanyInDIFCSEO,
  DigitalMarketingAndSEOCompanyInDIFCSEO,
  EcommerceSEOCompanyInDIFCSEO,
  ExpertSEOConsultantsInDIFCSEO,
  GoogleRankingSEOCompanyInDIFCSEO,
  GuaranteedSEOResultsInDIFCSEO,
  LeadingSEOCompanyInDIFCSEO,
  LocalSEOCompanyInDIFCSEO,
  OnPageAndOffPageSEOInDIFCSEO,
  ProfessionalSEOCompanyInDIFCSEO,
  SearchEngineOptimizationCompanyInDIFCSEO,
  SEOAgencyInDIFCSEO,
  SEOAndPPCAgencyInDIFCSEO,
  SEOAndSocialMediaMarketingInDIFCSEO,
  SEOContentWritingServicesInDIFCSEO,
  SEOOptimizationServicesInDIFCSEO,
  SEOServicesInDIFCSEO,
  SEOSpecialistsInDIFCSEO,
  SmallBusinessSEOCompanyInDIFCSEO,
  TopSEOCompanyInDIFCSEO,
  WebsiteSEOCompanyInDIFCSEO,
  WhiteHatSEOCompanyInDIFCSEO,
} from "helmets/SEO/Difc";
import {
  AffordableWebsiteDesignCompanyInBurDubaiSEO,
  BestWebDevelopmentCompanyInBurDubaiSEO,
  BusinessWebsiteDesignInBurDubaiSEO,
  CorporateWebsiteDesignInBurDubaiSEO,
  CreativeWebsiteDesignCompanyInBurDubaiSEO,
  CustomWebsiteDevelopmentInBurDubaiSEO,
  CustomWebSolutionsInBurDubaiSEO,
  EcommerceWebsiteDesignCompanyInBurDubaiSEO,
  ExpertWebDesignersInBurDubaiSEO,
  FastAndSecureWebsiteDevelopmentInBurDubaiSEO,
  FullServiceWebDesignAgencyInBurDubaiSEO,
  MobileFriendlyWebsiteDevelopmentInBurDubaiSEO,
  ProfessionalWebsiteDesignersInBurDubaiSEO,
  ResponsiveWebsiteDesignInBurDubaiSEO,
  SEOFriendlyWebsiteDesignInBurDubaiSEO,
  SmallBusinessWebsiteDesignInBurDubaiSEO,
  TopWebsiteDesignCompanyInBurDubaiSEO,
  UIUXDesignCompanyInBurDubaiSEO,
  UserFriendlyWebsiteDesignServicesInBurDubaiSEO,
  WebsiteDesignAndDevelopmentCompanyInBurDubaiSEO,
  WebsiteMaintenanceAndSupportInBurDubaiSEO,
  WebsiteRedesignServicesInBurDubaiSEO,
  WordPressWebsiteDesignInBurDubaiSEO,
} from "helmets/WebDevelopment/bur_dubai";
import {
  AffordableWebsiteDesignCompanyInDubaiSEO,
  BestWebsiteDesignCompanyInDubaiSEO,
  BusinessWebsiteDesignInDubaiSEO,
  CorporateWebsiteDesignInDubaiSEO,
  CreativeWebsiteDesignCompanyInDubaiSEO,
  CustomWebsiteDevelopmentInDubaiSEO,
  EcommerceWebsiteDesignCompanyInDubaiSEO,
  ExpertWebDesignersInDubaiSEO,
  FastAndSecureWebsiteDevelopmentInDubaiSEO,
  ProfessionalWebsiteDesignersInDubaiSEO,
  ResponsiveWebsiteDesignInDubaiSEO,
  SEOFriendlyWebsiteDesignInDubaiSEO,
  SmallBusinessWebsiteDesignInDubaiSEO,
  TopWebsiteDesignCompanyInDubaiSEO,
  UserFriendlyWebsiteDesignServicesInDubaiSEO,
  WebDevelopmentAgencyInDubaiSEO,
  WebsiteDesignAndDevelopmentCompanyInDubaiSEO,
  WebsiteMaintenanceAndSupportInDubaiSEO,
  WebsiteRedesignServicesInDubaiSEO,
  WordPressWebsiteDesignInDubaiSEO,
} from "helmets/WebDevelopment/dubai";
import {
  AffordableWebsiteDesignCompanyInAlBarshaSEO,
  BestWebsiteDesignCompanyInAlBarshaSEO,
  BusinessWebsiteDesignInAlBarshaSEO,
  CorporateWebsiteDesignInAlBarshaSEO,
  CreativeWebsiteDesignCompanyInAlBarshaSEO,
  EcommerceWebsiteDesignCompanyInAlBarshaSEO,
  ExpertWebDesignersInAlBarshaSEO,
  FastAndSecureWebsiteDevelopmentInAlBarshaSEO,
  ProfessionalWebsiteDesignersInAlBarshaSEO,
  ResponsiveWebsiteDesignInAlBarshaSEO,
  SEOFriendlyWebsiteDesignInAlBarshaSEO,
  SmallBusinessWebsiteDesignInAlBarshaSEO,
  TopWebsiteDesignCompanyInAlBarshaSEO,
  UserFriendlyWebsiteDesignServicesInAlBarshaSEO,
  WebDevelopmentAgencyInAlBarshaSEO,
  WebsiteDesignAndDevelopmentCompanyInAlBarshaSEO,
  WebsiteMaintenanceAndSupportInAlBarshaSEO,
  WebsiteRedesignServicesInAlBarshaSEO,
  WordPressWebsiteDesignInAlBarshaSEO,
} from "helmets/WebDevelopment/al_barsha";
import {
  AffordableWebsiteDesignCompanyInDIFCSEO,
  BestWebsiteDesignCompanyInDIFCSEO,
  BusinessWebsiteDesignInDIFCSEO,
  CorporateWebsiteDesignInDIFCSEO,
  CreativeWebsiteDesignCompanyInDIFCSEO,
  CustomWebsiteDevelopmentInDIFCSEO,
  EcommerceWebsiteDesignCompanyInDIFCSEO,
  ExpertWebDesignersInDIFCSEO,
  FastAndSecureWebsiteDevelopmentInDIFCSEO,
  ProfessionalWebsiteDesignersInDIFCSEO,
  ResponsiveWebsiteDesignInDIFCSEO,
  SEOFriendlyWebsiteDesignInDIFCSEO,
  SmallBusinessWebsiteDesignInDIFCSEO,
  TopWebsiteDesignCompanyInDIFCSEO,
  UserFriendlyWebsiteDesignServicesInDIFCSEO,
  WebDevelopmentAgencyInDIFCSEO,
  WebsiteDesignAndDevelopmentCompanyInDIFCSEO,
  WebsiteMaintenanceAndSupportInDIFCSEO,
  WebsiteRedesignServicesInDIFCSEO,
  WordPressWebsiteDesignInDIFCSEO,
} from "helmets/WebDevelopment/difc";
import {
  AffordableWebHostingServicesInBurDubaiSEO,
  BestWebHostingSolutionsInBurDubaiSEO,
  BusinessWebHostingSolutionsInBurDubaiSEO,
  CloudWebHostingInBurDubaiSEO,
  DedicatedServerHostingInBurDubaiSEO,
  DomainAndWebHostingInBurDubaiSEO,
  EcommerceWebHostingInBurDubaiSEO,
  FastAndSecureWebHostingInBurDubaiSEO,
  LocalWebHostingCompanyInBurDubaiSEO,
  ManagedWebHostingServicesInBurDubaiSEO,
  ProfessionalWebHostingCompanyInBurDubaiSEO,
  ReliableWebHostingServicesInBurDubaiSEO,
  SecureWebsiteHostingInBurDubaiSEO,
  SharedWebHostingServicesInBurDubaiSEO,
  TopWebHostingProviderInBurDubaiSEO,
  UAEWebHostingProviderInBurDubaiSEO,
  VPSHostingProviderInBurDubaiSEO,
  WebHostingWithEmailServicesInBurDubaiSEO,
  WebsiteMaintenanceAndHostingInBurDubaiSEO,
  WordPressHostingInBurDubaiSEO,
} from "helmets/WebManagement/bur_dubai";
import {
  AffordableWebHostingServicesInDubaiSEO,
  BestWebHostingSolutionsInDubaiSEO,
  BusinessWebHostingSolutionsInDubaiSEO,
  CloudWebHostingInDubaiSEO,
  DedicatedServerHostingInDubaiSEO,
  DomainAndWebHostingInDubaiSEO,
  EcommerceWebHostingInDubaiSEO,
  FastAndSecureWebHostingInDubaiSEO,
  LocalWebHostingCompanyInDubaiSEO,
  ManagedWebHostingServicesInDubaiSEO,
  ProfessionalWebHostingCompanyInDubaiSEO,
  ReliableWebHostingServicesInDubaiSEO,
  SecureWebsiteHostingInDubaiSEO,
  SharedWebHostingServicesInDubaiSEO,
  TopWebHostingProviderInDubaiSEO,
  UAEBasedWebHostingProviderInDubaiSEO,
  VPSHostingProviderInDubaiSEO,
  WebHostingWithEmailServicesInDubaiSEO,
  WebsiteMaintenanceAndHostingInDubaiSEO,
  WordPressHostingInDubaiSEO,
} from "helmets/WebManagement/dubai";
import {
  AffordableWebHostingServicesInAlBarshaSEO,
  BestWebHostingSolutionsInAlBarshaSEO,
  BusinessWebHostingSolutionsInAlBarshaSEO,
  CloudWebHostingInAlBarshaSEO,
  DedicatedServerHostingInAlBarshaSEO,
  DomainAndWebHostingInAlBarshaSEO,
  EcommerceWebHostingInAlBarshaSEO,
  FastAndSecureWebHostingInAlBarshaSEO,
  LocalWebHostingCompanyInAlBarshaSEO,
  ManagedWebHostingServicesInAlBarshaSEO,
  ProfessionalWebHostingCompanyInAlBarshaSEO,
  ReliableWebHostingServicesInAlBarshaSEO,
  SecureWebsiteHostingInAlBarshaSEO,
  SharedWebHostingServicesInAlBarshaSEO,
  TopWebHostingProviderInAlBarshaSEO,
  UAEBasedWebHostingProviderInAlBarshaSEO,
  VPSHostingProviderInAlBarshaSEO,
  WebHostingWithEmailServicesInAlBarshaSEO,
  WebsiteMaintenanceAndHostingInAlBarshaSEO,
  WordPressHostingInAlBarshaSEO,
} from "helmets/WebManagement/al_barsha";
import {
  AffordableWebHostingServicesInDIFCSEO,
  BestWebHostingSolutionsInDIFCSEO,
  BusinessWebHostingSolutionsInDIFCSEO,
  CloudWebHostingInDIFCSEO,
  DedicatedServerHostingInDIFCSEO,
  DomainAndWebHostingInDIFCSEO,
  EcommerceWebHostingInDIFCSEO,
  FastAndSecureWebHostingInDIFCSEO,
  LocalWebHostingCompanyInDIFCSEO,
  ManagedWebHostingServicesInDIFCSEO,
  ProfessionalWebHostingCompanyInDIFCSEO,
  ReliableWebHostingServicesInDIFCSEO,
  SecureWebsiteHostingInDIFCSEO,
  SharedWebHostingServicesInDIFCSEO,
  TopWebHostingProviderInDIFCSEO,
  UAEBasedWebHostingProviderInDIFCSEO,
  VPSHostingProviderInDIFCSEO,
  WebHostingWithEmailServicesInDIFCSEO,
  WebsiteMaintenanceAndHostingInDIFCSEO,
  WordPressHostingInDIFCSEO,
} from "helmets/WebManagement/difc";
import {
  BestContentCreationServicesInDubaiSEO,
  BlogAndArticleWritingInDubaiSEO,
  ContentCreationAgencyInDubaiSEO,
  CorporateContentSolutionsInDubaiSEO,
  CreativeContentCreatorsInDubaiSEO,
  ProfessionalContentMarketingInDubaiSEO,
  SEOContentWritingInDubaiSEO,
  SocialMediaContentCreationInDubaiSEO,
  VideoProductionServicesInDubaiSEO,
  WebsiteContentWritingInDubaiSEO,
} from "helmets/ContentMarketing/dubai";
import {
  AffordableContentMarketingInBurDubaiSEO,
  BestContentCreationServicesInBurDubaiSEO,
  BlogWritingServicesInBurDubaiSEO,
  ContentStrategyAgencyInBurDubaiSEO,
  DigitalContentSolutionsInBurDubaiSEO,
  ProfessionalContentCreatorsInBurDubaiSEO,
  SEOContentWritingInBurDubaiSEO,
  SocialMediaContentCreationInBurDubaiSEO,
  VideoContentCreationServicesInBurDubaiSEO,
  WebsiteContentWritingInBurDubaiSEO,
} from "helmets/ContentMarketing/bur_dubai";
import {
  AffordableDigitalContentSolutionsInAlBarshaSEO,
  BestContentCreationServicesInAlBarshaSEO,
  BrandStorytellingServicesInAlBarshaSEO,
  ContentWritingAgencyInAlBarshaSEO,
  MarketingCopywritingInAlBarshaSEO,
  ProfessionalContentMarketingInAlBarshaSEO,
  SEOContentWritingInAlBarshaSEO,
  SocialMediaContentManagementInAlBarshaSEO,
  VideoProductionAndEditingInAlBarshaSEO,
  WebsiteAndBlogContentWritingInAlBarshaSEO,
} from "helmets/ContentMarketing/al_barsha";
import {
  BestContentCreationServicesInDIFCSEO,
  BrandStorytellingAndStrategyInDIFCSEO,
  CorporateContentSolutionsInDIFCSEO,
  CreativeContentAgencyInDIFCSEO,
  HighQualityDigitalContentInDIFCSEO,
  ProfessionalContentCreatorsInDIFCSEO,
  SEOContentMarketingInDIFCSEO,
  SocialMediaGrowthServicesInDIFCSEO,
  VideoAndSocialMediaContentInDIFCSEO,
  WebsiteAndBlogContentWritingInDIFCSEO,
} from "helmets/ContentMarketing/difc";
import {
  AffordableSocialMediaAdvertisingInBurDubaiSEO,
  BestFacebookAdAgencyInBurDubaiSEO,
  BestSocialMediaAdvertisingInBurDubaiSEO,
  DigitalMarketingAndSocialMediaAdsInBurDubaiSEO,
  FacebookAdvertisingServicesInBurDubaiSEO,
  GoogleAndSocialMediaAdsInBurDubaiSEO,
  InfluencerMarketingAndAdsInBurDubaiSEO,
  InstagramAdsManagementInBurDubaiSEO,
  LinkedInAdvertisingServicesInBurDubaiSEO,
  LocalSocialMediaAdsInBurDubaiSEO,
  PaidSocialMediaCampaignsInBurDubaiSEO,
  PerformanceMarketingAgencyInBurDubaiSEO,
  SocialMediaAdAgencyInBurDubaiSEO,
  SocialMediaAdCreativesInBurDubaiSEO,
  SocialMediaBrandingAndAdsInBurDubaiSEO,
  SocialMediaMarketingAgencyInBurDubaiSEO,
  SocialMediaPPCAgencyInBurDubaiSEO,
  SocialMediaPromotionInBurDubaiSEO,
  TikTokAdsManagementInBurDubaiSEO,
  YouTubeVideoAdvertisingInBurDubaiSEO,
} from "helmets/PaidAdvertising/bur_dubai";
import {
  BestFacebookAdStrategiesInDubaiSEO,
  BestPPCAndSocialMediaAdsInDubaiSEO,
  BestSocialMediaAdvertisingInDubaiSEO,
  DigitalMarketingAndAdvertisingInDubaiSEO,
  ECommerceSocialMediaAdvertisingInDubaiSEO,
  FacebookAdvertisingAgencyInDubaiSEO,
  GoogleAndFacebookAdsAgencyInDubaiSEO,
  InfluencerMarketingAgencyInDubaiSEO,
  InstagramAdManagementInDubaiSEO,
  LinkedInMarketingServicesInDubaiSEO,
  LocalBusinessAdvertisingOnSocialMediaInDubaiSEO,
  PaidSocialMediaCampaignsInDubaiSEO,
  SocialMediaContentAndAdsInDubaiSEO,
  SocialMediaGrowthServicesInDubaiSEO,
  SocialMediaLeadGenerationInDubaiSEO,
  SocialMediaMarketingAgencyInDubaiSEO,
  SocialMediaPPCAgencyInDubaiSEO,
  SocialMediaPromotionForBusinessesInDubaiSEO,
  TikTokAdvertisingInDubaiSEO,
  YouTubeAndVideoAdsInDubaiSEO,
} from "helmets/PaidAdvertising/dubai";
import {
  AffordableFacebookAdServicesInAlBarshaSEO,
  BestROISocialMediaAdsInAlBarshaSEO,
  BestSocialMediaAdvertisingInAlBarshaSEO,
  BestSocialMediaMarketingAgencyInAlBarshaSEO,
  DigitalMarketingServicesInAlBarshaSEO,
  EcommerceSocialMediaAdsInAlBarshaSEO,
  FacebookAndInstagramLeadGenerationInAlBarshaSEO,
  GoogleAndSocialMediaPPCAdsInAlBarshaSEO,
  InstagramAndFacebookAdsInAlBarshaSEO,
  LinkedInAdsForBusinessesInAlBarshaSEO,
  LocalBusinessAdvertisingInAlBarshaSEO,
  PerformanceMarketingAgencyInAlBarshaSEO,
  SocialMediaAdAgencyInAlBarshaSEO,
  SocialMediaAnalyticsAndAdvertisingInAlBarshaSEO,
  SocialMediaBrandingAndPaidAdsInAlBarshaSEO,
  SocialMediaGrowthServicesInAlBarshaSEO,
  SocialMediaRetargetingAdsInAlBarshaSEO,
  TikTokAndYouTubeAdsInAlBarshaSEO,
  VideoMarketingAndSocialAdsInAlBarshaSEO,
  YouTubeVideoPromotionsInAlBarshaSEO,
} from "helmets/PaidAdvertising/al_barsha";
import {
  BestDigitalAdvertisingCompanyInDIFCSEO,
  BestPerformingSocialAdsInDIFCSEO,
  BestSocialMediaAdvertisingInDIFCSEO,
  FacebookAdManagementInDIFCSEO,
  FacebookAndInstagramAdsForStartupsInDIFCSEO,
  GoogleAndSocialMediaAdSpecialistsInDIFCSEO,
  HighROISocialMediaAdsInDIFCSEO,
  InfluencerMarketingAgencyInDIFCSEO,
  LinkedInAdsForProfessionalsInDIFCSEO,
  LocalBusinessDigitalAdsInDIFCSEO,
  PaidSocialMediaCampaignsForBusinessesInDIFCSEO,
  SocialMediaBrandingAndDigitalStrategyInDIFCSEO,
  SocialMediaLeadGenerationExpertsInDIFCSEO,
  SocialMediaMarketingAgencyInDIFCSEO,
  SocialMediaPPCCampaignsInDIFCSEO,
  SocialMediaPromotionsInDIFCSEO,
  SocialMediaVideoMarketingInDIFCSEO,
  TikTokAdvertisingForBrandsInDIFCSEO,
  YouTubeAndInstagramAdsInDIFCSEO,
} from "helmets/PaidAdvertising/difc";
import {
  AffordableSocialMediaMarketingInBurDubaiSEO,
  BestSocialMediaMarketingInBurDubaiSEO,
  DigitalMarketingAndSocialMediaServicesInBurDubaiSEO,
  EcommerceSocialMediaMarketingInBurDubaiSEO,
  FacebookMarketingAgencyInBurDubaiSEO,
  GoogleAndSocialMediaMarketingInBurDubaiSEO,
  InfluencerMarketingAgencyInBurDubaiSEO,
  InstagramMarketingServicesInBurDubaiSEO,
  LinkedInMarketingExpertsInBurDubaiSEO,
  LocalBusinessSocialMediaMarketingInBurDubaiSEO,
  PaidSocialMediaMarketingInBurDubaiSEO,
  SocialMediaAdvertisingAndPromotionsInBurDubaiSEO,
  SocialMediaBrandingServicesInBurDubaiSEO,
  SocialMediaGrowthServicesInBurDubaiSEO,
  SocialMediaManagementCompanyInBurDubaiSEO,
  SocialMediaStrategyExpertsInBurDubaiSEO,
  TikTokMarketingAgencyInBurDubaiSEO,
  YouTubeAndVideoMarketingInBurDubaiSEO,
} from "helmets/SocialMediaManagement/bur_dubai";
import {
  AffordableSocialMediaManagementInDubaiSEO,
  BestSocialMediaConsultantsInDubaiSEO,
  BestSocialMediaMarketingInDubaiSEO,
  BusinessSocialMediaStrategiesInDubaiSEO,
  DigitalMarketingAndSocialMediaAgencyInDubaiSEO,
  EcommerceSocialMediaMarketingInDubaiSEO,
  GoogleAndSocialMediaAdsInDubaiSEO,
  InstagramAndFacebookMarketingInDubaiSEO,
  LinkedInMarketingSolutionsInDubaiSEO,
  PerformanceMarketingAndSocialMediaInDubaiSEO,
  SocialMediaAdvertisingInDubaiSEO,
  SocialMediaEngagementStrategiesInDubaiSEO,
  SocialMediaReputationManagementInDubaiSEO,
  SocialMediaSEOOptimizationInDubaiSEO,
  TikTokMarketingForBusinessesInDubaiSEO,
  TopRatedSocialMediaMarketingAgencyInDubaiSEO,
  YouTubeAndVideoMarketingServicesInDubaiSEO,
} from "helmets/SocialMediaManagement/dubai";
import {
  AffordableSocialMediaManagementInAlBarshaSEO,
  BestEcommerceSocialMediaMarketingInAlBarshaSEO,
  BestSocialMediaMarketingInAlBarshaSEO,
  BusinessSocialMediaSolutionsInAlBarshaSEO,
  DigitalAndSocialMediaMarketingInAlBarshaSEO,
  FacebookAndInstagramMarketingInAlBarshaSEO,
  GoogleAndSocialMediaGrowthStrategiesInAlBarshaSEO,
  InfluencerMarketingAgencyInAlBarshaSEO,
  LinkedInAdvertisingAndMarketingInAlBarshaSEO,
  LocalBusinessSocialMediaManagementInAlBarshaSEO,
  PaidAdsAndSocialMediaPromotionsInAlBarshaSEO,
  PerformanceMarketingThroughSocialMediaInAlBarshaSEO,
  SocialMediaAdvertisingExpertsInAlBarshaSEO,
  SocialMediaBrandingAndStrategyInAlBarshaSEO,
  SocialMediaContentMarketingInAlBarshaSEO,
  SocialMediaGrowthAgencyInAlBarshaSEO,
  SocialMediaMarketingAgencyInAlBarshaSEO,
  SocialMediaSEOAndAnalyticsInAlBarshaSEO,
  TikTokAndYouTubeMarketingInAlBarshaSEO,
  VideoMarketingForSocialMediaInAlBarshaSEO,
} from "helmets/SocialMediaManagement/al_barsha";

const HomePage = () => {
  const [selectedService, setSelectedService] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleServiceClick = (service) => {
    setSelectedService(service);
    setIsModalOpen(true); // Open the modal
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedService(null);
  };

  return (
    <>
      <Helmet>
        <title>Home | MilaajBrandset</title>
        <meta
          name="description"
          content="Welcome to MilaajBrandset. We provide top-notch digital solutions including web development, digital marketing, and more to help your business succeed online."
        />
        <meta
          name="keywords"
          content="MilaajBrandset, digital solutions, web development, digital marketing, SEO services, online business solutions"
        />
        <meta property="og:title" content="Home | MilaajBrandset" />
        <meta
          property="og:description"
          content="Welcome to MilaajBrandset. We provide top-notch digital solutions including web development, digital marketing, and more to help your business succeed online."
        />
      </Helmet>
      <Hero />
      <MainFeature />
      {/* Assigning id="features" to Features section */}
      <Features id="features" onServiceClick={handleServiceClick} />
      <MainFeature2 />
      <TwoColumnList features isBlack={true} black={false} />
      {/* <Blog /> */}
      <FAQ
        imageSrc={customerSupportIllustrationSrc}
        imageContain={true}
        imageShadow={false}
        subheading="FAQs"
        heading={
          <>
            Do you have <span tw="text-primary-500">Questions?</span>
          </>
        }
      />
      <ContactUsForm />

      {/* Render the Modal */}
      {isModalOpen && (
        <Modal onClose={handleCloseModal}>
          <TwoColumnList black={true} selectedService={selectedService} />
        </Modal>
      )}
    </>
  );
};

const AboutPage = () => (
  <>
    <Helmet>
      <title>About Us | MilaajBrandset</title>
      <meta
        name="description"
        content="Learn more about MilaajBrandset, our values, and our mission to deliver exceptional digital solutions tailored to your business needs."
      />
      <meta
        name="keywords"
        content="MilaajBrandset, about us, company information, digital solutions, business values"
      />
      <meta property="og:title" content="About Us | MilaajBrandset" />
      <meta
        property="og:description"
        content="Learn more about MilaajBrandset, our values, and our mission to deliver exceptional digital solutions tailored to your business needs."
      />
    </Helmet>
    <Aboutus />
  </>
);

const ContactPage = () => (
  <>
    <Helmet>
      <title>Contact Us | MilaajBrandset</title>
      <meta
        name="description"
        content="Get in touch with MilaajBrandset. Whether you have questions about our services or need support, we're here to help you."
      />
      <meta
        name="keywords"
        content="MilaajBrandset, contact us, customer support, digital solutions, business inquiries"
      />
      <meta property="og:title" content="Contact Us | MilaajBrandset" />
      <meta
        property="og:description"
        content="Get in touch with MilaajBrandset. Whether you have questions about our services or need support, we're here to help you."
      />
    </Helmet>
    <ContactUs />
  </>
);

const ServicesPage = () => (
  <>
    <Helmet>
      <title>Services | MilaajBrandset</title>
      <meta
        name="description"
        content="Explore the wide range of digital services offered by MilaajBrandset, including web development, SEO, and more to grow your business."
      />
      <meta
        name="keywords"
        content="MilaajBrandset, services, digital marketing, web development, SEO, business growth"
      />
      <meta property="og:title" content="Services | MilaajBrandset" />
      <meta
        property="og:description"
        content="Explore the wide range of digital services offered by MilaajBrandset, including web development, SEO, and more to grow your business."
      />
    </Helmet>
    <Services />
  </>
);

const MultiFormService = () => (
  <>
    <Helmet>
      <title>Services | MilaajBrandset</title>
      <meta
        name="description"
        content="Explore our comprehensive services, including branding, web design, digital marketing, and more, tailored to help your business grow."
      />
      <meta
        name="keywords"
        content="MilaajBrandset services, branding, web design, digital marketing, e-commerce solutions"
      />
      <meta property="og:title" content="Services | MilaajBrandset" />
      <meta
        property="og:description"
        content="Explore our services like branding, web design, digital marketing, and e-commerce solutions tailored for your business growth."
      />
    </Helmet>

    <MultiStepForm />
  </>
);

const MultiFormContact = () => (
  <>
    <Helmet>
      <title>Contact Us | MilaajBrandset</title>
      <meta
        name="description"
        content="Get in touch with MilaajBrandset to discuss your project or learn more about our services. We’re here to help!"
      />
      <meta
        name="keywords"
        content="MilaajBrandset contact, get in touch, business inquiries, customer support"
      />
      <meta property="og:title" content="Contact Us | MilaajBrandset" />
      <meta
        property="og:description"
        content="Reach out to MilaajBrandset to discuss your project or learn more about our services."
      />
    </Helmet>
    <MultiFormContact />
  </>
);

const BlogPage = () => (
  <>
    <Helmet>
      <title>Blog | MilaajBrandset</title>
      <meta
        name="description"
        content="Read our latest blog posts on digital marketing, web development, and other topics related to enhancing your business online presence."
      />
      <meta
        name="keywords"
        content="MilaajBrandset, blog, digital marketing, web development, business insights, industry news"
      />
      <meta property="og:title" content="Blog | MilaajBrandset" />
      <meta
        property="og:description"
        content="Read our latest blog posts on digital marketing, web development, and other topics related to enhancing your business online presence."
      />
    </Helmet>
    <BlogIndex />
  </>
);

const DigitalMarketingPage = () => (
  <>
    <Helmet>
      <title>Digital Marketing | MilaajBrandset</title>
      <meta
        name="description"
        content="Discover MilaajBrandset's digital marketing services designed to enhance your online presence and drive business growth."
      />
      <meta
        name="keywords"
        content="MilaajBrandset, digital marketing, SEO, online advertising, business growth"
      />
      <meta property="og:title" content="Digital Marketing | MilaajBrandset" />
      <meta
        property="og:description"
        content="Discover MilaajBrandset's digital marketing services designed to enhance your online presence and drive business growth."
      />
    </Helmet>
    <DigitalMarketing />
  </>
);

const WebDevelopmentPage = () => (
  <>
    <Helmet>
      <title>Web Development | MilaajBrandset</title>
      <meta
        name="description"
        content="Our web development services offer cutting-edge solutions to build and optimize your website for better performance and user experience."
      />
      <meta
        name="keywords"
        content="MilaajBrandset, web development, website design, user experience, digital solutions"
      />
      <meta property="og:title" content="Web Development | MilaajBrandset" />
      <meta
        property="og:description"
        content="Our web development services offer cutting-edge solutions to build and optimize your website for better performance and user experience."
      />
    </Helmet>
    <WebDevelopment />
  </>
);

const ECommercePage = () => (
  <>
    <Helmet>
      <title>E-Commerce | MilaajBrandset</title>
      <meta
        name="description"
        content="Boost your online sales with MilaajBrandset's e-commerce solutions designed to enhance your store's performance and customer experience."
      />
      <meta
        name="keywords"
        content="MilaajBrandset, e-commerce solutions, online sales, store optimization, digital retail"
      />
      <meta property="og:title" content="E-Commerce | MilaajBrandset" />
      <meta
        property="og:description"
        content="Boost your online sales with MilaajBrandset's e-commerce solutions designed to enhance your store's performance and customer experience."
      />
    </Helmet>
    <ECommerce />
  </>
);

const BrandIdentityDevelopmentPage = () => (
  <>
    <Helmet>
      <title>Brand Identity Development | MilaajBrandset</title>
      <meta
        name="description"
        content="Craft a distinctive brand identity that tells your story and resonates with your audience. MilaajBrandset focuses on creating a cohesive brand image that reflects your values and stands out in the market."
      />
      <meta
        name="keywords"
        content="Brand Identity Development, Logo Design, Brand Messaging, Style Guides, Brand Strategy, MilaajBrandset"
      />
      <meta
        property="og:title"
        content="Brand Identity Development | MilaajBrandset"
      />
      <meta
        property="og:description"
        content="Craft a distinctive brand identity that tells your story and resonates with your audience. MilaajBrandset focuses on creating a cohesive brand image that reflects your values and stands out in the market."
      />
      <meta property="og:type" content="website" />
      <meta
        property="og:url"
        content="https://www.milaajbrandset.com/brand-identity-development"
      />
      <meta
        property="og:image"
        content="https://www.milaajbrandset.com/images/brand-identity-og-image.jpg"
      />
      <meta name="twitter:card" content="summary_large_image" />
      <meta
        name="twitter:title"
        content="Brand Identity Development | MilaajBrandset"
      />
      <meta
        name="twitter:description"
        content="Craft a distinctive brand identity that tells your story and resonates with your audience. MilaajBrandset focuses on creating a cohesive brand image that reflects your values and stands out in the market."
      />
      <meta
        name="twitter:image"
        content="https://www.milaajbrandset.com/images/brand-identity-twitter-image.jpg"
      />
      {/* Add more meta tags as needed */}
    </Helmet>
    <BrandIdentityDevelopment />
  </>
);

const SingleBlogPostPage = () => (
  <>
    <Helmet>
      <title>Blog Post | MilaajBrandset</title>
      <meta
        name="description"
        content="Read the latest insights and updates from MilaajBrandset's blog. Explore detailed articles and expert opinions on various topics."
      />
      <meta
        name="keywords"
        content="MilaajBrandset, blog post, insights, updates, expert opinions"
      />
      <meta property="og:title" content="Blog Post | MilaajBrandset" />
      <meta
        property="og:description"
        content="Read the latest insights and updates from MilaajBrandset's blog. Explore detailed articles and expert opinions on various topics."
      />
    </Helmet>
    <SingleBlogPost />
  </>
);

const SocialMediaMarketingPage = () => (
  <>
    <Helmet>
      <title>Social Media Marketing | MilaajBrandset</title>
      <meta
        name="description"
        content="Enhance your social media presence with MilaajBrandset's social media marketing services. Drive engagement and growth across platforms."
      />
      <meta
        name="keywords"
        content="MilaajBrandset, social media marketing, social media strategy, engagement, growth"
      />
      <meta
        property="og:title"
        content="Social Media Marketing | MilaajBrandset"
      />
      <meta
        property="og:description"
        content="Enhance your social media presence with MilaajBrandset's social media marketing services. Drive engagement and growth across platforms."
      />
    </Helmet>
    <SocialMediaMarketing />
  </>
);

const SEOpage = () => (
  <>
    <Helmet>
      <title>SEO Services | MilaajBrandset</title>
      <meta
        name="description"
        content="Boost your online visibility with MilaajBrandset's SEO services. Improve your website's search engine ranking and drive more traffic to your site."
      />
      <meta
        name="keywords"
        content="MilaajBrandset, SEO services, search engine optimization, website ranking, online visibility"
      />
      <meta property="og:title" content="SEO Services | MilaajBrandset" />
      <meta
        property="og:description"
        content="Boost your online visibility with MilaajBrandset's SEO services. Improve your website's search engine ranking and drive more traffic to your site."
      />
    </Helmet>
    <SeoPage />
  </>
);

const VideoAdProductionPage = () => (
  <>
    <Helmet>
      <title>Video Ad Production | MilaajBrandset</title>
      <meta
        name="description"
        content="Create impactful video advertisements that tell your brand’s story. MilaajBrandset offers comprehensive video ad production services from concept to final edit, designed to engage and convert your audience."
      />
      <meta
        name="keywords"
        content="Video Ad Production, Concept Development, Scriptwriting, Professional Editing, Targeted Distribution, MilaajBrandset"
      />
      <meta
        property="og:title"
        content="Video Ad Production | MilaajBrandset"
      />
      <meta
        property="og:description"
        content="Create impactful video advertisements that tell your brand’s story. MilaajBrandset offers comprehensive video ad production services from concept to final edit, designed to engage and convert your audience."
      />
      <meta property="og:type" content="website" />
      <meta
        property="og:url"
        content="https://www.milaajbrandset.com/video-ad-production"
      />
      <meta
        property="og:image"
        content="https://www.milaajbrandset.com/images/video-ad-og-image.jpg"
      />
      <meta name="twitter:card" content="summary_large_image" />
      <meta
        name="twitter:title"
        content="Video Ad Production | MilaajBrandset"
      />
      <meta
        name="twitter:description"
        content="Create impactful video advertisements that tell your brand’s story. MilaajBrandset offers comprehensive video ad production services from concept to final edit, designed to engage and convert your audience."
      />
      <meta
        name="twitter:image"
        content="https://www.milaajbrandset.com/images/video-ad-twitter-image.jpg"
      />
      {/* Add more meta tags as needed */}
    </Helmet>
    <VideoAdProduction />
  </>
);

const BoostBrandPage = () => (
  <>
    <Helmet>
      <title>Branding and Digital Solutions | MilaajBrandset</title>
      <meta
        name="description"
        content="Elevate your brand with MilaajBrandset's cutting-edge social media management, strategic brand positioning, and creative solutions. Let's make your brand shine."
      />
      <meta
        name="keywords"
        content="branding, social media management, digital solutions, MilaajBrandset, brand positioning, creative content, video production, paid media campaigns"
      />
      <meta
        property="og:title"
        content="Branding and Digital Solutions | MilaajBrandset"
      />
      <meta
        property="og:description"
        content="Elevate your brand with MilaajBrandset's cutting-edge social media management, strategic brand positioning, and creative solutions. Let's make your brand shine."
      />
      <meta
        property="og:image"
        content="path-to-image" /* Replace with actual image path */
      />
    </Helmet>
    <BoostYourBrand />
  </>
);

const CustomWebDevelopmentPage = () => (
  <>
    <Helmet>
      <title>Custom Web Development | MilaajBrandset</title>
      <meta
        name="description"
        content="Get bespoke web development services with MilaajBrandset, tailored to meet your unique business needs and goals."
      />
      <meta
        name="keywords"
        content="MilaajBrandset, custom web development, bespoke websites, business solutions"
      />
      <meta
        property="og:title"
        content="Custom Web Development | MilaajBrandset"
      />
      <meta
        property="og:description"
        content="Get bespoke web development services with MilaajBrandset, tailored to meet your unique business needs and goals."
      />
    </Helmet>
    <CustomWebDevelopment />
  </>
);

const SocialMediaBrandingPage = () => (
  <>
    <Helmet>
      <title>Social Media Branding | MilaajBrandset</title>
      <meta
        name="description"
        content="Boost your brand's presence with MilaajBrandset's social media branding services. Create a strong and recognizable brand identity online."
      />
      <meta
        name="keywords"
        content="MilaajBrandset, social media branding, brand identity, online presence, brand recognition"
      />
      <meta
        property="og:title"
        content="Social Media Branding | MilaajBrandset"
      />
      <meta
        property="og:description"
        content="Boost your brand's presence with MilaajBrandset's social media branding services. Create a strong and recognizable brand identity online."
      />
    </Helmet>
    <SocialMediaBranding />
  </>
);

const PaidMediaCampaignsPage = () => (
  <>
    <Helmet>
      <title>Paid Media Campaigns | MilaajBrandset</title>
      <meta
        name="description"
        content="Drive targeted traffic and maximize ROI with MilaajBrandset's data-driven paid media campaigns. We create and manage effective ads across multiple platforms to ensure your budget is utilized efficiently."
      />
      <meta
        name="keywords"
        content="Paid Media Campaigns, Precision Targeting, Cost Optimization, Comprehensive Tracking, Creative Designs, MilaajBrandset"
      />
      <meta
        property="og:title"
        content="Paid Media Campaigns | MilaajBrandset"
      />
      <meta
        property="og:description"
        content="Drive targeted traffic and maximize ROI with MilaajBrandset's data-driven paid media campaigns. We create and manage effective ads across multiple platforms to ensure your budget is utilized efficiently."
      />
      <meta property="og:type" content="website" />
      <meta
        property="og:url"
        content="https://www.milaajbrandset.com/paid-media-campaigns"
      />
      <meta
        property="og:image"
        content="https://www.milaajbrandset.com/images/paid-media-og-image.jpg"
      />
      <meta name="twitter:card" content="summary_large_image" />
      <meta
        name="twitter:title"
        content="Paid Media Campaigns | MilaajBrandset"
      />
      <meta
        name="twitter:description"
        content="Drive targeted traffic and maximize ROI with MilaajBrandset's data-driven paid media campaigns. We create and manage effective ads across multiple platforms to ensure your budget is utilized efficiently."
      />
      <meta
        name="twitter:image"
        content="https://www.milaajbrandset.com/images/paid-media-twitter-image.jpg"
      />
      {/* Add more meta tags as needed */}
    </Helmet>
    <PaidMediaCampaigns />
  </>
);

const UIUXPage = () => (
  <>
    <Helmet>
      <title>UI/UX Design Solutions | MilaajBrandset</title>
      <meta
        name="description"
        content="Enhance user experiences with MilaajBrandset's UI/UX design solutions. Discover our approach to creating intuitive and engaging user interfaces that drive results."
      />
      <meta
        name="keywords"
        content="MilaajBrandset, UI design, UX design, user experience, user interface, design solutions, interactive design"
      />
      <meta
        property="og:title"
        content="UI/UX Design Solutions | MilaajBrandset"
      />
      <meta
        property="og:description"
        content="Enhance user experiences with MilaajBrandset's UI/UX design solutions. Discover our approach to creating intuitive and engaging user interfaces that drive results."
      />
    </Helmet>
    <UI_UX />
  </>
);

const App = () => {
  return (
    <AnimationRevealPage>
      <CustomCursor />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/contact" element={<ContactPage />} />
        <Route path="/blogs" element={<BlogPage />} />
        <Route path="/services" element={<ServicesPage />} />
        <Route path="/blog/:id/:title" element={<SingleBlogPostPage />} />
        <Route path="/faq" element={<Faq />} />
        <Route path="/search-engine-optimization" element={<SEOpage />} />
        <Route
          path="/social-media-marketing"
          element={<SocialMediaMarketingPage />}
        />
        <Route
          path="/social-media-branding"
          element={<SocialMediaBrandingPage />}
        />
        <Route path="/boost-your-brand" element={<BoostBrandPage />} />
        <Route
          path="/custom-web-development"
          element={<CustomWebDevelopmentPage />}
        />
        <Route
          path="/video-ad-production"
          element={<VideoAdProductionPage />}
        />{" "}
        {/* SEO-Enhanced Route */}
        <Route
          path="/brand-identity-development"
          element={<BrandIdentityDevelopmentPage />}
        />
        <Route
          path="/paid-media-campaigns"
          element={<PaidMediaCampaignsPage />}
        />
        <Route path="/digital-marketing" element={<DigitalMarketingPage />} />
        <Route path="/web-development" element={<WebDevelopmentPage />} />
        <Route path="/ui-ux-design" element={<UIUXPage />} />
        <Route path="/e-commerce" element={<ECommercePage />} />
        <Route path="/grow" element={<MultiFormService />} />
        {/* SEO PAGES-ADVERTISING-BUR-DUBAI */}
        <Route
          path="/advertising-agency-bur-dubai"
          element={<AdvertisingAgencyPage />}
        />
        <Route
          path="/no-1-advertising-agency-bur-dubai"
          element={<No1AdvertisingAgencyPage />}
        />
        <Route
          path="/affordable-advertising-bur-dubai"
          element={<AffordableAdvertisingPage />}
        />
        <Route
          path="/local-advertising-agency-dubai"
          element={<LocalAdvertisingAgencyPage />}
        />
        <Route
          path="/top-rated-advertising-agency"
          element={<TopAdvertisingAgencyPage />}
        />
        <Route
          path="/professional-advertising-bur-duai"
          element={<ProfessionalAdvertisingAgencyPage />}
        />
        <Route
          path="/reliable-advertising-agency-bur-dubai"
          element={<ReliableAdvertisingAgencyPage />}
        />
        <Route
          path="/expert-advertising-agency-bur-dubai"
          element={<ExpertAdvertisingAgencyPage />}
        />
        <Route
          path="/top-advertising-agency-bur-dubai"
          element={<TopRatedAdvertisingAgencyPage />}
        />
        <Route
          path="/full-service-advertising-agency-bur-dubai"
          element={<FullServiceAdvertisingAgencyPage />}
        />
        <Route
          path="/creative-advertising-agency-bur-dubai"
          element={<CreativeAdvertisingAgencyPage />}
        />
        <Route
          path="/innovative-advertising-agency-bur-dubai"
          element={<InnovativeAdvertisingAgencyPage />}
        />
        <Route
          path="/digital-advertising-agency-bur-dubai"
          element={<DigitalAdvertisingAgencyPage />}
        />
        <Route
          path="/best-advertising-agency-bur-dubai"
          element={<BestAdvertisingAgencyPage />}
        />
        <Route
          path="/leading-advertising-agency-bur-dubai"
          element={<LeadingAdvertisingAgencyPage />}
        />
        <Route
          path="/excellent-advertising-agency-bur-dubai"
          element={<ExcellentAdvertisingAgencyPage />}
        />
        {/* SEO-DUBAI */}
        <Route
          path="/advertising-agency-dubai"
          element={<AdvertisingAgencyDubaiPage />}
        />
        <Route
          path="/best-advertising-agency-dubai"
          element={<BestAdvertisingAgencyDubaiPage />}
        />
        <Route
          path="/professional-advertising-agency-dubai"
          element={<ProfessionalAdvertisingAgencyPage />}
        />
        <Route
          path="/expert-advertising-agency-dubai"
          element={<ExpertAdvertisingAgencyDubaiPage />}
        />
        <Route
          path="/affordable-advertising-agency-dubai"
          element={<AffordableAdvertisingAgencyDubaiPage />}
        />
        <Route
          path="/no1-advertising-agency-dubai"
          element={<No1AdvertisingAgencyDubaiPage />}
        />
        <Route
          path="/top-advertising-agency-dubai"
          element={<TopAdvertisingAgencyDubaiPage />}
        />
        <Route
          path="/excellent-advertising-agency-in-dubai"
          element={<ExcellentAdvertisingAgencyDubaiPage />}
        />
        <Route
          path="/reliable-advertising-agency-in-dubai"
          element={<ReliableAdvertisingAgencyDubaiPage />}
        />
        <Route
          path="/creative-advertising-agency-in-dubai"
          element={<CreativeAdvertisingAgencyDubaiPage />}
        />
        {/* SEO PAGES BRANDING BUR DUBAI */}
        <Route
          path="/branding-agency-in-bur-dubai"
          element={<BrandingAgencyBurDubaiPage />}
        />
        {/* SEO PAGES - BRANDING DUBAI */}
        <Route
          path="/branding-agency-in-dubai"
          element={<BrandingAgencyDubaiPage />}
        />
        ;{/* SEO PAGE -  SEO AGENCY BUR DUBAI */}
        <Route
          path="/seo-agency-in-bur-dubai"
          element={<SeoAgencyBurDubaiPage />}
        />
        <Route
          path="/difc-seo-experts-for-small-businesses"
          element={<DifcSeoExpertsSmallBusinessesPage />}
        />
        <Route
          path="/affordable-seo-services-in-bur-dubai"
          element={<AffordableSeoServicesBurDubaiPage />}
        />
        {/* SEO PAGES - SEO AGENCY DUBAI */}
        <Route path="/seo-agency-in-dubai" element={<SeoAgencyDubaiPage />} />;
        <Route
          path="/local-seo-services-in-al-barsha"
          element={<LocalSeoServicesAlBarshaPage />}
        />
        ;{/* SEO PAGES-Web Development dubai */}
        <Route
          path="/web-development-agency-in-dubai"
          element={<WebDevelopmentAgencyDubaiPage />}
        />
        {/* SEO PAGES - Web Development bur Dubai */}
        <Route
          path="/web-development-agency-in-bur-dubai"
          element={<WebDevelopmentAgencyBurDubaiPage />}
        />
        <Route
          path="/web-management-services-in-difc"
          element={<WebManagementServicesDifcPage />}
        />
        <Route
          path="/web-hosting-solutions-in-bur-dubai"
          element={<WebHostingSolutionsBurDubaiPage />}
        />
        <Route
          path="/website-maintenance-in-al-barsha"
          element={<WebsiteMaintenanceAlBarshaPage />}
        />
        <Route
          path="/best-web-management-services-in-difc"
          element={<BestWebManagementServicesDifcPage />}
        />
        <Route
          path="/custom-website-development-in-al-barsha"
          element={<CustomWebsiteDevelopmentAlBarshaPage />}
        />
        <Route
          path="/difc-professional-web-designers"
          element={<DifcProfessionalWebDesignersPage />}
        />
        <Route
          path="/responsive-website-design-bur-dubai"
          element={<ResponsiveWebsiteDesignBurDubaiPage />}
        />
        <Route
          path="/best-website-design-company-bur-dubai"
          element={<BestWebsiteDesignCompanyBurDubaiPage />}
        />
        <Route
          path="/marketing-company-in-al-barsha"
          element={<MarketingCompanyAlBarshaPage />}
        />
        <Route
          path="/digital-marketing-agency-near-difc"
          element={<DigitalMarketingAgencyNearDifcPage />}
        />
        <Route
          path="/best-branding-services-in-bur-dubai"
          element={<BestBrandingServicesBurDubaiPage />}
        />
        <Route
          path="/creative-agency-in-al-barsha"
          element={<CreativeAgencyAlBarshaPage />}
        />
        <Route
          path="/best-advertising-agency-in-bur-dubai"
          element={<BestAdvertisingAgencyBurDubaiPage />}
        />
        <Route
          path="/top-digital-marketing-agency-in-al-barsha"
          element={<TopDigitalMarketingAgencyAlBarshaPage />}
        />
        <Route
          path="/creative-branding-agency-near-difc"
          element={<CreativeBrandingAgencyNearDifcPage />}
        />
        <Route
          path="/creative-branding-agency-near-difc"
          element={<CreativeBrandingAgencySEO />}
        />
        <Route
          path="/affordable-marketing-services-in-bur-dubai"
          element={<AffordableMarketingServicesSEO />}
        />
        <Route
          path="/professional-social-media-management-in-al-barsha"
          element={<ProfessionalSocialMediaManagementSEO />}
        />
        <Route
          path="/website-design-and-development-in-difc"
          element={<WebsiteDesignAndDevelopmentSEO />}
        />
        <Route
          path="/expert-seo-services-in-bur-dubai"
          element={<ExpertSEOSEO />}
        />
        <Route
          path="/social-media-marketing-companies-near-al-barsha"
          element={<SocialMediaMarketingSEO />}
        />
        <Route
          path="/branding-and-digital-solutions-in-difc"
          element={<BrandingAndDigitalSolutionsSEO />}
        />
        <Route
          path="/full-service-digital-agency-in-bur-dubai"
          element={<FullServiceDigitalAgencySEO />}
        />
        <Route
          path="/reliable-marketing-agencies-near-me"
          element={<ReliableMarketingAgenciesSEO />}
        />
        <Route
          path="/affordable-content-marketing-companies-in-al-barsha"
          element={<AffordableContentMarketingSEO />}
        />
        <Route
          path="/professional-branding-agencies-in-dubai"
          element={<ProfessionalBrandingAgenciesSEO />}
        />
        <Route
          path="/content-creation-services-in-bur-dubai"
          element={<ContentCreationServicesSEO />}
        />
        <Route
          path="/content-marketing-agency-in-difc"
          element={<ContentMarketingAgencyDIFCSEO />}
        />
        <Route
          path="/blog-writing-services-in-al-barsha"
          element={<BlogWritingServicesSEO />}
        />
        <Route
          path="/ppc-advertising-services-in-bur-dubai"
          element={<PPCAdvertisingServicesSEO />}
        />
        <Route
          path="/google-ads-management-in-difc"
          element={<GoogleAdsManagementDifcPage />}
        />
        <Route
          path="/social-media-advertising-services-in-al-barsha"
          element={<SocialMediaAdvertisingServicesSEO />}
        />
        <Route
          path="/social-media-marketing-services-in-bur-dubai"
          element={<SocialMediaMarketingServicesSEO />}
        />
        <Route
          path="/social-media-managers-in-al-barsha"
          element={<SocialMediaManagersSEO />}
        />
        <Route
          path="/difc-instagram-facebook-ads-experts"
          element={<DIFCInstagramFacebookAdsSEO />}
        />
        <Route
          path="/social-media-marketing-team-in-bur-dubai"
          element={<SocialMediaMarketingTeamSEO />}
        />
        <Route
          path="/best-social-media-marketing-team-in-bur-dubai"
          element={<BestSocialMediaMarketingTeamSEO />}
        />
        <Route
          path="/social-media-marketers-in-bur-dubai"
          element={<SocialMediaMarketersSEO />}
        />
        <Route
          path="/ux-design-company-in-al-barsha"
          element={<UXDesignCompanySEO />}
        />
        <Route
          path="/top-branding-agency-bur-dubai"
          element={<TopBrandingAgencyBurDubaiPage />}
        />
        <Route
          path="/creative-branding-agency-bur-dubai"
          element={<CreativeBrandingAgencyBurDubaiPage />}
        />
        <Route
          path="/professional-branding-agency-in-bur-dubai"
          element={<ProfessionalBrandingAgencySEO />}
        />
        <Route
          path="/brand-identity-agency-in-bur-dubai"
          element={<BrandIdentityAgencySEO />}
        />
        <Route
          path="/logo-and-branding-agency-in-bur-dubai"
          element={<LogoAndBrandingAgencySEO />}
        />
        <Route
          path="/marketing-and-branding-agency-in-bur-dubai"
          element={<MarketingAndBrandingAgencySEO />}
        />
        <Route
          path="/brand-development-agency-in-bur-dubai"
          element={<BrandDevelopmentAgencySEO />}
        />
        <Route
          path="/affordable-branding-agency-in-bur-dubai"
          element={<AffordableBrandingAgencySEO />}
        />
        <Route
          path="/corporate-branding-agency-in-bur-dubai"
          element={<CorporateBrandingAgencySEO />}
        />
        <Route
          path="/leading-branding-agency-in-bur-dubai"
          element={<LeadingBrandingAgencySEO />}
        />
        <Route
          path="/branding-and-design-agency-in-bur-dubai"
          element={<BrandingAndDesignAgencySEO />}
        />
        <Route
          path="/full-service-branding-agency-in-bur-dubai"
          element={<FullServiceBrandingAgencySEO />}
        />
        <Route
          path="/dubai-based-branding-agency"
          element={<DubaiBasedBrandingAgencySEO />}
        />
        <Route
          path="/luxury-branding-agency-in-bur-dubai"
          element={<LuxuryBrandingAgencySEO />}
        />
        <Route
          path="/innovative-branding-agency-in-bur-dubai"
          element={<InnovativeBrandingAgencySEO />}
        />
        <Route
          path="/business-branding-agency-in-bur-dubai"
          element={<BusinessBrandingAgencySEO />}
        />
        <Route
          path="/top-rated-branding-agency-in-bur-dubai"
          element={<TopRatedBrandingAgencySEO />}
        />
        <Route
          path="/logo-design-and-branding-in-bur-dubai"
          element={<LogoDesignAndBrandingSEO />}
        />
        <Route
          path="/best-creative-branding-agency-in-bur-dubai"
          element={<BestCreativeBrandingAgencySEO />}
        />
        <Route
          path="/digital-branding-agency-in-bur-dubai"
          element={<DigitalBrandingAgencySEO />}
        />
        <Route
          path="/best-branding-agency-in-dubai"
          element={<BestBrandingAgencySEO />}
        />
        <Route
          path="/top-branding-agency-in-dubai"
          element={<TopBrandingAgencySEO />}
        />
        <Route
          path="/creative-branding-agency-in-dubai"
          element={<CreativeBrandingAgencyDubaiSEO />}
        />
        <Route
          path="/professional-branding-agency-in-dubai"
          element={<ProfessionalBrandingAgencyDubaiSEO />}
        />
        <Route
          path="/brand-identity-agency-in-dubai"
          element={<BrandIdentityAgencyDubaiSEO />}
        />
        <Route
          path="/logo-and-branding-agency-in-dubai"
          element={<LogoAndBrandingAgencyDubaiSEO />}
        />
        <Route
          path="/marketing-and-branding-agency-in-dubai"
          element={<MarketingAndBrandingAgencyDubaiSEO />}
        />
        <Route
          path="/brand-development-agency-in-dubai"
          element={<BrandDevelopmentAgencyDubaiSEO />}
        />
        <Route
          path="/affordable-branding-agency-in-dubai"
          element={<AffordableBrandingAgencyDubaiSEO />}
        />
        <Route
          path="/corporate-branding-agency-in-dubai"
          element={<CorporateBrandingAgencyDubaiSEO />}
        />
        <Route
          path="/leading-branding-agency-in-dubai"
          element={<LeadingBrandingAgencyDubaiSEO />}
        />
        <Route
          path="/branding-and-design-agency-in-dubai"
          element={<BrandingAndDesignAgencyDubaiSEO />}
        />
        <Route
          path="/full-service-branding-agency-in-dubai"
          element={<FullServiceBrandingAgencyDubaiSEO />}
        />
        <Route
          path="/luxury-branding-agency-in-dubai"
          element={<LuxuryBrandingAgencyDubaiSEO />}
        />
        <Route
          path="/innovative-branding-agency-in-dubai"
          element={<InnovativeBrandingAgencyDubaiSEO />}
        />
        <Route
          path="/business-branding-agency-in-dubai"
          element={<BusinessBrandingAgencyDubaiSEO />}
        />
        <Route
          path="/affordable-branding-services-in-dubai"
          element={<AffordableBrandingServicesSEO />}
        />
        <Route
          path="/top-rated-branding-agency-in-dubai"
          element={<TopRatedBrandingAgencyDubaiSEO />}
        />
        <Route
          path="/digital-branding-agency-in-dubai"
          element={<DigitalBrandingAgencyDubaiSEO />}
        />
        <Route
          path="/logo-design-and-branding-in-dubai"
          element={<LogoDesignAndBrandingDubaiSEO />}
        />
        <Route
          path="/best-creative-branding-agency-in-dubai"
          element={<BestCreativeBrandingAgencyDubaiSEO />}
        />
        <Route
          path="/best-branding-agency-in-al-barsha"
          element={<BestBrandingAgencyInAlBarshaSEO />}
        />
        <Route
          path="/top-branding-agency-in-al-barsha"
          element={<TopBrandingAgencyInAlBarshaSEO />}
        />
        <Route
          path="/creative-branding-agency-in-al-barsha"
          element={<CreativeBrandingAgencyInAlBarshaSEO />}
        />
        <Route
          path="/professional-branding-agency-in-al-barsha"
          element={<ProfessionalBrandingAgencyInAlBarshaSEO />}
        />
        <Route
          path="/brand-identity-agency-in-al-barsha"
          element={<BrandIdentityAgencyInAlBarshaSEO />}
        />
        <Route
          path="/logo-and-branding-agency-in-al-barsha"
          element={<LogoAndBrandingAgencyInAlBarshaSEO />}
        />
        <Route
          path="/marketing-and-branding-agency-in-al-barsha"
          element={<MarketingAndBrandingAgencyInAlBarshaSEO />}
        />
        <Route
          path="/brand-development-agency-in-al-barsha"
          element={<BrandDevelopmentAgencyInAlBarshaSEO />}
        />
        <Route
          path="/affordable-branding-agency-in-al-barsha"
          element={<AffordableBrandingAgencyInAlBarshaSEO />}
        />
        <Route
          path="/corporate-branding-agency-in-al-barsha"
          element={<CorporateBrandingAgencyInAlBarshaSEO />}
        />
        <Route
          path="/leading-branding-agency-in-al-barsha"
          element={<LeadingBrandingAgencyInAlBarshaSEO />}
        />
        <Route
          path="/branding-and-design-agency-in-al-barsha"
          element={<BrandingAndDesignAgencyInAlBarshaSEO />}
        />
        <Route
          path="/full-service-branding-agency-in-al-barsha"
          element={<FullServiceBrandingAgencyInAlBarshaSEO />}
        />
        <Route
          path="/al-barsha-based-branding-agency"
          element={<AlBarshaBasedBrandingAgencySEO />}
        />
        <Route
          path="/luxury-branding-agency-in-al-barsha"
          element={<LuxuryBrandingAgencyInAlBarshaSEO />}
        />
        <Route
          path="/business-branding-agency-in-al-barsha"
          element={<BusinessBrandingAgencyInAlBarshaSEO />}
        />
        <Route
          path="/innovative-branding-agency-in-al-barsha"
          element={<InnovativeBrandingAgencyInAlBarshaSEO />}
        />
        <Route
          path="/affordable-branding-services-in-al-barsha"
          element={<AffordableBrandingServicesInAlBarshaSEO />}
        />
        <Route
          path="/top-rated-branding-agency-in-al-barsha"
          element={<TopRatedBrandingAgencyInAlBarshaSEO />}
        />
        <Route
          path="/logo-design-and-branding-in-al-barsha"
          element={<LogoDesignAndBrandingInAlBarshaSEO />}
        />
        <Route
          path="/digital-branding-agency-in-al-barsha"
          element={<DigitalBrandingAgencyInAlBarshaSEO />}
        />
        <Route
          path="/best-creative-branding-agency-in-al-barsha"
          element={<BestCreativeBrandingAgencyInAlBarshaSEO />}
        />
        <Route
          path="/best-branding-agency-in-difc"
          element={<BestBrandingAgencyInDIFCSEO />}
        />
        <Route
          path="/top-branding-agency-in-difc"
          element={<TopBrandingAgencyInDIFCSEO />}
        />
        <Route
          path="/creative-branding-agency-in-difc"
          element={<CreativeBrandingAgencyInDIFCSEO />}
        />
        <Route
          path="/professional-branding-agency-in-difc"
          element={<ProfessionalBrandingAgencyInDIFCSEO />}
        />
        <Route
          path="/brand-identity-agency-in-difc"
          element={<BrandIdentityAgencyInDIFCSEO />}
        />
        <Route
          path="/logo-and-branding-agency-in-difc"
          element={<LogoAndBrandingAgencyInDIFCSEO />}
        />
        <Route
          path="/marketing-and-branding-agency-in-difc"
          element={<MarketingAndBrandingAgencyInDIFCSEO />}
        />
        <Route
          path="/brand-development-agency-in-difc"
          element={<BrandDevelopmentAgencyInDIFCSEO />}
        />
        <Route
          path="/affordable-branding-agency-in-difc"
          element={<AffordableBrandingAgencyInDIFCSEO />}
        />
        <Route
          path="/corporate-branding-agency-in-difc"
          element={<CorporateBrandingAgencyInDIFCSEO />}
        />
        <Route
          path="/branding-and-design-agency-in-difc"
          element={<BrandingAndDesignAgencyInDIFCSEO />}
        />
        <Route
          path="/leading-branding-agency-in-difc"
          element={<LeadingBrandingAgencyInDIFCSEO />}
        />
        <Route
          path="/full-service-branding-agency-in-difc"
          element={<FullServiceBrandingAgencyInDIFCSEO />}
        />
        <Route
          path="/difc-based-branding-agency"
          element={<DIFCBasedBrandingAgencySEO />}
        />
        <Route
          path="/luxury-branding-agency-in-difc"
          element={<LuxuryBrandingAgencyInDIFCSEO />}
        />
        <Route
          path="/innovative-branding-agency-in-difc"
          element={<InnovativeBrandingAgencyInDIFCSEO />}
        />
        <Route
          path="/business-branding-agency-in-difc"
          element={<BusinessBrandingAgencyInDIFCSEO />}
        />
        <Route
          path="/affordable-branding-services-in-difc"
          element={<AffordableBrandingServicesInDIFCSEO />}
        />
        <Route
          path="/top-rated-branding-agency-in-difc"
          element={<TopRatedBrandingAgencyInDIFCSEO />}
        />
        <Route
          path="/logo-design-and-branding-in-difc"
          element={<LogoDesignAndBrandingInDIFCSEO />}
        />
        <Route
          path="/digital-branding-agency-in-difc"
          element={<DigitalBrandingAgencyInDIFCSEO />}
        />
        <Route
          path="/best-creative-branding-agency-in-difc"
          element={<BestCreativeBrandingAgencyInDIFCSEO />}
        />
        <Route
          path="/best-seo-company-in-bur-dubai"
          element={<BestSEOCompanyInBurDubaiSEO />}
        />
        <Route
          path="/top-seo-company-in-bur-dubai"
          element={<TopSEOCompanyInBurDubaiSEO />}
        />
        <Route
          path="/professional-seo-company-in-bur-dubai"
          element={<ProfessionalSEOCompanyInBurDubaiSEO />}
        />
        <Route
          path="/affordable-seo-company-in-bur-dubai"
          element={<AffordableSEOCompanyInBurDubaiSEO />}
        />
        <Route
          path="/digital-marketing-and-seo-company-in-bur-dubai"
          element={<DigitalMarketingAndSEOCompanyInBurDubaiSEO />}
        />
        <Route
          path="/local-seo-company-in-bur-dubai"
          element={<LocalSEOCompanyInBurDubaiSEO />}
        />
        <Route
          path="/leading-seo-company-in-bur-dubai"
          element={<LeadingSEOCompanyInBurDubaiSEO />}
        />
        <Route
          path="/seo-services-in-bur-dubai"
          element={<SEOServicesInBurDubaiSEO />}
        />
        <Route
          path="/expert-seo-consultants-in-bur-dubai"
          element={<ExpertSEOConsultantsInBurDubaiSEO />}
        />
        <Route
          path="/seo-specialists-in-bur-dubai"
          element={<SEOSpecialistsInBurDubaiSEO />}
        />
        <Route
          path="/website-seo-company-in-bur-dubai"
          element={<WebsiteSEOCompanyInBurDubaiSEO />}
        />
        <Route
          path="/ecommerce-seo-company-in-bur-dubai"
          element={<EcommerceSEOCompanyInBurDubaiSEO />}
        />
        <Route
          path="/seo-optimization-services-in-bur-dubai"
          element={<SEOOptimizationServicesInBurDubaiSEO />}
        />
        <Route
          path="/google-ranking-seo-company-in-bur-dubai"
          element={<GoogleRankingSEOCompanyInBurDubaiSEO />}
        />
        <Route
          path="/seo-and-social-media-marketing-in-bur-dubai"
          element={<SEOAndSocialMediaMarketingInBurDubaiSEO />}
        />
        <Route
          path="/best-local-seo-agency-in-bur-dubai"
          element={<BestLocalSEOAgencyInBurDubaiSEO />}
        />
        <Route
          path="/search-engine-optimization-company-in-bur-dubai"
          element={<SearchEngineOptimizationCompanyInBurDubaiSEO />}
        />
        <Route
          path="/seo-content-writing-services-in-bur-dubai"
          element={<SEOContentWritingServicesInBurDubaiSEO />}
        />
        <Route
          path="/seo-and-ppc-agency-in-bur-dubai"
          element={<SEOAndPPCAgencyInBurDubaiSEO />}
        />
        <Route
          path="/white-hat-seo-company-in-bur-dubai"
          element={<WhiteHatSEOCompanyInBurDubaiSEO />}
        />
        <Route
          path="/best-digital-marketing-and-seo-services-in-bur-dubai"
          element={<BestDigitalMarketingAndSEOInBurDubaiSEO />}
        />
        <Route
          path="/on-page-and-off-page-seo-in-bur-dubai"
          element={<OnPageAndOffPageSEOInBurDubaiSEO />}
        />
        <Route
          path="/small-business-seo-company-in-bur-dubai"
          element={<SmallBusinessSEOCompanyInBurDubaiSEO />}
        />
        <Route
          path="/best-seo-company-in-dubai"
          element={<BestSEOCompanyInDubaiSEO />}
        />
        <Route
          path="/top-seo-company-in-dubai"
          element={<TopSEOCompanyInDubaiSEO />}
        />
        <Route
          path="/professional-seo-company-in-dubai"
          element={<ProfessionalSEOCompanyInDubaiSEO />}
        />
        <Route
          path="/affordable-seo-company-in-dubai"
          element={<AffordableSEOCompanyInDubaiSEO />}
        />
        <Route path="/seo-company-in-dubai" element={<SEOAgencyInDubaiSEO />} />
        <Route
          path="/digital-marketing-and-seo-company-in-dubai"
          element={<DigitalMarketingAndSEOCompanyInDubaiSEO />}
        />
        <Route
          path="/local-seo-company-in-dubai"
          element={<LocalSEOCompanyInDubaiSEO />}
        />
        <Route
          path="/leading-seo-company-in-dubai"
          element={<LeadingSEOCompanyInDubaiSEO />}
        />
        <Route
          path="/seo-services-in-dubai"
          element={<SEOServicesInDubaiSEO />}
        />
        <Route
          path="/expert-seo-consultants-in-dubai"
          element={<ExpertSEOConsultantsInDubaiSEO />}
        />
        <Route
          path="/seo-specialists-in-dubai"
          element={<SEOSpecialistsInDubaiSEO />}
        />
        <Route
          path="/website-seo-company-in-dubai"
          element={<WebsiteSEOCompanyInDubaiSEO />}
        />
        <Route
          path="/ecommerce-seo-company-in-dubai"
          element={<EcommerceSEOCompanyInDubaiSEO />}
        />
        <Route
          path="/seo-optimization-services-in-dubai"
          element={<SEOOptimizationServicesInDubaiSEO />}
        />
        <Route
          path="/google-ranking-seo-company-in-dubai"
          element={<GoogleRankingSEOCompanyInDubaiSEO />}
        />
        <Route
          path="/seo-and-social-media-marketing-in-dubai"
          element={<SEOAndSocialMediaMarketingInDubaiSEO />}
        />
        <Route
          path="/best-local-seo-agency-in-dubai"
          element={<BestLocalSEOAgencyInDubaiSEO />}
        />
        <Route
          path="/guaranteed-seo-results-in-dubai"
          element={<GuaranteedSEOResultsInDubaiSEO />}
        />
        <Route
          path="/search-engine-optimization-company-in-dubai"
          element={<SearchEngineOptimizationCompanyInDubaiSEO />}
        />
        <Route
          path="/seo-content-writing-services-in-dubai"
          element={<SEOContentWritingServicesInDubaiSEO />}
        />
        <Route
          path="/seo-and-ppc-agency-in-dubai"
          element={<SEOAndPPCAgencyInDubaiSEO />}
        />
        <Route
          path="/white-hat-seo-company-in-dubai"
          element={<WhiteHatSEOCompanyInDubaiSEO />}
        />
        <Route
          path="/best-digital-marketing-and-seo-services-in-dubai"
          element={<BestDigitalMarketingAndSEOInDubaiSEO />}
        />
        <Route
          path="/on-page-and-off-page-seo-in-dubai"
          element={<OnPageAndOffPageSEOInDubaiSEO />}
        />
        <Route
          path="/small-business-seo-company-in-dubai"
          element={<SmallBusinessSEOCompanyInDubaiSEO />}
        />
        <Route
          path="/best-seo-company-in-al-barsha"
          element={<BestSEOCompanyInAlBarshaSEO />}
        />
        <Route
          path="/top-seo-company-in-al-barsha"
          element={<TopSEOCompanyInAlBarshaSEO />}
        />
        <Route
          path="/professional-seo-company-in-al-barsha"
          element={<ProfessionalSEOCompanyInAlBarshaSEO />}
        />
        <Route
          path="/affordable-seo-company-in-al-barsha"
          element={<AffordableSEOCompanyInAlBarshaSEO />}
        />
        <Route
          path="/seo-agency-in-al-barsha"
          element={<SEOAgencyInAlBarshaSEO />}
        />
        <Route
          path="/digital-marketing-and-seo-company-in-al-barsha"
          element={<DigitalMarketingAndSEOCompanyInAlBarshaSEO />}
        />
        <Route
          path="/local-seo-company-in-al-barsha"
          element={<LocalSEOCompanyInAlBarshaSEO />}
        />
        <Route
          path="/leading-seo-company-in-al-barsha"
          element={<LeadingSEOCompanyInAlBarshaSEO />}
        />
        <Route
          path="/seo-services-in-al-barsha"
          element={<SEOServicesInAlBarshaSEO />}
        />
        <Route
          path="/expert-seo-consultants-in-al-barsha"
          element={<ExpertSEOConsultantsInAlBarshaSEO />}
        />
        <Route
          path="/seo-specialists-in-al-barsha"
          element={<SEOSpecialistsInAlBarshaSEO />}
        />
        <Route
          path="/website-seo-company-in-al-barsha"
          element={<WebsiteSEOCompanyInAlBarshaSEO />}
        />
        <Route
          path="/ecommerce-seo-company-in-al-barsha"
          element={<EcommerceSEOCompanyInAlBarshaSEO />}
        />
        <Route
          path="/google-ranking-seo-company-in-al-barsha"
          element={<GoogleRankingSEOCompanyInAlBarshaSEO />}
        />
        <Route
          path="/seo-optimization-services-in-al-barsha"
          element={<SEOOptimizationServicesInAlBarshaSEO />}
        />
        <Route
          path="/seo-and-social-media-marketing-in-al-barsha"
          element={<SEOAndSocialMediaMarketingInAlBarshaSEO />}
        />
        <Route
          path="/best-local-seo-agency-in-al-barsha"
          element={<BestLocalSEOAgencyInAlBarshaSEO />}
        />
        <Route
          path="/guaranteed-seo-results-in-al-barsha"
          element={<GuaranteedSEOResultsInAlBarshaSEO />}
        />
        <Route
          path="/search-engine-optimization-company-in-al-barsha"
          element={<SearchEngineOptimizationCompanyInAlBarshaSEO />}
        />
        <Route
          path="/seo-content-writing-services-in-al-barsha"
          element={<SEOContentWritingServicesInAlBarshaSEO />}
        />
        <Route
          path="/seo-and-ppc-agency-in-al-barsha"
          element={<SEOAndPPCAgencyInAlBarshaSEO />}
        />
        <Route
          path="/white-hat-seo-company-in-al-barsha"
          element={<WhiteHatSEOCompanyInAlBarshaSEO />}
        />
        <Route
          path="/best-digital-marketing-and-seo-in-al-barsha"
          element={<BestDigitalMarketingAndSEOInAlBarshaSEO />}
        />
        <Route
          path="/on-page-and-off-page-seo-in-al-barsha"
          element={<OnPageAndOffPageSEOInAlBarshaSEO />}
        />
        <Route
          path="/small-business-seo-company-in-al-barsha"
          element={<SmallBusinessSEOCompanyInAlBarshaSEO />}
        />
        <Route
          path="/best-seo-company-in-difc"
          element={<BestSEOCompanyInDIFCSEO />}
        />
        <Route
          path="/top-seo-company-in-difc"
          element={<TopSEOCompanyInDIFCSEO />}
        />
        <Route
          path="/professional-seo-company-in-difc"
          element={<ProfessionalSEOCompanyInDIFCSEO />}
        />
        <Route
          path="/affordable-seo-company-in-difc"
          element={<AffordableSEOCompanyInDIFCSEO />}
        />
        <Route path="/seo-agency-in-difc" element={<SEOAgencyInDIFCSEO />} />
        <Route
          path="/digital-marketing-and-seo-company-in-difc"
          element={<DigitalMarketingAndSEOCompanyInDIFCSEO />}
        />
        <Route
          path="/local-seo-company-in-difc"
          element={<LocalSEOCompanyInDIFCSEO />}
        />
        <Route
          path="/leading-seo-company-in-difc"
          element={<LeadingSEOCompanyInDIFCSEO />}
        />
        <Route
          path="/seo-services-in-difc"
          element={<SEOServicesInDIFCSEO />}
        />
        <Route
          path="/expert-seo-consultants-in-difc"
          element={<ExpertSEOConsultantsInDIFCSEO />}
        />
        <Route
          path="/seo-specialists-in-difc"
          element={<SEOSpecialistsInDIFCSEO />}
        />
        <Route
          path="/website-seo-company-in-difc"
          element={<WebsiteSEOCompanyInDIFCSEO />}
        />
        <Route
          path="/ecommerce-seo-company-in-difc"
          element={<EcommerceSEOCompanyInDIFCSEO />}
        />
        <Route
          path="/google-ranking-seo-company-in-difc"
          element={<GoogleRankingSEOCompanyInDIFCSEO />}
        />
        <Route
          path="/seo-optimization-services-in-difc"
          element={<SEOOptimizationServicesInDIFCSEO />}
        />
        <Route
          path="/seo-and-social-media-marketing-in-difc"
          element={<SEOAndSocialMediaMarketingInDIFCSEO />}
        />
        <Route
          path="/best-local-seo-agency-in-difc"
          element={<BestLocalSEOAgencyInDIFCSEO />}
        />
        <Route
          path="/guaranteed-seo-results-in-difc"
          element={<GuaranteedSEOResultsInDIFCSEO />}
        />
        <Route
          path="/search-engine-optimization-company-in-difc"
          element={<SearchEngineOptimizationCompanyInDIFCSEO />}
        />
        <Route
          path="/seo-content-writing-services-in-difc"
          element={<SEOContentWritingServicesInDIFCSEO />}
        />
        <Route
          path="/seo-and-ppc-agency-in-difc"
          element={<SEOAndPPCAgencyInDIFCSEO />}
        />
        <Route
          path="/white-hat-seo-company-in-difc"
          element={<WhiteHatSEOCompanyInDIFCSEO />}
        />
        <Route
          path="/best-digital-marketing-and-seo-in-difc"
          element={<BestDigitalMarketingAndSEOInDIFCSEO />}
        />
        <Route
          path="/on-page-and-off-page-seo-in-difc"
          element={<OnPageAndOffPageSEOInDIFCSEO />}
        />
        <Route
          path="/small-business-seo-company-in-difc"
          element={<SmallBusinessSEOCompanyInDIFCSEO />}
        />
        <Route
          path="/top-website-design-company-in-bur-dubai"
          element={<TopWebsiteDesignCompanyInBurDubaiSEO />}
        />
        <Route
          path="/affordable-website-design-company-in-bur-dubai"
          element={<AffordableWebsiteDesignCompanyInBurDubaiSEO />}
        />
        <Route
          path="/professional-website-designers-in-bur-dubai"
          element={<ProfessionalWebsiteDesignersInBurDubaiSEO />}
        />
        <Route
          path="/creative-website-design-company-in-bur-dubai"
          element={<CreativeWebsiteDesignCompanyInBurDubaiSEO />}
        />
        <Route
          path="/custom-website-development-in-bur-dubai"
          element={<CustomWebsiteDevelopmentInBurDubaiSEO />}
        />
        <Route
          path="/website-design-and-development-company-in-bur-dubai"
          element={<WebsiteDesignAndDevelopmentCompanyInBurDubaiSEO />}
        />
        <Route
          path="/business-website-design-in-bur-dubai"
          element={<BusinessWebsiteDesignInBurDubaiSEO />}
        />
        <Route
          path="/responsive-website-design-in-bur-dubai"
          element={<ResponsiveWebsiteDesignInBurDubaiSEO />}
        />
        <Route
          path="/ecommerce-website-design-company-in-bur-dubai"
          element={<EcommerceWebsiteDesignCompanyInBurDubaiSEO />}
        />
        <Route
          path="/expert-web-designers-in-bur-dubai"
          element={<ExpertWebDesignersInBurDubaiSEO />}
        />
        <Route
          path="/website-redesign-services-in-bur-dubai"
          element={<WebsiteRedesignServicesInBurDubaiSEO />}
        />
        <Route
          path="/wordpress-website-design-in-bur-dubai"
          element={<WordPressWebsiteDesignInBurDubaiSEO />}
        />
        <Route
          path="/corporate-website-design-in-bur-dubai"
          element={<CorporateWebsiteDesignInBurDubaiSEO />}
        />
        <Route
          path="/small-business-website-design-in-bur-dubai"
          element={<SmallBusinessWebsiteDesignInBurDubaiSEO />}
        />
        <Route
          path="/fast-and-secure-website-development-in-bur-dubai"
          element={<FastAndSecureWebsiteDevelopmentInBurDubaiSEO />}
        />
        <Route
          path="/seo-friendly-website-design-in-bur-dubai"
          element={<SEOFriendlyWebsiteDesignInBurDubaiSEO />}
        />
        <Route
          path="/user-friendly-website-design-services-in-bur-dubai"
          element={<UserFriendlyWebsiteDesignServicesInBurDubaiSEO />}
        />
        <Route
          path="/website-maintenance-and-support-in-bur-dubai"
          element={<WebsiteMaintenanceAndSupportInBurDubaiSEO />}
        />
        <Route
          path="/custom-web-solutions-in-bur-dubai"
          element={<CustomWebSolutionsInBurDubaiSEO />}
        />
        <Route
          path="/full-service-web-design-agency-in-bur-dubai"
          element={<FullServiceWebDesignAgencyInBurDubaiSEO />}
        />
        <Route
          path="/ui-ux-design-company-in-bur-dubai"
          element={<UIUXDesignCompanyInBurDubaiSEO />}
        />
        <Route
          path="/mobile-friendly-website-development-in-bur-dubai"
          element={<MobileFriendlyWebsiteDevelopmentInBurDubaiSEO />}
        />
        <Route
          path="/best-web-development-company-in-bur-dubai"
          element={<BestWebDevelopmentCompanyInBurDubaiSEO />}
        />
        <Route
          path="/best-website-design-company-in-dubai"
          element={<BestWebsiteDesignCompanyInDubaiSEO />}
        />
        <Route
          path="/top-website-design-company-in-dubai"
          element={<TopWebsiteDesignCompanyInDubaiSEO />}
        />
        <Route
          path="/affordable-website-design-company-in-dubai"
          element={<AffordableWebsiteDesignCompanyInDubaiSEO />}
        />
        <Route
          path="/professional-website-designers-in-dubai"
          element={<ProfessionalWebsiteDesignersInDubaiSEO />}
        />
        <Route
          path="/creative-website-design-company-in-dubai"
          element={<CreativeWebsiteDesignCompanyInDubaiSEO />}
        />
        <Route
          path="/custom-website-development-in-dubai"
          element={<CustomWebsiteDevelopmentInDubaiSEO />}
        />
        <Route
          path="/website-design-and-development-company-in-dubai"
          element={<WebsiteDesignAndDevelopmentCompanyInDubaiSEO />}
        />
        <Route
          path="/business-website-design-in-dubai"
          element={<BusinessWebsiteDesignInDubaiSEO />}
        />
        <Route
          path="/responsive-website-design-in-dubai"
          element={<ResponsiveWebsiteDesignInDubaiSEO />}
        />
        <Route
          path="/ecommerce-website-design-company-in-dubai"
          element={<EcommerceWebsiteDesignCompanyInDubaiSEO />}
        />
        <Route
          path="/expert-web-designers-in-dubai"
          element={<ExpertWebDesignersInDubaiSEO />}
        />
        <Route
          path="/website-redesign-services-in-dubai"
          element={<WebsiteRedesignServicesInDubaiSEO />}
        />
        <Route
          path="/wordpress-website-design-in-dubai"
          element={<WordPressWebsiteDesignInDubaiSEO />}
        />
        <Route
          path="/corporate-website-design-in-dubai"
          element={<CorporateWebsiteDesignInDubaiSEO />}
        />
        <Route
          path="/small-business-website-design-in-dubai"
          element={<SmallBusinessWebsiteDesignInDubaiSEO />}
        />
        <Route
          path="/fast-and-secure-website-development-in-dubai"
          element={<FastAndSecureWebsiteDevelopmentInDubaiSEO />}
        />
        <Route
          path="/seo-friendly-website-design-in-dubai"
          element={<SEOFriendlyWebsiteDesignInDubaiSEO />}
        />
        <Route
          path="/web-development-agency-in-dubai"
          element={<WebDevelopmentAgencyInDubaiSEO />}
        />
        <Route
          path="/user-friendly-website-design-services-in-dubai"
          element={<UserFriendlyWebsiteDesignServicesInDubaiSEO />}
        />
        <Route
          path="/website-maintenance-and-support-in-dubai"
          element={<WebsiteMaintenanceAndSupportInDubaiSEO />}
        />
        <Route
          path="/best-website-design-company-in-al-barsha"
          element={<BestWebsiteDesignCompanyInAlBarshaSEO />}
        />
        <Route
          path="/top-website-design-company-in-al-barsha"
          element={<TopWebsiteDesignCompanyInAlBarshaSEO />}
        />
        <Route
          path="/affordable-website-design-company-in-al-barsha"
          element={<AffordableWebsiteDesignCompanyInAlBarshaSEO />}
        />
        <Route
          path="/professional-website-designers-in-al-barsha"
          element={<ProfessionalWebsiteDesignersInAlBarshaSEO />}
        />
        <Route
          path="/creative-website-design-company-in-al-barsha"
          element={<CreativeWebsiteDesignCompanyInAlBarshaSEO />}
        />
        <Route
          path="/website-design-and-development-company-in-al-barsha"
          element={<WebsiteDesignAndDevelopmentCompanyInAlBarshaSEO />}
        />
        <Route
          path="/business-website-design-in-al-barsha"
          element={<BusinessWebsiteDesignInAlBarshaSEO />}
        />
        <Route
          path="/responsive-website-design-in-al-barsha"
          element={<ResponsiveWebsiteDesignInAlBarshaSEO />}
        />
        <Route
          path="/ecommerce-website-design-company-in-al-barsha"
          element={<EcommerceWebsiteDesignCompanyInAlBarshaSEO />}
        />
        <Route
          path="/expert-web-designers-in-al-barsha"
          element={<ExpertWebDesignersInAlBarshaSEO />}
        />
        <Route
          path="/website-redesign-services-in-al-barsha"
          element={<WebsiteRedesignServicesInAlBarshaSEO />}
        />
        <Route
          path="/wordpress-website-design-in-al-barsha"
          element={<WordPressWebsiteDesignInAlBarshaSEO />}
        />
        <Route
          path="/corporate-website-design-in-al-barsha"
          element={<CorporateWebsiteDesignInAlBarshaSEO />}
        />
        <Route
          path="/small-business-website-design-in-al-barsha"
          element={<SmallBusinessWebsiteDesignInAlBarshaSEO />}
        />
        <Route
          path="/fast-and-secure-website-development-in-al-barsha"
          element={<FastAndSecureWebsiteDevelopmentInAlBarshaSEO />}
        />
        <Route
          path="/seo-friendly-website-design-in-al-barsha"
          element={<SEOFriendlyWebsiteDesignInAlBarshaSEO />}
        />
        <Route
          path="/web-development-agency-in-al-barsha"
          element={<WebDevelopmentAgencyInAlBarshaSEO />}
        />
        <Route
          path="/user-friendly-website-design-services-in-al-barsha"
          element={<UserFriendlyWebsiteDesignServicesInAlBarshaSEO />}
        />
        <Route
          path="/website-maintenance-and-support-in-al-barsha"
          element={<WebsiteMaintenanceAndSupportInAlBarshaSEO />}
        />
        <Route
          path="/best-website-design-company-in-difc"
          element={<BestWebsiteDesignCompanyInDIFCSEO />}
        />
        <Route
          path="/top-website-design-company-in-difc"
          element={<TopWebsiteDesignCompanyInDIFCSEO />}
        />
        <Route
          path="/affordable-website-design-company-in-difc"
          element={<AffordableWebsiteDesignCompanyInDIFCSEO />}
        />
        <Route
          path="/professional-website-designers-in-difc"
          element={<ProfessionalWebsiteDesignersInDIFCSEO />}
        />
        <Route
          path="/creative-website-design-company-in-difc"
          element={<CreativeWebsiteDesignCompanyInDIFCSEO />}
        />
        <Route
          path="/custom-website-development-in-difc"
          element={<CustomWebsiteDevelopmentInDIFCSEO />}
        />
        <Route
          path="/website-design-and-development-company-in-difc"
          element={<WebsiteDesignAndDevelopmentCompanyInDIFCSEO />}
        />
        <Route
          path="/business-website-design-in-difc"
          element={<BusinessWebsiteDesignInDIFCSEO />}
        />
        <Route
          path="/responsive-website-design-in-difc"
          element={<ResponsiveWebsiteDesignInDIFCSEO />}
        />
        <Route
          path="/ecommerce-website-design-company-in-difc"
          element={<EcommerceWebsiteDesignCompanyInDIFCSEO />}
        />
        <Route
          path="/expert-web-designers-in-difc"
          element={<ExpertWebDesignersInDIFCSEO />}
        />
        <Route
          path="/website-redesign-services-in-difc"
          element={<WebsiteRedesignServicesInDIFCSEO />}
        />
        <Route
          path="/wordpress-website-design-in-difc"
          element={<WordPressWebsiteDesignInDIFCSEO />}
        />
        <Route
          path="/corporate-website-design-in-difc"
          element={<CorporateWebsiteDesignInDIFCSEO />}
        />
        <Route
          path="/small-business-website-design-in-difc"
          element={<SmallBusinessWebsiteDesignInDIFCSEO />}
        />
        <Route
          path="/fast-and-secure-website-development-in-difc"
          element={<FastAndSecureWebsiteDevelopmentInDIFCSEO />}
        />
        <Route
          path="/seo-friendly-website-design-in-difc"
          element={<SEOFriendlyWebsiteDesignInDIFCSEO />}
        />
        <Route
          path="/web-development-agency-in-difc"
          element={<WebDevelopmentAgencyInDIFCSEO />}
        />
        <Route
          path="/user-friendly-website-design-services-in-difc"
          element={<UserFriendlyWebsiteDesignServicesInDIFCSEO />}
        />
        <Route
          path="/website-maintenance-and-support-in-difc"
          element={<WebsiteMaintenanceAndSupportInDIFCSEO />}
        />
        <Route
          path="/best-web-hosting-solutions-in-bur-dubai"
          element={<BestWebHostingSolutionsInBurDubaiSEO />}
        />
        <Route
          path="/affordable-web-hosting-services-in-bur-dubai"
          element={<AffordableWebHostingServicesInBurDubaiSEO />}
        />
        <Route
          path="/top-web-hosting-provider-in-bur-dubai"
          element={<TopWebHostingProviderInBurDubaiSEO />}
        />
        <Route
          path="/fast-and-secure-web-hosting-in-bur-dubai"
          element={<FastAndSecureWebHostingInBurDubaiSEO />}
        />
        <Route
          path="/professional-web-hosting-company-in-bur-dubai"
          element={<ProfessionalWebHostingCompanyInBurDubaiSEO />}
        />
        <Route
          path="/reliable-web-hosting-services-in-bur-dubai"
          element={<ReliableWebHostingServicesInBurDubaiSEO />}
        />
        <Route
          path="/cloud-web-hosting-in-bur-dubai"
          element={<CloudWebHostingInBurDubaiSEO />}
        />
        <Route
          path="/business-web-hosting-solutions-in-bur-dubai"
          element={<BusinessWebHostingSolutionsInBurDubaiSEO />}
        />
        <Route
          path="/vps-hosting-provider-in-bur-dubai"
          element={<VPSHostingProviderInBurDubaiSEO />}
        />
        <Route
          path="/dedicated-server-hosting-in-bur-dubai"
          element={<DedicatedServerHostingInBurDubaiSEO />}
        />
        <Route
          path="/shared-web-hosting-services-in-bur-dubai"
          element={<SharedWebHostingServicesInBurDubaiSEO />}
        />
        <Route
          path="/ecommerce-web-hosting-in-bur-dubai"
          element={<EcommerceWebHostingInBurDubaiSEO />}
        />
        <Route
          path="/managed-web-hosting-services-in-bur-dubai"
          element={<ManagedWebHostingServicesInBurDubaiSEO />}
        />
        <Route
          path="/domain-and-web-hosting-in-bur-dubai"
          element={<DomainAndWebHostingInBurDubaiSEO />}
        />
        <Route
          path="/wordpress-hosting-in-bur-dubai"
          element={<WordPressHostingInBurDubaiSEO />}
        />
        <Route
          path="/secure-website-hosting-in-bur-dubai"
          element={<SecureWebsiteHostingInBurDubaiSEO />}
        />
        <Route
          path="/web-hosting-with-email-services-in-bur-dubai"
          element={<WebHostingWithEmailServicesInBurDubaiSEO />}
        />
        <Route
          path="/website-maintenance-and-hosting-in-bur-dubai"
          element={<WebsiteMaintenanceAndHostingInBurDubaiSEO />}
        />
        <Route
          path="/local-web-hosting-company-in-bur-dubai"
          element={<LocalWebHostingCompanyInBurDubaiSEO />}
        />
        <Route
          path="/uae-based-web-hosting-provider-in-bur-dubai"
          element={<UAEWebHostingProviderInBurDubaiSEO />}
        />
        <Route
          path="/best-web-hosting-solutions-in-dubai"
          element={<BestWebHostingSolutionsInDubaiSEO />}
        />
        <Route
          path="/affordable-web-hosting-services-in-dubai"
          element={<AffordableWebHostingServicesInDubaiSEO />}
        />
        <Route
          path="/top-web-hosting-provider-in-dubai"
          element={<TopWebHostingProviderInDubaiSEO />}
        />
        <Route
          path="/fast-and-secure-web-hosting-in-dubai"
          element={<FastAndSecureWebHostingInDubaiSEO />}
        />
        <Route
          path="/professional-web-hosting-company-in-dubai"
          element={<ProfessionalWebHostingCompanyInDubaiSEO />}
        />
        <Route
          path="/reliable-web-hosting-services-in-dubai"
          element={<ReliableWebHostingServicesInDubaiSEO />}
        />
        <Route
          path="/cloud-web-hosting-in-dubai"
          element={<CloudWebHostingInDubaiSEO />}
        />
        <Route
          path="/business-web-hosting-solutions-in-dubai"
          element={<BusinessWebHostingSolutionsInDubaiSEO />}
        />
        <Route
          path="/vps-hosting-provider-in-dubai"
          element={<VPSHostingProviderInDubaiSEO />}
        />
        <Route
          path="/dedicated-server-hosting-in-dubai"
          element={<DedicatedServerHostingInDubaiSEO />}
        />
        <Route
          path="/shared-web-hosting-services-in-dubai"
          element={<SharedWebHostingServicesInDubaiSEO />}
        />
        <Route
          path="/ecommerce-web-hosting-in-dubai"
          element={<EcommerceWebHostingInDubaiSEO />}
        />
        <Route
          path="/managed-web-hosting-services-in-dubai"
          element={<ManagedWebHostingServicesInDubaiSEO />}
        />
        <Route
          path="/domain-and-web-hosting-in-dubai"
          element={<DomainAndWebHostingInDubaiSEO />}
        />
        <Route
          path="/wordpress-hosting-in-dubai"
          element={<WordPressHostingInDubaiSEO />}
        />
        <Route
          path="/secure-website-hosting-in-dubai"
          element={<SecureWebsiteHostingInDubaiSEO />}
        />
        <Route
          path="/web-hosting-with-email-services-in-dubai"
          element={<WebHostingWithEmailServicesInDubaiSEO />}
        />
        <Route
          path="/website-maintenance-and-hosting-in-dubai"
          element={<WebsiteMaintenanceAndHostingInDubaiSEO />}
        />
        <Route
          path="/local-web-hosting-company-in-dubai"
          element={<LocalWebHostingCompanyInDubaiSEO />}
        />
        <Route
          path="/uae-based-web-hosting-provider-in-dubai"
          element={<UAEBasedWebHostingProviderInDubaiSEO />}
        />
        <Route
          path="/best-web-hosting-solutions-in-al-barsha"
          element={<BestWebHostingSolutionsInAlBarshaSEO />}
        />
        <Route
          path="/affordable-web-hosting-services-in-al-barsha"
          element={<AffordableWebHostingServicesInAlBarshaSEO />}
        />
        <Route
          path="/top-web-hosting-provider-in-al-barsha"
          element={<TopWebHostingProviderInAlBarshaSEO />}
        />
        <Route
          path="/fast-and-secure-web-hosting-in-al-barsha"
          element={<FastAndSecureWebHostingInAlBarshaSEO />}
        />
        <Route
          path="/professional-web-hosting-company-in-al-barsha"
          element={<ProfessionalWebHostingCompanyInAlBarshaSEO />}
        />
        <Route
          path="/reliable-web-hosting-services-in-al-barsha"
          element={<ReliableWebHostingServicesInAlBarshaSEO />}
        />
        <Route
          path="/cloud-web-hosting-in-al-barsha"
          element={<CloudWebHostingInAlBarshaSEO />}
        />
        <Route
          path="/business-web-hosting-solutions-in-al-barsha"
          element={<BusinessWebHostingSolutionsInAlBarshaSEO />}
        />
        <Route
          path="/vps-hosting-provider-in-al-barsha"
          element={<VPSHostingProviderInAlBarshaSEO />}
        />
        <Route
          path="/dedicated-server-hosting-in-al-barsha"
          element={<DedicatedServerHostingInAlBarshaSEO />}
        />
        <Route
          path="/shared-web-hosting-services-in-al-barsha"
          element={<SharedWebHostingServicesInAlBarshaSEO />}
        />
        <Route
          path="/ecommerce-web-hosting-in-al-barsha"
          element={<EcommerceWebHostingInAlBarshaSEO />}
        />
        <Route
          path="/managed-web-hosting-services-in-al-barsha"
          element={<ManagedWebHostingServicesInAlBarshaSEO />}
        />
        <Route
          path="/domain-and-web-hosting-in-al-barsha"
          element={<DomainAndWebHostingInAlBarshaSEO />}
        />
        <Route
          path="/wordpress-hosting-in-al-barsha"
          element={<WordPressHostingInAlBarshaSEO />}
        />
        <Route
          path="/secure-website-hosting-in-al-barsha"
          element={<SecureWebsiteHostingInAlBarshaSEO />}
        />
        <Route
          path="/web-hosting-with-email-services-in-al-barsha"
          element={<WebHostingWithEmailServicesInAlBarshaSEO />}
        />
        <Route
          path="/website-maintenance-and-hosting-in-al-barsha"
          element={<WebsiteMaintenanceAndHostingInAlBarshaSEO />}
        />
        <Route
          path="/local-web-hosting-company-in-al-barsha"
          element={<LocalWebHostingCompanyInAlBarshaSEO />}
        />
        <Route
          path="/uae-based-web-hosting-provider-in-al-barsha"
          element={<UAEBasedWebHostingProviderInAlBarshaSEO />}
        />
        <Route
          path="/best-web-hosting-solutions-in-difc"
          element={<BestWebHostingSolutionsInDIFCSEO />}
        />
        <Route
          path="/affordable-web-hosting-services-in-difc"
          element={<AffordableWebHostingServicesInDIFCSEO />}
        />
        <Route
          path="/top-web-hosting-provider-in-difc"
          element={<TopWebHostingProviderInDIFCSEO />}
        />
        <Route
          path="/fast-and-secure-web-hosting-in-difc"
          element={<FastAndSecureWebHostingInDIFCSEO />}
        />
        <Route
          path="/professional-web-hosting-company-in-difc"
          element={<ProfessionalWebHostingCompanyInDIFCSEO />}
        />
        <Route
          path="/reliable-web-hosting-services-in-difc"
          element={<ReliableWebHostingServicesInDIFCSEO />}
        />
        <Route
          path="/cloud-web-hosting-in-difc"
          element={<CloudWebHostingInDIFCSEO />}
        />
        <Route
          path="/business-web-hosting-solutions-in-difc"
          element={<BusinessWebHostingSolutionsInDIFCSEO />}
        />
        <Route
          path="/vps-hosting-provider-in-difc"
          element={<VPSHostingProviderInDIFCSEO />}
        />
        <Route
          path="/dedicated-server-hosting-in-difc"
          element={<DedicatedServerHostingInDIFCSEO />}
        />
        <Route
          path="/shared-web-hosting-services-in-difc"
          element={<SharedWebHostingServicesInDIFCSEO />}
        />
        <Route
          path="/ecommerce-web-hosting-in-difc"
          element={<EcommerceWebHostingInDIFCSEO />}
        />
        <Route
          path="/managed-web-hosting-services-in-difc"
          element={<ManagedWebHostingServicesInDIFCSEO />}
        />
        <Route
          path="/domain-and-web-hosting-in-difc"
          element={<DomainAndWebHostingInDIFCSEO />}
        />
        <Route
          path="/wordpress-hosting-in-difc"
          element={<WordPressHostingInDIFCSEO />}
        />
        <Route
          path="/secure-website-hosting-in-difc"
          element={<SecureWebsiteHostingInDIFCSEO />}
        />
        <Route
          path="/web-hosting-with-email-services-in-difc"
          element={<WebHostingWithEmailServicesInDIFCSEO />}
        />
        <Route
          path="/website-maintenance-and-hosting-in-difc"
          element={<WebsiteMaintenanceAndHostingInDIFCSEO />}
        />
        <Route
          path="/local-web-hosting-company-in-difc"
          element={<LocalWebHostingCompanyInDIFCSEO />}
        />
        <Route
          path="/uae-based-web-hosting-provider-in-difc"
          element={<UAEBasedWebHostingProviderInDIFCSEO />}
        />
        <Route
          path="/best-content-creation-services-in-bur-dubai"
          element={<BestContentCreationServicesInBurDubaiSEO />}
        />
        <Route
          path="/professional-content-creators-in-bur-dubai"
          element={<ProfessionalContentCreatorsInBurDubaiSEO />}
        />
        <Route
          path="/social-media-content-creation-in-bur-dubai"
          element={<SocialMediaContentCreationInBurDubaiSEO />}
        />
        <Route
          path="/video-content-creation-services-in-bur-dubai"
          element={<VideoContentCreationServicesInBurDubaiSEO />}
        />
        <Route
          path="/website-content-writing-in-bur-dubai"
          element={<WebsiteContentWritingInBurDubaiSEO />}
        />
        <Route
          path="/affordable-content-marketing-in-bur-dubai"
          element={<AffordableContentMarketingInBurDubaiSEO />}
        />
        <Route
          path="/digital-content-solutions-in-bur-dubai"
          element={<DigitalContentSolutionsInBurDubaiSEO />}
        />
        <Route
          path="/content-strategy-agency-in-bur-dubai"
          element={<ContentStrategyAgencyInBurDubaiSEO />}
        />
        <Route
          path="/seo-content-writing-in-bur-dubai"
          element={<SEOContentWritingInBurDubaiSEO />}
        />
        <Route
          path="/blog-writing-services-in-bur-dubai"
          element={<BlogWritingServicesInBurDubaiSEO />}
        />
        <Route
          path="/best-content-creation-services-in-dubai"
          element={<BestContentCreationServicesInDubaiSEO />}
        />
        <Route
          path="/professional-content-marketing-in-dubai"
          element={<ProfessionalContentMarketingInDubaiSEO />}
        />
        <Route
          path="/social-media-content-creation-in-dubai"
          element={<SocialMediaContentCreationInDubaiSEO />}
        />
        <Route
          path="/video-production-services-in-dubai"
          element={<VideoProductionServicesInDubaiSEO />}
        />
        <Route
          path="/creative-content-creators-in-dubai"
          element={<CreativeContentCreatorsInDubaiSEO />}
        />
        <Route
          path="/website-content-writing-in-dubai"
          element={<WebsiteContentWritingInDubaiSEO />}
        />
        <Route
          path="/seo-content-writing-in-dubai"
          element={<SEOContentWritingInDubaiSEO />}
        />
        <Route
          path="/corporate-content-solutions-in-dubai"
          element={<CorporateContentSolutionsInDubaiSEO />}
        />
        <Route
          path="/blog-and-article-writing-in-dubai"
          element={<BlogAndArticleWritingInDubaiSEO />}
        />
        <Route
          path="/content-creation-agency-in-dubai"
          element={<ContentCreationAgencyInDubaiSEO />}
        />
        <Route
          path="/best-content-creation-services-in-al-barsha"
          element={<BestContentCreationServicesInAlBarshaSEO />}
        />
        <Route
          path="/social-media-content-management-in-al-barsha"
          element={<SocialMediaContentManagementInAlBarshaSEO />}
        />
        <Route
          path="/video-production-and-editing-in-al-barsha"
          element={<VideoProductionAndEditingInAlBarshaSEO />}
        />
        <Route
          path="/website-and-blog-content-writing-in-al-barsha"
          element={<WebsiteAndBlogContentWritingInAlBarshaSEO />}
        />
        <Route
          path="/affordable-digital-content-solutions-in-al-barsha"
          element={<AffordableDigitalContentSolutionsInAlBarshaSEO />}
        />
        <Route
          path="/seo-content-writing-in-al-barsha"
          element={<SEOContentWritingInAlBarshaSEO />}
        />
        <Route
          path="/brand-storytelling-services-in-al-barsha"
          element={<BrandStorytellingServicesInAlBarshaSEO />}
        />
        <Route
          path="/professional-content-marketing-in-al-barsha"
          element={<ProfessionalContentMarketingInAlBarshaSEO />}
        />
        <Route
          path="/marketing-copywriting-in-al-barsha"
          element={<MarketingCopywritingInAlBarshaSEO />}
        />
        <Route
          path="/content-writing-agency-in-al-barsha"
          element={<ContentWritingAgencyInAlBarshaSEO />}
        />
        <Route
          path="/best-content-creation-services-in-difc"
          element={<BestContentCreationServicesInDIFCSEO />}
        />
        <Route
          path="/video-and-social-media-content-in-difc"
          element={<VideoAndSocialMediaContentInDIFCSEO />}
        />
        <Route
          path="/creative-content-agency-in-difc"
          element={<CreativeContentAgencyInDIFCSEO />}
        />
        <Route
          path="/seo-content-marketing-in-difc"
          element={<SEOContentMarketingInDIFCSEO />}
        />
        <Route
          path="/website-and-blog-content-writing-in-difc"
          element={<WebsiteAndBlogContentWritingInDIFCSEO />}
        />
        <Route
          path="/professional-content-creators-in-difc"
          element={<ProfessionalContentCreatorsInDIFCSEO />}
        />
        <Route
          path="/high-quality-digital-content-in-difc"
          element={<HighQualityDigitalContentInDIFCSEO />}
        />
        <Route
          path="/brand-storytelling-and-strategy-in-difc"
          element={<BrandStorytellingAndStrategyInDIFCSEO />}
        />
        <Route
          path="/corporate-content-solutions-in-difc"
          element={<CorporateContentSolutionsInDIFCSEO />}
        />
        <Route
          path="/social-media-growth-services-in-difc"
          element={<SocialMediaGrowthServicesInDIFCSEO />}
        />
        <Route
          path="/best-social-media-advertising-in-bur-dubai"
          element={<BestSocialMediaAdvertisingInBurDubaiSEO />}
        />
        <Route
          path="/social-media-marketing-agency-in-bur-dubai"
          element={<SocialMediaMarketingAgencyInBurDubaiSEO />}
        />
        <Route
          path="/facebook-advertising-services-in-bur-dubai"
          element={<FacebookAdvertisingServicesInBurDubaiSEO />}
        />
        <Route
          path="/instagram-ads-management-in-bur-dubai"
          element={<InstagramAdsManagementInBurDubaiSEO />}
        />
        <Route
          path="/social-media-ad-agency-in-bur-dubai"
          element={<SocialMediaAdAgencyInBurDubaiSEO />}
        />
        <Route
          path="/digital-marketing-and-social-media-ads-in-bur-dubai"
          element={<DigitalMarketingAndSocialMediaAdsInBurDubaiSEO />}
        />
        <Route
          path="/paid-social-media-campaigns-in-bur-dubai"
          element={<PaidSocialMediaCampaignsInBurDubaiSEO />}
        />
        <Route
          path="/linkedin-advertising-services-in-bur-dubai"
          element={<LinkedInAdvertisingServicesInBurDubaiSEO />}
        />
        <Route
          path="/social-media-promotion-in-bur-dubai"
          element={<SocialMediaPromotionInBurDubaiSEO />}
        />
        <Route
          path="/tiktok-ads-management-in-bur-dubai"
          element={<TikTokAdsManagementInBurDubaiSEO />}
        />
        <Route
          path="/google-and-social-media-ads-in-bur-dubai"
          element={<GoogleAndSocialMediaAdsInBurDubaiSEO />}
        />
        <Route
          path="/social-media-ppc-agency-in-bur-dubai"
          element={<SocialMediaPPCAgencyInBurDubaiSEO />}
        />
        <Route
          path="/best-facebook-ad-agency-in-bur-dubai"
          element={<BestFacebookAdAgencyInBurDubaiSEO />}
        />
        <Route
          path="/local-social-media-ads-in-bur-dubai"
          element={<LocalSocialMediaAdsInBurDubaiSEO />}
        />
        <Route
          path="/influencer-marketing-and-ads-in-bur-dubai"
          element={<InfluencerMarketingAndAdsInBurDubaiSEO />}
        />
        <Route
          path="/youtube-video-advertising-in-bur-dubai"
          element={<YouTubeVideoAdvertisingInBurDubaiSEO />}
        />
        <Route
          path="/affordable-social-media-advertising-in-bur-dubai"
          element={<AffordableSocialMediaAdvertisingInBurDubaiSEO />}
        />
        <Route
          path="/social-media-branding-and-ads-in-bur-dubai"
          element={<SocialMediaBrandingAndAdsInBurDubaiSEO />}
        />
        <Route
          path="/social-media-ad-creatives-in-bur-dubai"
          element={<SocialMediaAdCreativesInBurDubaiSEO />}
        />
        <Route
          path="/performance-marketing-agency-in-bur-dubai"
          element={<PerformanceMarketingAgencyInBurDubaiSEO />}
        />
        <Route
          path="/best-social-media-advertising-in-dubai"
          element={<BestSocialMediaAdvertisingInDubaiSEO />}
        />
        <Route
          path="/social-media-marketing-agency-in-dubai"
          element={<SocialMediaMarketingAgencyInDubaiSEO />}
        />
        <Route
          path="/facebook-advertising-agency-in-dubai"
          element={<FacebookAdvertisingAgencyInDubaiSEO />}
        />
        <Route
          path="/instagram-ad-management-in-dubai"
          element={<InstagramAdManagementInDubaiSEO />}
        />
        <Route
          path="/youtube-and-video-ads-in-dubai"
          element={<YouTubeAndVideoAdsInDubaiSEO />}
        />
        <Route
          path="/linkedin-marketing-services-in-dubai"
          element={<LinkedInMarketingServicesInDubaiSEO />}
        />
        <Route
          path="/best-ppc-and-social-media-ads-in-dubai"
          element={<BestPPCAndSocialMediaAdsInDubaiSEO />}
        />
        <Route
          path="/social-media-growth-services-in-dubai"
          element={<SocialMediaGrowthServicesInDubaiSEO />}
        />
        <Route
          path="/tiktok-advertising-in-dubai"
          element={<TikTokAdvertisingInDubaiSEO />}
        />
        <Route
          path="/best-facebook-ad-strategies-in-dubai"
          element={<BestFacebookAdStrategiesInDubaiSEO />}
        />
        <Route
          path="/paid-social-media-campaigns-in-dubai"
          element={<PaidSocialMediaCampaignsInDubaiSEO />}
        />
        <Route
          path="/google-and-facebook-ads-agency-in-dubai"
          element={<GoogleAndFacebookAdsAgencyInDubaiSEO />}
        />
        <Route
          path="/social-media-ppc-agency-in-dubai"
          element={<SocialMediaPPCAgencyInDubaiSEO />}
        />
        <Route
          path="/social-media-promotion-for-businesses-in-dubai"
          element={<SocialMediaPromotionForBusinessesInDubaiSEO />}
        />
        <Route
          path="/digital-marketing-and-advertising-in-dubai"
          element={<DigitalMarketingAndAdvertisingInDubaiSEO />}
        />
        <Route
          path="/local-business-advertising-on-social-media-in-dubai"
          element={<LocalBusinessAdvertisingOnSocialMediaInDubaiSEO />}
        />
        <Route
          path="/influencer-marketing-agency-in-dubai"
          element={<InfluencerMarketingAgencyInDubaiSEO />}
        />
        <Route
          path="/social-media-content-and-ads-in-dubai"
          element={<SocialMediaContentAndAdsInDubaiSEO />}
        />
        <Route
          path="/social-media-lead-generation-in-dubai"
          element={<SocialMediaLeadGenerationInDubaiSEO />}
        />
        <Route
          path="/ecommerce-social-media-advertising-in-dubai"
          element={<ECommerceSocialMediaAdvertisingInDubaiSEO />}
        />
        <Route
          path="/best-social-media-advertising-in-al-barsha"
          element={<BestSocialMediaAdvertisingInAlBarshaSEO />}
        />
        <Route
          path="/social-media-ad-agency-in-al-barsha"
          element={<SocialMediaAdAgencyInAlBarshaSEO />}
        />
        <Route
          path="/instagram-and-facebook-ads-in-al-barsha"
          element={<InstagramAndFacebookAdsInAlBarshaSEO />}
        />
        <Route
          path="/digital-marketing-services-in-al-barsha"
          element={<DigitalMarketingServicesInAlBarshaSEO />}
        />
        <Route
          path="/linkedin-ads-for-businesses-in-al-barsha"
          element={<LinkedInAdsForBusinessesInAlBarshaSEO />}
        />
        <Route
          path="/tiktok-and-youtube-ads-in-al-barsha"
          element={<TikTokAndYouTubeAdsInAlBarshaSEO />}
        />
        <Route
          path="/performance-marketing-agency-in-al-barsha"
          element={<PerformanceMarketingAgencyInAlBarshaSEO />}
        />
        <Route
          path="/social-media-growth-services-in-al-barsha"
          element={<SocialMediaGrowthServicesInAlBarshaSEO />}
        />
        <Route
          path="/affordable-facebook-ad-services-in-al-barsha"
          element={<AffordableFacebookAdServicesInAlBarshaSEO />}
        />
        <Route
          path="/ecommerce-social-media-ads-in-al-barsha"
          element={<EcommerceSocialMediaAdsInAlBarshaSEO />}
        />
        <Route
          path="/best-social-media-marketing-agency-in-al-barsha"
          element={<BestSocialMediaMarketingAgencyInAlBarshaSEO />}
        />
        <Route
          path="/google-and-social-media-ppc-ads-in-al-barsha"
          element={<GoogleAndSocialMediaPPCAdsInAlBarshaSEO />}
        />
        <Route
          path="/local-business-advertising-in-al-barsha"
          element={<LocalBusinessAdvertisingInAlBarshaSEO />}
        />
        <Route
          path="/social-media-retargeting-ads-in-al-barsha"
          element={<SocialMediaRetargetingAdsInAlBarshaSEO />}
        />
        <Route
          path="/social-media-branding-and-paid-ads-in-al-barsha"
          element={<SocialMediaBrandingAndPaidAdsInAlBarshaSEO />}
        />
        <Route
          path="/facebook-and-instagram-lead-generation-in-al-barsha"
          element={<FacebookAndInstagramLeadGenerationInAlBarshaSEO />}
        />
        <Route
          path="/video-marketing-and-social-ads-in-al-barsha"
          element={<VideoMarketingAndSocialAdsInAlBarshaSEO />}
        />
        <Route
          path="/social-media-analytics-and-advertising-in-al-barsha"
          element={<SocialMediaAnalyticsAndAdvertisingInAlBarshaSEO />}
        />
        <Route
          path="/youtube-video-promotions-in-al-barsha"
          element={<YouTubeVideoPromotionsInAlBarshaSEO />}
        />
        <Route
          path="/best-roi-social-media-ads-in-al-barsha"
          element={<BestROISocialMediaAdsInAlBarshaSEO />}
        />
        <Route
          path="/best-social-media-advertising-in-difc"
          element={<BestSocialMediaAdvertisingInDIFCSEO />}
        />
        <Route
          path="/social-media-marketing-agency-in-difc"
          element={<SocialMediaMarketingAgencyInDIFCSEO />}
        />
        <Route
          path="/facebook-ad-management-in-difc"
          element={<FacebookAdManagementInDIFCSEO />}
        />
        <Route
          path="/linkedin-ads-for-professionals-in-difc"
          element={<LinkedInAdsForProfessionalsInDIFCSEO />}
        />
        <Route
          path="/social-media-ppc-campaigns-in-difc"
          element={<SocialMediaPPCCampaignsInDIFCSEO />}
        />
        <Route
          path="/youtube-and-instagram-ads-in-difc"
          element={<YouTubeAndInstagramAdsInDIFCSEO />}
        />
        <Route
          path="/tiktok-advertising-for-brands-in-difc"
          element={<TikTokAdvertisingForBrandsInDIFCSEO />}
        />
        <Route
          path="/google-and-social-media-ad-specialists-in-difc"
          element={<GoogleAndSocialMediaAdSpecialistsInDIFCSEO />}
        />
        <Route
          path="/social-media-growth-services-in-difc"
          element={<SocialMediaGrowthServicesInDIFCSEO />}
        />
        <Route
          path="/best-digital-advertising-company-in-difc"
          element={<BestDigitalAdvertisingCompanyInDIFCSEO />}
        />
        <Route
          path="/social-media-promotions-in-difc"
          element={<SocialMediaPromotionsInDIFCSEO />}
        />
        <Route
          path="/paid-social-media-campaigns-for-businesses-in-difc"
          element={<PaidSocialMediaCampaignsForBusinessesInDIFCSEO />}
        />
        <Route
          path="/social-media-lead-generation-experts-in-difc"
          element={<SocialMediaLeadGenerationExpertsInDIFCSEO />}
        />
        <Route
          path="/facebook-and-instagram-ads-for-startups-in-difc"
          element={<FacebookAndInstagramAdsForStartupsInDIFCSEO />}
        />
        <Route
          path="/social-media-video-marketing-in-difc"
          element={<SocialMediaVideoMarketingInDIFCSEO />}
        />
        <Route
          path="/high-roi-social-media-ads-in-difc"
          element={<HighROISocialMediaAdsInDIFCSEO />}
        />
        <Route
          path="/influencer-marketing-agency-in-difc"
          element={<InfluencerMarketingAgencyInDIFCSEO />}
        />
        <Route
          path="/best-performing-social-ads-in-difc"
          element={<BestPerformingSocialAdsInDIFCSEO />}
        />
        <Route
          path="/local-business-digital-ads-in-difc"
          element={<LocalBusinessDigitalAdsInDIFCSEO />}
        />
        <Route
          path="/social-media-branding-and-digital-strategy-in-difc"
          element={<SocialMediaBrandingAndDigitalStrategyInDIFCSEO />}
        />
        <Route
          path="/best-social-media-marketing-in-bur-dubai"
          element={<BestSocialMediaMarketingInBurDubaiSEO />}
        />
        <Route
          path="/digital-marketing-and-social-media-services-in-bur-dubai"
          element={<DigitalMarketingAndSocialMediaServicesInBurDubaiSEO />}
        />
        <Route
          path="/instagram-marketing-services-in-bur-dubai"
          element={<InstagramMarketingServicesInBurDubaiSEO />}
        />
        <Route
          path="/facebook-marketing-agency-in-bur-dubai"
          element={<FacebookMarketingAgencyInBurDubaiSEO />}
        />
        <Route
          path="/social-media-growth-services-in-bur-dubai"
          element={<SocialMediaGrowthServicesInBurDubaiSEO />}
        />
        <Route
          path="/social-media-management-company-in-bur-dubai"
          element={<SocialMediaManagementCompanyInBurDubaiSEO />}
        />
        <Route
          path="/youtube-and-video-marketing-in-bur-dubai"
          element={<YouTubeAndVideoMarketingInBurDubaiSEO />}
        />
        <Route
          path="/linkedin-marketing-experts-in-bur-dubai"
          element={<LinkedInMarketingExpertsInBurDubaiSEO />}
        />
        <Route
          path="/tiktok-marketing-agency-in-bur-dubai"
          element={<TikTokMarketingAgencyInBurDubaiSEO />}
        />
        <Route
          path="/affordable-social-media-marketing-in-bur-dubai"
          element={<AffordableSocialMediaMarketingInBurDubaiSEO />}
        />
        <Route
          path="/social-media-branding-services-in-bur-dubai"
          element={<SocialMediaBrandingServicesInBurDubaiSEO />}
        />
        <Route
          path="/ecommerce-social-media-marketing-in-bur-dubai"
          element={<EcommerceSocialMediaMarketingInBurDubaiSEO />}
        />
        <Route
          path="/influencer-marketing-agency-in-bur-dubai"
          element={<InfluencerMarketingAgencyInBurDubaiSEO />}
        />
        <Route
          path="/paid-social-media-marketing-in-bur-dubai"
          element={<PaidSocialMediaMarketingInBurDubaiSEO />}
        />
        <Route
          path="/social-media-advertising-and-promotions-in-bur-dubai"
          element={<SocialMediaAdvertisingAndPromotionsInBurDubaiSEO />}
        />
        <Route
          path="/social-media-strategy-experts-in-bur-dubai"
          element={<SocialMediaStrategyExpertsInBurDubaiSEO />}
        />
        <Route
          path="/google-and-social-media-marketing-in-bur-dubai"
          element={<GoogleAndSocialMediaMarketingInBurDubaiSEO />}
        />
        <Route
          path="/local-business-social-media-marketing-in-bur-dubai"
          element={<LocalBusinessSocialMediaMarketingInBurDubaiSEO />}
        />
        <Route
          path="/best-social-media-marketing-in-dubai"
          element={<BestSocialMediaMarketingInDubaiSEO />}
        />
        <Route
          path="/top-rated-social-media-marketing-agency-in-dubai"
          element={<TopRatedSocialMediaMarketingAgencyInDubaiSEO />}
        />
        <Route
          path="/instagram-and-facebook-marketing-in-dubai"
          element={<InstagramAndFacebookMarketingInDubaiSEO />}
        />
        <Route
          path="/social-media-advertising-in-dubai"
          element={<SocialMediaAdvertisingInDubaiSEO />}
        />
        <Route
          path="/linkedin-marketing-solutions-in-dubai"
          element={<LinkedInMarketingSolutionsInDubaiSEO />}
        />
        <Route
          path="/digital-marketing-and-social-media-agency-in-dubai"
          element={<DigitalMarketingAndSocialMediaAgencyInDubaiSEO />}
        />
        <Route
          path="/tiktok-marketing-for-businesses-in-dubai"
          element={<TikTokMarketingForBusinessesInDubaiSEO />}
        />
        <Route
          path="/google-and-social-media-ads-in-dubai"
          element={<GoogleAndSocialMediaAdsInDubaiSEO />}
        />
        <Route
          path="/affordable-social-media-management-in-dubai"
          element={<AffordableSocialMediaManagementInDubaiSEO />}
        />
        <Route
          path="/social-media-content-creation-in-dubai"
          element={<SocialMediaContentCreationInDubaiSEO />}
        />
        <Route
          path="/youtube-and-video-marketing-services-in-dubai"
          element={<YouTubeAndVideoMarketingServicesInDubaiSEO />}
        />
        <Route
          path="/ecommerce-social-media-marketing-in-dubai"
          element={<EcommerceSocialMediaMarketingInDubaiSEO />}
        />
        <Route
          path="/social-media-engagement-strategies-in-dubai"
          element={<SocialMediaEngagementStrategiesInDubaiSEO />}
        />
        <Route
          path="/best-social-media-consultants-in-dubai"
          element={<BestSocialMediaConsultantsInDubaiSEO />}
        />
        <Route
          path="/social-media-seo-optimization-in-dubai"
          element={<SocialMediaSEOOptimizationInDubaiSEO />}
        />
        <Route
          path="/performance-marketing-and-social-media-in-dubai"
          element={<PerformanceMarketingAndSocialMediaInDubaiSEO />}
        />
        <Route
          path="/social-media-reputation-management-in-dubai"
          element={<SocialMediaReputationManagementInDubaiSEO />}
        />
        <Route
          path="/business-social-media-strategies-in-dubai"
          element={<BusinessSocialMediaStrategiesInDubaiSEO />}
        />
        <Route
          path="/best-social-media-marketing-in-al-barsha"
          element={<BestSocialMediaMarketingInAlBarshaSEO />}
        />
        <Route
          path="/social-media-marketing-agency-in-al-barsha"
          element={<SocialMediaMarketingAgencyInAlBarshaSEO />}
        />
        <Route
          path="/facebook-and-instagram-marketing-in-al-barsha"
          element={<FacebookAndInstagramMarketingInAlBarshaSEO />}
        />
        <Route
          path="/social-media-growth-agency-in-al-barsha"
          element={<SocialMediaGrowthAgencyInAlBarshaSEO />}
        />
        <Route
          path="/linkedin-advertising-and-marketing-in-al-barsha"
          element={<LinkedInAdvertisingAndMarketingInAlBarshaSEO />}
        />
        <Route
          path="/affordable-social-media-management-in-al-barsha"
          element={<AffordableSocialMediaManagementInAlBarshaSEO />}
        />
        <Route
          path="/social-media-branding-and-strategy-in-al-barsha"
          element={<SocialMediaBrandingAndStrategyInAlBarshaSEO />}
        />
        <Route
          path="/tiktok-and-youtube-marketing-in-al-barsha"
          element={<TikTokAndYouTubeMarketingInAlBarshaSEO />}
        />
        <Route
          path="/social-media-advertising-experts-in-al-barsha"
          element={<SocialMediaAdvertisingExpertsInAlBarshaSEO />}
        />
        <Route
          path="/business-social-media-solutions-in-al-barsha"
          element={<BusinessSocialMediaSolutionsInAlBarshaSEO />}
        />
        <Route
          path="/social-media-content-marketing-in-al-barsha"
          element={<SocialMediaContentMarketingInAlBarshaSEO />}
        />
        <Route
          path="/digital-and-social-media-marketing-in-al-barsha"
          element={<DigitalAndSocialMediaMarketingInAlBarshaSEO />}
        />
        <Route
          path="/performance-marketing-through-social-media-in-al-barsha"
          element={<PerformanceMarketingThroughSocialMediaInAlBarshaSEO />}
        />
        <Route
          path="/social-media-seo-and-analytics-in-al-barsha"
          element={<SocialMediaSEOAndAnalyticsInAlBarshaSEO />}
        />
        <Route
          path="/influencer-marketing-agency-in-al-barsha"
          element={<InfluencerMarketingAgencyInAlBarshaSEO />}
        />
        <Route
          path="/best-ecommerce-social-media-marketing-in-al-barsha"
          element={<BestEcommerceSocialMediaMarketingInAlBarshaSEO />}
        />
        <Route
          path="/paid-ads-and-social-media-promotions-in-al-barsha"
          element={<PaidAdsAndSocialMediaPromotionsInAlBarshaSEO />}
        />
        <Route
          path="/local-business-social-media-management-in-al-barsha"
          element={<LocalBusinessSocialMediaManagementInAlBarshaSEO />}
        />
        <Route
          path="/video-marketing-for-social-media-in-al-barsha"
          element={<VideoMarketingForSocialMediaInAlBarshaSEO />}
        />
        <Route
          path="/google-and-social-media-growth-strategies-in-al-barsha"
          element={<GoogleAndSocialMediaGrowthStrategiesInAlBarshaSEO />}
        />
        
      </Routes>
      <Footer />
    </AnimationRevealPage>
  );
};

export default () => (
  <Router>
    <App />
  </Router>
);
