import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import Header from "Components/headers/PageHeader";
import MainFeature1 from "Components/Features/TwoColWithButton.js"; // Reused component
import Features from "Components/Features/ThreeColumnPages"; // Reused component
import {
  FaSearch,
  FaChartBar,
  FaUsers,
  FaHandshake,
  FaAward,
  FaLightbulb,
} from "react-icons/fa";

// Styled Components
const Subheading = tw.span`uppercase tracking-wider text-sm`;
const Heading = tw.h1`text-2xl md:text-4xl lg:text-5xl font-bold`;
const Description = tw.p`text-base md:text-lg lg:text-xl`;

// Leading SEO Company in DIFC Page Component
export const LeadingSEOCompanyInDIFC = () => {
  return (
    <AnimationRevealPage>
      {/* Header Section */}
      <Header from="Leading SEO Company in DIFC" />

      {/* Hero Section */}
      <MainFeature1
        subheading={
          <Subheading>Your Trusted Partner for Online Success</Subheading>
        }
        heading={
          <Heading>
            Elevate Your Online Presence with{" "}
            <span tw="text-primary-500">Leading SEO Company in DIFC</span>
          </Heading>
        }
        description={
          <Description>
            At MilaajBrandset, we specialize in delivering expert SEO services
            tailored for businesses in the Dubai International Financial Centre
            (DIFC). As the leading SEO company in DIFC, our team of seasoned
            specialists brings data-driven strategies, fresh ideas, and a
            client-centric approach to help businesses improve their search
            engine rankings, drive organic traffic, and achieve measurable
            results.
          </Description>
        }
        imageSrcs={[
          "https://images.unsplash.com/photo-1612431531980-e605e8175f9c?w=1000", // Al Barsha skyline with business buildings
          "https://images.unsplash.com/photo-1519389950473-47ba0277781c?w=1000", // Digital marketing team working on SEO strategy
          "https://images.unsplash.com/photo-1559136555-9303baea8ebd?w=1000", // SEO data visualization on computer screens
        ]}
        widths={["55%", "60%", "70%"]}
        heights={["45%", "60%", "35%"]}
        imageColumnStyle="height: 400px;"
        imageStyle="width: 45%; height: 60%;"
        imagePositions={[
          "left: 35%; top: 20%;",
          "right: 30%; top: 10%;",
          "left: 32%; bottom: 20%;",
        ]}
        primaryButtonText="Get Started Today"
        buttonRounded={true}
        textOnLeft={false}
      />

      {/* Key Benefits Section */}
      <Features
        subheading={<Subheading>Why Choose Us?</Subheading>}
        heading="Your Trusted Experts at the Leading SEO Company in DIFC"
        description="We combine specialization, innovation, and measurable results to deliver exceptional value."
        cards={[
          {
            icon: <FaSearch color="#ff5464" size={40} />,
            title: "Proven SEO Strategies",
            description:
              "Our data-driven approach ensures maximum visibility on search engines.",
          },
          {
            icon: <FaChartBar color="#ff5464" size={40} />,
            title: "Data-Driven Insights",
            description:
              "Backed by research to ensure maximum impact and growth.",
          },
          {
            icon: <FaUsers color="#ff5464" size={40} />,
            title: "Audience-Centric Approach",
            description:
              "We prioritize the needs and behaviors of your target audience.",
          },
          {
            icon: <FaHandshake color="#ff5464" size={40} />,
            title: "Client-Centric Solutions",
            description:
              "Tailored strategies designed to meet your unique goals.",
          },
          {
            icon: <FaAward color="#ff5464" size={40} />,
            title: "Proven Expertise",
            description:
              "Years of experience helping businesses rank higher on Google.",
          },
          {
            icon: <FaLightbulb color="#ff5464" size={40} />,
            title: "Creative Excellence",
            description:
              "Fresh perspectives and innovative ideas for every project.",
          },
        ]}
      />

      {/* Our Process Section */}
      <MainFeature1
        subheading={<Subheading>How We Work</Subheading>}
        heading="Our Streamlined SEO Process"
        description={
          <Description>
            Our process ensures quality, efficiency, and measurable results:
            <ul tw="mt-4 list-disc pl-6">
              <li>
                Discovery & Research: Understand your business and target
                audience.
              </li>
              <li>
                Keyword Analysis: Identify high-value keywords for your niche.
              </li>
              <li>
                Strategy Development: Create tailored SEO plans for your goals.
              </li>
              <li>
                Implementation: Optimize your website and content for search
                engines.
              </li>
              <li>
                Monitoring & Reporting: Track performance and adjust strategies
                for maximum impact.
              </li>
            </ul>
          </Description>
        }
        imageSrcs={[
          "https://images.unsplash.com/photo-1586473219010-2ffc57b0d282?w=1000", // Team reviewing SEO analytics
          "https://images.unsplash.com/photo-1542744173-05336fcc7ad4?w=1000", // Keyword research and planning session
          "https://images.unsplash.com/photo-1579389083078-4e7018379f7e?w=1000", // Content optimization meeting
        ]}
        widths={["60%", "55%", "70%"]}
        heights={["60%", "45%", "35%"]}
        imageColumnStyle="height: 400px;"
        imageStyle="width: 45%; height: 60%;"
        imagePositions={[
          "right: 30%; top: 10%;",
          "left: 35%; bottom: 20%;",
          "right: 32%; bottom: 10%;",
        ]}
        primaryButtonText="Learn More"
        buttonRounded={true}
        textOnLeft={true}
      />

      {/* Success Stories Section */}
      <Features
        subheading={<Subheading>Success Stories</Subheading>}
        heading="Results That Speak for Themselves"
        description="Here’s how our SEO services have helped businesses grow in DIFC."
        cards={[
          {
            icon: <FaAward color="#ff5464" size={40} />,
            title: "Improved Rankings",
            description:
              "A financial consultancy ranked #1 on Google for their primary keyword within three months.",
          },
          {
            icon: <FaHandshake color="#ff5464" size={40} />,
            title: "Client Satisfaction",
            description:
              "Achieved a 98% client retention rate through consistent results.",
          },
          {
            icon: <FaLightbulb color="#ff5464" size={40} />,
            title: "Increased Traffic",
            description:
              "Boosted a retail client’s organic traffic by 150% in six months.",
          },
        ]}
      />

      {/* Call-to-Action Section */}
      <MainFeature1
        subheading={
          <Subheading>Ready to Boost Your Online Presence?</Subheading>
        }
        heading="Let’s Grow Your Business Together"
        description={
          <Description>
            Partner with our expert SEO team in DIFC to unlock the full
            potential of your online presence. Whether you’re looking to improve
            your rankings, increase traffic, or enhance your brand’s visibility,
            we’re here to help.
          </Description>
        }
        imageSrcs={[
          "https://images.unsplash.com/photo-1517502884422-41eaead166d4?w=1000", // Al Barsha business professionals in meeting
          "https://images.unsplash.com/photo-1562654501-a0ccc0fc3fb1?w=1000", // SEO success metrics dashboard
          "https://images.unsplash.com/photo-1600880292203-757bb62b4baf?w=1000", // Client testimonial session in Dubai office
        ]}
        widths={["55%", "60%", "70%"]}
        heights={["45%", "60%", "35%"]}
        imageColumnStyle="height: 400px;"
        imageStyle="width: 45%; height: 60%;"
        imagePositions={[
          "left: 35%; top: 20%;",
          "right: 30%; top: 10%;",
          "left: 32%; bottom: 20%;",
        ]}
        primaryButtonText="Contact Us Today"
        buttonRounded={true}
        textOnLeft={false}
      />
    </AnimationRevealPage>
  );
};
