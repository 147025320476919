import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import Header from "Components/headers/PageHeader";
import MainFeature1 from "Components/Features/TwoColWithButton.js"; // Reused component
import Features from "Components/Features/ThreeColumnPages"; // Reused component
import {
  FaPuzzlePiece,
  FaLaptopCode,
  FaUsers,
  FaHandshake,
  FaAward,
  FaLightbulb,
} from "react-icons/fa";

// Styled Components
const Subheading = tw.span`uppercase tracking-wider text-sm`;
const Heading = tw.h1`text-2xl md:text-4xl lg:text-5xl font-bold`;
const Description = tw.p`text-base md:text-lg lg:text-xl`;

// Custom Web Solutions in Bur Dubai Page Component
export const CustomWebSolutionsInBurDubai = () => {
  return (
    <AnimationRevealPage>
      {/* Header Section */}
      <Header from="Custom Web Solutions in Bur Dubai" />

      {/* Hero Section */}
      <MainFeature1
        subheading={
          <Subheading>
            Your Trusted Partner for Tailored Web Solutions
          </Subheading>
        }
        heading={
          <Heading>
            Elevate Your Business with{" "}
            <span tw="text-primary-500">Custom Web Solutions in Bur Dubai</span>
          </Heading>
        }
        description={
          <Description>
            At MilaajBrandset, we specialize in delivering expert custom web
            solutions tailored for businesses in Bur Dubai. Our team of seasoned
            developers and designers brings fresh ideas, technical expertise,
            and a client-centric approach to help businesses create bespoke
            websites, applications, and integrations that align with their
            unique goals and drive results.
          </Description>
        }
        imageSrcs={[
          "https://images.pexels.com/photos/270360/pexels-photo-270360.jpeg?auto=compress&cs=tinysrgb&w=500", // Custom web solution coding visuals
          "https://images.pexels.com/photos/3184292/pexels-photo-3184292.jpeg?auto=compress&cs=tinysrgb&w=500", // Team collaborating on bespoke project
          "https://images.pexels.com/photos/3182746/pexels-photo-3182746.jpeg?auto=compress&cs=tinysrgb&w=500", // Creative planning for custom solutions
        ]}
        widths={["55%", "60%", "70%"]}
        heights={["45%", "60%", "35%"]}
        imageColumnStyle="height: 400px;"
        imageStyle="width: 45%; height: 60%;"
        imagePositions={[
          "left: 35%; top: 20%;",
          "right: 30%; top: 10%;",
          "left: 32%; bottom: 20%;",
        ]}
        primaryButtonText="Get Started Today"
        buttonRounded={true}
        textOnLeft={false}
      />

      {/* Key Benefits Section */}
      <Features
        subheading={<Subheading>Why Choose Us?</Subheading>}
        heading="Your Trusted Experts at Custom Web Solutions in Bur Dubai"
        description="We combine customization, innovation, and measurable results to deliver exceptional value."
        cards={[
          {
            icon: <FaPuzzlePiece color="#ff5464" size={40} />,
            title: "Tailored Solutions",
            description:
              "Custom websites, applications, and integrations designed for your unique needs.",
          },
          {
            icon: <FaLaptopCode color="#ff5464" size={40} />,
            title: "Scalable Designs",
            description: "Flexible solutions that grow with your business.",
          },
          {
            icon: <FaUsers color="#ff5464" size={40} />,
            title: "Audience-Centric Approach",
            description:
              "We prioritize user experience and seamless functionality.",
          },
          {
            icon: <FaHandshake color="#ff5464" size={40} />,
            title: "Client-Centric Solutions",
            description: "Strategies tailored to meet your specific goals.",
          },
          {
            icon: <FaAward color="#ff5464" size={40} />,
            title: "Proven Expertise",
            description:
              "Years of experience helping businesses succeed online.",
          },
          {
            icon: <FaLightbulb color="#ff5464" size={40} />,
            title: "Creative Excellence",
            description:
              "Fresh perspectives and innovative ideas for every project.",
          },
        ]}
      />

      {/* Our Process Section */}
      <MainFeature1
        subheading={<Subheading>How We Work</Subheading>}
        heading="Our Streamlined Custom Web Solutions Process"
        description={
          <Description>
            Our process ensures quality, efficiency, and measurable results:
            <ul tw="mt-4 list-disc pl-6">
              <li>
                Discovery & Research: Understand your business and target
                audience.
              </li>
              <li>
                Planning & Strategy: Define goals, features, and technical
                requirements.
              </li>
              <li>
                Design Development: Craft visually stunning and functional
                designs.
              </li>
              <li>
                Development & Testing: Build and rigorously test your solution
                for performance.
              </li>
              <li>
                Launch & Support: Deploy your solution and provide ongoing
                support.
              </li>
            </ul>
          </Description>
        }
        imageSrcs={[
          "https://images.pexels.com/photos/1181355/pexels-photo-1181355.jpeg?auto=compress&cs=tinysrgb&w=500", // Custom design development process
          "https://images.pexels.com/photos/3184431/pexels-photo-3184431.jpeg?auto=compress&cs=tinysrgb&w=500", // Team strategizing custom features
          "https://images.pexels.com/photos/3182832/pexels-photo-3182832.jpeg?auto=compress&cs=tinysrgb&w=500", // Testing custom solutions
        ]}
        widths={["60%", "55%", "70%"]}
        heights={["60%", "45%", "35%"]}
        imageColumnStyle="height: 400px;"
        imageStyle="width: 45%; height: 60%;"
        imagePositions={[
          "right: 30%; top: 10%;",
          "left: 35%; bottom: 20%;",
          "right: 32%; bottom: 10%;",
        ]}
        primaryButtonText="Learn More"
        buttonRounded={true}
        textOnLeft={true}
      />

      {/* Success Stories Section */}
      <Features
        subheading={<Subheading>Success Stories</Subheading>}
        heading="Results That Speak for Themselves"
        description="Here’s how our custom web solutions have helped businesses grow in Bur Dubai."
        cards={[
          {
            icon: <FaAward color="#ff5464" size={40} />,
            title: "Increased Efficiency",
            description:
              "A local logistics company streamlined operations with a custom CRM system.",
          },
          {
            icon: <FaHandshake color="#ff5464" size={40} />,
            title: "Client Satisfaction",
            description:
              "Achieved a 98% client retention rate through consistent results.",
          },
          {
            icon: <FaLightbulb color="#ff5464" size={40} />,
            title: "Enhanced Scalability",
            description:
              "A startup scaled its platform seamlessly with our custom web solution.",
          },
        ]}
      />

      {/* Call-to-Action Section */}
      <MainFeature1
        subheading={
          <Subheading>Ready to Unlock Your Business Potential?</Subheading>
        }
        heading="Let’s Grow Your Business Together"
        description={
          <Description>
            Partner with our expert custom web solutions team in Bur Dubai to
            create tailored solutions that drive results. Whether you’re looking
            for a custom website, application, or integration, we’re here to
            help.
          </Description>
        }
        imageSrcs={[
          "https://images.pexels.com/photos/3184423/pexels-photo-3184423.jpeg?auto=compress&cs=tinysrgb&w=500", // Happy team celebrating success
          "https://images.pexels.com/photos/1181406/pexels-photo-1181406.jpeg?auto=compress&cs=tinysrgb&w=500", // Custom web solution visuals
          "https://images.pexels.com/photos/1232995/pexels-photo-1232995.jpeg?auto=compress&cs=tinysrgb&w=500", // Handshake symbolizing partnership
        ]}
        widths={["55%", "60%", "70%"]}
        heights={["45%", "60%", "35%"]}
        imageColumnStyle="height: 400px;"
        imageStyle="width: 45%; height: 60%;"
        imagePositions={[
          "left: 35%; top: 20%;",
          "right: 30%; top: 10%;",
          "left: 32%; bottom: 20%;",
        ]}
        primaryButtonText="Contact Us Today"
        buttonRounded={true}
        textOnLeft={false}
      />
    </AnimationRevealPage>
  );
};
