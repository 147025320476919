import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import Header from "Components/headers/PageHeader";
import MainFeature1 from "Components/Features/TwoColWithButton.js"; // Reused component
import Features from "Components/Features/ThreeColumnPages"; // Reused component
import {
  FaSearch,
  FaChartBar,
  FaUsers,
  FaHandshake,
  FaAward,
  FaLightbulb,
} from "react-icons/fa";

// Styled Components
const Subheading = tw.span`uppercase tracking-wider text-sm`;
const Heading = tw.h1`text-2xl md:text-4xl lg:text-5xl font-bold`;
const Description = tw.p`text-base md:text-lg lg:text-xl`;

// On-Page and Off-Page SEO in DIFC Page Component
export const OnPageAndOffPageSEOInDIFC = () => {
  return (
    <AnimationRevealPage>
      {/* Header Section */}
      <Header from="On-Page and Off-Page SEO in DIFC" />

      {/* Hero Section */}
      <MainFeature1
        subheading={<Subheading>Your Trusted Partner for Comprehensive SEO</Subheading>}
        heading={
          <Heading>
            Elevate Your Online Presence with{" "}
            <span tw="text-primary-500">On-Page and Off-Page SEO in DIFC</span>
          </Heading>
        }
        description={
          <Description>
            At MilaajBrandset, we specialize in delivering expert on-page and off-page SEO services tailored for businesses in the Dubai International Financial Centre (DIFC). Our team of seasoned specialists brings data-driven strategies, fresh ideas, and a client-centric approach to help businesses improve their search engine rankings, drive organic traffic, and achieve measurable results.
          </Description>
        }
        imageSrcs={[
            "https://images.unsplash.com/photo-1486312338219-ce68d2c6f44d?w=1000", // SEO optimization workspace
            "https://images.unsplash.com/photo-1472289065668-ce650ac443d2?w=1000", // Technical SEO analysis
            "https://images.unsplash.com/photo-1461749280684-dccba630e2f6?w=1000" // Code and content optimization
          ]}
        widths={["55%", "60%", "70%"]}
        heights={["45%", "60%", "35%"]}
        imageColumnStyle="height: 400px;"
        imageStyle="width: 45%; height: 60%;"
        imagePositions={[
          "left: 35%; top: 20%;",
          "right: 30%; top: 10%;",
          "left: 32%; bottom: 20%;",
        ]}
        primaryButtonText="Get Started Today"
        buttonRounded={true}
        textOnLeft={false}
      />

      {/* Key Benefits Section */}
      <Features
        subheading={<Subheading>Why Choose Us?</Subheading>}
        heading="Your Trusted Experts at On-Page and Off-Page SEO in DIFC"
        description="We combine specialization, innovation, and measurable results to deliver exceptional value."
        cards={[
          {
            icon: <FaSearch color="#ff5464" size={40} />,
            title: "On-Page Optimization",
            description:
              "We optimize website elements like content, meta tags, and structure for better rankings.",
          },
          {
            icon: <FaChartBar color="#ff5464" size={40} />,
            title: "Off-Page Authority Building",
            description:
              "We build high-quality backlinks and enhance your domain authority.",
          },
          {
            icon: <FaUsers color="#ff5464" size={40} />,
            title: "Audience-Centric Approach",
            description:
              "We prioritize the needs and behaviors of your target audience.",
          },
          {
            icon: <FaHandshake color="#ff5464" size={40} />,
            title: "Client-Centric Solutions",
            description:
              "Tailored strategies designed to meet your unique goals.",
          },
          {
            icon: <FaAward color="#ff5464" size={40} />,
            title: "Proven Expertise",
            description:
              "Years of experience helping businesses succeed online.",
          },
          {
            icon: <FaLightbulb color="#ff5464" size={40} />,
            title: "Creative Excellence",
            description:
              "Fresh perspectives and innovative ideas for every project.",
          },
        ]}
      />

      {/* Our Process Section */}
      <MainFeature1
        subheading={<Subheading>How We Work</Subheading>}
        heading="Our Streamlined On-Page and Off-Page SEO Process"
        description={
          <Description>
            Our process ensures quality, efficiency, and measurable results:
            <ul tw="mt-4 list-disc pl-6">
              <li>Discovery & Research: Understand your business and target audience.</li>
              <li>Keyword Analysis: Identify high-value keywords for your niche.</li>
              <li>On-Page Optimization: Optimize website elements like content, meta tags, and structure.</li>
              <li>Off-Page Strategy: Build high-quality backlinks and enhance domain authority.</li>
              <li>Monitoring & Reporting: Track performance and adjust strategies for maximum impact.</li>
            </ul>
          </Description>
        }
        imageSrcs={[
            "https://images.unsplash.com/photo-1507238691740-187a5b1d37b8?w=1000", // On-page optimization
            "https://images.unsplash.com/photo-1558442092-ca185c41bf86?w=1000", // Link building strategy
            "https://images.unsplash.com/photo-1551288049-bebda4e38f71?w=1000" // SEO metrics analysis
          ]}
        widths={["60%", "55%", "70%"]}
        heights={["60%", "45%", "35%"]}
        imageColumnStyle="height: 400px;"
        imageStyle="width: 45%; height: 60%;"
        imagePositions={[
          "right: 30%; top: 10%;",
          "left: 35%; bottom: 20%;",
          "right: 32%; bottom: 10%;",
        ]}
        primaryButtonText="Learn More"
        buttonRounded={true}
        textOnLeft={true}
      />

      {/* Success Stories Section */}
      <Features
        subheading={<Subheading>Success Stories</Subheading>}
        heading="Results That Speak for Themselves"
        description="Here’s how our on-page and off-page SEO services have helped businesses grow in DIFC."
        cards={[
          {
            icon: <FaAward color="#ff5464" size={40} />,
            title: "Improved Rankings",
            description:
              "A financial consultancy ranked #1 on Google for their primary keyword within three months.",
          },
          {
            icon: <FaHandshake color="#ff5464" size={40} />,
            title: "Client Satisfaction",
            description:
              "Achieved a 98% client retention rate through consistent results.",
          },
          {
            icon: <FaLightbulb color="#ff5464" size={40} />,
            title: "Increased Traffic",
            description:
              "Boosted a retail client’s organic traffic by 150% in six months.",
          },
        ]}
      />

      {/* Call-to-Action Section */}
      <MainFeature1
        subheading={<Subheading>Ready to Boost Your SEO Strategy?</Subheading>}
        heading="Let’s Grow Your Business Together"
        description={
          <Description>
            Partner with our expert on-page and off-page SEO team in DIFC to unlock the full potential of your online presence. Whether you’re looking to improve your rankings, increase traffic, or enhance your brand’s visibility, we’re here to help.
          </Description>
        }
        imageSrcs={ [
            "https://images.unsplash.com/photo-1552664730-d307ca884978?w=1000", // Team analyzing SEO results
            "https://images.unsplash.com/photo-1493612276216-ee3925520721?w=1000", // Website optimization
            "https://images.unsplash.com/photo-1531973576160-7125cd663d86?w=1000" // SEO success meeting
          ]}
        widths={["55%", "60%", "70%"]}
        heights={["45%", "60%", "35%"]}
        imageColumnStyle="height: 400px;"
        imageStyle="width: 45%; height: 60%;"
        imagePositions={[
          "left: 35%; top: 20%;",
          "right: 30%; top: 10%;",
          "left: 32%; bottom: 20%;",
        ]}
        primaryButtonText="Contact Us Today"
        buttonRounded={true}
        textOnLeft={false}
      />
    </AnimationRevealPage>
  );
};

