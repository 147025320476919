import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import Header from "Components/headers/PageHeader";
import MainFeature1 from "Components/Features/TwoColWithButton.js"; // Reused component
import Features from "Components/Features/ThreeColumnPages"; // Reused component
import {
  FaChartLine,
  FaUsers,
  FaHandshake,
  FaAward,
  FaMapMarkerAlt,
  FaDollarSign,
} from "react-icons/fa";

// Styled Components
const Subheading = tw.span`uppercase tracking-wider text-sm`;
const Heading = tw.h1`text-2xl md:text-4xl lg:text-5xl font-bold`;
const Description = tw.p`text-base md:text-lg lg:text-xl`;

// Affordable Social Media Management in Dubai Page Component
export const AffordableSocialMediaManagementInDubai = () => {
  return (
    <AnimationRevealPage>
      {/* Header Section */}
      <Header from="Affordable Social Media Management in Dubai" />

      {/* Hero Section */}
      <MainFeature1
        subheading={
          <Subheading>
            Maximize Your Online Presence Without Breaking the Bank
          </Subheading>
        }
        heading={
          <Heading>
            Unlock Growth with{" "}
            <span tw="text-primary-500">
              Affordable Social Media Management in Dubai
            </span>
          </Heading>
        }
        description={
          <Description>
            At MilaajBrandset, we specialize in delivering professional yet
            affordable social media management services tailored for businesses
            across Dubai. Our team combines creativity, strategy, and local
            insights to help you grow your audience, engage your community, and
            achieve measurable results—all within your budget.
          </Description>
        }
        imageSrcs={[
          "https://images.unsplash.com/photo-1611162617213-7d7a39e9b1d7?ixlib=rb-4.0.3&auto=format&fit=crop&w=1000&q=80", // Social media marketing dashboard
          "https://images.unsplash.com/photo-1552581234-26160f608093?ixlib=rb-4.0.3&auto=format&fit=crop&w=1000&q=80", // Team collaboration on social media strategy
          "https://images.unsplash.com/photo-1527689368864-4dbcb132065c?ixlib=rb-4.0.3&auto=format&fit=crop&w=1000&q=80", // Social media content creation
        ]}
        widths={["55%", "60%", "70%"]}
        heights={["45%", "60%", "35%"]}
        imageColumnStyle="height: 400px;"
        imageStyle="width: 45%; height: 60%;"
        imagePositions={[
          "left: 35%; top: 20%;",
          "right: 30%; top: 10%;",
          "left: 32%; bottom: 20%;",
        ]}
        primaryButtonText="Get Started Today"
        buttonRounded={true}
        textOnLeft={false}
      />

      {/* Key Benefits Section */}
      <Features
        subheading={<Subheading>Why Choose Us?</Subheading>}
        heading="Your Trusted Partner for Affordable Social Media Management in Dubai"
        description="We combine creativity, strategy, and innovation to deliver unmatched value."
        cards={[
          {
            icon: <FaDollarSign color="#ff5464" size={40} />,
            title: "Affordable Pricing",
            description:
              "High-quality social media management services designed for every budget.",
          },
          {
            icon: <FaChartLine color="#ff5464" size={40} />,
            title: "Data-Driven Strategies",
            description:
              "Leverage advanced analytics to optimize performance and maximize ROI.",
          },
          {
            icon: <FaMapMarkerAlt color="#ff5464" size={40} />,
            title: "Localized Expertise",
            description:
              "Understand Dubai's unique market needs and cultural nuances.",
          },
          {
            icon: <FaUsers color="#ff5464" size={40} />,
            title: "Audience Engagement",
            description:
              "Build meaningful connections with your target audience through storytelling.",
          },
          {
            icon: <FaHandshake color="#ff5464" size={40} />,
            title: "Client-Centric Approach",
            description:
              "Collaborate closely with you to ensure alignment with your vision.",
          },
          {
            icon: <FaAward color="#ff5464" size={40} />,
            title: "Proven Track Record",
            description:
              "Delivering exceptional results for Dubai clients since [Year].",
          },
        ]}
      />

      {/* Our Process Section */}
      <MainFeature1
        subheading={<Subheading>How We Work</Subheading>}
        heading="Our Streamlined Process for Social Media Management in Dubai"
        description={
          <Description>
            Our Dubai-focused process ensures quality, efficiency, and
            measurable results:
            <ul tw="mt-4 list-disc pl-6">
              <li>
                Discovery & Research: Understand your business, audience, and
                goals.
              </li>
              <li>
                Audience Analysis: Identify your target demographic and their
                preferences.
              </li>
              <li>
                Strategy Development: Create a customized roadmap for growth and
                engagement.
              </li>
              <li>
                Content Creation: Design visually stunning posts, Stories, and
                videos.
              </li>
              <li>
                Campaign Launch: Promote your brand across platforms like
                Facebook, Instagram, LinkedIn, and TikTok targeting Dubai
                audiences.
              </li>
              <li>
                Optimization & Analysis: Continuously refine strategies based on
                performance metrics and feedback.
              </li>
            </ul>
          </Description>
        }
        imageSrcs={[
          "https://images.unsplash.com/photo-1533750516284-e82fc1601d44?ixlib=rb-4.0.3&auto=format&fit=crop&w=1000&q=80", // Social media analytics dashboard
          "https://images.unsplash.com/photo-1560472355-a3b4bcfe790c?ixlib=rb-4.0.3&auto=format&fit=crop&w=1000&q=80", // Content planning meeting
          "https://images.unsplash.com/photo-1563986768494-4dee2763ff3f?ixlib=rb-4.0.3&auto=format&fit=crop&w=1000&q=80", // Mobile phone with social media apps
        ]}
        widths={["60%", "55%", "70%"]}
        heights={["60%", "45%", "35%"]}
        imageColumnStyle="height: 400px;"
        imageStyle="width: 45%; height: 60%;"
        imagePositions={[
          "right: 30%; top: 10%;",
          "left: 35%; bottom: 20%;",
          "right: 32%; bottom: 10%;",
        ]}
        primaryButtonText="Learn More"
        buttonRounded={true}
        textOnLeft={true}
      />

      {/* Success Stories Section */}
      <Features
        subheading={<Subheading>Success Stories</Subheading>}
        heading="Results That Speak for Themselves"
        description="Here’s how our affordable social media management services have empowered Dubai businesses."
        cards={[
          {
            icon: <FaAward color="#ff5464" size={40} />,
            title: "Campaign ROI",
            description:
              "Helped a café in Jumeirah increase foot traffic by 200% through targeted Instagram campaigns.",
          },
          {
            icon: <FaHandshake color="#ff5464" size={40} />,
            title: "Client Satisfaction",
            description:
              "98% client retention rate through consistent excellence and measurable results.",
          },
          {
            icon: <FaDollarSign color="#ff5464" size={40} />,
            title: "Cost-Effective Solutions",
            description:
              "Generated 10,000+ impressions for a startup using organic and paid strategies within a modest budget.",
          },
        ]}
      />

      {/* Call-to-Action Section */}
      <MainFeature1
        subheading={<Subheading>Ready to Amplify Your Brand?</Subheading>}
        heading="Let’s Elevate Your Business Together in Dubai"
        description={
          <Description>
            Partner with Dubai’s leading affordable social media management
            experts to unlock your business’s full potential. Whether you’re
            launching a campaign, driving traffic, or boosting engagement, we’re
            here to help—without breaking the bank.
          </Description>
        }
        imageSrcs={[
          "https://images.unsplash.com/photo-1512314889357-e157c22f938d?ixlib=rb-4.0.3&auto=format&fit=crop&w=1000&q=80", // Business growth concept
          "https://images.unsplash.com/photo-1534224039826-c7a0eda0e6b3?ixlib=rb-4.0.3&auto=format&fit=crop&w=1000&q=80", // Dubai skyline
          "https://images.unsplash.com/photo-1557804506-669a67965ba0?ixlib=rb-4.0.3&auto=format&fit=crop&w=1000&q=80", // Handshake symbolizing partnership
        ]}
        widths={["55%", "60%", "70%"]}
        heights={["45%", "60%", "35%"]}
        imageColumnStyle="height: 400px;"
        imageStyle="width: 45%; height: 60%;"
        imagePositions={[
          "left: 35%; top: 20%;",
          "right: 30%; top: 10%;",
          "left: 32%; bottom: 20%;",
        ]}
        primaryButtonText="Contact Us Today"
        buttonRounded={true}
        textOnLeft={false}
      />
    </AnimationRevealPage>
  );
};
