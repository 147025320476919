import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import Header from "Components/headers/PageHeader";
import MainFeature1 from "Components/Features/TwoColWithButton.js"; // Reused component
import Features from "Components/Features/ThreeColumnPages"; // Reused component
import {
  FaChartLine,
  FaUsers,
  FaHandshake,
  FaAward,
  FaMapMarkerAlt,
  FaBriefcase,
} from "react-icons/fa";

// Styled Components
const Subheading = tw.span`uppercase tracking-wider text-sm`;
const Heading = tw.h1`text-2xl md:text-4xl lg:text-5xl font-bold`;
const Description = tw.p`text-base md:text-lg lg:text-xl`;

// LinkedIn Ads for Professionals in DIFC Page Component
export const LinkedInAdsForProfessionalsInDIFC = () => {
  return (
    <AnimationRevealPage>
      {/* Header Section */}
      <Header from="LinkedIn Ads for Professionals in DIFC" />

      {/* Hero Section */}
      <MainFeature1
        subheading={
          <Subheading>Engage Decision-Makers with LinkedIn Ads</Subheading>
        }
        heading={
          <Heading>
            Unlock Your Potential with{" "}
            <span tw="text-primary-500">
              The Best LinkedIn Ads for Professionals in DIFC
            </span>
          </Heading>
        }
        description={
          <Description>
            At MilaajBrandset, we specialize in delivering professional LinkedIn
            ad services tailored for businesses and professionals in DIFC,
            Dubai. Our team combines creativity, strategy, and local insights to
            help you connect with decision-makers, build authority, and achieve
            measurable results through targeted LinkedIn campaigns.
          </Description>
        }
        imageSrcs={[
          "https://images.unsplash.com/photo-1611944212129-29977ae1398c?auto=format&fit=crop&q=80", // LinkedIn-focused business networking
          "https://images.unsplash.com/photo-1560439514-4e9645039924?auto=format&fit=crop&q=80", // Professional business meeting
          "https://images.unsplash.com/photo-1557804506-669a67965ba0?auto=format&fit=crop&q=80", // Professional workspace
        ]}
        widths={["55%", "60%", "70%"]}
        heights={["45%", "60%", "35%"]}
        imageColumnStyle="height: 400px;"
        imageStyle="width: 45%; height: 60%;"
        imagePositions={[
          "left: 35%; top: 20%;",
          "right: 30%; top: 10%;",
          "left: 32%; bottom: 20%;",
        ]}
        primaryButtonText="Get Started Today"
        buttonRounded={true}
        textOnLeft={false}
      />

      {/* Key Benefits Section */}
      <Features
        subheading={<Subheading>Why Choose Us?</Subheading>}
        heading="Your Trusted Partner for LinkedIn Ads in DIFC"
        description="We combine creativity, strategy, and innovation to deliver unmatched value."
        cards={[
          {
            icon: <FaBriefcase color="#ff5464" size={40} />,
            title: "Targeted Campaigns",
            description:
              "Reach decision-makers and professionals in DIFC with precision-targeted LinkedIn ads.",
          },
          {
            icon: <FaChartLine color="#ff5464" size={40} />,
            title: "Data-Driven Strategies",
            description:
              "Leverage advanced analytics to optimize performance and maximize ROI.",
          },
          {
            icon: <FaMapMarkerAlt color="#ff5464" size={40} />,
            title: "Localized Expertise",
            description:
              "Understand DIFC's unique market needs and professional landscape.",
          },
          {
            icon: <FaUsers color="#ff5464" size={40} />,
            title: "Audience Engagement",
            description:
              "Build meaningful connections with your target audience through engaging LinkedIn content.",
          },
          {
            icon: <FaHandshake color="#ff5464" size={40} />,
            title: "Client-Centric Approach",
            description:
              "Collaborate closely with you to ensure alignment with your vision.",
          },
          {
            icon: <FaAward color="#ff5464" size={40} />,
            title: "Proven Track Record",
            description:
              "Delivering exceptional results for DIFC clients since [Year].",
          },
        ]}
      />

      {/* Our Process Section */}
      <MainFeature1
        subheading={<Subheading>How We Work</Subheading>}
        heading="Our Streamlined Process for LinkedIn Ads in DIFC"
        description={
          <Description>
            Our DIFC-focused process ensures quality, efficiency, and measurable
            results:
            <ul tw="mt-4 list-disc pl-6">
              <li>
                Discovery & Research: Understand your business, audience, and
                goals.
              </li>
              <li>
                Audience Analysis: Identify your target demographic and their
                preferences.
              </li>
              <li>
                Strategy Development: Create a customized roadmap for growth and
                engagement.
              </li>
              <li>
                Creative Execution: Design visually stunning and engaging
                LinkedIn ads (Sponsored Content, InMail, Text Ads).
              </li>
              <li>
                Campaign Launch: Promote your brand across LinkedIn targeting
                DIFC professionals and decision-makers.
              </li>
              <li>
                Optimization & Analysis: Continuously refine strategies based on
                performance metrics and feedback.
              </li>
            </ul>
          </Description>
        }
        imageSrcs={[
          "https://images.unsplash.com/photo-1611944212129-29977ae1398c?auto=format&fit=crop&q=80", // LinkedIn-focused business networking
          "https://images.unsplash.com/photo-1560439514-4e9645039924?auto=format&fit=crop&q=80", // Professional business meeting
          "https://images.unsplash.com/photo-1557804506-669a67965ba0?auto=format&fit=crop&q=80", // Professional workspace
        ]}
        widths={["60%", "55%", "70%"]}
        heights={["60%", "45%", "35%"]}
        imageColumnStyle="height: 400px;"
        imageStyle="width: 45%; height: 60%;"
        imagePositions={[
          "right: 30%; top: 10%;",
          "left: 35%; bottom: 20%;",
          "right: 32%; bottom: 10%;",
        ]}
        primaryButtonText="Learn More"
        buttonRounded={true}
        textOnLeft={true}
      />

      {/* Success Stories Section */}
      <Features
        subheading={<Subheading>Success Stories</Subheading>}
        heading="Results That Speak for Themselves"
        description="Here’s how our LinkedIn ad services have empowered DIFC professionals and businesses."
        cards={[
          {
            icon: <FaAward color="#ff5464" size={40} />,
            title: "Campaign ROI",
            description:
              "Helped a financial consultancy in DIFC achieve a 15x ROI on their LinkedIn ad spend within six months.",
          },
          {
            icon: <FaHandshake color="#ff5464" size={40} />,
            title: "Client Satisfaction",
            description:
              "98% client retention rate through consistent excellence and measurable results.",
          },
          {
            icon: <FaBriefcase color="#ff5464" size={40} />,
            title: "Lead Generation",
            description:
              "Generated 500+ qualified leads for a luxury real estate firm in DIFC using LinkedIn Sponsored Content.",
          },
        ]}
      />

      {/* Call-to-Action Section */}
      <MainFeature1
        subheading={<Subheading>Ready to Amplify Your Brand?</Subheading>}
        heading="Let’s Elevate Your Business Together in DIFC"
        description={
          <Description>
            Partner with DIFC’s leading LinkedIn ad experts to unlock your
            brand’s full potential. Whether you’re launching a campaign, driving
            traffic, or boosting engagement, we’re here to help.
          </Description>
        }
        imageSrcs={[
          "https://images.unsplash.com/photo-1600880292089-90a7e086ee0c?auto=format&fit=crop&q=80", // Business professionals celebrating
          "https://images.unsplash.com/photo-1512453979798-5ea266f8880c?auto=format&fit=crop&q=80", // Dubai business skyline
          "https://images.unsplash.com/photo-1600880292203-757bb62b4baf?auto=format&fit=crop&q=80", // Professional handshake
        ]}
        widths={["55%", "60%", "70%"]}
        heights={["45%", "60%", "35%"]}
        imageColumnStyle="height: 400px;"
        imageStyle="width: 45%; height: 60%;"
        imagePositions={[
          "left: 35%; top: 20%;",
          "right: 30%; top: 10%;",
          "left: 32%; bottom: 20%;",
        ]}
        primaryButtonText="Contact Us Today"
        buttonRounded={true}
        textOnLeft={false}
      />
    </AnimationRevealPage>
  );
};
