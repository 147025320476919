import { AffordableSocialMediaMarketingInBurDubai } from "Pages/SeoPages/SocialMediaManagement/bur_dubai/AffordableSocialMediaMarketingInBurDubai";
import { BestSocialMediaMarketingInBurDubai } from "Pages/SeoPages/SocialMediaManagement/bur_dubai/BestSocialMediaMarketingInBurDubai";
import { DigitalMarketingAndSocialMediaServicesInBurDubai } from "Pages/SeoPages/SocialMediaManagement/bur_dubai/DigitalMarketingAndSocialMediaServicesInBurDubai";
import { EcommerceSocialMediaMarketingInBurDubai } from "Pages/SeoPages/SocialMediaManagement/bur_dubai/EcommerceSocialMediaMarketingInBurDubai";
import { FacebookMarketingAgencyInBurDubai } from "Pages/SeoPages/SocialMediaManagement/bur_dubai/FacebookMarketingAgencyInBurDubai";
import { GoogleAndSocialMediaMarketingInBurDubai } from "Pages/SeoPages/SocialMediaManagement/bur_dubai/GoogleAndSocialMediaMarketingInBurDubai";
import { InfluencerMarketingAgencyInBurDubai } from "Pages/SeoPages/SocialMediaManagement/bur_dubai/InfluencerMarketingAgencyInBurDubai";
import { InstagramMarketingServicesInBurDubai } from "Pages/SeoPages/SocialMediaManagement/bur_dubai/InstagramMarketingServicesInBurDubai";
import { LinkedInMarketingExpertsInBurDubai } from "Pages/SeoPages/SocialMediaManagement/bur_dubai/LinkedInMarketingExpertsInBurDubai";
import { LocalBusinessSocialMediaMarketingInBurDubai } from "Pages/SeoPages/SocialMediaManagement/bur_dubai/LocalBusinessSocialMediaMarketingInBurDubai";
import { PaidSocialMediaMarketingInBurDubai } from "Pages/SeoPages/SocialMediaManagement/bur_dubai/PaidSocialMediaMarketingInBurDubai";
import { SocialMediaAdvertisingAndPromotionsInBurDubai } from "Pages/SeoPages/SocialMediaManagement/bur_dubai/SocialMediaAdvertisingAndPromotionsInBurDubai";
import { SocialMediaBrandingServicesInBurDubai } from "Pages/SeoPages/SocialMediaManagement/bur_dubai/SocialMediaBrandingServicesInBurDubai";
import { SocialMediaGrowthServicesInBurDubai } from "Pages/SeoPages/SocialMediaManagement/bur_dubai/SocialMediaGrowthServicesInBurDubai";
import { SocialMediaManagementCompanyInBurDubai } from "Pages/SeoPages/SocialMediaManagement/bur_dubai/SocialMediaManagementCompanyInBurDubai";
import { SocialMediaStrategyExpertsInBurDubai } from "Pages/SeoPages/SocialMediaManagement/bur_dubai/SocialMediaStrategyExpertsInBurDubai";
import { TikTokMarketingAgencyInBurDubai } from "Pages/SeoPages/SocialMediaManagement/bur_dubai/TikTokMarketingAgencyInBurDubai";
import { YouTubeAndVideoMarketingInBurDubai } from "Pages/SeoPages/SocialMediaManagement/bur_dubai/YouTubeAndVideoMarketingInBurDubai";
import { Helmet } from "react-helmet";

export const BestSocialMediaMarketingInBurDubaiSEO = () => (
    <>
      <Helmet>
        <title>Best Social Media Marketing in Bur Dubai | MilaajBrandset</title>
        <meta
          name="description"
          content="MilaajBrandset offers expert social media marketing services in Bur Dubai, combining creativity, localization, and data-driven strategies to elevate your online presence and drive growth."
        />
        <meta
          name="keywords"
          content="social media marketing Bur Dubai, Facebook ads Bur Dubai, Instagram ads Bur Dubai, TikTok marketing Bur Dubai, digital marketing agency Bur Dubai, Dubai"
        />
        <meta property="og:title" content="Best Social Media Marketing in Bur Dubai | MilaajBrandset" />
        <meta
          property="og:description"
          content="MilaajBrandset offers expert social media marketing services in Bur Dubai, combining creativity, localization, and data-driven strategies to elevate your online presence and drive growth."
        />
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "ProfessionalService",
              "name": "MilaajBrandset",
              "image": "https://milaajbrandset.com/favicon.ico",
              "description": "MilaajBrandset offers expert social media marketing services in Bur Dubai, combining creativity, localization, and data-driven strategies to elevate your online presence and drive growth.",
              "address": {
                "@type": "PostalAddress",
                "addressLocality": "Bur Dubai",
                "addressRegion": "Dubai",
                "addressCountry": "AE"
              },
              "geo": {
                "@type": "GeoCoordinates",
                "latitude": 25.2644, // Approximate latitude for Bur Dubai
                "longitude": 55.3079 // Approximate longitude for Bur Dubai
              }
            }
          `}
        </script>
      </Helmet>
      <BestSocialMediaMarketingInBurDubai />
    </>
  );


  export const DigitalMarketingAndSocialMediaServicesInBurDubaiSEO = () => (
    <>
      <Helmet>
        <title>Digital Marketing and Social Media Services in Bur Dubai | MilaajBrandset</title>
        <meta
          name="description"
          content="MilaajBrandset offers expert digital marketing and social media services in Bur Dubai, combining creativity, localization, and data-driven strategies to elevate your online presence and drive growth."
        />
        <meta
          name="keywords"
          content="digital marketing Bur Dubai, social media services Bur Dubai, Google Ads Bur Dubai, Facebook ads Bur Dubai, Instagram ads Bur Dubai, TikTok marketing Bur Dubai, digital marketing agency Bur Dubai, Dubai"
        />
        <meta property="og:title" content="Digital Marketing and Social Media Services in Bur Dubai | MilaajBrandset" />
        <meta
          property="og:description"
          content="MilaajBrandset offers expert digital marketing and social media services in Bur Dubai, combining creativity, localization, and data-driven strategies to elevate your online presence and drive growth."
        />
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "ProfessionalService",
              "name": "MilaajBrandset",
              "image": "https://milaajbrandset.com/favicon.ico",
              "description": "MilaajBrandset offers expert digital marketing and social media services in Bur Dubai, combining creativity, localization, and data-driven strategies to elevate your online presence and drive growth.",
              "address": {
                "@type": "PostalAddress",
                "addressLocality": "Bur Dubai",
                "addressRegion": "Dubai",
                "addressCountry": "AE"
              },
              "geo": {
                "@type": "GeoCoordinates",
                "latitude": 25.2644, // Approximate latitude for Bur Dubai
                "longitude": 55.3079 // Approximate longitude for Bur Dubai
              }
            }
          `}
        </script>
      </Helmet>
      <DigitalMarketingAndSocialMediaServicesInBurDubai />
    </>
  );
  

  export const InstagramMarketingServicesInBurDubaiSEO = () => (
    <>
      <Helmet>
        <title>Instagram Marketing Services in Bur Dubai | MilaajBrandset</title>
        <meta
          name="description"
          content="MilaajBrandset offers expert Instagram marketing services in Bur Dubai, combining creativity, localization, and data-driven strategies to elevate your online presence and drive growth."
        />
        <meta
          name="keywords"
          content="Instagram marketing Bur Dubai, Instagram ads Bur Dubai, Instagram Reels Bur Dubai, Instagram Stories Bur Dubai, social media marketing Bur Dubai, digital marketing agency Bur Dubai, Dubai"
        />
        <meta property="og:title" content="Instagram Marketing Services in Bur Dubai | MilaajBrandset" />
        <meta
          property="og:description"
          content="MilaajBrandset offers expert Instagram marketing services in Bur Dubai, combining creativity, localization, and data-driven strategies to elevate your online presence and drive growth."
        />
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "ProfessionalService",
              "name": "MilaajBrandset",
              "image": "https://milaajbrandset.com/favicon.ico",
              "description": "MilaajBrandset offers expert Instagram marketing services in Bur Dubai, combining creativity, localization, and data-driven strategies to elevate your online presence and drive growth.",
              "address": {
                "@type": "PostalAddress",
                "addressLocality": "Bur Dubai",
                "addressRegion": "Dubai",
                "addressCountry": "AE"
              },
              "geo": {
                "@type": "GeoCoordinates",
                "latitude": 25.2644, // Approximate latitude for Bur Dubai
                "longitude": 55.3079 // Approximate longitude for Bur Dubai
              }
            }
          `}
        </script>
      </Helmet>
      <InstagramMarketingServicesInBurDubai />
    </>
  );
  

  export const FacebookMarketingAgencyInBurDubaiSEO = () => (
    <>
      <Helmet>
        <title>Facebook Marketing Agency in Bur Dubai | MilaajBrandset</title>
        <meta
          name="description"
          content="MilaajBrandset is a leading Facebook marketing agency in Bur Dubai, offering creative, localized, and data-driven strategies to elevate your online presence and drive growth."
        />
        <meta
          name="keywords"
          content="Facebook marketing Bur Dubai, Facebook ads Bur Dubai, Facebook agency Bur Dubai, social media marketing Bur Dubai, digital marketing agency Bur Dubai, Dubai"
        />
        <meta property="og:title" content="Facebook Marketing Agency in Bur Dubai | MilaajBrandset" />
        <meta
          property="og:description"
          content="MilaajBrandset is a leading Facebook marketing agency in Bur Dubai, offering creative, localized, and data-driven strategies to elevate your online presence and drive growth."
        />
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "ProfessionalService",
              "name": "MilaajBrandset",
              "image": "https://milaajbrandset.com/favicon.ico",
              "description": "MilaajBrandset is a leading Facebook marketing agency in Bur Dubai, offering creative, localized, and data-driven strategies to elevate your online presence and drive growth.",
              "address": {
                "@type": "PostalAddress",
                "addressLocality": "Bur Dubai",
                "addressRegion": "Dubai",
                "addressCountry": "AE"
              },
              "geo": {
                "@type": "GeoCoordinates",
                "latitude": 25.2644, // Approximate latitude for Bur Dubai
                "longitude": 55.3079 // Approximate longitude for Bur Dubai
              }
            }
          `}
        </script>
      </Helmet>
      <FacebookMarketingAgencyInBurDubai />
    </>
  );
  

  export const SocialMediaGrowthServicesInBurDubaiSEO = () => (
    <>
      <Helmet>
        <title>Social Media Growth Services in Bur Dubai | MilaajBrandset</title>
        <meta
          name="description"
          content="MilaajBrandset offers expert social media growth services in Bur Dubai, combining creativity, localization, and data-driven strategies to elevate your online presence and drive growth."
        />
        <meta
          name="keywords"
          content="social media growth Bur Dubai, Instagram growth Bur Dubai, Facebook growth Bur Dubai, TikTok growth Bur Dubai, social media marketing Bur Dubai, digital marketing agency Bur Dubai, Dubai"
        />
        <meta property="og:title" content="Social Media Growth Services in Bur Dubai | MilaajBrandset" />
        <meta
          property="og:description"
          content="MilaajBrandset offers expert social media growth services in Bur Dubai, combining creativity, localization, and data-driven strategies to elevate your online presence and drive growth."
        />
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "ProfessionalService",
              "name": "MilaajBrandset",
              "image": "https://milaajbrandset.com/favicon.ico",
              "description": "MilaajBrandset offers expert social media growth services in Bur Dubai, combining creativity, localization, and data-driven strategies to elevate your online presence and drive growth.",
              "address": {
                "@type": "PostalAddress",
                "addressLocality": "Bur Dubai",
                "addressRegion": "Dubai",
                "addressCountry": "AE"
              },
              "geo": {
                "@type": "GeoCoordinates",
                "latitude": 25.2644, // Approximate latitude for Bur Dubai
                "longitude": 55.3079 // Approximate longitude for Bur Dubai
              }
            }
          `}
        </script>
      </Helmet>
      <SocialMediaGrowthServicesInBurDubai />
    </>
  );
  

  export const SocialMediaManagementCompanyInBurDubaiSEO = () => (
    <>
      <Helmet>
        <title>Social Media Management Company in Bur Dubai | MilaajBrandset</title>
        <meta
          name="description"
          content="MilaajBrandset is a leading social media management company in Bur Dubai, offering creative, localized, and data-driven strategies to elevate your online presence and drive growth."
        />
        <meta
          name="keywords"
          content="social media management Bur Dubai, Facebook management Bur Dubai, Instagram management Bur Dubai, TikTok management Bur Dubai, digital marketing agency Bur Dubai, Dubai"
        />
        <meta property="og:title" content="Social Media Management Company in Bur Dubai | MilaajBrandset" />
        <meta
          property="og:description"
          content="MilaajBrandset is a leading social media management company in Bur Dubai, offering creative, localized, and data-driven strategies to elevate your online presence and drive growth."
        />
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "ProfessionalService",
              "name": "MilaajBrandset",
              "image": "https://milaajbrandset.com/favicon.ico",
              "description": "MilaajBrandset is a leading social media management company in Bur Dubai, offering creative, localized, and data-driven strategies to elevate your online presence and drive growth.",
              "address": {
                "@type": "PostalAddress",
                "addressLocality": "Bur Dubai",
                "addressRegion": "Dubai",
                "addressCountry": "AE"
              },
              "geo": {
                "@type": "GeoCoordinates",
                "latitude": 25.2644, // Approximate latitude for Bur Dubai
                "longitude": 55.3079 // Approximate longitude for Bur Dubai
              }
            }
          `}
        </script>
      </Helmet>
      <SocialMediaManagementCompanyInBurDubai />
    </>
  );
  

  export const YouTubeAndVideoMarketingInBurDubaiSEO = () => (
    <>
      <Helmet>
        <title>YouTube and Video Marketing in Bur Dubai | MilaajBrandset</title>
        <meta
          name="description"
          content="MilaajBrandset offers expert YouTube and video marketing services in Bur Dubai, combining creativity, localization, and data-driven strategies to elevate your online presence and drive growth."
        />
        <meta
          name="keywords"
          content="YouTube marketing Bur Dubai, video marketing Bur Dubai, explainer videos Bur Dubai, YouTube ads Bur Dubai, video ads Bur Dubai, digital marketing agency Bur Dubai, Dubai"
        />
        <meta property="og:title" content="YouTube and Video Marketing in Bur Dubai | MilaajBrandset" />
        <meta
          property="og:description"
          content="MilaajBrandset offers expert YouTube and video marketing services in Bur Dubai, combining creativity, localization, and data-driven strategies to elevate your online presence and drive growth."
        />
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "ProfessionalService",
              "name": "MilaajBrandset",
              "image": "https://milaajbrandset.com/favicon.ico",
              "description": "MilaajBrandset offers expert YouTube and video marketing services in Bur Dubai, combining creativity, localization, and data-driven strategies to elevate your online presence and drive growth.",
              "address": {
                "@type": "PostalAddress",
                "addressLocality": "Bur Dubai",
                "addressRegion": "Dubai",
                "addressCountry": "AE"
              },
              "geo": {
                "@type": "GeoCoordinates",
                "latitude": 25.2644, // Approximate latitude for Bur Dubai
                "longitude": 55.3079 // Approximate longitude for Bur Dubai
              }
            }
          `}
        </script>
      </Helmet>
      <YouTubeAndVideoMarketingInBurDubai />
    </>
  );


  export const LinkedInMarketingExpertsInBurDubaiSEO = () => (
    <>
      <Helmet>
        <title>LinkedIn Marketing Experts in Bur Dubai | MilaajBrandset</title>
        <meta
          name="description"
          content="MilaajBrandset offers expert LinkedIn marketing services in Bur Dubai, combining creativity, localization, and data-driven strategies to elevate your professional presence and drive growth."
        />
        <meta
          name="keywords"
          content="LinkedIn marketing Bur Dubai, LinkedIn ads Bur Dubai, LinkedIn experts Bur Dubai, B2B marketing Bur Dubai, digital marketing agency Bur Dubai, Dubai"
        />
        <meta property="og:title" content="LinkedIn Marketing Experts in Bur Dubai | MilaajBrandset" />
        <meta
          property="og:description"
          content="MilaajBrandset offers expert LinkedIn marketing services in Bur Dubai, combining creativity, localization, and data-driven strategies to elevate your professional presence and drive growth."
        />
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "ProfessionalService",
              "name": "MilaajBrandset",
              "image": "https://milaajbrandset.com/favicon.ico",
              "description": "MilaajBrandset offers expert LinkedIn marketing services in Bur Dubai, combining creativity, localization, and data-driven strategies to elevate your professional presence and drive growth.",
              "address": {
                "@type": "PostalAddress",
                "addressLocality": "Bur Dubai",
                "addressRegion": "Dubai",
                "addressCountry": "AE"
              },
              "geo": {
                "@type": "GeoCoordinates",
                "latitude": 25.2644, // Approximate latitude for Bur Dubai
                "longitude": 55.3079 // Approximate longitude for Bur Dubai
              }
            }
          `}
        </script>
      </Helmet>
      <LinkedInMarketingExpertsInBurDubai />
    </>
  );
  

  export const TikTokMarketingAgencyInBurDubaiSEO = () => (
    <>
      <Helmet>
        <title>TikTok Marketing Agency in Bur Dubai | MilaajBrandset</title>
        <meta
          name="description"
          content="MilaajBrandset is a leading TikTok marketing agency in Bur Dubai, offering creative, localized, and data-driven strategies to elevate your online presence and drive growth."
        />
        <meta
          name="keywords"
          content="TikTok marketing Bur Dubai, TikTok ads Bur Dubai, TikTok agency Bur Dubai, social media marketing Bur Dubai, digital marketing agency Bur Dubai, Dubai"
        />
        <meta property="og:title" content="TikTok Marketing Agency in Bur Dubai | MilaajBrandset" />
        <meta
          property="og:description"
          content="MilaajBrandset is a leading TikTok marketing agency in Bur Dubai, offering creative, localized, and data-driven strategies to elevate your online presence and drive growth."
        />
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "ProfessionalService",
              "name": "MilaajBrandset",
              "image": "https://milaajbrandset.com/favicon.ico",
              "description": "MilaajBrandset is a leading TikTok marketing agency in Bur Dubai, offering creative, localized, and data-driven strategies to elevate your online presence and drive growth.",
              "address": {
                "@type": "PostalAddress",
                "addressLocality": "Bur Dubai",
                "addressRegion": "Dubai",
                "addressCountry": "AE"
              },
              "geo": {
                "@type": "GeoCoordinates",
                "latitude": 25.2644, // Approximate latitude for Bur Dubai
                "longitude": 55.3079 // Approximate longitude for Bur Dubai
              }
            }
          `}
        </script>
      </Helmet>
      <TikTokMarketingAgencyInBurDubai />
    </>
  );

  export const AffordableSocialMediaMarketingInBurDubaiSEO = () => (
    <>
      <Helmet>
        <title>Affordable Social Media Marketing in Bur Dubai | MilaajBrandset</title>
        <meta
          name="description"
          content="MilaajBrandset offers affordable social media marketing services in Bur Dubai, combining creativity, localization, and data-driven strategies to elevate your online presence and drive growth—without breaking the bank."
        />
        <meta
          name="keywords"
          content="affordable social media marketing Bur Dubai, cheap social media ads Bur Dubai, social media management Bur Dubai, digital marketing agency Bur Dubai, Dubai"
        />
        <meta property="og:title" content="Affordable Social Media Marketing in Bur Dubai | MilaajBrandset" />
        <meta
          property="og:description"
          content="MilaajBrandset offers affordable social media marketing services in Bur Dubai, combining creativity, localization, and data-driven strategies to elevate your online presence and drive growth—without breaking the bank."
        />
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "ProfessionalService",
              "name": "MilaajBrandset",
              "image": "https://milaajbrandset.com/favicon.ico",
              "description": "MilaajBrandset offers affordable social media marketing services in Bur Dubai, combining creativity, localization, and data-driven strategies to elevate your online presence and drive growth—without breaking the bank.",
              "address": {
                "@type": "PostalAddress",
                "addressLocality": "Bur Dubai",
                "addressRegion": "Dubai",
                "addressCountry": "AE"
              },
              "geo": {
                "@type": "GeoCoordinates",
                "latitude": 25.2644, // Approximate latitude for Bur Dubai
                "longitude": 55.3079 // Approximate longitude for Bur Dubai
              }
            }
          `}
        </script>
      </Helmet>
      <AffordableSocialMediaMarketingInBurDubai />
    </>
  );
  


  export const SocialMediaBrandingServicesInBurDubaiSEO = () => (
    <>
      <Helmet>
        <title>Social Media Branding Services in Bur Dubai | MilaajBrandset</title>
        <meta
          name="description"
          content="MilaajBrandset offers expert social media branding services in Bur Dubai, combining creativity, localization, and data-driven strategies to elevate your online presence and drive growth."
        />
        <meta
          name="keywords"
          content="social media branding Bur Dubai, brand identity Bur Dubai, social media strategy Bur Dubai, digital marketing agency Bur Dubai, Dubai"
        />
        <meta property="og:title" content="Social Media Branding Services in Bur Dubai | MilaajBrandset" />
        <meta
          property="og:description"
          content="MilaajBrandset offers expert social media branding services in Bur Dubai, combining creativity, localization, and data-driven strategies to elevate your online presence and drive growth."
        />
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "ProfessionalService",
              "name": "MilaajBrandset",
              "image": "https://milaajbrandset.com/favicon.ico",
              "description": "MilaajBrandset offers expert social media branding services in Bur Dubai, combining creativity, localization, and data-driven strategies to elevate your online presence and drive growth.",
              "address": {
                "@type": "PostalAddress",
                "addressLocality": "Bur Dubai",
                "addressRegion": "Dubai",
                "addressCountry": "AE"
              },
              "geo": {
                "@type": "GeoCoordinates",
                "latitude": 25.2644, // Approximate latitude for Bur Dubai
                "longitude": 55.3079 // Approximate longitude for Bur Dubai
              }
            }
          `}
        </script>
      </Helmet>
      <SocialMediaBrandingServicesInBurDubai />
    </>
  );
  
  export const EcommerceSocialMediaMarketingInBurDubaiSEO = () => (
    <>
      <Helmet>
        <title>E-commerce Social Media Marketing in Bur Dubai | MilaajBrandset</title>
        <meta
          name="description"
          content="MilaajBrandset offers expert e-commerce social media marketing services in Bur Dubai, combining creativity, localization, and data-driven strategies to elevate your online presence and drive sales."
        />
        <meta
          name="keywords"
          content="e-commerce social media marketing Bur Dubai, online store marketing Bur Dubai, social media ads Bur Dubai, digital marketing agency Bur Dubai, Dubai"
        />
        <meta property="og:title" content="E-commerce Social Media Marketing in Bur Dubai | MilaajBrandset" />
        <meta
          property="og:description"
          content="MilaajBrandset offers expert e-commerce social media marketing services in Bur Dubai, combining creativity, localization, and data-driven strategies to elevate your online presence and drive sales."
        />
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "ProfessionalService",
              "name": "MilaajBrandset",
              "image": "https://milaajbrandset.com/favicon.ico",
              "description": "MilaajBrandset offers expert e-commerce social media marketing services in Bur Dubai, combining creativity, localization, and data-driven strategies to elevate your online presence and drive sales.",
              "address": {
                "@type": "PostalAddress",
                "addressLocality": "Bur Dubai",
                "addressRegion": "Dubai",
                "addressCountry": "AE"
              },
              "geo": {
                "@type": "GeoCoordinates",
                "latitude": 25.2644, // Approximate latitude for Bur Dubai
                "longitude": 55.3079 // Approximate longitude for Bur Dubai
              }
            }
          `}
        </script>
      </Helmet>
      <EcommerceSocialMediaMarketingInBurDubai />
    </>
  );
  


  export const InfluencerMarketingAgencyInBurDubaiSEO = () => (
    <>
      <Helmet>
        <title>Influencer Marketing Agency in Bur Dubai | MilaajBrandset</title>
        <meta
          name="description"
          content="MilaajBrandset offers expert influencer marketing services in Bur Dubai, combining creativity, localization, and data-driven strategies to elevate your online presence and drive growth."
        />
        <meta
          name="keywords"
          content="influencer marketing Bur Dubai, social media influencers Bur Dubai, TikTok influencers Bur Dubai, Instagram influencers Bur Dubai, digital marketing agency Bur Dubai, Dubai"
        />
        <meta property="og:title" content="Influencer Marketing Agency in Bur Dubai | MilaajBrandset" />
        <meta
          property="og:description"
          content="MilaajBrandset offers expert influencer marketing services in Bur Dubai, combining creativity, localization, and data-driven strategies to elevate your online presence and drive growth."
        />
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "ProfessionalService",
              "name": "MilaajBrandset",
              "image": "https://milaajbrandset.com/favicon.ico",
              "description": "MilaajBrandset offers expert influencer marketing services in Bur Dubai, combining creativity, localization, and data-driven strategies to elevate your online presence and drive growth.",
              "address": {
                "@type": "PostalAddress",
                "addressLocality": "Bur Dubai",
                "addressRegion": "Dubai",
                "addressCountry": "AE"
              },
              "geo": {
                "@type": "GeoCoordinates",
                "latitude": 25.2644, // Approximate latitude for Bur Dubai
                "longitude": 55.3079 // Approximate longitude for Bur Dubai
              }
            }
          `}
        </script>
      </Helmet>
      <InfluencerMarketingAgencyInBurDubai />
    </>
  );
  

  export const PaidSocialMediaMarketingInBurDubaiSEO = () => (
    <>
      <Helmet>
        <title>Paid Social Media Marketing in Bur Dubai | MilaajBrandset</title>
        <meta
          name="description"
          content="MilaajBrandset offers expert paid social media marketing services in Bur Dubai, combining creativity, localization, and data-driven strategies to elevate your online presence and drive growth—without breaking the bank."
        />
        <meta
          name="keywords"
          content="paid social media marketing Bur Dubai, Facebook ads Bur Dubai, Instagram ads Bur Dubai, LinkedIn ads Bur Dubai, TikTok ads Bur Dubai, digital marketing agency Bur Dubai, Dubai"
        />
        <meta property="og:title" content="Paid Social Media Marketing in Bur Dubai | MilaajBrandset" />
        <meta
          property="og:description"
          content="MilaajBrandset offers expert paid social media marketing services in Bur Dubai, combining creativity, localization, and data-driven strategies to elevate your online presence and drive growth—without breaking the bank."
        />
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "ProfessionalService",
              "name": "MilaajBrandset",
              "image": "https://milaajbrandset.com/favicon.ico",
              "description": "MilaajBrandset offers expert paid social media marketing services in Bur Dubai, combining creativity, localization, and data-driven strategies to elevate your online presence and drive growth—without breaking the bank.",
              "address": {
                "@type": "PostalAddress",
                "addressLocality": "Bur Dubai",
                "addressRegion": "Dubai",
                "addressCountry": "AE"
              },
              "geo": {
                "@type": "GeoCoordinates",
                "latitude": 25.2644, // Approximate latitude for Bur Dubai
                "longitude": 55.3079 // Approximate longitude for Bur Dubai
              }
            }
          `}
        </script>
      </Helmet>
      <PaidSocialMediaMarketingInBurDubai />
    </>
  );

  export const SocialMediaAdvertisingAndPromotionsInBurDubaiSEO = () => (
    <>
      <Helmet>
        <title>Social Media Advertising and Promotions in Bur Dubai | MilaajBrandset</title>
        <meta
          name="description"
          content="MilaajBrandset offers expert social media advertising and promotions in Bur Dubai, combining creativity, localization, and data-driven strategies to elevate your online presence and drive growth."
        />
        <meta
          name="keywords"
          content="social media advertising Bur Dubai, social media promotions Bur Dubai, Facebook ads Bur Dubai, Instagram ads Bur Dubai, TikTok promotions Bur Dubai, digital marketing agency Bur Dubai, Dubai"
        />
        <meta property="og:title" content="Social Media Advertising and Promotions in Bur Dubai | MilaajBrandset" />
        <meta
          property="og:description"
          content="MilaajBrandset offers expert social media advertising and promotions in Bur Dubai, combining creativity, localization, and data-driven strategies to elevate your online presence and drive growth."
        />
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "ProfessionalService",
              "name": "MilaajBrandset",
              "image": "https://milaajbrandset.com/favicon.ico",
              "description": "MilaajBrandset offers expert social media advertising and promotions in Bur Dubai, combining creativity, localization, and data-driven strategies to elevate your online presence and drive growth.",
              "address": {
                "@type": "PostalAddress",
                "addressLocality": "Bur Dubai",
                "addressRegion": "Dubai",
                "addressCountry": "AE"
              },
              "geo": {
                "@type": "GeoCoordinates",
                "latitude": 25.2644, // Approximate latitude for Bur Dubai
                "longitude": 55.3079 // Approximate longitude for Bur Dubai
              }
            }
          `}
        </script>
      </Helmet>
      <SocialMediaAdvertisingAndPromotionsInBurDubai />
    </>
  );
  


  export const SocialMediaStrategyExpertsInBurDubaiSEO = () => (
    <>
      <Helmet>
        <title>Social Media Strategy Experts in Bur Dubai | MilaajBrandset</title>
        <meta
          name="description"
          content="MilaajBrandset offers expert social media strategy services in Bur Dubai, combining creativity, localization, and data-driven insights to elevate your online presence and drive growth."
        />
        <meta
          name="keywords"
          content="social media strategy Bur Dubai, social media planning Bur Dubai, digital marketing agency Bur Dubai, Instagram strategy Bur Dubai, Facebook strategy Bur Dubai, Dubai"
        />
        <meta property="og:title" content="Social Media Strategy Experts in Bur Dubai | MilaajBrandset" />
        <meta
          property="og:description"
          content="MilaajBrandset offers expert social media strategy services in Bur Dubai, combining creativity, localization, and data-driven insights to elevate your online presence and drive growth."
        />
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "ProfessionalService",
              "name": "MilaajBrandset",
              "image": "https://milaajbrandset.com/favicon.ico",
              "description": "MilaajBrandset offers expert social media strategy services in Bur Dubai, combining creativity, localization, and data-driven insights to elevate your online presence and drive growth.",
              "address": {
                "@type": "PostalAddress",
                "addressLocality": "Bur Dubai",
                "addressRegion": "Dubai",
                "addressCountry": "AE"
              },
              "geo": {
                "@type": "GeoCoordinates",
                "latitude": 25.2644, // Approximate latitude for Bur Dubai
                "longitude": 55.3079 // Approximate longitude for Bur Dubai
              }
            }
          `}
        </script>
      </Helmet>
      <SocialMediaStrategyExpertsInBurDubai />
    </>
  );
  

  export const GoogleAndSocialMediaMarketingInBurDubaiSEO = () => (
    <>
      <Helmet>
        <title>Google and Social Media Marketing in Bur Dubai | MilaajBrandset</title>
        <meta
          name="description"
          content="MilaajBrandset offers expert Google Ads and social media marketing services in Bur Dubai, combining creativity, localization, and data-driven strategies to elevate your online presence and drive growth."
        />
        <meta
          name="keywords"
          content="Google Ads Bur Dubai, social media marketing Bur Dubai, digital marketing agency Bur Dubai, Facebook ads Bur Dubai, Instagram marketing Bur Dubai, Dubai"
        />
        <meta property="og:title" content="Google and Social Media Marketing in Bur Dubai | MilaajBrandset" />
        <meta
          property="og:description"
          content="MilaajBrandset offers expert Google Ads and social media marketing services in Bur Dubai, combining creativity, localization, and data-driven strategies to elevate your online presence and drive growth."
        />
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "ProfessionalService",
              "name": "MilaajBrandset",
              "image": "https://milaajbrandset.com/favicon.ico",
              "description": "MilaajBrandset offers expert Google Ads and social media marketing services in Bur Dubai, combining creativity, localization, and data-driven strategies to elevate your online presence and drive growth.",
              "address": {
                "@type": "PostalAddress",
                "addressLocality": "Bur Dubai",
                "addressRegion": "Dubai",
                "addressCountry": "AE"
              },
              "geo": {
                "@type": "GeoCoordinates",
                "latitude": 25.2644, // Approximate latitude for Bur Dubai
                "longitude": 55.3079 // Approximate longitude for Bur Dubai
              }
            }
          `}
        </script>
      </Helmet>
      <GoogleAndSocialMediaMarketingInBurDubai />
    </>
  );
  

  export const LocalBusinessSocialMediaMarketingInBurDubaiSEO = () => (
    <>
      <Helmet>
        <title>Local Business Social Media Marketing in Bur Dubai | MilaajBrandset</title>
        <meta
          name="description"
          content="MilaajBrandset offers expert social media marketing services for local businesses in Bur Dubai, combining creativity, localization, and data-driven strategies to elevate your online presence and drive growth."
        />
        <meta
          name="keywords"
          content="local business social media marketing Bur Dubai, small business marketing Bur Dubai, Facebook ads for local businesses Bur Dubai, Instagram marketing for shops Bur Dubai, digital marketing agency Bur Dubai, Dubai"
        />
        <meta property="og:title" content="Local Business Social Media Marketing in Bur Dubai | MilaajBrandset" />
        <meta
          property="og:description"
          content="MilaajBrandset offers expert social media marketing services for local businesses in Bur Dubai, combining creativity, localization, and data-driven strategies to elevate your online presence and drive growth."
        />
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "ProfessionalService",
              "name": "MilaajBrandset",
              "image": "https://milaajbrandset.com/favicon.ico",
              "description": "MilaajBrandset offers expert social media marketing services for local businesses in Bur Dubai, combining creativity, localization, and data-driven strategies to elevate your online presence and drive growth.",
              "address": {
                "@type": "PostalAddress",
                "addressLocality": "Bur Dubai",
                "addressRegion": "Dubai",
                "addressCountry": "AE"
              },
              "geo": {
                "@type": "GeoCoordinates",
                "latitude": 25.2644, // Approximate latitude for Bur Dubai
                "longitude": 55.3079 // Approximate longitude for Bur Dubai
              }
            }
          `}
        </script>
      </Helmet>
      <LocalBusinessSocialMediaMarketingInBurDubai />
    </>
  );
  

