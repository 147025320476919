import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import Header from "Components/headers/PageHeader";
import MainFeature1 from "Components/Features/TwoColWithButton.js"; // Reused component
import Features from "Components/Features/ThreeColumnPages"; // Reused component
import {
  FaMobileAlt,
  FaLaptopCode,
  FaUsers,
  FaHandshake,
  FaAward,
  FaLightbulb,
} from "react-icons/fa";

// Styled Components
const Subheading = tw.span`uppercase tracking-wider text-sm`;
const Heading = tw.h1`text-2xl md:text-4xl lg:text-5xl font-bold`;
const Description = tw.p`text-base md:text-lg lg:text-xl`;

// Mobile-Friendly Website Development in Bur Dubai Page Component
export const MobileFriendlyWebsiteDevelopmentInBurDubai = () => {
  return (
    <AnimationRevealPage>
      {/* Header Section */}
      <Header from="Mobile-Friendly Website Development in Bur Dubai" />

      {/* Hero Section */}
      <MainFeature1
        subheading={
          <Subheading>
            Your Trusted Partner for Responsive Web Solutions
          </Subheading>
        }
        heading={
          <Heading>
            Elevate Your Online Presence with{" "}
            <span tw="text-primary-500">
              Mobile-Friendly Website Development in Bur Dubai
            </span>
          </Heading>
        }
        description={
          <Description>
            At MilaajBrandset, we specialize in delivering expert
            mobile-friendly website development services tailored for businesses
            in Bur Dubai. Our team of seasoned developers brings fresh ideas,
            technical expertise, and a client-centric approach to help
            businesses create websites that are responsive, fast-loading, and
            optimized for all devices, ensuring a seamless user experience.
          </Description>
        }
        imageSrcs={[
          "https://images.pexels.com/photos/607812/pexels-photo-607812.jpeg?auto=compress&cs=tinysrgb&w=500", // Mobile-friendly website on smartphone
          "https://images.pexels.com/photos/3184291/pexels-photo-3184291.jpeg?auto=compress&cs=tinysrgb&w=500", // Team collaborating on responsive design
          "https://images.pexels.com/photos/3182746/pexels-photo-3182746.jpeg?auto=compress&cs=tinysrgb&w=500", // Creative planning for mobile optimization
        ]}
        widths={["55%", "60%", "70%"]}
        heights={["45%", "60%", "35%"]}
        imageColumnStyle="height: 400px;"
        imageStyle="width: 45%; height: 60%;"
        imagePositions={[
          "left: 35%; top: 20%;",
          "right: 30%; top: 10%;",
          "left: 32%; bottom: 20%;",
        ]}
        primaryButtonText="Get Started Today"
        buttonRounded={true}
        textOnLeft={false}
      />

      {/* Key Benefits Section */}
      <Features
        subheading={<Subheading>Why Choose Us?</Subheading>}
        heading="Your Trusted Experts at Mobile-Friendly Website Development in Bur Dubai"
        description="We combine responsiveness, innovation, and measurable results to deliver exceptional value."
        cards={[
          {
            icon: <FaMobileAlt color="#ff5464" size={40} />,
            title: "Responsive Design",
            description:
              "Websites optimized for all devices, from desktops to smartphones.",
          },
          {
            icon: <FaLaptopCode color="#ff5464" size={40} />,
            title: "Fast Loading Speeds",
            description:
              "Optimized performance ensures lightning-fast loading times.",
          },
          {
            icon: <FaUsers color="#ff5464" size={40} />,
            title: "Audience-Centric Approach",
            description:
              "We prioritize user experience and seamless functionality.",
          },
          {
            icon: <FaHandshake color="#ff5464" size={40} />,
            title: "Client-Centric Solutions",
            description:
              "Tailored strategies designed to meet your unique goals.",
          },
          {
            icon: <FaAward color="#ff5464" size={40} />,
            title: "Proven Expertise",
            description:
              "Years of experience helping businesses succeed online.",
          },
          {
            icon: <FaLightbulb color="#ff5464" size={40} />,
            title: "Creative Excellence",
            description:
              "Fresh perspectives and innovative ideas for every project.",
          },
        ]}
      />

      {/* Our Process Section */}
      <MainFeature1
        subheading={<Subheading>How We Work</Subheading>}
        heading="Our Streamlined Mobile-Friendly Website Development Process"
        description={
          <Description>
            Our process ensures quality, efficiency, and measurable results:
            <ul tw="mt-4 list-disc pl-6">
              <li>
                Discovery & Research: Understand your business and target
                audience.
              </li>
              <li>
                Planning & Strategy: Define goals, features, and technical
                requirements.
              </li>
              <li>
                Design Development: Craft visually stunning and functional
                designs.
              </li>
              <li>
                Development & Testing: Build and rigorously test your website
                for responsiveness and performance.
              </li>
              <li>
                Launch & Support: Deploy your website and provide ongoing
                support.
              </li>
            </ul>
          </Description>
        }
        imageSrcs={[
          "https://images.pexels.com/photos/1181355/pexels-photo-1181355.jpeg?auto=compress&cs=tinysrgb&w=500", // Mobile-friendly design development process
          "https://images.pexels.com/photos/3184431/pexels-photo-3184431.jpeg?auto=compress&cs=tinysrgb&w=500", // Team strategizing responsive features
          "https://images.pexels.com/photos/3182832/pexels-photo-3182832.jpeg?auto=compress&cs=tinysrgb&w=500", // Testing mobile responsiveness
        ]}
        widths={["60%", "55%", "70%"]}
        heights={["60%", "45%", "35%"]}
        imageColumnStyle="height: 400px;"
        imageStyle="width: 45%; height: 60%;"
        imagePositions={[
          "right: 30%; top: 10%;",
          "left: 35%; bottom: 20%;",
          "right: 32%; bottom: 10%;",
        ]}
        primaryButtonText="Learn More"
        buttonRounded={true}
        textOnLeft={true}
      />

      {/* Success Stories Section */}
      <Features
        subheading={<Subheading>Success Stories</Subheading>}
        heading="Results That Speak for Themselves"
        description="Here’s how our mobile-friendly website development services have helped businesses grow in Bur Dubai."
        cards={[
          {
            icon: <FaAward color="#ff5464" size={40} />,
            title: "Improved User Engagement",
            description:
              "A local startup saw a 60% increase in mobile user engagement within three months.",
          },
          {
            icon: <FaHandshake color="#ff5464" size={40} />,
            title: "Client Satisfaction",
            description:
              "Achieved a 98% client retention rate through consistent results.",
          },
          {
            icon: <FaLightbulb color="#ff5464" size={40} />,
            title: "Enhanced Mobile Performance",
            description:
              "Reduced bounce rates by 50% with responsive design and fast loading speeds.",
          },
        ]}
      />

      {/* Call-to-Action Section */}
      <MainFeature1
        subheading={
          <Subheading>Ready to Optimize Your Website for Mobile?</Subheading>
        }
        heading="Let’s Grow Your Business Together"
        description={
          <Description>
            Partner with our expert mobile-friendly website development team in
            Bur Dubai to ensure your website delivers a seamless experience
            across all devices. Whether you’re looking to create a new website,
            optimize an existing one, or enhance functionality, we’re here to
            help.
          </Description>
        }
        imageSrcs={[
          "https://images.pexels.com/photos/3184423/pexels-photo-3184423.jpeg?auto=compress&cs=tinysrgb&w=500", // Happy team celebrating success
          "https://images.pexels.com/photos/607812/pexels-photo-607812.jpeg?auto=compress&cs=tinysrgb&w=500", // Mobile-friendly website visuals
          "https://images.pexels.com/photos/1232995/pexels-photo-1232995.jpeg?auto=compress&cs=tinysrgb&w=500", // Handshake symbolizing partnership
        ]}
        widths={["55%", "60%", "70%"]}
        heights={["45%", "60%", "35%"]}
        imageColumnStyle="height: 400px;"
        imageStyle="width: 45%; height: 60%;"
        imagePositions={[
          "left: 35%; top: 20%;",
          "right: 30%; top: 10%;",
          "left: 32%; bottom: 20%;",
        ]}
        primaryButtonText="Contact Us Today"
        buttonRounded={true}
        textOnLeft={false}
      />
    </AnimationRevealPage>
  );
};
