import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import Header from "Components/headers/PageHeader";
import MainFeature1 from "Components/Features/TwoColWithButton.js"; // Reused component
import Features from "Components/Features/ThreeColumnPages"; // Reused component
import {
  FaSearch,
  FaChartBar,
  FaUsers,
  FaHandshake,
  FaAward,
  FaLightbulb,
} from "react-icons/fa";

// Styled Components
const Subheading = tw.span`uppercase tracking-wider text-sm`;
const Heading = tw.h1`text-2xl md:text-4xl lg:text-5xl font-bold`;
const Description = tw.p`text-base md:text-lg lg:text-xl`;

// E-commerce SEO Company in DIFC Page Component
export const EcommerceSEOCompanyInDIFC = () => {
  return (
    <AnimationRevealPage>
      {/* Header Section */}
      <Header from="E-commerce SEO Company in DIFC" />

      {/* Hero Section */}
      <MainFeature1
        subheading={
          <Subheading>Your Trusted Partner for E-commerce Success</Subheading>
        }
        heading={
          <Heading>
            Elevate Your Online Store with{" "}
            <span tw="text-primary-500">E-commerce SEO Company in DIFC</span>
          </Heading>
        }
        description={
          <Description>
            At MilaajBrandset, we specialize in delivering expert e-commerce SEO
            services tailored for businesses in the Dubai International
            Financial Centre (DIFC). Our team of seasoned specialists brings
            data-driven strategies, fresh ideas, and a client-centric approach
            to help businesses optimize their online stores, improve search
            engine rankings, and achieve measurable results.
          </Description>
        }
        imageSrcs={[
          "https://images.unsplash.com/photo-1607083206968-13611e3d76db?w=1000", // E-commerce store dashboard
          "https://images.unsplash.com/photo-1560472355-536de3962603?w=1000", // Online shopping experience
          "https://images.unsplash.com/photo-1556155092-490a1ba16284?w=1000", // E-commerce analytics and growth
        ]}
        widths={["55%", "60%", "70%"]}
        heights={["45%", "60%", "35%"]}
        imageColumnStyle="height: 400px;"
        imageStyle="width: 45%; height: 60%;"
        imagePositions={[
          "left: 35%; top: 20%;",
          "right: 30%; top: 10%;",
          "left: 32%; bottom: 20%;",
        ]}
        primaryButtonText="Get Started Today"
        buttonRounded={true}
        textOnLeft={false}
      />

      {/* Key Benefits Section */}
      <Features
        subheading={<Subheading>Why Choose Us?</Subheading>}
        heading="Your Trusted Experts at the E-commerce SEO Company in DIFC"
        description="We combine specialization, innovation, and measurable results to deliver exceptional value."
        cards={[
          {
            icon: <FaSearch color="#ff5464" size={40} />,
            title: "Proven E-commerce SEO Strategies",
            description:
              "Our data-driven approach ensures maximum visibility for your products.",
          },
          {
            icon: <FaChartBar color="#ff5464" size={40} />,
            title: "Data-Driven Insights",
            description:
              "Backed by research to ensure maximum impact and growth.",
          },
          {
            icon: <FaUsers color="#ff5464" size={40} />,
            title: "Audience-Centric Approach",
            description:
              "We prioritize the needs and behaviors of your target audience.",
          },
          {
            icon: <FaHandshake color="#ff5464" size={40} />,
            title: "Client-Centric Solutions",
            description:
              "Tailored strategies designed to meet your unique goals.",
          },
          {
            icon: <FaAward color="#ff5464" size={40} />,
            title: "Proven Expertise",
            description:
              "Years of experience helping e-commerce businesses rank higher on Google.",
          },
          {
            icon: <FaLightbulb color="#ff5464" size={40} />,
            title: "Creative Excellence",
            description:
              "Fresh perspectives and innovative ideas for every project.",
          },
        ]}
      />

      {/* Our Process Section */}
      <MainFeature1
        subheading={<Subheading>How We Work</Subheading>}
        heading="Our Streamlined E-commerce SEO Process"
        description={
          <Description>
            Our process ensures quality, efficiency, and measurable results:
            <ul tw="mt-4 list-disc pl-6">
              <li>
                Discovery & Research: Understand your business and target
                audience.
              </li>
              <li>
                Keyword Analysis: Identify high-value keywords for your niche.
              </li>
              <li>
                Strategy Development: Create tailored SEO plans for your goals.
              </li>
              <li>
                Implementation: Optimize your website and product pages for
                search engines.
              </li>
              <li>
                Monitoring & Reporting: Track performance and adjust strategies
                for maximum impact.
              </li>
            </ul>
          </Description>
        }
        imageSrcs={[
          "https://images.unsplash.com/photo-1520333789090-1afc82db536a?w=1000", // E-commerce product optimization
          "https://images.unsplash.com/photo-1571026927189-c7ce126967df?w=1000", // Category structure planning
          "https://images.unsplash.com/photo-1541518763669-27fef04b14ea?w=1000", // Product listing optimization
        ]}
        widths={["60%", "55%", "70%"]}
        heights={["60%", "45%", "35%"]}
        imageColumnStyle="height: 400px;"
        imageStyle="width: 45%; height: 60%;"
        imagePositions={[
          "right: 30%; top: 10%;",
          "left: 35%; bottom: 20%;",
          "right: 32%; bottom: 10%;",
        ]}
        primaryButtonText="Learn More"
        buttonRounded={true}
        textOnLeft={true}
      />

      {/* Success Stories Section */}
      <Features
        subheading={<Subheading>Success Stories</Subheading>}
        heading="Results That Speak for Themselves"
        description="Here’s how our e-commerce SEO services have helped businesses grow in DIFC."
        cards={[
          {
            icon: <FaAward color="#ff5464" size={40} />,
            title: "Improved Rankings",
            description:
              "An online fashion store ranked #1 on Google for their primary keyword within three months.",
          },
          {
            icon: <FaHandshake color="#ff5464" size={40} />,
            title: "Client Satisfaction",
            description:
              "Achieved a 98% client retention rate through consistent results.",
          },
          {
            icon: <FaLightbulb color="#ff5464" size={40} />,
            title: "Increased Sales",
            description:
              "Boosted a retail client’s online sales by 200% in six months.",
          },
        ]}
      />

      {/* Call-to-Action Section */}
      <MainFeature1
        subheading={
          <Subheading>Ready to Boost Your E-commerce Business?</Subheading>
        }
        heading="Let’s Grow Your Online Store Together"
        description={
          <Description>
            Partner with our expert e-commerce SEO team in DIFC to unlock the
            full potential of your online store. Whether you’re looking to
            improve your rankings, increase traffic, or enhance your brand’s
            visibility, we’re here to help.
          </Description>
        }
        imageSrcs={[
          "https://images.unsplash.com/photo-1563013544-824ae1b704d3?w=1000", // E-commerce store success
          "https://images.unsplash.com/photo-1532619675605-1ede6c2ed2b0?w=1000", // Online shopping conversion
          "https://images.unsplash.com/photo-1563986768494-4dee2763ff3f?w=1000", // Dubai-based online retail growth
        ]}
        widths={["55%", "60%", "70%"]}
        heights={["45%", "60%", "35%"]}
        imageColumnStyle="height: 400px;"
        imageStyle="width: 45%; height: 60%;"
        imagePositions={[
          "left: 35%; top: 20%;",
          "right: 30%; top: 10%;",
          "left: 32%; bottom: 20%;",
        ]}
        primaryButtonText="Contact Us Today"
        buttonRounded={true}
        textOnLeft={false}
      />
    </AnimationRevealPage>
  );
};
