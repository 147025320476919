import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import Header from "Components/headers/PageHeader";
import MainFeature1 from "Components/Features/TwoColWithButton.js"; // Reused component
import Features from "Components/Features/ThreeColumnPages"; // Reused component
import {
  FaSearch,
  FaChartBar,
  FaUsers,
  FaHandshake,
  FaAward,
  FaLightbulb,
} from "react-icons/fa";

// Styled Components
const Subheading = tw.span`uppercase tracking-wider text-sm`;
const Heading = tw.h1`text-2xl md:text-4xl lg:text-5xl font-bold`;
const Description = tw.p`text-base md:text-lg lg:text-xl`;

// Small Business SEO Company in Bur Dubai Page Component
export const SmallBusinessSEOCompanyInBurDubai = () => {
  return (
    <AnimationRevealPage>
      {/* Header Section */}
      <Header from="Small Business SEO Company in Bur Dubai" />

      {/* Hero Section */}
      <MainFeature1
        subheading={<Subheading>Your Trusted SEO Partner for Small Businesses</Subheading>}
        heading={
          <Heading>
            Elevate Your Online Presence with{" "}
            <span tw="text-primary-500">Small Business SEO Company in Bur Dubai</span>
          </Heading>
        }
        description={
          <Description>
            At MilaajBrandset, we specialize in delivering expert SEO services tailored for small businesses in Bur Dubai. Our team of seasoned SEO specialists brings data-driven strategies, fresh ideas, and a client-centric approach to help small businesses improve their online visibility, drive organic traffic, and achieve measurable results—all within budget.
          </Description>
        }
        imageSrcs={[
            "https://images.unsplash.com/photo-1551288049-bebda4e38f71?w=1000", // Digital marketing team analyzing data
            "https://images.unsplash.com/photo-1579389083078-4e7018379f7e?w=1000", // SEO dashboard and analytics
            "https://images.unsplash.com/photo-1517245386807-bb43f82c33c4?w=1000" // Modern office meeting space
          ]}
        widths={["55%", "60%", "70%"]}
        heights={["45%", "60%", "35%"]}
        imageColumnStyle="height: 400px;"
        imageStyle="width: 45%; height: 60%;"
        imagePositions={[
          "left: 35%; top: 20%;",
          "right: 30%; top: 10%;",
          "left: 32%; bottom: 20%;",
        ]}
        primaryButtonText="Get Started Today"
        buttonRounded={true}
        textOnLeft={false}
      />

      {/* Key Benefits Section */}
      <Features
        subheading={<Subheading>Why Choose Us?</Subheading>}
        heading="Your Trusted Small Business SEO Experts in Bur Dubai"
        description="We combine affordability, innovation, and measurable results to deliver exceptional value."
        cards={[
          {
            icon: <FaSearch color="#ff5464" size={40} />,
            title: "Affordable SEO Strategies",
            description:
              "Tailored solutions designed to fit small business budgets.",
          },
          {
            icon: <FaChartBar color="#ff5464" size={40} />,
            title: "Data-Driven Insights",
            description:
              "Backed by research to ensure maximum impact and growth.",
          },
          {
            icon: <FaUsers color="#ff5464" size={40} />,
            title: "Audience-Centric Approach",
            description:
              "We prioritize the needs and behaviors of your target audience.",
          },
          {
            icon: <FaHandshake color="#ff5464" size={40} />,
            title: "Client-Centric Solutions",
            description:
              "Customized strategies to meet the unique goals of small businesses.",
          },
          {
            icon: <FaAward color="#ff5464" size={40} />,
            title: "Proven Expertise",
            description:
              "Years of experience helping small businesses rank higher on Google.",
          },
          {
            icon: <FaLightbulb color="#ff5464" size={40} />,
            title: "Creative Excellence",
            description:
              "Fresh perspectives and innovative ideas for every project.",
          },
        ]}
      />

      {/* Our Process Section */}
      <MainFeature1
        subheading={<Subheading>How We Work</Subheading>}
        heading="Our Streamlined Small Business SEO Process"
        description={
          <Description>
            Our process ensures quality, efficiency, and measurable results:
            <ul tw="mt-4 list-disc pl-6">
              <li>Discovery & Research: Understand your business and target audience.</li>
              <li>Keyword Analysis: Identify high-value keywords for your niche.</li>
              <li>Strategy Development: Create tailored SEO plans for your goals.</li>
              <li>Implementation: Optimize your website and content for search engines.</li>
              <li>Monitoring & Reporting: Track performance and adjust strategies for maximum impact.</li>
            </ul>
          </Description>
        }
        imageSrcs={[
            "https://images.unsplash.com/photo-1460925895917-afdab827c52f?w=1000", // SEO keyword research visualization
            "https://images.unsplash.com/photo-1553877522-43269d4ea984?w=1000", // Digital strategy planning
            "https://images.unsplash.com/photo-1432888622747-4eb9a8efeb07?w=1000" // Analytics and performance metrics
          ]}
        widths={["60%", "55%", "70%"]}
        heights={["60%", "45%", "35%"]}
        imageColumnStyle="height: 400px;"
        imageStyle="width: 45%; height: 60%;"
        imagePositions={[
          "right: 30%; top: 10%;",
          "left: 35%; bottom: 20%;",
          "right: 32%; bottom: 10%;",
        ]}
        primaryButtonText="Learn More"
        buttonRounded={true}
        textOnLeft={true}
      />

      {/* Success Stories Section */}
      <Features
        subheading={<Subheading>Success Stories</Subheading>}
        heading="Results That Speak for Themselves"
        description="Here’s how our small business SEO services have helped businesses grow in Bur Dubai."
        cards={[
          {
            icon: <FaAward color="#ff5464" size={40} />,
            title: "Improved Rankings",
            description:
              "A local startup ranked #1 on Google for their primary keyword within three months.",
          },
          {
            icon: <FaHandshake color="#ff5464" size={40} />,
            title: "Client Satisfaction",
            description:
              "Achieved a 98% client retention rate through consistent results.",
          },
          {
            icon: <FaLightbulb color="#ff5464" size={40} />,
            title: "Increased Traffic",
            description:
              "Boosted a retail client’s organic traffic by 150% in six months.",
          },
        ]}
      />

      {/* Call-to-Action Section */}
      <MainFeature1
        subheading={<Subheading>Ready to Boost Your Online Presence?</Subheading>}
        heading="Let’s Grow Your Small Business Together"
        description={
          <Description>
            Partner with our expert small business SEO company in Bur Dubai to unlock the full potential of your online presence. Whether you’re looking to improve your rankings, increase traffic, or enhance your brand’s visibility, we’re here to help.
          </Description>
        }
        imageSrcs={[
            "https://images.unsplash.com/photo-1552664730-d307ca884978?w=1000", // Team collaboration on digital strategy
            "https://images.unsplash.com/photo-1531973576160-7125cd663d86?w=1000", // Marketing success celebration
            "https://images.unsplash.com/photo-1450101499163-c8848c66ca85?w=1000" // Business growth representation
          ]}
        widths={["55%", "60%", "70%"]}
        heights={["45%", "60%", "35%"]}
        imageColumnStyle="height: 400px;"
        imageStyle="width: 45%; height: 60%;"
        imagePositions={[
          "left: 35%; top: 20%;",
          "right: 30%; top: 10%;",
          "left: 32%; bottom: 20%;",
        ]}
        primaryButtonText="Contact Us Today"
        buttonRounded={true}
        textOnLeft={false}
      />
    </AnimationRevealPage>
  );
};

