import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import Header from "Components/headers/PageHeader";
import MainFeature1 from "Components/Features/TwoColWithButton.js"; // Reused component
import Features from "Components/Features/ThreeColumnPages"; // Reused component
import {
  FaPaintBrush,
  FaChartBar,
  FaUsers,
  FaHandshake,
  FaAward,
  FaLightbulb,
} from "react-icons/fa";

// Styled Components
const Subheading = tw.span`uppercase tracking-wider text-sm`;
const Heading = tw.h1`text-2xl md:text-4xl lg:text-5xl font-bold`;
const Description = tw.p`text-base md:text-lg lg:text-xl`;

// Creative Branding Agency in Al Barsha Page Component
export const CreativeBrandingAgencyInAlBarsha = () => {
  return (
    <AnimationRevealPage>
      {/* Header Section */}
      <Header from="Creative Branding Agency in Al Barsha" />

      {/* Hero Section */}
      <MainFeature1
        subheading={
          <Subheading>Your Trusted Creative Branding Partner</Subheading>
        }
        heading={
          <Heading>
            Elevate Your Brand with{" "}
            <span tw="text-primary-500">
              Creative Branding Agency in Al Barsha
            </span>
          </Heading>
        }
        description={
          <Description>
            At MilaajBrandset, we specialize in delivering innovative and
            visually stunning branding solutions that captivate audiences and
            drive business growth. As a leading creative branding agency in Al
            Barsha, we bring fresh ideas, data-driven insights, and a
            client-centric approach to every project.
          </Description>
        }
        imageSrcs={[
          "https://images.unsplash.com/photo-1560179707-f14e90ef3623?w=1000", // Creative office space with designers
          "https://images.unsplash.com/photo-1572044162444-ad60f128bdea?w=1000", // Design team brainstorming
          "https://images.unsplash.com/photo-1545235617-9465d2a55698?w=1000", // Creative workspace with mood boards
        ]}
        widths={["55%", "60%", "70%"]}
        heights={["45%", "60%", "35%"]}
        imageColumnStyle="height: 400px;"
        imageStyle="width: 45%; height: 60%;"
        imagePositions={[
          "left: 35%; top: 20%;",
          "right: 30%; top: 10%;",
          "left: 32%; bottom: 20%;",
        ]}
        primaryButtonText="Get Started Today"
        buttonRounded={true}
        textOnLeft={false}
      />

      {/* Key Benefits Section */}
      <Features
        subheading={<Subheading>Why Choose Us?</Subheading>}
        heading="Your Trusted Creative Branding Experts in Al Barsha"
        description="We combine creativity, expertise, and measurable results to deliver exceptional value."
        cards={[
          {
            icon: <FaPaintBrush color="#ff5464" size={40} />,
            title: "Innovative Brand Identities",
            description:
              "Our designs create a strong and memorable brand presence.",
          },
          {
            icon: <FaChartBar color="#ff5464" size={40} />,
            title: "Data-Driven Insights",
            description: "Backed by research to ensure maximum impact and ROI.",
          },
          {
            icon: <FaUsers color="#ff5464" size={40} />,
            title: "Audience-Centric Approach",
            description:
              "We prioritize the needs and behaviors of your target audience.",
          },
          {
            icon: <FaHandshake color="#ff5464" size={40} />,
            title: "Client-Centric Solutions",
            description:
              "Tailored strategies designed to meet your unique goals.",
          },
          {
            icon: <FaAward color="#ff5464" size={40} />,
            title: "Proven Expertise",
            description: "Years of experience crafting successful brands.",
          },
          {
            icon: <FaLightbulb color="#ff5464" size={40} />,
            title: "Creative Excellence",
            description:
              "Fresh perspectives and innovative ideas for every project.",
          },
        ]}
      />

      {/* Our Process Section */}
      <MainFeature1
        subheading={<Subheading>How We Work</Subheading>}
        heading="Our Streamlined Creative Branding Process"
        description={
          <Description>
            Our process ensures quality, efficiency, and measurable results:
            <ul tw="mt-4 list-disc pl-6">
              <li>
                Discovery & Research: Understand your business and target
                audience.
              </li>
              <li>
                Strategy Development: Create tailored plans for your goals.
              </li>
              <li>
                Design Development: Craft visually appealing and cohesive brand
                elements.
              </li>
              <li>
                Implementation: Roll out your brand identity across platforms.
              </li>
            </ul>
          </Description>
        }
        imageSrcs={[
          "https://images.unsplash.com/photo-1581291518633-83b4ebd1d83e?w=1000", // Design workshop in progress
          "https://images.unsplash.com/photo-1576267423048-15c0040fec78?w=1000", // Creative team collaboration
          "https://images.unsplash.com/photo-1581291519195-ef11498d1cf2?w=1000", // Brand strategy development session
        ]}
        widths={["60%", "55%", "70%"]}
        heights={["60%", "45%", "35%"]}
        imageColumnStyle="height: 400px;"
        imageStyle="width: 45%; height: 60%;"
        imagePositions={[
          "right: 30%; top: 10%;",
          "left: 35%; bottom: 20%;",
          "right: 32%; bottom: 10%;",
        ]}
        primaryButtonText="Learn More"
        buttonRounded={true}
        textOnLeft={true}
      />

      {/* Success Stories Section */}
      <Features
        subheading={<Subheading>Success Stories</Subheading>}
        heading="Results That Speak for Themselves"
        description="Here’s how our creative branding services have helped businesses grow in Al Barsha."
        cards={[
          {
            icon: <FaAward color="#ff5464" size={40} />,
            title: "Brand Growth",
            description:
              "A local startup saw a 350% increase in brand recognition within six months.",
          },
          {
            icon: <FaHandshake color="#ff5464" size={40} />,
            title: "Client Satisfaction",
            description:
              "Achieved a 95% client retention rate through consistent results.",
          },
          {
            icon: <FaLightbulb color="#ff5464" size={40} />,
            title: "Creative Solutions",
            description:
              "Boosted a retail client’s sales by 60% with a rebranded identity.",
          },
        ]}
      />

      {/* Call-to-Action Section */}
      <MainFeature1
        subheading={<Subheading>Ready to Elevate Your Brand?</Subheading>}
        heading="Let’s Build Your Brand Together"
        description={
          <Description>
            Partner with our expert creative branding agency in Al Barsha to
            unlock the full potential of your brand. Whether you’re looking to
            refresh your identity or launch a new brand strategy, we’re here to
            help.
          </Description>
        }
        imageSrcs={[
          "https://images.unsplash.com/photo-1600880292203-757bb62b4baf?w=1000", // Modern creative studio
          "https://images.unsplash.com/photo-1613979813737-c59e3c9ed89f?w=1000", // Design presentation meeting
          "https://images.unsplash.com/photo-1601933973783-43cf8a7d4c5f?w=1000", // Innovative workspace environment
        ]}
        widths={["55%", "60%", "70%"]}
        heights={["45%", "60%", "35%"]}
        imageColumnStyle="height: 400px;"
        imageStyle="width: 45%; height: 60%;"
        imagePositions={[
          "left: 35%; top: 20%;",
          "right: 30%; top: 10%;",
          "left: 32%; bottom: 20%;",
        ]}
        primaryButtonText="Contact Us Today"
        buttonRounded={true}
        textOnLeft={false}
      />
    </AnimationRevealPage>
  );
};
