import { AffordableSeoServicesBurDubai } from "Pages/SeoPages/Seo/AffordableSeoServicesBurDubai";
import { LocalSeoServicesAlBarsha } from "Pages/SeoPages/Seo/al_barsha/LocalSeoServicesAlBarsha";
import { SeoAgencyBurDubai } from "Pages/SeoPages/Seo/bur_dubai/SeoAgencyBurDubai";
import { DifcSeoExpertsSmallBusinesses } from "Pages/SeoPages/Seo/DifcSeoExpertsSmallBusinesses";
import { SeoAgencyDubai } from "Pages/SeoPages/Seo/bur_dubai/SeoAgencyDubai";
import { ExpertSEOInBurDubai } from "Pages/SeoPages/Seo/ExpertSEOInBurDubai";
import { Helmet } from "react-helmet";

export const SeoAgencyBurDubaiPage = () => (
    <>
      <Helmet>
        <title>SEO Agency in Bur Dubai | MilaajBrandset</title>
        <meta
          name="description"
          content="MilaajBrandset is an SEO agency in Bur Dubai, offering strategic SEO solutions to enhance your online presence and drive organic traffic."
        />
        <meta
          name="keywords"
          content="SEO agency in Bur Dubai, Bur Dubai SEO, SEO services Dubai, digital marketing Dubai"
        />
        <meta
          property="og:title"
          content="SEO Agency in Bur Dubai | MilaajBrandset"
        />
        <meta
          property="og:description"
          content="An SEO agency in Bur Dubai offering strategic SEO solutions to enhance your online presence and drive organic traffic."
        />
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "ProfessionalService",
              "name": "MilaajBrandset",
              "image": "https://milaajbrandset.com/favicon.ico",
              "description": "An SEO agency in Bur Dubai offering strategic SEO solutions to enhance your online presence and drive organic traffic.",
              "address": {
                "@type": "PostalAddress",
                "streetAddress": "Bur Dubai",
                "addressLocality": "Dubai",
                "postalCode": "XXXXX",
                "addressCountry": "AE"
              },
              "geo": {
                "@type": "GeoCoordinates",
                "latitude": 25.2631,
                "longitude": 55.2975
              }
            }
          `}
        </script>
      </Helmet>
      <SeoAgencyBurDubai />
    </>
  );
  
  
  
  
  export const SeoAgencyDubaiPage = () => (
    <>
      <Helmet>
        <title>SEO Agency in Dubai | MilaajBrandset</title>
        <meta
          name="description"
          content="MilaajBrandset is an SEO agency in Dubai, offering tailored search engine optimization solutions for businesses. From keyword research to technical SEO, we help you rank higher in Dubai search engines."
        />
        <meta
          name="keywords"
          content="SEO agency in Dubai, Dubai SEO, search engine optimization Dubai, local SEO Dubai"
        />
        <meta
          property="og:title"
          content="SEO Agency in Dubai | MilaajBrandset"
        />
        <meta
          property="og:description"
          content="An SEO agency in Dubai offering tailored search engine optimization solutions for businesses. From keyword research to technical SEO, we help you rank higher in Dubai search engines."
        />
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "ProfessionalService",
              "name": "MilaajBrandset",
              "image": "https://milaajbrandset.com/favicon.ico",
              "description": "An SEO agency in Dubai offering tailored search engine optimization solutions for businesses.",
              "address": {
                "@type": "PostalAddress",
                "streetAddress": "Dubai",
                "addressLocality": "Dubai",
                "postalCode": "XXXXX",
                "addressCountry": "AE"
              },
              "geo": {
                "@type": "GeoCoordinates",
                "latitude": 25.2631,
                "longitude": 55.2975
              }
            }
          `}
        </script>
      </Helmet>
      <SeoAgencyDubai />
    </>
  );
  
  
  export const LocalSeoServicesAlBarshaPage = () => (
    <>
      <Helmet>
        <title>Local SEO Services in Al Barsha | MilaajBrandset</title>
        <meta
          name="description"
          content="MilaajBrandset is a local SEO agency in Al Barsha, offering tailored search engine optimization solutions for businesses. From keyword research to technical SEO, we help you rank higher in Al Barsha search engines."
        />
        <meta
          name="keywords"
          content="local SEO services in Al Barsha, Al Barsha SEO, search engine optimization Al Barsha, local SEO Al Barsha"
        />
        <meta
          property="og:title"
          content="Local SEO Services in Al Barsha | MilaajBrandset"
        />
        <meta
          property="og:description"
          content="A local SEO agency in Al Barsha offering tailored search engine optimization solutions for businesses. From keyword research to technical SEO, we help you rank higher in Al Barsha search engines."
        />
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "ProfessionalService",
              "name": "MilaajBrandset",
              "image": "https://milaajbrandset.com/favicon.ico",
              "description": "A local SEO agency in Al Barsha offering tailored search engine optimization solutions for businesses.",
              "address": {
                "@type": "PostalAddress",
                "streetAddress": "Al Barsha",
                "addressLocality": "Dubai",
                "postalCode": "XXXXX",
                "addressCountry": "AE"
              },
              "geo": {
                "@type": "GeoCoordinates",
                "latitude": 25.2631,
                "longitude": 55.2975
              }
            }
          `}
        </script>
      </Helmet>
      <LocalSeoServicesAlBarsha />
    </>
  );



export const DifcSeoExpertsSmallBusinessesPage = () => (
  <>
    <Helmet>
      <title>DIFC SEO Experts for Small Businesses | MilaajBrandset</title>
      <meta
        name="description"
        content="MilaajBrandset is a DIFC SEO agency specializing in search engine optimization for small businesses. From keyword research to technical SEO, we help you rank higher in DIFC search engines."
      />
      <meta
        name="keywords"
        content="DIFC SEO experts, small business SEO DIFC, SEO for small businesses in DIFC, local SEO DIFC"
      />
      <meta
        property="og:title"
        content="DIFC SEO Experts for Small Businesses | MilaajBrandset"
      />
      <meta
        property="og:description"
        content="A DIFC SEO agency specializing in search engine optimization for small businesses. From keyword research to technical SEO, we help you rank higher in DIFC search engines."
      />
      <script type="application/ld+json">
        {`
          {
            "@context": "https://schema.org",
            "@type": "ProfessionalService",
            "name": "MilaajBrandset",
            "image": "https://milaajbrandset.com/favicon.ico",
            "description": "A DIFC SEO agency specializing in search engine optimization for small businesses.",
            "address": {
              "@type": "PostalAddress",
              "streetAddress": "DIFC",
              "addressLocality": "Dubai",
              "postalCode": "XXXXX",
              "addressCountry": "AE"
            },
            "geo": {
              "@type": "GeoCoordinates",
              "latitude": 25.2631,
              "longitude": 55.2975
            }
          }
        `}
      </script>
    </Helmet>
    <DifcSeoExpertsSmallBusinesses />
  </>
);

export const AffordableSeoServicesBurDubaiPage = () => (
  <>
    <Helmet>
      <title>Affordable SEO Services in Bur Dubai | MilaajBrandset</title>
      <meta
        name="description"
        content="MilaajBrandset offers affordable SEO services in Bur Dubai, helping businesses grow without breaking the bank. From keyword research to technical SEO, we provide high-quality solutions tailored to your budget and needs."
      />
      <meta
        name="keywords"
        content="affordable SEO services in Bur Dubai, SEO for small businesses Bur Dubai, budget-friendly SEO Bur Dubai, local SEO Bur Dubai"
      />
      <meta
        property="og:title"
        content="Affordable SEO Services in Bur Dubai | MilaajBrandset"
      />
      <meta
        property="og:description"
        content="MilaajBrandset offers affordable SEO services in Bur Dubai, helping businesses grow without breaking the bank. From keyword research to technical SEO, we provide high-quality solutions tailored to your budget and needs."
      />
      <script type="application/ld+json">
        {`
          {
            "@context": "https://schema.org",
            "@type": "ProfessionalService",
            "name": "MilaajBrandset",
            "image": "https://milaajbrandset.com/favicon.ico",
            "description": "MilaajBrandset offers affordable SEO services in Bur Dubai, helping businesses grow without breaking the bank.",
            "address": {
              "@type": "PostalAddress",
              "streetAddress": "Bur Dubai",
              "addressLocality": "Dubai",
              "postalCode": "XXXXX",
              "addressCountry": "AE"
            },
            "geo": {
              "@type": "GeoCoordinates",
              "latitude": 25.2631,
              "longitude": 55.2975
            }
          }
        `}
      </script>
    </Helmet>
    <AffordableSeoServicesBurDubai />
  </>
);

export const ExpertSEOSEO = () => (
  <>
    <Helmet>
      <title>Expert SEO Services in Bur Dubai | MilaajBrandset</title>
      <meta
        name="description"
        content="MilaajBrandset offers expert SEO services in Bur Dubai, including keyword optimization, technical SEO, and local SEO. Improve your search rankings and drive more organic traffic to your website."
      />
      <meta
        name="keywords"
        content="SEO services Bur Dubai, expert SEO Bur Dubai, local SEO Bur Dubai, keyword optimization Bur Dubai"
      />
      <meta
        property="og:title"
        content="Expert SEO Services in Bur Dubai | MilaajBrandset"
      />
      <meta
        property="og:description"
        content="MilaajBrandset offers expert SEO services in Bur Dubai, including keyword optimization, technical SEO, and local SEO. Improve your search rankings and drive more organic traffic to your website."
      />
      <script type="application/ld+json">
        {`
          {
            "@context": "https://schema.org",
            "@type": "ProfessionalService",
            "name": "MilaajBrandset",
            "image": "https://milaajbrandset.com/favicon.ico",
            "description": "MilaajBrandset provides expert SEO services in Bur Dubai, helping businesses improve their search rankings and drive organic traffic.",
            "address": {
              "@type": "PostalAddress",
              "streetAddress": "Bur Dubai",
              "addressLocality": "Dubai",
              "postalCode": "XXXXX",
              "addressCountry": "AE"
            },
            "geo": {
              "@type": "GeoCoordinates",
              "latitude": 25.2631,
              "longitude": 55.2975
            }
          }
        `}
      </script>
    </Helmet>
    <ExpertSEOInBurDubai />
  </>
);

