import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import Header from "Components/headers/PageHeader";
import MainFeature1 from "Components/Features/TwoColWithButton.js"; // Reused component
import Features from "Components/Features/ThreeColumnPages"; // Reused component
import {
  FaTrophy,
  FaServer,
  FaShieldAlt,
  FaUsers,
  FaHandshake,
  FaAward,
} from "react-icons/fa";

// Styled Components
const Subheading = tw.span`uppercase tracking-wider text-sm`;
const Heading = tw.h1`text-2xl md:text-4xl lg:text-5xl font-bold`;
const Description = tw.p`text-base md:text-lg lg:text-xl`;

// Top Web Hosting Provider in Bur Dubai Page Component
export const TopWebHostingProviderInBurDubai = () => {
  return (
    <AnimationRevealPage>
      {/* Header Section */}
      <Header from="Top Web Hosting Provider in Bur Dubai" />

      {/* Hero Section */}
      <MainFeature1
        subheading={<Subheading>Your Trusted Leader in Web Hosting</Subheading>}
        heading={
          <Heading>
            Elevate Your Online Presence with{" "}
            <span tw="text-primary-500">
              Top Web Hosting Provider in Bur Dubai
            </span>
          </Heading>
        }
        description={
          <Description>
            At MilaajBrandset, we are proud to be recognized as the top web
            hosting provider in Bur Dubai. Our team of seasoned professionals
            ensures your website remains secure, fast, and scalable, providing
            exceptional hosting services that meet the unique needs of your
            business. With a proven track record of delivering reliable and
            innovative solutions, we are your trusted partner for all web
            hosting needs.
          </Description>
        }
        imageSrcs={[
          "https://images.unsplash.com/photo-1557682250-33bd709cbe85?w=800&auto=format&fit=crop&q=80&ixlib=rb-4.0.3", // Modern server room
          "https://images.unsplash.com/photo-1573164713988-8665fc963095?w=800&auto=format&fit=crop&q=80&ixlib=rb-4.0.3", // Professional data center
          "https://images.unsplash.com/photo-1558494949-ef010cbdcc31?w=800&auto=format&fit=crop&q=80&ixlib=rb-4.0.3", // Close-up of networking equipment
        ]}
        widths={["55%", "60%", "70%"]}
        heights={["45%", "60%", "35%"]}
        imageColumnStyle="height: 400px;"
        imageStyle="width: 45%; height: 60%;"
        imagePositions={[
          "left: 35%; top: 20%;",
          "right: 30%; top: 10%;",
          "left: 32%; bottom: 20%;",
        ]}
        primaryButtonText="Get Started Today"
        buttonRounded={true}
        textOnLeft={false}
      />

      {/* Key Benefits Section */}
      <Features
        subheading={<Subheading>Why Choose Us?</Subheading>}
        heading="Your Trusted Experts at Top Web Hosting Services in Bur Dubai"
        description="We combine leadership, reliability, and innovation to deliver exceptional value."
        cards={[
          {
            icon: <FaTrophy color="#ff5464" size={40} />,
            title: "Industry Leadership",
            description:
              "Recognized as the top web hosting provider in Bur Dubai.",
          },
          {
            icon: <FaServer color="#ff5464" size={40} />,
            title: "High-Performance Servers",
            description:
              "Ensure your website is fast, reliable, and always online.",
          },
          {
            icon: <FaShieldAlt color="#ff5464" size={40} />,
            title: "Enhanced Security",
            description:
              "Protect your website and data from cyber threats with proactive measures.",
          },
          {
            icon: <FaUsers color="#ff5464" size={40} />,
            title: "Audience-Centric Approach",
            description:
              "We prioritize user experience and seamless functionality.",
          },
          {
            icon: <FaHandshake color="#ff5464" size={40} />,
            title: "Client-Centric Solutions",
            description:
              "Tailored strategies designed to meet your unique goals.",
          },
          {
            icon: <FaAward color="#ff5464" size={40} />,
            title: "Proven Track Record",
            description:
              "Delivering exceptional results for businesses of all sizes.",
          },
        ]}
      />

      {/* Our Process Section */}
      <MainFeature1
        subheading={<Subheading>How We Work</Subheading>}
        heading="Our Streamlined Web Hosting Process"
        description={
          <Description>
            Our process ensures quality, efficiency, and measurable results:
            <ul tw="mt-4 list-disc pl-6">
              <li>
                Discovery & Research: Understand your business and technical
                requirements.
              </li>
              <li>
                Planning & Strategy: Define hosting plans and server
                configurations.
              </li>
              <li>
                Implementation: Deploy your website on secure and scalable
                servers.
              </li>
              <li>
                Monitoring & Optimization: Continuously monitor performance and
                optimize resources.
              </li>
              <li>
                Ongoing Support: Provide 24/7 assistance and resolve issues
                promptly.
              </li>
            </ul>
          </Description>
        }
        imageSrcs={[
          "https://images.unsplash.com/photo-1551288049-bebda4e38f71?w=800&auto=format&fit=crop&q=80&ixlib=rb-4.0.3", // Server infrastructure
          "https://images.unsplash.com/photo-1504868584819-f8e8b4b6d7e3?w=800&auto=format&fit=crop&q=80&ixlib=rb-4.0.3", // Team collaborating on technical project
          "https://images.unsplash.com/photo-1581092918056-0c4c3acd3789?w=800&auto=format&fit=crop&q=80&ixlib=rb-4.0.3", // Server monitoring dashboard
        ]}
        widths={["60%", "55%", "70%"]}
        heights={["60%", "45%", "35%"]}
        imageColumnStyle="height: 400px;"
        imageStyle="width: 45%; height: 60%;"
        imagePositions={[
          "right: 30%; top: 10%;",
          "left: 35%; bottom: 20%;",
          "right: 32%; bottom: 10%;",
        ]}
        primaryButtonText="Learn More"
        buttonRounded={true}
        textOnLeft={true}
      />

      {/* Success Stories Section */}
      <Features
        subheading={<Subheading>Success Stories</Subheading>}
        heading="Results That Speak for Themselves"
        description="Here’s how our top web hosting services have helped businesses grow in Bur Dubai."
        cards={[
          {
            icon: <FaAward color="#ff5464" size={40} />,
            title: "Improved Uptime",
            description:
              "A local startup achieved 99.9% uptime with our reliable hosting services.",
          },
          {
            icon: <FaHandshake color="#ff5464" size={40} />,
            title: "Client Satisfaction",
            description:
              "Achieved a 98% client retention rate through consistent results.",
          },
          {
            icon: <FaTrophy color="#ff5464" size={40} />,
            title: "Leadership in Innovation",
            description:
              "Implemented cutting-edge technologies to enhance performance and scalability.",
          },
        ]}
      />

      {/* Call-to-Action Section */}
      <MainFeature1
        subheading={<Subheading>Ready to Partner with the Best?</Subheading>}
        heading="Let’s Grow Your Business Together"
        description={
          <Description>
            Partner with the leading web hosting provider in Bur Dubai to unlock
            the full potential of your online presence. Whether you’re looking
            to host a new website, migrate an existing one, or enhance
            performance, we’re here to help.
          </Description>
        }
        imageSrcs={[
          "https://images.unsplash.com/photo-1600880292089-90a7e086ee0c?w=800&auto=format&fit=crop&q=80&ixlib=rb-4.0.3", // Business handshake in modern office
          "https://images.unsplash.com/photo-1606765962248-7ff407b51667?w=800&auto=format&fit=crop&q=80&ixlib=rb-4.0.3", // Server rack with blue lighting
          "https://images.unsplash.com/photo-1498050108023-c5249f4df085?w=800&auto=format&fit=crop&q=80&ixlib=rb-4.0.3", // Coding/development work
        ]}
        widths={["55%", "60%", "70%"]}
        heights={["45%", "60%", "35%"]}
        imageColumnStyle="height: 400px;"
        imageStyle="width: 45%; height: 60%;"
        imagePositions={[
          "left: 35%; top: 20%;",
          "right: 30%; top: 10%;",
          "left: 32%; bottom: 20%;",
        ]}
        primaryButtonText="Contact Us Today"
        buttonRounded={true}
        textOnLeft={false}
      />
    </AnimationRevealPage>
  );
};
