import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import Header from "Components/headers/PageHeader";
import MainFeature1 from "Components/Features/TwoColWithButton.js"; // Reused component
import Features from "Components/Features/ThreeColumnPages"; // Reused component
import {
  FaSearch,
  FaChartBar,
  FaUsers,
  FaHandshake,
  FaAward,
  FaLightbulb,
} from "react-icons/fa";

// Styled Components
const Subheading = tw.span`uppercase tracking-wider text-sm`;
const Heading = tw.h1`text-2xl md:text-4xl lg:text-5xl font-bold`;
const Description = tw.p`text-base md:text-lg lg:text-xl`;

// Affordable SEO Company in DIFC Page Component
export const AffordableSEOCompanyInDIFC = () => {
  return (
    <AnimationRevealPage>
      {/* Header Section */}
      <Header from="Affordable SEO Company in DIFC" />

      {/* Hero Section */}
      <MainFeature1
        subheading={<Subheading>Your Trusted Partner for Online Success</Subheading>}
        heading={
          <Heading>
            Elevate Your Online Presence with{" "}
            <span tw="text-primary-500">Affordable SEO Company in DIFC</span>
          </Heading>
        }
        description={
          <Description>
            At MilaajBrandset, we specialize in delivering expert yet affordable SEO services tailored for businesses in the Dubai International Financial Centre (DIFC). Our team of seasoned SEO specialists brings data-driven strategies, fresh ideas, and a client-centric approach to help businesses improve their search engine rankings, drive organic traffic, and achieve measurable results—without breaking the bank.
          </Description>
        }
        imageSrcs={[
            "https://images.unsplash.com/photo-1460925895917-afdab827c52f?w=1000", // SEO dashboard visualization showing affordability
            "https://images.unsplash.com/photo-1546412414-e1885259563a?w=1000", // Dubai skyline with business district
            "https://images.unsplash.com/photo-1551288049-bebda4e38f71?w=1000", // Team working on cost-effective digital strategies
          ]}
        widths={["55%", "60%", "70%"]}
        heights={["45%", "60%", "35%"]}
        imageColumnStyle="height: 400px;"
        imageStyle="width: 45%; height: 60%;"
        imagePositions={[
          "left: 35%; top: 20%;",
          "right: 30%; top: 10%;",
          "left: 32%; bottom: 20%;",
        ]}
        primaryButtonText="Get Started Today"
        buttonRounded={true}
        textOnLeft={false}
      />

      {/* Key Benefits Section */}
      <Features
        subheading={<Subheading>Why Choose Us?</Subheading>}
        heading="Your Trusted Experts at the Affordable SEO Company in DIFC"
        description="We combine affordability, innovation, and measurable results to deliver exceptional value."
        cards={[
          {
            icon: <FaSearch color="#ff5464" size={40} />,
            title: "Cost-Effective SEO Strategies",
            description:
              "Our affordable solutions ensure maximum ROI without compromising quality.",
          },
          {
            icon: <FaChartBar color="#ff5464" size={40} />,
            title: "Data-Driven Insights",
            description:
              "Backed by research to ensure maximum impact and growth.",
          },
          {
            icon: <FaUsers color="#ff5464" size={40} />,
            title: "Audience-Centric Approach",
            description:
              "We prioritize the needs and behaviors of your target audience.",
          },
          {
            icon: <FaHandshake color="#ff5464" size={40} />,
            title: "Client-Centric Solutions",
            description:
              "Tailored strategies designed to meet your unique goals.",
          },
          {
            icon: <FaAward color="#ff5464" size={40} />,
            title: "Proven Expertise",
            description:
              "Years of experience helping businesses rank higher on Google.",
          },
          {
            icon: <FaLightbulb color="#ff5464" size={40} />,
            title: "Creative Excellence",
            description:
              "Fresh perspectives and innovative ideas for every project.",
          },
        ]}
      />

      {/* Our Process Section */}
      <MainFeature1
        subheading={<Subheading>How We Work</Subheading>}
        heading="Our Streamlined SEO Process"
        description={
          <Description>
            Our process ensures quality, efficiency, and measurable results:
            <ul tw="mt-4 list-disc pl-6">
              <li>Discovery & Research: Understand your business and target audience.</li>
              <li>Keyword Analysis: Identify high-value keywords for your niche.</li>
              <li>Strategy Development: Create tailored SEO plans for your goals.</li>
              <li>Implementation: Optimize your website and content for search engines.</li>
              <li>Monitoring & Reporting: Track performance and adjust strategies for maximum impact.</li>
            </ul>
          </Description>
        }
        imageSrcs={[
            "https://images.unsplash.com/photo-1584291527918-8d0645edc3d5?w=1000", // Analysis of budget-friendly SEO options
            "https://images.unsplash.com/photo-1556155092-490a1ba16284?w=1000", // Team planning affordable SEO strategies
            "https://images.unsplash.com/photo-1533749871411-5e21e14bcc7d?w=1000", // Value-based SEO tools and metrics
          ]}
        widths={["60%", "55%", "70%"]}
        heights={["60%", "45%", "35%"]}
        imageColumnStyle="height: 400px;"
        imageStyle="width: 45%; height: 60%;"
        imagePositions={[
          "right: 30%; top: 10%;",
          "left: 35%; bottom: 20%;",
          "right: 32%; bottom: 10%;",
        ]}
        primaryButtonText="Learn More"
        buttonRounded={true}
        textOnLeft={true}
      />

      {/* Success Stories Section */}
      <Features
        subheading={<Subheading>Success Stories</Subheading>}
        heading="Results That Speak for Themselves"
        description="Here’s how our affordable SEO services have helped businesses grow in DIFC."
        cards={[
          {
            icon: <FaAward color="#ff5464" size={40} />,
            title: "Improved Rankings",
            description:
              "A financial consultancy ranked #1 on Google for their primary keyword within three months.",
          },
          {
            icon: <FaHandshake color="#ff5464" size={40} />,
            title: "Client Satisfaction",
            description:
              "Achieved a 98% client retention rate through consistent results.",
          },
          {
            icon: <FaLightbulb color="#ff5464" size={40} />,
            title: "Increased Traffic",
            description:
              "Boosted a retail client’s organic traffic by 150% in six months.",
          },
        ]}
      />

      {/* Call-to-Action Section */}
      <MainFeature1
        subheading={<Subheading>Ready to Boost Your Online Presence?</Subheading>}
        heading="Let’s Grow Your Business Together"
        description={
          <Description>
            Partner with our expert SEO team in DIFC to unlock the full potential of your online presence. Whether you’re looking to improve your rankings, increase traffic, or enhance your brand’s visibility, we’re here to help—at an affordable price.
          </Description>
        }
        imageSrcs={[
            "https://images.unsplash.com/photo-1600880292089-90a7e086ee0c?w=1000", // Affordable workspace showing cost-efficiency
            "https://images.unsplash.com/photo-1573496359142-b8d87734a5a2?w=1000", // Client meeting discussing budget-friendly options
            "https://images.unsplash.com/photo-1552581234-26160f608093?w=1000", // Collaborative environment in a Dubai office
          ]}
        widths={["55%", "60%", "70%"]}
        heights={["45%", "60%", "35%"]}
        imageColumnStyle="height: 400px;"
        imageStyle="width: 45%; height: 60%;"
        imagePositions={[
          "left: 35%; top: 20%;",
          "right: 30%; top: 10%;",
          "left: 32%; bottom: 20%;",
        ]}
        primaryButtonText="Contact Us Today"
        buttonRounded={true}
        textOnLeft={false}
      />
    </AnimationRevealPage>
  );
};

