import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import Header from "Components/headers/PageHeader";
import MainFeature1 from "Components/Features/TwoColWithButton.js"; // Reused component
import Features from "Components/Features/ThreeColumnPages"; // Reused component
import {
  FaBuilding,
  FaPaintBrush,
  FaUsers,
  FaHandshake,
  FaAward,
  FaLightbulb,
} from "react-icons/fa";

// Styled Components
const Subheading = tw.span`uppercase tracking-wider text-sm`;
const Heading = tw.h1`text-2xl md:text-4xl lg:text-5xl font-bold`;
const Description = tw.p`text-base md:text-lg lg:text-xl`;

// Corporate Website Design in DIFC Page Component
export const CorporateWebsiteDesignInDIFC = () => {
  return (
    <AnimationRevealPage>
      {/* Header Section */}
      <Header from="Corporate Website Design in DIFC" />

      {/* Hero Section */}
      <MainFeature1
        subheading={
          <Subheading>
            Your Trusted Partner for Professional Corporate Web Solutions
          </Subheading>
        }
        heading={
          <Heading>
            Elevate Your Online Presence with{" "}
            <span tw="text-primary-500">Corporate Website Design in DIFC</span>
          </Heading>
        }
        description={
          <Description>
            At MilaajBrandset, we specialize in delivering expert corporate
            website design services tailored for businesses in the Dubai
            International Financial Centre (DIFC). Our team of seasoned
            professionals combines creativity, technical expertise, and a
            client-centric approach to create websites that are visually
            stunning, functional, and optimized for success, ensuring measurable
            results for your business.
          </Description>
        }
        imageSrcs={[
          "https://images.pexels.com/photos/3184291/pexels-photo-3184291.jpeg?auto=compress&cs=tinysrgb&w=500", // Corporate website workspace
          "https://images.pexels.com/photos/3184465/pexels-photo-3184465.jpeg?auto=compress&cs=tinysrgb&w=500", // Team collaboration
          "https://images.pexels.com/photos/3183150/pexels-photo-3183150.jpeg?auto=compress&cs=tinysrgb&w=500", // Creative brainstorming
        ]}
        widths={["55%", "60%", "70%"]}
        heights={["45%", "60%", "35%"]}
        imageColumnStyle="height: 400px;"
        imageStyle="width: 45%; height: 60%;"
        imagePositions={[
          "left: 35%; top: 20%;",
          "right: 30%; top: 10%;",
          "left: 32%; bottom: 20%;",
        ]}
        primaryButtonText="Get Started Today"
        buttonRounded={true}
        textOnLeft={false}
      />

      {/* Key Benefits Section */}
      <Features
        subheading={<Subheading>Why Choose Us?</Subheading>}
        heading="Your Trusted Experts at Corporate Website Design in DIFC"
        description="We combine professionalism, innovation, and measurable results to deliver exceptional value."
        cards={[
          {
            icon: <FaBuilding color="#ff5464" size={40} />,
            title: "Professional Corporate Websites",
            description:
              "Designed to elevate your brand and drive business growth.",
          },
          {
            icon: <FaPaintBrush color="#ff5464" size={40} />,
            title: "Visually Stunning Designs",
            description:
              "Crafted to captivate users and enhance your brand identity.",
          },
          {
            icon: <FaUsers color="#ff5464" size={40} />,
            title: "Audience-Centric Approach",
            description:
              "We prioritize user experience and seamless functionality.",
          },
          {
            icon: <FaHandshake color="#ff5464" size={40} />,
            title: "Client-Centric Solutions",
            description:
              "Tailored strategies designed to meet your unique goals.",
          },
          {
            icon: <FaAward color="#ff5464" size={40} />,
            title: "Proven Track Record",
            description:
              "Delivering exceptional results for businesses of all sizes.",
          },
          {
            icon: <FaLightbulb color="#ff5464" size={40} />,
            title: "Creative Excellence",
            description:
              "Fresh perspectives and innovative ideas for every project.",
          },
        ]}
      />

      {/* Our Process Section */}
      <MainFeature1
        subheading={<Subheading>How We Work</Subheading>}
        heading="Our Streamlined Corporate Website Design Process"
        description={
          <Description>
            Our process ensures quality, efficiency, and measurable results:
            <ul tw="mt-4 list-disc pl-6">
              <li>
                Discovery & Research: Understand your business and target
                audience.
              </li>
              <li>
                Planning & Strategy: Define goals, features, and technical
                requirements.
              </li>
              <li>
                Design Development: Craft visually stunning and functional
                designs.
              </li>
              <li>
                Development & Testing: Build and rigorously test your website
                for performance.
              </li>
              <li>
                Launch & Support: Deploy your website and provide ongoing
                support.
              </li>
            </ul>
          </Description>
        }
        imageSrcs={[
          "https://images.pexels.com/photos/3184325/pexels-photo-3184325.jpeg?auto=compress&cs=tinysrgb&w=500", // Design and development visuals
          "https://images.pexels.com/photos/3184431/pexels-photo-3184431.jpeg?auto=compress&cs=tinysrgb&w=500", // Team planning process
          "https://images.pexels.com/photos/3182832/pexels-photo-3182832.jpeg?auto=compress&cs=tinysrgb&w=500", // Wireframing and prototyping
        ]}
        widths={["60%", "55%", "70%"]}
        heights={["60%", "45%", "35%"]}
        imageColumnStyle="height: 400px;"
        imageStyle="width: 45%; height: 60%;"
        imagePositions={[
          "right: 30%; top: 10%;",
          "left: 35%; bottom: 20%;",
          "right: 32%; bottom: 10%;",
        ]}
        primaryButtonText="Learn More"
        buttonRounded={true}
        textOnLeft={true}
      />

      {/* Success Stories Section */}
      <Features
        subheading={<Subheading>Success Stories</Subheading>}
        heading="Results That Speak for Themselves"
        description="Here’s how our corporate website design services have helped businesses grow in DIFC."
        cards={[
          {
            icon: <FaAward color="#ff5464" size={40} />,
            title: "Improved Performance",
            description:
              "A local startup saw a 50% increase in website loading speeds within three months.",
          },
          {
            icon: <FaHandshake color="#ff5464" size={40} />,
            title: "Client Satisfaction",
            description:
              "Achieved a 98% client retention rate through consistent results.",
          },
          {
            icon: <FaLightbulb color="#ff5464" size={40} />,
            title: "Enhanced Functionality",
            description:
              "Implemented advanced features, improving user engagement significantly.",
          },
        ]}
      />

      {/* Call-to-Action Section */}
      <MainFeature1
        subheading={
          <Subheading>Ready for Corporate Website Design?</Subheading>
        }
        heading="Let’s Grow Your Business Together"
        description={
          <Description>
            Partner with the leading corporate website design experts in DIFC to
            unlock the full potential of your online presence. Whether you’re
            looking to create a new website, optimize an existing one, or
            enhance functionality, we’re here to help.
          </Description>
        }
        imageSrcs={[
          "https://images.pexels.com/photos/3184423/pexels-photo-3184423.jpeg?auto=compress&cs=tinysrgb&w=500", // Happy team celebrating success
          "https://images.pexels.com/photos/3184296/pexels-photo-3184296.jpeg?auto=compress&cs=tinysrgb&w=500", // Professional corporate design
          "https://images.pexels.com/photos/1232995/pexels-photo-1232995.jpeg?auto=compress&cs=tinysrgb&w=500", // Handshake for partnership
        ]}
        widths={["55%", "60%", "70%"]}
        heights={["45%", "60%", "35%"]}
        imageColumnStyle="height: 400px;"
        imageStyle="width: 45%; height: 60%;"
        imagePositions={[
          "left: 35%; top: 20%;",
          "right: 30%; top: 10%;",
          "left: 32%; bottom: 20%;",
        ]}
        primaryButtonText="Contact Us Today"
        buttonRounded={true}
        textOnLeft={false}
      />
    </AnimationRevealPage>
  );
};
