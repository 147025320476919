import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; // eslint-disable-line
import {
  SectionHeading
} from "Components/misc/Headings";
import { ReactComponent as SvgDotPattern } from "images/dot-pattern.svg";
import BoostBrandImage from "../../images/BoostBrandImage.png";

const Container = tw.section`relative`;
const TwoColumn = tw.div`flex flex-col  md:flex-row justify-between max-w-screen-xl mx-auto py-5 md:py-5`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-5/12 flex-shrink-0 h-80 md:h-auto relative`;
const TextColumn = styled(Column)((props) => [
  tw`flex flex-row mx-auto md:w-full mt-16 md:mt-0`,
  
]);

const Image = styled.div((props) => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded bg-contain bg-no-repeat bg-center h-full`,
]);
const TextContent = tw.div`lg:py-8 text-center md:text-left font-poppins`;

const SubheadingWrapper = tw.div`w-full  flex items-center justify-center md:justify-start md:w-auto`;

const Line = styled.div`
  width: 30px;
  height: 1px;
  width: 50px;
  background-color: grey; // Adjust color as needed
`;

const Text = styled.h2`
  padding: 0 5px;
  color: #ff5464; // Adjust color as needed
  font-size: 20px; // Adjust size as needed
  white-space: nowrap;
`;

const Heading = tw(SectionHeading)`mt-4 text-black font-custom text-3xl sm:text-4xl lg:text-5xl text-center md:text-left`;
const Description = tw.p`mt-4 px-5 sm:px-0 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`;


const DecoratorBlob = styled(SvgDotPattern)((props) => [
  tw`w-20 h-20 absolute right-0 bottom-0 transform translate-x-1/2 translate-y-1/2 fill-current text-primary-500 -z-10`,
]);

// const CallToActionWrapper = styled.div`
//   ${tw`flex flex-col py-6 rounded-3xl mt-8`}
// `;

const CallToActionWrapper = styled.div`
  ${tw`flex w-3/4 flex-col p-6 rounded-3xl shadow-lg mt-8 mx-auto md:mx-0`}
  border: 3px solid #ff5464; /* Replace with your preferred color */
`;


const CallToActionText = styled.p`
  ${tw`text-start text-black font-semibold
   text-2xl md:text-3xl lg:text-3xl xl:text-3xl font-medium mb-4`}
`;

const CallToActionButton = styled.button`
  ${tw`bg-customPurple text-white text-lg font-poppins font-semibold border-2 py-2 md:py-4 xl:py-4 lg:py-4 px-8 rounded-lg hover:bg-white hover:cursor-pointer hover:text-customPurple transition-all duration-300`}

 
  }
`;

const Span = styled.span`
  ${tw`text-customPurple`};
`;

export default ({
  subheading = "Boost You Brand",
  heading = (
    <>
      
      <span tw="text-customPurple">
        Your Brand
      </span>{" "}
      Deserve's To Shine.
    </>
  ),
  description = "With cutting-edge Social Media Management, Strategic Brand Positioning, Creative Content Creation, Video Production, Paid Media Campaigns, and more, we have everything your brand needs to shine and stand out from the competition. Let us take your brand to new heights!",
  primaryButtonText = "Get Started",
  primaryButtonUrl = "#",
  imageSrc = BoostBrandImage,
  imageCss = null,
  imageContainerCss = null,
  imageDecoratorBlob = false,
  imageDecoratorBlobCss = null,
  imageInsideDiv = true,
  statistics = null,
  textOnLeft = false,
}) => {
  // const defaultStatistics = [
  //   {
  //     key: "Happy Clients",
  //     value: "500+"
  //   },
  //   {
  //     key: "Projects Completed",
  //     value: "1200+"
  //   },
  //   {
  //     key: "Awards Won",
  //     value: "50+"
  //   }
  // ];

  // if (!statistics) statistics = defaultStatistics;

  return (
    <Container>
      <TwoColumn css={!imageInsideDiv && tw`md:items-center`}>
        <ImageColumn css={imageContainerCss}>
          {imageInsideDiv ? (
            <Image
              imageSrc={BoostBrandImage}
              css={imageCss}
              alt="Statistics Illustration"
            />
          ) : (
            <img src={imageSrc} css={imageCss} alt="Statistics Illustration" />
          )}
          {imageDecoratorBlob && <DecoratorBlob css={imageDecoratorBlobCss} />}
        </ImageColumn>
        <TextColumn textOnLeft={textOnLeft}>
          <TextContent>
            {subheading && (
              <SubheadingWrapper>
                <Line />
                <Text>{subheading}</Text>
                <Line />
              </SubheadingWrapper>
            )}
            <Heading>{heading}</Heading>
            <Description>{description}</Description>
            {/* <Statistics>
              {statistics.map((statistic, index) => (
                <Statistic key={index}>
                  <Value>{statistic.value}</Value>
                  <Key>{statistic.key}</Key>
                </Statistic>
              ))}
            </Statistics> */}
            <CallToActionWrapper>
              <CallToActionText>
                Ready to <Span className="text-black font-bold">elevate</Span>{" "}
                your brand?
              </CallToActionText>
              <a href="https://wa.me/971505225056" target="_blank" rel="noopener noreferrer">
              <CallToActionButton>Let’s Chat</CallToActionButton>
              </a>
            </CallToActionWrapper>
          </TextContent>
        </TextColumn>
      </TwoColumn>
    </Container>
  );
};
