import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import Header from "Components/headers/PageHeader";
import MainFeature1 from "Components/Features/TwoColWithButton.js"; // Reused component
import Features from "Components/Features/ThreeColumnPages"; // Reused component
import {
  FaChartLine,
  FaUsers,
  FaHandshake,
  FaAward,
  FaMapMarkerAlt,
  FaDollarSign,
} from "react-icons/fa";

// Styled Components
const Subheading = tw.span`uppercase tracking-wider text-sm`;
const Heading = tw.h1`text-2xl md:text-4xl lg:text-5xl font-bold`;
const Description = tw.p`text-base md:text-lg lg:text-xl`;

// Paid Social Media Marketing in Bur Dubai Page Component
export const PaidSocialMediaMarketingInBurDubai = () => {
  return (
    <AnimationRevealPage>
      {/* Header Section */}
      <Header from="Paid Social Media Marketing in Bur Dubai" />

      {/* Hero Section */}
      <MainFeature1
        subheading={
          <Subheading>
            Maximize Your ROI with Strategic Paid Social Media Campaigns
          </Subheading>
        }
        heading={
          <Heading>
            Unlock Growth with{" "}
            <span tw="text-primary-500">
              The Best Paid Social Media Marketing in Bur Dubai
            </span>
          </Heading>
        }
        description={
          <Description>
            At MilaajBrandset, we specialize in delivering professional paid
            social media marketing services tailored for businesses in Bur
            Dubai. Our team combines creativity, strategy, and local insights to
            help you reach your target audience, drive conversions, and achieve
            measurable results through targeted ad campaigns.
          </Description>
        }
        imageSrcs={[
          "https://images.unsplash.com/photo-1611162616475-46b635cb6868?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1074&q=80", // Social media ads on devices
          "https://images.unsplash.com/photo-1551434678-e076c223a692?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1170&q=80", // Marketing team analyzing data
          "https://images.unsplash.com/photo-1533750349088-cd871a92f312?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1170&q=80", // Performance dashboard
        ]}
        widths={["55%", "60%", "70%"]}
        heights={["45%", "60%", "35%"]}
        imageColumnStyle="height: 400px;"
        imageStyle="width: 45%; height: 60%;"
        imagePositions={[
          "left: 35%; top: 20%;",
          "right: 30%; top: 10%;",
          "left: 32%; bottom: 20%;",
        ]}
        primaryButtonText="Get Started Today"
        buttonRounded={true}
        textOnLeft={false}
      />

      {/* Key Benefits Section */}
      <Features
        subheading={<Subheading>Why Choose Us?</Subheading>}
        heading="Your Trusted Partner for Paid Social Media Marketing in Bur Dubai"
        description="We combine creativity, strategy, and innovation to deliver unmatched value."
        cards={[
          {
            icon: <FaDollarSign color="#ff5464" size={40} />,
            title: "Cost-Effective Ads",
            description:
              "Maximize your budget with highly targeted and optimized paid campaigns.",
          },
          {
            icon: <FaChartLine color="#ff5464" size={40} />,
            title: "Data-Driven Strategies",
            description:
              "Leverage advanced analytics to optimize performance and maximize ROI.",
          },
          {
            icon: <FaMapMarkerAlt color="#ff5464" size={40} />,
            title: "Localized Expertise",
            description:
              "Understand Bur Dubai's unique market needs and cultural nuances.",
          },
          {
            icon: <FaUsers color="#ff5464" size={40} />,
            title: "Audience Targeting",
            description:
              "Reach your ideal customers through precise demographic and interest-based targeting.",
          },
          {
            icon: <FaHandshake color="#ff5464" size={40} />,
            title: "Client-Centric Approach",
            description:
              "Collaborate closely with you to ensure alignment with your vision.",
          },
          {
            icon: <FaAward color="#ff5464" size={40} />,
            title: "Proven Track Record",
            description:
              "Delivering exceptional results for Bur Dubai clients since [Year].",
          },
        ]}
      />

      {/* Our Process Section */}
      <MainFeature1
        subheading={<Subheading>How We Work</Subheading>}
        heading="Our Streamlined Process for Paid Social Media Marketing in Bur Dubai"
        description={
          <Description>
            Our Bur Dubai-focused process ensures quality, efficiency, and
            measurable results:
            <ul tw="mt-4 list-disc pl-6">
              <li>
                Discovery & Research: Understand your business, audience, and
                goals.
              </li>
              <li>
                Audience Analysis: Identify your target demographic and their
                preferences.
              </li>
              <li>
                Strategy Development: Create a customized roadmap for growth and
                engagement.
              </li>
              <li>
                Campaign Setup: Design visually stunning ads and set up
                targeting parameters.
              </li>
              <li>
                Campaign Launch: Promote your brand across platforms like
                Facebook, Instagram, LinkedIn, and TikTok targeting Bur Dubai
                audiences.
              </li>
              <li>
                Optimization & Analysis: Continuously refine strategies based on
                performance metrics and feedback.
              </li>
            </ul>
          </Description>
        }
        imageSrcs={[
          "https://images.unsplash.com/photo-1460925895917-afdab827c52f?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1115&q=80", // Digital marketing analytics
          "https://images.unsplash.com/photo-1432888622747-4eb9a8f5a70d?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1074&q=80", // Ad campaign planning
          "https://images.unsplash.com/photo-1579389083078-4e7018379f7e?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1170&q=80", // Social media platforms on mobile
        ]}
        widths={["60%", "55%", "70%"]}
        heights={["60%", "45%", "35%"]}
        imageColumnStyle="height: 400px;"
        imageStyle="width: 45%; height: 60%;"
        imagePositions={[
          "right: 30%; top: 10%;",
          "left: 35%; bottom: 20%;",
          "right: 32%; bottom: 10%;",
        ]}
        primaryButtonText="Learn More"
        buttonRounded={true}
        textOnLeft={true}
      />

      {/* Success Stories Section */}
      <Features
        subheading={<Subheading>Success Stories</Subheading>}
        heading="Results That Speak for Themselves"
        description="Here’s how our paid social media marketing services have empowered Bur Dubai businesses."
        cards={[
          {
            icon: <FaAward color="#ff5464" size={40} />,
            title: "Campaign ROI",
            description:
              "Helped a retail store in Bur Dubai increase sales by 200% through Facebook and Instagram ads.",
          },
          {
            icon: <FaHandshake color="#ff5464" size={40} />,
            title: "Client Satisfaction",
            description:
              "98% client retention rate through consistent excellence and measurable results.",
          },
          {
            icon: <FaDollarSign color="#ff5464" size={40} />,
            title: "Budget Optimization",
            description:
              "Generated 500+ leads for a startup in Bur Dubai within a limited marketing budget.",
          },
        ]}
      />

      {/* Call-to-Action Section */}
      <MainFeature1
        subheading={<Subheading>Ready to Amplify Your Brand?</Subheading>}
        heading="Let’s Elevate Your Business Together in Bur Dubai"
        description={
          <Description>
            Partner with Bur Dubai’s leading paid social media marketing experts
            to unlock your business’s full potential. Whether you’re launching a
            campaign, driving traffic, or boosting engagement, we’re here to
            help—without breaking the bank.
          </Description>
        }
        imageSrcs={[
          "https://images.unsplash.com/photo-1556761175-5973dc0f32e7?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1632&q=80", // Team celebrating successful campaign
          "https://images.unsplash.com/photo-1546412414-8035e1776c9a?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1170&q=80", // Dubai skyline
          "https://images.unsplash.com/photo-1557804506-669a67965ba0?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1074&q=80", // Business meeting
        ]}
        widths={["55%", "60%", "70%"]}
        heights={["45%", "60%", "35%"]}
        imageColumnStyle="height: 400px;"
        imageStyle="width: 45%; height: 60%;"
        imagePositions={[
          "left: 35%; top: 20%;",
          "right: 30%; top: 10%;",
          "left: 32%; bottom: 20%;",
        ]}
        primaryButtonText="Contact Us Today"
        buttonRounded={true}
        textOnLeft={false}
      />
    </AnimationRevealPage>
  );
};
