import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import Header from "Components/headers/PageHeader";
import MainFeature1 from "Components/Features/TwoColWithButton.js"; // Reused component
import Features from "Components/Features/ThreeColumnPages"; // Reused component
import {
  FaPenNib,
  FaChartLine,
  FaUsers,
  FaHandshake,
  FaAward,
  FaMapMarkerAlt,
} from "react-icons/fa";

// Styled Components
const Subheading = tw.span`uppercase tracking-wider text-sm`;
const Heading = tw.h1`text-2xl md:text-4xl lg:text-5xl font-bold`;
const Description = tw.p`text-base md:text-lg lg:text-xl`;

// Social Media Ad Agency in Bur Dubai Page Component
export const SocialMediaAdAgencyInBurDubai = () => {
  return (
    <AnimationRevealPage>
      {/* Header Section */}
      <Header from="Social Media Ad Agency in Bur Dubai" />

      {/* Hero Section */}
      <MainFeature1
        subheading={
          <Subheading>Maximize Your Brand's Online Presence</Subheading>
        }
        heading={
          <Heading>
            Unlock Your Potential with{" "}
            <span tw="text-primary-500">
              Social Media Ad Agency in Bur Dubai
            </span>
          </Heading>
        }
        description={
          <Description>
            At MilaajBrandset, we are Bur Dubai’s leading social media ad
            agency, delivering professional and innovative strategies tailored
            for businesses in the area. Our team combines creativity, local
            insights, and data-driven approaches to help you grow your audience,
            engage your community, and achieve measurable results.
          </Description>
        }
        imageSrcs={[
          "https://images.unsplash.com/photo-1611162618071-b39a2ec055fb?ixlib=rb-4.0.3&auto=format&fit=crop&w=500&q=60", // Social media visuals
          "https://images.unsplash.com/photo-1551288049-bebda4e38f71?ixlib=rb-4.0.3&auto=format&fit=crop&w=500&q=60", // Team collaboration
          "https://images.unsplash.com/photo-1552581234-26160f608093?ixlib=rb-4.0.3&auto=format&fit=crop&w=500&q=60", // Client success brainstorming
        ]}
        widths={["55%", "60%", "70%"]}
        heights={["45%", "60%", "35%"]}
        imageColumnStyle="height: 400px;"
        imageStyle="width: 45%; height: 60%;"
        imagePositions={[
          "left: 35%; top: 20%;",
          "right: 30%; top: 10%;",
          "left: 32%; bottom: 20%;",
        ]}
        primaryButtonText="Get Started Today"
        buttonRounded={true}
        textOnLeft={false}
      />

      {/* Key Benefits Section */}
      <Features
        subheading={<Subheading>Why Choose Us?</Subheading>}
        heading="Your Trusted Social Media Ad Agency in Bur Dubai"
        description="We combine creativity, strategy, and innovation to deliver unmatched value."
        cards={[
          {
            icon: <FaPenNib color="#ff5464" size={40} />,
            title: "Engaging Content",
            description:
              "Create captivating ads that resonate with your target audience.",
          },
          {
            icon: <FaChartLine color="#ff5464" size={40} />,
            title: "Data-Driven Strategies",
            description:
              "Leverage analytics to optimize your ad performance and maximize ROI.",
          },
          {
            icon: <FaMapMarkerAlt color="#ff5464" size={40} />,
            title: "Localized Expertise",
            description:
              "Understand Bur Dubai's unique market needs and cultural nuances.",
          },
          {
            icon: <FaUsers color="#ff5464" size={40} />,
            title: "Audience Growth",
            description:
              "Implement strategies to attract and retain a loyal following.",
          },
          {
            icon: <FaHandshake color="#ff5464" size={40} />,
            title: "Client-Centric Approach",
            description:
              "Collaborate closely with you to ensure alignment with your vision.",
          },
          {
            icon: <FaAward color="#ff5464" size={40} />,
            title: "Proven Track Record",
            description:
              "Delivering exceptional results for Bur Dubai clients since [Year].",
          },
        ]}
      />

      {/* Our Process Section */}
      <MainFeature1
        subheading={<Subheading>How We Work</Subheading>}
        heading="Our Streamlined Process for Social Media Advertising in Bur Dubai"
        description={
          <Description>
            Our Bur Dubai-focused process ensures quality, efficiency, and
            measurable results:
            <ul tw="mt-4 list-disc pl-6">
              <li>
                Discovery & Research: Understand your business, audience, and
                goals.
              </li>
              <li>
                Audience Analysis: Identify your target demographic and their
                preferences.
              </li>
              <li>
                Strategy Development: Create a customized roadmap for ad
                campaigns.
              </li>
              <li>
                Creative Execution: Design eye-catching ads that align with your
                brand identity.
              </li>
              <li>
                Campaign Launch: Deploy targeted ads across platforms like
                Facebook, Instagram, and LinkedIn.
              </li>
              <li>
                Optimization & Analysis: Continuously refine strategies based on
                performance metrics and feedback.
              </li>
            </ul>
          </Description>
        }
        imageSrcs={[
          "https://images.unsplash.com/photo-1560472354-b33ff0c44a43?ixlib=rb-4.0.3&auto=format&fit=crop&w=500&q=60", // Social media strategy planning
          "https://images.unsplash.com/photo-1552664730-d307ca884978?ixlib=rb-4.0.3&auto=format&fit=crop&w=500&q=60", // Team analyzing data
          "https://images.unsplash.com/photo-1576267423445-b2e0074d68a4?ixlib=rb-4.0.3&auto=format&fit=crop&w=500&q=60", // Client meeting discussion
        ]}
        widths={["60%", "55%", "70%"]}
        heights={["60%", "45%", "35%"]}
        imageColumnStyle="height: 400px;"
        imageStyle="width: 45%; height: 60%;"
        imagePositions={[
          "right: 30%; top: 10%;",
          "left: 35%; bottom: 20%;",
          "right: 32%; bottom: 10%;",
        ]}
        primaryButtonText="Learn More"
        buttonRounded={true}
        textOnLeft={true}
      />

      {/* Success Stories Section */}
      <Features
        subheading={<Subheading>Success Stories</Subheading>}
        heading="Results That Speak for Themselves"
        description="Here’s how our social media ad services have empowered Bur Dubai businesses."
        cards={[
          {
            icon: <FaAward color="#ff5464" size={40} />,
            title: "Ad Performance",
            description:
              "Helped a local restaurant in Bur Dubai increase sales by 300% through targeted Instagram Story ads.",
          },
          {
            icon: <FaHandshake color="#ff5464" size={40} />,
            title: "Client Satisfaction",
            description:
              "98% client retention rate through consistent excellence and measurable results.",
          },
          {
            icon: <FaPenNib color="#ff5464" size={40} />,
            title: "Creative Excellence",
            description:
              "Designed over 500 high-performing social media ads for Bur Dubai businesses in [Year].",
          },
        ]}
      />

      {/* Call-to-Action Section */}
      <MainFeature1
        subheading={<Subheading>Ready to Amplify Your Brand?</Subheading>}
        heading="Let’s Elevate Your Business Together in Bur Dubai"
        description={
          <Description>
            Partner with Bur Dubai’s leading social media ad agency to unlock
            your brand’s full potential. Whether you’re launching a campaign,
            driving traffic, or boosting conversions, we’re here to help.
          </Description>
        }
        imageSrcs={[
          "https://images.unsplash.com/photo-1531482615713-2afd69097998?ixlib=rb-4.0.3&auto=format&fit=crop&w=500&q=60", // Team celebrating success
          "https://images.unsplash.com/photo-1512453979798-5ea266f8880c?ixlib=rb-4.0.3&auto=format&fit=crop&w=500&q=60", // Dubai skyline with tech elements
          "https://images.unsplash.com/photo-1557804506-669a67965ba0?ixlib=rb-4.0.3&auto=format&fit=crop&w=500&q=60", // Business partnership concept
        ]}
        widths={["55%", "60%", "70%"]}
        heights={["45%", "60%", "35%"]}
        imageColumnStyle="height: 400px;"
        imageStyle="width: 45%; height: 60%;"
        imagePositions={[
          "left: 35%; top: 20%;",
          "right: 30%; top: 10%;",
          "left: 32%; bottom: 20%;",
        ]}
        primaryButtonText="Contact Us Today"
        buttonRounded={true}
        textOnLeft={false}
      />
    </AnimationRevealPage>
  );
};
