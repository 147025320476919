import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import Header from "Components/headers/PageHeader";
import MainFeature1 from "Components/Features/TwoColWithButton.js"; // Reused component
import Features from "Components/Features/ThreeColumnPages"; // Reused component
import {
  FaChartLine,
  FaUsers,
  FaHandshake,
  FaAward,
  FaMapMarkerAlt,
  FaVideo,
} from "react-icons/fa";

// Styled Components
const Subheading = tw.span`uppercase tracking-wider text-sm`;
const Heading = tw.h1`text-2xl md:text-4xl lg:text-5xl font-bold`;
const Description = tw.p`text-base md:text-lg lg:text-xl`;

// YouTube and Video Marketing Services in Dubai Page Component
export const YouTubeAndVideoMarketingServicesInDubai = () => {
  return (
    <AnimationRevealPage>
      {/* Header Section */}
      <Header from="YouTube and Video Marketing Services in Dubai" />

      {/* Hero Section */}
      <MainFeature1
        subheading={
          <Subheading>
            Maximize Your Reach with Engaging Video Content
          </Subheading>
        }
        heading={
          <Heading>
            Unlock Growth with{" "}
            <span tw="text-primary-500">
              The Best YouTube and Video Marketing in Dubai
            </span>
          </Heading>
        }
        description={
          <Description>
            At MilaajBrandset, we specialize in delivering professional YouTube
            and video marketing services tailored for businesses across Dubai.
            Our team combines creativity, strategy, and local insights to help
            you craft visually stunning videos, grow your YouTube channel, and
            achieve measurable results through strategic campaigns.
          </Description>
        }
        imageSrcs={[
          "https://images.unsplash.com/photo-1551817959-d5f10a1bc62a?ixlib=rb-4.0.3&auto=format&fit=crop&w=1080&q=80", // Video production setup
          "https://images.unsplash.com/photo-1629752187687-3d3c7ea3a21b?ixlib=rb-4.0.3&auto=format&fit=crop&w=1080&q=80", // Social media marketing on tablet
          "https://images.unsplash.com/photo-1512453979798-5ea266f8880c?ixlib=rb-4.0.3&auto=format&fit=crop&w=1080&q=80", // Dubai skyline for local context
        ]}
        widths={["55%", "60%", "70%"]}
        heights={["45%", "60%", "35%"]}
        imageColumnStyle="height: 400px;"
        imageStyle="width: 45%; height: 60%;"
        imagePositions={[
          "left: 35%; top: 20%;",
          "right: 30%; top: 10%;",
          "left: 32%; bottom: 20%;",
        ]}
        primaryButtonText="Get Started Today"
        buttonRounded={true}
        textOnLeft={false}
      />

      {/* Key Benefits Section */}
      <Features
        subheading={<Subheading>Why Choose Us?</Subheading>}
        heading="Your Trusted Partner for YouTube and Video Marketing in Dubai"
        description="We combine creativity, strategy, and innovation to deliver unmatched value."
        cards={[
          {
            icon: <FaVideo color="#ff5464" size={40} />,
            title: "Engaging Videos",
            description:
              "Craft high-quality, visually stunning videos that capture attention.",
          },
          {
            icon: <FaChartLine color="#ff5464" size={40} />,
            title: "Data-Driven Strategies",
            description:
              "Leverage advanced analytics to optimize performance and maximize ROI.",
          },
          {
            icon: <FaMapMarkerAlt color="#ff5464" size={40} />,
            title: "Localized Expertise",
            description:
              "Understand Dubai's unique market needs and cultural nuances.",
          },
          {
            icon: <FaUsers color="#ff5464" size={40} />,
            title: "Audience Engagement",
            description:
              "Build meaningful connections with your target audience through storytelling.",
          },
          {
            icon: <FaHandshake color="#ff5464" size={40} />,
            title: "Client-Centric Approach",
            description:
              "Collaborate closely with you to ensure alignment with your vision.",
          },
          {
            icon: <FaAward color="#ff5464" size={40} />,
            title: "Proven Track Record",
            description:
              "Delivering exceptional results for Dubai clients since [Year].",
          },
        ]}
      />

      {/* Our Process Section */}
      <MainFeature1
        subheading={<Subheading>How We Work</Subheading>}
        heading="Our Streamlined Process for YouTube and Video Marketing in Dubai"
        description={
          <Description>
            Our Dubai-focused process ensures quality, efficiency, and
            measurable results:
            <ul tw="mt-4 list-disc pl-6">
              <li>
                Discovery & Research: Understand your business, audience, and
                goals.
              </li>
              <li>
                Audience Analysis: Identify your target demographic and their
                preferences.
              </li>
              <li>
                Strategy Development: Create a customized roadmap for video
                themes and formats.
              </li>
              <li>
                Creative Execution: Design visually stunning videos tailored for
                YouTube, social media, and websites.
              </li>
              <li>
                Campaign Launch: Promote your brand across platforms like
                YouTube, Instagram, Facebook, and TikTok targeting Dubai
                audiences.
              </li>
              <li>
                Optimization & Analysis: Continuously refine strategies based on
                performance metrics and feedback.
              </li>
            </ul>
          </Description>
        }
        imageSrcs={[
          "https://images.unsplash.com/photo-1542744173-8e7e53415bb0?ixlib=rb-4.0.3&auto=format&fit=crop&w=1080&q=80", // Team planning video content
          "https://images.unsplash.com/photo-1616165415772-a15e7e71b191?ixlib=rb-4.0.3&auto=format&fit=crop&w=1080&q=80", // Video editing workstation
          "https://images.unsplash.com/photo-1551288049-bebda4e38f71?ixlib=rb-4.0.3&auto=format&fit=crop&w=1080&q=80", // Analysis dashboard with metrics
        ]}
        widths={["60%", "55%", "70%"]}
        heights={["60%", "45%", "35%"]}
        imageColumnStyle="height: 400px;"
        imageStyle="width: 45%; height: 60%;"
        imagePositions={[
          "right: 30%; top: 10%;",
          "left: 35%; bottom: 20%;",
          "right: 32%; bottom: 10%;",
        ]}
        primaryButtonText="Learn More"
        buttonRounded={true}
        textOnLeft={true}
      />

      {/* Success Stories Section */}
      <Features
        subheading={<Subheading>Success Stories</Subheading>}
        heading="Results That Speak for Themselves"
        description="Here’s how our YouTube and video marketing services have empowered Dubai businesses."
        cards={[
          {
            icon: <FaAward color="#ff5464" size={40} />,
            title: "Campaign ROI",
            description:
              "Helped a fitness studio in Dubai increase sign-ups by 300% using YouTube Ads and tutorials.",
          },
          {
            icon: <FaHandshake color="#ff5464" size={40} />,
            title: "Client Satisfaction",
            description:
              "98% client retention rate through consistent excellence and measurable results.",
          },
          {
            icon: <FaVideo color="#ff5464" size={40} />,
            title: "Viral Success",
            description:
              "Generated 1 million+ views for a tech startup using explainer videos on YouTube.",
          },
        ]}
      />

      {/* Call-to-Action Section */}
      <MainFeature1
        subheading={<Subheading>Ready to Amplify Your Brand?</Subheading>}
        heading="Let’s Elevate Your Business Together in Dubai"
        description={
          <Description>
            Partner with Dubai’s leading YouTube and video marketing experts to
            unlock your business’s full potential. Whether you’re launching a
            campaign, driving traffic, or boosting engagement, we’re here to
            help.
          </Description>
        }
        imageSrcs={[
          "https://images.unsplash.com/photo-1600880292089-90a7e086ee0c?ixlib=rb-4.0.3&auto=format&fit=crop&w=1080&q=80", // Team collaboration in media agency
          "https://images.unsplash.com/photo-1546412414-e1885e51148b?ixlib=rb-4.0.3&auto=format&fit=crop&w=1080&q=80", // Dubai business district at night
          "https://images.unsplash.com/photo-1485827404703-89b55fcc595e?ixlib=rb-4.0.3&auto=format&fit=crop&w=1080&q=80", // YouTube/video marketing on devices
        ]}
        widths={["55%", "60%", "70%"]}
        heights={["45%", "60%", "35%"]}
        imageColumnStyle="height: 400px;"
        imageStyle="width: 45%; height: 60%;"
        imagePositions={[
          "left: 35%; top: 20%;",
          "right: 30%; top: 10%;",
          "left: 32%; bottom: 20%;",
        ]}
        primaryButtonText="Contact Us Today"
        buttonRounded={true}
        textOnLeft={false}
      />
    </AnimationRevealPage>
  );
};
