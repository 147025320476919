import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import Header from "Components/headers/PageHeader";
import MainFeature1 from "Components/Features/TwoColWithButton.js"; // Reused component
import Features from "Components/Features/ThreeColumnPages"; // Reused component
import {
  FaChartLine,
  FaUsers,
  FaHandshake,
  FaAward,
  FaMapMarkerAlt,
  FaStore,
} from "react-icons/fa";

// Styled Components
const Subheading = tw.span`uppercase tracking-wider text-sm`;
const Heading = tw.h1`text-2xl md:text-4xl lg:text-5xl font-bold`;
const Description = tw.p`text-base md:text-lg lg:text-xl`;

// Local Business Advertising in Al Barsha Page Component
export const LocalBusinessAdvertisingInAlBarsha = () => {
  return (
    <AnimationRevealPage>
      {/* Header Section */}
      <Header from="Local Business Advertising in Al Barsha" />

      {/* Hero Section */}
      <MainFeature1
        subheading={
          <Subheading>Maximize Your Reach with Local Advertising</Subheading>
        }
        heading={
          <Heading>
            Unlock Your Potential with{" "}
            <span tw="text-primary-500">
              The Best Local Business Advertising in Al Barsha
            </span>
          </Heading>
        }
        description={
          <Description>
            At MilaajBrandset, we specialize in delivering professional
            advertising services tailored for local businesses in Al Barsha,
            Dubai. Our team combines creativity, strategy, and local insights to
            help you grow your audience, engage your community, and achieve
            measurable results through targeted campaigns.
          </Description>
        }
        imageSrcs={[
          "https://images.unsplash.com/photo-1562577309-4932fdd64cd1?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2074&q=80", // Local business social media
          "https://images.unsplash.com/photo-1579869847514-7c1a19d2d2ad?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2134&q=80", // Social media local marketing
          "https://images.unsplash.com/photo-1517245386807-bb43f82c33c4?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80", // Local community engagement
        ]}
        widths={["55%", "60%", "70%"]}
        heights={["45%", "60%", "35%"]}
        imageColumnStyle="height: 400px;"
        imageStyle="width: 45%; height: 60%;"
        imagePositions={[
          "left: 35%; top: 20%;",
          "right: 30%; top: 10%;",
          "left: 32%; bottom: 20%;",
        ]}
        primaryButtonText="Get Started Today"
        buttonRounded={true}
        textOnLeft={false}
      />

      {/* Key Benefits Section */}
      <Features
        subheading={<Subheading>Why Choose Us?</Subheading>}
        heading="Your Trusted Partner for Local Business Advertising in Al Barsha"
        description="We combine creativity, strategy, and innovation to deliver unmatched value."
        cards={[
          {
            icon: <FaStore color="#ff5464" size={40} />,
            title: "Hyperlocal Targeting",
            description:
              "Reach customers in Al Barsha with precision-targeted advertising campaigns.",
          },
          {
            icon: <FaChartLine color="#ff5464" size={40} />,
            title: "Data-Driven Strategies",
            description:
              "Leverage advanced analytics to optimize performance and maximize ROI.",
          },
          {
            icon: <FaMapMarkerAlt color="#ff5464" size={40} />,
            title: "Localized Expertise",
            description:
              "Understand Al Barsha's unique market needs and cultural nuances.",
          },
          {
            icon: <FaUsers color="#ff5464" size={40} />,
            title: "Community Engagement",
            description:
              "Build meaningful connections with your target audience through engaging ads.",
          },
          {
            icon: <FaHandshake color="#ff5464" size={40} />,
            title: "Client-Centric Approach",
            description:
              "Collaborate closely with you to ensure alignment with your vision.",
          },
          {
            icon: <FaAward color="#ff5464" size={40} />,
            title: "Proven Track Record",
            description:
              "Delivering exceptional results for Al Barsha clients since [Year].",
          },
        ]}
      />

      {/* Our Process Section */}
      <MainFeature1
        subheading={<Subheading>How We Work</Subheading>}
        heading="Our Streamlined Process for Local Business Advertising in Al Barsha"
        description={
          <Description>
            Our Al Barsha-focused process ensures quality, efficiency, and
            measurable results:
            <ul tw="mt-4 list-disc pl-6">
              <li>
                Discovery & Research: Understand your business, audience, and
                goals.
              </li>
              <li>
                Audience Analysis: Identify your target demographic and their
                preferences.
              </li>
              <li>
                Strategy Development: Create a customized roadmap for growth.
              </li>
              <li>
                Creative Execution: Design visually stunning and engaging ads
                (Local SEO, Social Media Ads, Google Ads).
              </li>
              <li>
                Campaign Launch: Promote your brand across platforms like
                Google, Facebook, Instagram, and TikTok.
              </li>
              <li>
                Optimization & Analysis: Continuously refine strategies based on
                performance metrics and feedback.
              </li>
            </ul>
          </Description>
        }
        imageSrcs={[
          "https://images.unsplash.com/photo-1568992687947-868a62a9f521?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2064&q=80", // Location-based marketing
          "https://images.unsplash.com/photo-1563986768494-4dee2763ff3f?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1170&q=80", // Social media strategy
          "https://images.unsplash.com/photo-1495725274072-fd5d0b961a5f?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2073&q=80", // Local business networking
        ]}
        widths={["60%", "55%", "70%"]}
        heights={["60%", "45%", "35%"]}
        imageColumnStyle="height: 400px;"
        imageStyle="width: 45%; height: 60%;"
        imagePositions={[
          "right: 30%; top: 10%;",
          "left: 35%; bottom: 20%;",
          "right: 32%; bottom: 10%;",
        ]}
        primaryButtonText="Learn More"
        buttonRounded={true}
        textOnLeft={true}
      />

      {/* Success Stories Section */}
      <Features
        subheading={<Subheading>Success Stories</Subheading>}
        heading="Results That Speak for Themselves"
        description="Here’s how our local business advertising services have empowered Al Barsha businesses."
        cards={[
          {
            icon: <FaAward color="#ff5464" size={40} />,
            title: "Foot Traffic Growth",
            description:
              "Helped a local café in Al Barsha increase daily customers by 150% through hyperlocal Google Ads.",
          },
          {
            icon: <FaHandshake color="#ff5464" size={40} />,
            title: "Client Satisfaction",
            description:
              "98% client retention rate through consistent excellence and measurable results.",
          },
          {
            icon: <FaStore color="#ff5464" size={40} />,
            title: "Community Loyalty",
            description:
              "Built long-term customer relationships for a boutique clothing store in Al Barsha through social media campaigns.",
          },
        ]}
      />

      {/* Call-to-Action Section */}
      <MainFeature1
        subheading={<Subheading>Ready to Amplify Your Brand?</Subheading>}
        heading="Let’s Elevate Your Business Together in Al Barsha"
        description={
          <Description>
            Partner with Al Barsha’s leading local business advertising experts
            to unlock your brand’s full potential. Whether you’re launching a
            campaign, driving traffic, or boosting engagement, we’re here to
            help.
          </Description>
        }
        imageSrcs={[
          "https://images.unsplash.com/photo-1535295972055-1c762f4483e5?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2069&q=80", // Team success
          "https://images.unsplash.com/photo-1546412414-e1885e51cde5?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2148&q=80", // Dubai city view
          "https://images.unsplash.com/photo-1517292987719-0369a794ec0f?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2074&q=80", // Local business partnership
        ]}
        widths={["55%", "60%", "70%"]}
        heights={["45%", "60%", "35%"]}
        imageColumnStyle="height: 400px;"
        imageStyle="width: 45%; height: 60%;"
        imagePositions={[
          "left: 35%; top: 20%;",
          "right: 30%; top: 10%;",
          "left: 32%; bottom: 20%;",
        ]}
        primaryButtonText="Contact Us Today"
        buttonRounded={true}
        textOnLeft={false}
      />
    </AnimationRevealPage>
  );
};
