
import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import CEOImage from "images/ceo-photo.png"; // Replace with your correct image path
import { FaQuoteLeft, FaQuoteRight } from "react-icons/fa";

const Container = styled.div`
  ${tw`relative`}
`;

const TopSection = styled.div`
  ${tw`relative flex flex-col justify-center px-8 py-8`}
  background-color: #000;
  color: #fff;
`;

const BottomSection = styled.div`
  ${tw`relative flex flex-col items-start gap-10 xs:p-0 sm:p-0 md:p-16 lg:p-16 xl:p-10 p-20 py-12`}
  background-color: #ff5464;
  border-bottom-left-radius: 8rem;

  @media (max-width: 768px) {
    border-bottom-left-radius: 6rem;
    padding: 2rem;
  }
`;

const TextBlock = styled.div`
  ${tw`text-left text-2xl xs:p-0 xs:w-full sm:w-full cmd:w-full xl:w-2/4 lg:w-2/4 sm:p-0 md:py-10 lg:py-10 xl:py-10 md:px-16 lg:px-16 xl:px-16 md:w-full leading-relaxed mb-8`}
  color: #fff;
`;

const HighlightText = styled.p`
  ${tw`text-left text-2xl xl:w-2/4 lg:w-2/4 sm:p-0 md:py-4 lg:py-4 xl:py-4 md:px-12 lg:px-12 xl:px-12 font-semibold mt-4`}
  color: white;
`;

const ProfileWrapper = styled.div`
  ${tw`flex flex-row items-center md:px-12 lg:px-12 xl:px-12 gap-8 justify-start w-full`}

  @media (max-width: 960px) {
    ${tw` items-center text-center`}
  }
`;

const ProfileImage = styled.img`
  ${tw`w-32 h-32 rounded-full`}
  object-fit: cover; /* Ensures the image is cropped and fills the container */
  transform: scale(1.2); /* Zoom effect (1.2x) */
  transition: transform 0.3s ease-in-out;

  &:hover {
    transform: scale(1.3); /* Optional: Slight zoom-in effect on hover */
  }
`;

const ProfileText = styled.div`
  ${tw`text-white px-4 text-left`}
`;

const ProfileName = styled.h3`
  ${tw`font-bold text-2xl`}
`;

const ProfileRole = styled.p`
  ${tw`text-lg`}
`;

const CEOImageWrapper = styled.div`
  ${tw`absolute right-[10rem] top-[5rem]`};
  z-index: 2; /* Ensure image appears above all other layers */

  img {
    width: 400px;
    height: auto;
    border-radius: 1rem;

    @media (max-width: 1280px) {
      display: none; /* Hides the image wrapper on smaller screens */
    }
  }
`;

const QuoteIconLeft = styled(FaQuoteLeft)`
  ${tw`text-4xl text-red-500 mb-4`}
`;

const QuoteIconRight = styled(FaQuoteRight)`
  ${tw`text-4xl text-red-500 mt-4`}
`;

export default () => {
  return (
    <Container>
      {/* Top Section */}
      <TopSection>
        <TextBlock>
          <QuoteIconLeft />
          <p>
            At MilaajBrandset, we believe that every brand has a unique story to
            tell. Our mission is to transform your vision into reality through
            innovative strategies and creative solutions. We’re committed to
            partnering with you every step of the way to ensure your brand not
            only stands out but thrives in today’s competitive landscape.
          </p>
          <QuoteIconRight />
        </TextBlock>
        <CEOImageWrapper>
          <img src={CEOImage} alt="CEO" />
        </CEOImageWrapper>
      </TopSection>

      {/* Bottom Section */}
      <BottomSection>
        {/* Text First Row */}
        <HighlightText>
          Every great brand starts with a powerful story. Let’s create yours
          together.
        </HighlightText>

        {/* Profile Wrapper in the Second Row */}
        <ProfileWrapper>
          <ProfileImage src={CEOImage} alt="CEO" />
          <ProfileText>
            <ProfileName>Midhlaj K</ProfileName>
            <ProfileRole>Founder and CEO</ProfileRole>
          </ProfileText>
        </ProfileWrapper>
      </BottomSection>
    </Container>
  );
};
