import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import Header from "Components/headers/PageHeader";
import MainFeature1 from "Components/Features/TwoColWithButton.js"; // Reused component
import Features from "Components/Features/ThreeColumnPages"; // Reused component
import {
  FaSearch,
  FaChartBar,
  FaUsers,
  FaHandshake,
  FaAward,
  FaLightbulb,
} from "react-icons/fa";

// Styled Components
const Subheading = tw.span`uppercase tracking-wider text-sm`;
const Heading = tw.h1`text-2xl md:text-4xl lg:text-5xl font-bold`;
const Description = tw.p`text-base md:text-lg lg:text-xl`;

// E-commerce SEO Company in Bur Dubai Page Component
export const EcommerceSEOCompanyInBurDubai = () => {
  return (
    <AnimationRevealPage>
      {/* Header Section */}
      <Header from="E-commerce SEO Company in Bur Dubai" />

      {/* Hero Section */}
      <MainFeature1
        subheading={<Subheading>Your Trusted E-commerce SEO Partner</Subheading>}
        heading={
          <Heading>
            Elevate Your E-commerce Store with{" "}
            <span tw="text-primary-500">E-commerce SEO Company in Bur Dubai</span>
          </Heading>
        }
        description={
          <Description>
            At MilaajBrandset, we specialize in delivering expert e-commerce SEO services in Bur Dubai that help online stores improve their visibility, drive organic traffic, and achieve measurable results. Our team of seasoned SEO specialists brings data-driven strategies, fresh ideas, and a client-centric approach to every project.
          </Description>
        }
        imageSrcs={[
            "https://images.unsplash.com/photo-1562577309-4932fdd64cd1?w=1000", // SEO dashboard/analytics visualization
            "https://images.unsplash.com/photo-1546412414-e1885259563a?w=1000", // Dubai skyline showing business district
            "https://images.unsplash.com/photo-1600880292203-757bb62b4baf?w=1000", // Team working on digital marketing strategy
          ]}
        widths={["55%", "60%", "70%"]}
        heights={["45%", "60%", "35%"]}
        imageColumnStyle="height: 400px;"
        imageStyle="width: 45%; height: 60%;"
        imagePositions={[
          "left: 35%; top: 20%;",
          "right: 30%; top: 10%;",
          "left: 32%; bottom: 20%;",
        ]}
        primaryButtonText="Get Started Today"
        buttonRounded={true}
        textOnLeft={false}
      />

      {/* Key Benefits Section */}
      <Features
        subheading={<Subheading>Why Choose Us?</Subheading>}
        heading="Your Trusted E-commerce SEO Experts in Bur Dubai"
        description="We combine specialization, innovation, and measurable results to deliver exceptional value."
        cards={[
          {
            icon: <FaSearch color="#ff5464" size={40} />,
            title: "Proven E-commerce SEO Strategies",
            description:
              "Our data-driven approach ensures maximum visibility and ROI.",
          },
          {
            icon: <FaChartBar color="#ff5464" size={40} />,
            title: "Data-Driven Insights",
            description:
              "Backed by research to ensure maximum impact and growth.",
          },
          {
            icon: <FaUsers color="#ff5464" size={40} />,
            title: "Audience-Centric Approach",
            description:
              "We prioritize the needs and behaviors of your target audience.",
          },
          {
            icon: <FaHandshake color="#ff5464" size={40} />,
            title: "Client-Centric Solutions",
            description:
              "Tailored strategies designed to meet your unique goals.",
          },
          {
            icon: <FaAward color="#ff5464" size={40} />,
            title: "Proven Expertise",
            description:
              "Years of experience helping e-commerce businesses rank higher on Google.",
          },
          {
            icon: <FaLightbulb color="#ff5464" size={40} />,
            title: "Creative Excellence",
            description:
              "Fresh perspectives and innovative ideas for every project.",
          },
        ]}
      />

      {/* Our Process Section */}
      <MainFeature1
        subheading={<Subheading>How We Work</Subheading>}
        heading="Our Streamlined E-commerce SEO Process"
        description={
          <Description>
            Our process ensures quality, efficiency, and measurable results:
            <ul tw="mt-4 list-disc pl-6">
              <li>Discovery & Research: Understand your business and target audience.</li>
              <li>Keyword Analysis: Identify high-value keywords for your niche.</li>
              <li>Strategy Development: Create tailored SEO plans for your goals.</li>
              <li>Implementation: Optimize your website and product pages for search engines.</li>
              <li>Monitoring & Reporting: Track performance and adjust strategies for maximum impact.</li>
            </ul>
          </Description>
        }
        imageSrcs={[
            "https://images.unsplash.com/photo-1516321318423-f06f85e504b3?w=1000", // SEO keyword research process
            "https://images.unsplash.com/photo-1573164713988-8665fc963095?w=1000", // Marketing team analyzing SEO reports
            "https://images.unsplash.com/photo-1460925895917-afdab827c52f?w=1000", // Website optimization visualization
          ]}
        widths={["60%", "55%", "70%"]}
        heights={["60%", "45%", "35%"]}
        imageColumnStyle="height: 400px;"
        imageStyle="width: 45%; height: 60%;"
        imagePositions={[
          "right: 30%; top: 10%;",
          "left: 35%; bottom: 20%;",
          "right: 32%; bottom: 10%;",
        ]}
        primaryButtonText="Learn More"
        buttonRounded={true}
        textOnLeft={true}
      />

      {/* Success Stories Section */}
      <Features
        subheading={<Subheading>Success Stories</Subheading>}
        heading="Results That Speak for Themselves"
        description="Here’s how our e-commerce SEO services have helped businesses grow in Bur Dubai."
        cards={[
          {
            icon: <FaAward color="#ff5464" size={40} />,
            title: "Improved Rankings",
            description:
              "A local e-commerce store ranked #1 on Google for their primary keyword within three months.",
          },
          {
            icon: <FaHandshake color="#ff5464" size={40} />,
            title: "Client Satisfaction",
            description:
              "Achieved a 98% client retention rate through consistent results.",
          },
          {
            icon: <FaLightbulb color="#ff5464" size={40} />,
            title: "Increased Traffic",
            description:
              "Boosted a retail client’s organic traffic by 150% in six months.",
          },
        ]}
      />

      {/* Call-to-Action Section */}
      <MainFeature1
        subheading={<Subheading>Ready to Boost Your E-commerce Store?</Subheading>}
        heading="Let’s Optimize Your Online Store Together"
        description={
          <Description>
            Partner with our expert e-commerce SEO company in Bur Dubai to unlock the full potential of your online presence. Whether you’re looking to improve your rankings, increase traffic, or enhance your website’s performance, we’re here to help.
          </Description>
        }
        imageSrcs={[
            "https://images.unsplash.com/photo-1591696205602-2f950c417cb9?w=1000", // Digital marketing success celebration
            "https://images.unsplash.com/photo-1552581234-26160f608093?w=1000", // Modern Dubai office with team planning
            "https://images.unsplash.com/photo-1582639510498-c821b3f6ef52?w=1000", // Business handshake in Dubai office setting
          ]}
        widths={["55%", "60%", "70%"]}
        heights={["45%", "60%", "35%"]}
        imageColumnStyle="height: 400px;"
        imageStyle="width: 45%; height: 60%;"
        imagePositions={[
          "left: 35%; top: 20%;",
          "right: 30%; top: 10%;",
          "left: 32%; bottom: 20%;",
        ]}
        primaryButtonText="Contact Us Today"
        buttonRounded={true}
        textOnLeft={false}
      />
    </AnimationRevealPage>
  );
};

