import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import Header from "Components/headers/PageHeader";
import MainFeature1 from "Components/Features/TwoColWithButton.js"; // Reused component
import Features from "Components/Features/ThreeColumnPages"; // Reused component
import {
  FaBriefcase,
  FaServer,
  FaShieldAlt,
  FaUsers,
  FaHandshake,
  FaAward,
} from "react-icons/fa";

// Styled Components
const Subheading = tw.span`uppercase tracking-wider text-sm`;
const Heading = tw.h1`text-2xl md:text-4xl lg:text-5xl font-bold`;
const Description = tw.p`text-base md:text-lg lg:text-xl`;

// Professional Web Hosting Company in Dubai Page Component
export const ProfessionalWebHostingCompanyInDubai = () => {
  return (
    <AnimationRevealPage>
      {/* Header Section */}
      <Header from="Professional Web Hosting Company in Dubai" />

      {/* Hero Section */}
      <MainFeature1
        subheading={
          <Subheading>
            Dubai’s Trusted Partner for Professional Hosting
          </Subheading>
        }
        heading={
          <Heading>
            Elevate Your Online Presence with{" "}
            <span tw="text-primary-500">
              A Professional Web Hosting Company in Dubai
            </span>
          </Heading>
        }
        description={
          <Description>
            At MilaajBrandset, we specialize in delivering expert,
            professional-grade web hosting solutions tailored for Dubai’s
            businesses and individuals. Our team combines cutting-edge
            technology with unparalleled customer service to ensure your website
            remains fast, secure, and reliable, empowering you to succeed in the
            digital landscape.
          </Description>
        }
        imageSrcs={[
          "https://images.unsplash.com/photo-1633265486501-2d743afe2e85?w=800&auto=format&fit=crop&q=80&ixlib=rb-4.0.3", // Professional server room
          "https://images.unsplash.com/photo-1600880292203-757bb62b4baf?w=800&auto=format&fit=crop&q=80&ixlib=rb-4.0.3", // Business professionals in modern office
          "https://images.unsplash.com/photo-1608475118656-198b020c2b97?w=800&auto=format&fit=crop&q=80&ixlib=rb-4.0.3", // Close-up of professional IT infrastructure
        ]}
        widths={["55%", "60%", "70%"]}
        heights={["45%", "60%", "35%"]}
        imageColumnStyle="height: 400px;"
        imageStyle="width: 45%; height: 60%;"
        imagePositions={[
          "left: 35%; top: 20%;",
          "right: 30%; top: 10%;",
          "left: 32%; bottom: 20%;",
        ]}
        primaryButtonText="Get Started Today"
        buttonRounded={true}
        textOnLeft={false}
      />

      {/* Key Benefits Section */}
      <Features
        subheading={<Subheading>Why Choose Us?</Subheading>}
        heading="Your Trusted Professional Web Hosting Provider in Dubai"
        description="We combine professionalism, reliability, and innovation to deliver unmatched value."
        cards={[
          {
            icon: <FaBriefcase color="#ff5464" size={40} />,
            title: "Professional Expertise",
            description:
              "Trusted by businesses of all sizes for our industry-leading hosting solutions.",
          },
          {
            icon: <FaServer color="#ff5464" size={40} />,
            title: "Reliable Infrastructure",
            description:
              "Guarantee 99.9% uptime with enterprise-grade servers optimized for performance.",
          },
          {
            icon: <FaShieldAlt color="#ff5464" size={40} />,
            title: "Enhanced Security",
            description:
              "Protect your website with advanced threat detection and encryption.",
          },
          {
            icon: <FaUsers color="#ff5464" size={40} />,
            title: "Dubai-Centric Support",
            description:
              "Localized expertise and 24/7 assistance tailored to Dubai businesses.",
          },
          {
            icon: <FaHandshake color="#ff5464" size={40} />,
            title: "Client-Centric Solutions",
            description:
              "Tailored strategies designed to meet your unique goals.",
          },
          {
            icon: <FaAward color="#ff5464" size={40} />,
            title: "Proven Track Record",
            description:
              "Delivering exceptional results for Dubai businesses since [Year].",
          },
        ]}
      />

      {/* Our Process Section */}
      <MainFeature1
        subheading={<Subheading>How We Work</Subheading>}
        heading="Our Streamlined Hosting Process for Dubai Businesses"
        description={
          <Description>
            Our Dubai-focused process ensures quality, efficiency, and
            measurable results:
            <ul tw="mt-4 list-disc pl-6">
              <li>
                Discovery & Research: Understand your business and technical
                requirements.
              </li>
              <li>
                Planning & Strategy: Define hosting plans optimized for
                performance and security.
              </li>
              <li>
                Implementation: Deploy your website on secure, scalable servers.
              </li>
              <li>
                Monitoring & Optimization: Continuously improve speed and
                reliability.
              </li>
              <li>
                Ongoing Support: Provide 24/7 assistance with a local touch.
              </li>
            </ul>
          </Description>
        }
        imageSrcs={[
          "https://images.unsplash.com/photo-1581092335397-9fa341108266?w=800&auto=format&fit=crop&q=80&ixlib=rb-4.0.3", // Professional monitoring dashboard
          "https://images.unsplash.com/photo-1542744173-8e7e53415bb0?w=800&auto=format&fit=crop&q=80&ixlib=rb-4.0.3", // Business team planning session
          "https://images.unsplash.com/photo-1498049794561-7780e7231661?w=800&auto=format&fit=crop&q=80&ixlib=rb-4.0.3", // Technical infrastructure close-up
        ]}
        widths={["60%", "55%", "70%"]}
        heights={["60%", "45%", "35%"]}
        imageColumnStyle="height: 400px;"
        imageStyle="width: 45%; height: 60%;"
        imagePositions={[
          "right: 30%; top: 10%;",
          "left: 35%; bottom: 20%;",
          "right: 32%; bottom: 10%;",
        ]}
        primaryButtonText="Learn More"
        buttonRounded={true}
        textOnLeft={true}
      />

      {/* Success Stories Section */}
      <Features
        subheading={<Subheading>Success Stories</Subheading>}
        heading="Results That Speak for Themselves"
        description="Here’s how our professional hosting solutions have empowered Dubai businesses."
        cards={[
          {
            icon: <FaAward color="#ff5464" size={40} />,
            title: "E-Commerce Growth",
            description:
              "Helped a Dubai-based online store reduce load times by 50%, boosting sales.",
          },
          {
            icon: <FaHandshake color="#ff5464" size={40} />,
            title: "Client Satisfaction",
            description:
              "98% retention rate from Dubai clients through consistent excellence.",
          },
          {
            icon: <FaBriefcase color="#ff5464" size={40} />,
            title: "Enterprise Reliability",
            description:
              "Achieved 99.9% uptime for a large-scale enterprise during peak seasons.",
          },
        ]}
      />

      {/* Call-to-Action Section */}
      <MainFeature1
        subheading={<Subheading>Ready for Professional Hosting?</Subheading>}
        heading="Let’s Grow Your Dubai Business Together"
        description={
          <Description>
            Partner with Dubai’s leading professional web hosting company to
            unlock your website’s full potential. Whether you’re launching a new
            site, migrating, or optimizing performance, we’re here to help.
          </Description>
        }
        imageSrcs={[
          "https://images.unsplash.com/photo-1556761175-5973dc0f32e7?w=800&auto=format&fit=crop&q=80&ixlib=rb-4.0.3", // Professional business team
          "https://images.unsplash.com/photo-1573164574230-db1d5e960238?w=800&auto=format&fit=crop&q=80&ixlib=rb-4.0.3", // Modern server rack with professional lighting
          "https://images.unsplash.com/photo-1579389083078-4e7018379f7e?w=800&auto=format&fit=crop&q=80&ixlib=rb-4.0.3", // Business handshake in professional setting
        ]}
        widths={["55%", "60%", "70%"]}
        heights={["45%", "60%", "35%"]}
        imageColumnStyle="height: 400px;"
        imageStyle="width: 45%; height: 60%;"
        imagePositions={[
          "left: 35%; top: 20%;",
          "right: 30%; top: 10%;",
          "left: 32%; bottom: 20%;",
        ]}
        primaryButtonText="Contact Us Today"
        buttonRounded={true}
        textOnLeft={false}
      />
    </AnimationRevealPage>
  );
};
