import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import Header from "Components/headers/PageHeader";
import MainFeature1 from "Components/Features/TwoColWithButton.js"; // Reused component
import Features from "Components/Features/ThreeColumnPages"; // Reused component
import {
  FaWordpress,
  FaPaintBrush,
  FaUsers,
  FaHandshake,
  FaAward,
  FaLightbulb,
} from "react-icons/fa";

// Styled Components
const Subheading = tw.span`uppercase tracking-wider text-sm`;
const Heading = tw.h1`text-2xl md:text-4xl lg:text-5xl font-bold`;
const Description = tw.p`text-base md:text-lg lg:text-xl`;

// WordPress Website Design in Al Barsha Page Component
export const WordPressWebsiteDesignInAlBarsha = () => {
  return (
    <AnimationRevealPage>
      {/* Header Section */}
      <Header from="WordPress Website Design in Al Barsha" />

      {/* Hero Section */}
      <MainFeature1
        subheading={
          <Subheading>Your Trusted Partner for WordPress Solutions</Subheading>
        }
        heading={
          <Heading>
            Elevate Your Online Presence with{" "}
            <span tw="text-primary-500">
              WordPress Website Design in Al Barsha
            </span>
          </Heading>
        }
        description={
          <Description>
            At MilaajBrandset, we specialize in delivering expert WordPress
            website design services tailored for businesses in Al Barsha, Dubai.
            Our team of seasoned professionals combines creativity, technical
            expertise, and a client-centric approach to create websites that are
            visually stunning, functional, and optimized for performance,
            ensuring measurable results for your business.
          </Description>
        }
        imageSrcs={[
          "https://images.pexels.com/photos/1181467/pexels-photo-1181467.jpeg?auto=compress&cs=tinysrgb&w=800&h=533", // WordPress website design on laptop
          "https://images.pexels.com/photos/3184296/pexels-photo-3184296.jpeg?auto=compress&cs=tinysrgb&w=800&h=533", // Team collaborating on WordPress design
          "https://images.pexels.com/photos/590022/pexels-photo-590022.jpeg?auto=compress&cs=tinysrgb&w=800&h=533", // Creative WordPress brainstorming
        ]}
        widths={["55%", "60%", "70%"]}
        heights={["45%", "60%", "35%"]}
        imageColumnStyle="height: 400px;"
        imageStyle="width: 45%; height: 60%;"
        imagePositions={[
          "left: 35%; top: 20%;",
          "right: 30%; top: 10%;",
          "left: 32%; bottom: 20%;",
        ]}
        primaryButtonText="Get Started Today"
        buttonRounded={true}
        textOnLeft={false}
      />

      {/* Key Benefits Section */}
      <Features
        subheading={<Subheading>Why Choose Us?</Subheading>}
        heading="Your Trusted Experts at WordPress Website Design in Al Barsha"
        description="We combine WordPress expertise, innovation, and measurable results to deliver exceptional value."
        cards={[
          {
            icon: <FaWordpress color="#ff5464" size={40} />,
            title: "Flexible & Scalable Solutions",
            description:
              "Leverage WordPress's power for dynamic and scalable websites.",
          },
          {
            icon: <FaPaintBrush color="#ff5464" size={40} />,
            title: "Visually Stunning Websites",
            description:
              "Crafted to captivate users and elevate your brand identity.",
          },
          {
            icon: <FaUsers color="#ff5464" size={40} />,
            title: "Audience-Centric Approach",
            description:
              "We prioritize user experience and seamless functionality.",
          },
          {
            icon: <FaHandshake color="#ff5464" size={40} />,
            title: "Client-Centric Solutions",
            description:
              "Tailored strategies designed to meet your unique goals.",
          },
          {
            icon: <FaAward color="#ff5464" size={40} />,
            title: "Proven Track Record",
            description:
              "Delivering exceptional results for businesses of all sizes.",
          },
          {
            icon: <FaLightbulb color="#ff5464" size={40} />,
            title: "Creative Excellence",
            description:
              "Fresh perspectives and innovative ideas for every project.",
          },
        ]}
      />

      {/* Our Process Section */}
      <MainFeature1
        subheading={<Subheading>How We Work</Subheading>}
        heading="Our Streamlined WordPress Website Design Process"
        description={
          <Description>
            Our process ensures quality, efficiency, and measurable results:
            <ul tw="mt-4 list-disc pl-6">
              <li>
                Discovery & Research: Understand your business and target
                audience.
              </li>
              <li>
                Planning & Strategy: Define goals, features, and technical
                requirements.
              </li>
              <li>
                Design Development: Craft visually stunning and functional
                designs.
              </li>
              <li>
                Development & Testing: Build and rigorously test your WordPress
                site for performance.
              </li>
              <li>
                Launch & Support: Deploy your website and provide ongoing
                support.
              </li>
            </ul>
          </Description>
        }
        imageSrcs={[
          "https://images.pexels.com/photos/577585/pexels-photo-577585.jpeg?auto=compress&cs=tinysrgb&w=800&h=533", // WordPress design process on screen
          "https://images.pexels.com/photos/3183150/pexels-photo-3183150.jpeg?auto=compress&cs=tinysrgb&w=800&h=533", // Team working on WordPress project
          "https://images.pexels.com/photos/590041/pexels-photo-590041.jpeg?auto=compress&cs=tinysrgb&w=800&h=533", // Planning WordPress layouts
        ]}
        widths={["60%", "55%", "70%"]}
        heights={["60%", "45%", "35%"]}
        imageColumnStyle="height: 400px;"
        imageStyle="width: 45%; height: 60%;"
        imagePositions={[
          "right: 30%; top: 10%;",
          "left: 35%; bottom: 20%;",
          "right: 32%; bottom: 10%;",
        ]}
        primaryButtonText="Learn More"
        buttonRounded={true}
        textOnLeft={true}
      />

      {/* Success Stories Section */}
      <Features
        subheading={<Subheading>Success Stories</Subheading>}
        heading="Results That Speak for Themselves"
        description="Here’s how our WordPress website design services have helped businesses grow in Al Barsha."
        cards={[
          {
            icon: <FaAward color="#ff5464" size={40} />,
            title: "Increased Traffic",
            description:
              "A local retailer saw a 70% increase in website traffic within six months.",
          },
          {
            icon: <FaHandshake color="#ff5464" size={40} />,
            title: "Client Satisfaction",
            description:
              "Achieved a 98% client retention rate through consistent results.",
          },
          {
            icon: <FaLightbulb color="#ff5464" size={40} />,
            title: "Enhanced Performance",
            description:
              "Boosted website loading speeds by 50%, reducing bounce rates significantly.",
          },
        ]}
      />

      {/* Call-to-Action Section */}
      <MainFeature1
        subheading={
          <Subheading>Ready to Build a WordPress Website?</Subheading>
        }
        heading="Let’s Grow Your Business Together"
        description={
          <Description>
            Partner with the leading WordPress website design experts in Al
            Barsha to unlock the full potential of your online presence. Whether
            you’re looking to create a new website, migrate to WordPress, or
            enhance functionality, we’re here to help.
          </Description>
        }
        imageSrcs={[
          "https://images.pexels.com/photos/3182773/pexels-photo-3182773.jpeg?auto=compress&cs=tinysrgb&w=800&h=533", // Team celebrating WordPress success
          "https://images.pexels.com/photos/39284/macbook-apple-imac-computer-39284.jpeg?auto=compress&cs=tinysrgb&w=800&h=533", // WordPress design workspace
          "https://images.pexels.com/photos/269077/pexels-photo-269077.jpeg?auto=compress&cs=tinysrgb&w=800&h=533", // Handshake symbolizing partnership
        ]}
        widths={["55%", "60%", "70%"]}
        heights={["45%", "60%", "35%"]}
        imageColumnStyle="height: 400px;"
        imageStyle="width: 45%; height: 60%;"
        imagePositions={[
          "left: 35%; top: 20%;",
          "right: 30%; top: 10%;",
          "left: 32%; bottom: 20%;",
        ]}
        primaryButtonText="Contact Us Today"
        buttonRounded={true}
        textOnLeft={false}
      />
    </AnimationRevealPage>
  );
};
