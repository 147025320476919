import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import Header from "Components/headers/PageHeader";
import MainFeature1 from "Components/Features/TwoColWithButton.js"; // Reused component
import Features from "Components/Features/ThreeColumnPages"; // Reused component
import {
  FaPaintBrush,
  FaChartBar,
  FaUsers,
  FaHandshake,
  FaAward,
  FaLightbulb,
} from "react-icons/fa";

// Styled Components
const Subheading = tw.span`uppercase tracking-wider text-sm`;
const Heading = tw.h1`text-2xl md:text-4xl lg:text-5xl font-bold`;
const Description = tw.p`text-base md:text-lg lg:text-xl`;

// Corporate Branding Agency in Al Barsha Page Component
export const CorporateBrandingAgencyInAlBarsha = () => {
  return (
    <AnimationRevealPage>
      {/* Header Section */}
      <Header from="Corporate Branding Agency in Al Barsha" />

      {/* Hero Section */}
      <MainFeature1
        subheading={<Subheading>Your Trusted Corporate Branding Partner</Subheading>}
        heading={
          <Heading>
            Elevate Your Business with{" "}
            <span tw="text-primary-500">Corporate Branding Agency in Al Barsha</span>
          </Heading>
        }
        description={
          <Description>
            At MilaajBrandset, we specialize in delivering professional and strategic corporate branding solutions that elevate businesses, build trust, and resonate with corporate audiences in Al Barsha. As a leading corporate branding agency, we bring fresh ideas, data-driven insights, and a client-centric approach to every project.
          </Description>
        }
        imageSrcs={[
            "https://images.unsplash.com/photo-1600880292203-757bb62b4baf?w=1000", // Modern office meeting room
            "https://images.unsplash.com/photo-1542744173-8e7e53415bb0?w=1000", // Team brainstorming session
            "https://images.unsplash.com/photo-1558403194-611308249627?w=1000", // Brand design materials
          ]}
        widths={["55%", "60%", "70%"]}
        heights={["45%", "60%", "35%"]}
        imageColumnStyle="height: 400px;"
        imageStyle="width: 45%; height: 60%;"
        imagePositions={[
          "left: 35%; top: 20%;",
          "right: 30%; top: 10%;",
          "left: 32%; bottom: 20%;",
        ]}
        primaryButtonText="Get Started Today"
        buttonRounded={true}
        textOnLeft={false}
      />

      {/* Key Benefits Section */}
      <Features
        subheading={<Subheading>Why Choose Us?</Subheading>}
        heading="Your Trusted Corporate Branding Experts in Al Barsha"
        description="We combine professionalism, expertise, and measurable results to deliver exceptional value."
        cards={[
          {
            icon: <FaPaintBrush color="#ff5464" size={40} />,
            title: "Professional Brand Identities",
            description:
              "Our designs create a strong and trustworthy brand presence.",
          },
          {
            icon: <FaChartBar color="#ff5464" size={40} />,
            title: "Data-Driven Insights",
            description:
              "Backed by research to ensure maximum impact and ROI.",
          },
          {
            icon: <FaUsers color="#ff5464" size={40} />,
            title: "Audience-Centric Approach",
            description:
              "We prioritize the needs and behaviors of your target audience.",
          },
          {
            icon: <FaHandshake color="#ff5464" size={40} />,
            title: "Client-Centric Solutions",
            description:
              "Tailored strategies designed to meet your unique goals.",
          },
          {
            icon: <FaAward color="#ff5464" size={40} />,
            title: "Proven Expertise",
            description:
              "Years of experience crafting successful corporate brands.",
          },
          {
            icon: <FaLightbulb color="#ff5464" size={40} />,
            title: "Creative Excellence",
            description:
              "Fresh perspectives and innovative ideas for every project.",
          },
        ]}
      />

      {/* Our Process Section */}
      <MainFeature1
        subheading={<Subheading>How We Work</Subheading>}
        heading="Our Streamlined Corporate Branding Process"
        description={
          <Description>
            Our process ensures quality, efficiency, and measurable results:
            <ul tw="mt-4 list-disc pl-6">
              <li>Discovery & Research: Understand your business and target audience.</li>
              <li>Strategy Development: Create tailored plans for your goals.</li>
              <li>Design Development: Craft visually appealing and cohesive brand elements.</li>
              <li>Implementation: Roll out your corporate brand identity across platforms.</li>
            </ul>
          </Description>
        }
        imageSrcs={[
            "https://images.unsplash.com/photo-1552664730-d307ca884978?w=1000", // Team collaboration
            "https://images.unsplash.com/photo-1531973576160-7125cd663d86?w=1000", // Strategy planning
            "https://images.unsplash.com/photo-1454165804606-c3d57bc86b40?w=1000", // Results presentation
          ]}
        widths={["60%", "55%", "70%"]}
        heights={["60%", "45%", "35%"]}
        imageColumnStyle="height: 400px;"
        imageStyle="width: 45%; height: 60%;"
        imagePositions={[
          "right: 30%; top: 10%;",
          "left: 35%; bottom: 20%;",
          "right: 32%; bottom: 10%;",
        ]}
        primaryButtonText="Learn More"
        buttonRounded={true}
        textOnLeft={true}
      />

      {/* Success Stories Section */}
      <Features
        subheading={<Subheading>Success Stories</Subheading>}
        heading="Results That Speak for Themselves"
        description="Here’s how our corporate branding services have helped businesses grow in Al Barsha."
        cards={[
          {
            icon: <FaAward color="#ff5464" size={40} />,
            title: "Brand Growth",
            description:
              "A local startup saw a 350% increase in brand recognition within six months.",
          },
          {
            icon: <FaHandshake color="#ff5464" size={40} />,
            title: "Client Satisfaction",
            description:
              "Achieved a 95% client retention rate through consistent results.",
          },
          {
            icon: <FaLightbulb color="#ff5464" size={40} />,
            title: "Creative Solutions",
            description:
              "Boosted a retail client’s sales by 60% with a rebranded identity.",
          },
        ]}
      />

      {/* Call-to-Action Section */}
      <MainFeature1
        subheading={<Subheading>Ready to Elevate Your Brand?</Subheading>}
        heading="Let’s Build Your Corporate Brand Together"
        description={
          <Description>
            Partner with our expert corporate branding agency in Al Barsha to unlock the full potential of your brand. Whether you’re looking to refresh your identity or launch a new brand strategy, we’re here to help.
          </Description>
        }
        imageSrcs={[
            "https://images.unsplash.com/photo-1560179707-f14e90ef3623?w=1000", // Corporate building
            "https://images.unsplash.com/photo-1557804506-669a67965ba0?w=1000", // Professional team meeting
            "https://images.unsplash.com/photo-1552581234-26160f608093?w=1000", // Handshake closing deal
          ]}
        widths={["55%", "60%", "70%"]}
        heights={["45%", "60%", "35%"]}
        imageColumnStyle="height: 400px;"
        imageStyle="width: 45%; height: 60%;"
        imagePositions={[
          "left: 35%; top: 20%;",
          "right: 30%; top: 10%;",
          "left: 32%; bottom: 20%;",
        ]}
        primaryButtonText="Contact Us Today"
        buttonRounded={true}
        textOnLeft={false}
      />
    </AnimationRevealPage>
  );
};

