import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import Header from "Components/headers/PageHeader";
import MainFeature1 from "Components/Features/TwoColWithButton.js"; // Reused component
import Features from "Components/Features/ThreeColumnPages"; // Reused component
import {
  FaCheckCircle,
  FaServer,
  FaShieldAlt,
  FaUsers,
  FaHandshake,
  FaAward,
} from "react-icons/fa";

// Styled Components
const Subheading = tw.span`uppercase tracking-wider text-sm`;
const Heading = tw.h1`text-2xl md:text-4xl lg:text-5xl font-bold`;
const Description = tw.p`text-base md:text-lg lg:text-xl`;

// Reliable Web Hosting Services in Al Barsha Page Component
export const ReliableWebHostingServicesInAlBarsha = () => {
  return (
    <AnimationRevealPage>
      {/* Header Section */}
      <Header from="Reliable Web Hosting Services in Al Barsha" />

      {/* Hero Section */}
      <MainFeature1
        subheading={
          <Subheading>Al Barsha’s Most Reliable Hosting Provider</Subheading>
        }
        heading={
          <Heading>
            Elevate Your Online Presence with{" "}
            <span tw="text-primary-500">Reliable Web Hosting in Al Barsha</span>
          </Heading>
        }
        description={
          <Description>
            At MilaajBrandset, we specialize in delivering reliable web hosting
            services tailored for businesses and individuals in Al Barsha. Our
            team combines cutting-edge technology with unparalleled customer
            service to ensure your website remains fast, secure, and available
            24/7, empowering you to succeed in the digital landscape.
          </Description>
        }
        imageSrcs={[
          "https://images.unsplash.com/photo-1560732488-7b5f5b8c8f91?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3", // Data center with servers
          "https://images.unsplash.com/photo-1558494949-ef010cbdcc31?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3", // Close-up of server hardware
          "https://images.unsplash.com/photo-1563986768494-4dee2763ff3f?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3", // Cloud computing concept
        ]}
        widths={["55%", "60%", "70%"]}
        heights={["45%", "60%", "35%"]}
        imageColumnStyle="height: 400px;"
        imageStyle="width: 45%; height: 60%;"
        imagePositions={[
          "left: 35%; top: 20%;",
          "right: 30%; top: 10%;",
          "left: 32%; bottom: 20%;",
        ]}
        primaryButtonText="Get Started Today"
        buttonRounded={true}
        textOnLeft={false}
      />

      {/* Key Benefits Section */}
      <Features
        subheading={<Subheading>Why Choose Us?</Subheading>}
        heading="Your Trusted Reliable Hosting Provider in Al Barsha"
        description="We combine reliability, security, and innovation to deliver unmatched value."
        cards={[
          {
            icon: <FaCheckCircle color="#ff5464" size={40} />,
            title: "Guaranteed Uptime",
            description:
              "99.9% uptime guarantee ensures your website is always online.",
          },
          {
            icon: <FaServer color="#ff5464" size={40} />,
            title: "High-Performance Servers",
            description:
              "Lightning-fast load times and seamless scalability for your website.",
          },
          {
            icon: <FaShieldAlt color="#ff5464" size={40} />,
            title: "Enhanced Security",
            description:
              "Protect your data with advanced encryption and proactive threat detection.",
          },
          {
            icon: <FaUsers color="#ff5464" size={40} />,
            title: "Personalized Support",
            description:
              "24/7 assistance tailored specifically to Al Barsha businesses and individuals.",
          },
          {
            icon: <FaHandshake color="#ff5464" size={40} />,
            title: "Client-Centric Solutions",
            description:
              "Tailored strategies designed to meet your unique goals.",
          },
          {
            icon: <FaAward color="#ff5464" size={40} />,
            title: "Proven Track Record",
            description:
              "Delivering exceptional results for Al Barsha clients since [Year].",
          },
        ]}
      />

      {/* Our Process Section */}
      <MainFeature1
        subheading={<Subheading>How We Work</Subheading>}
        heading="Our Streamlined Hosting Process for Al Barsha Businesses"
        description={
          <Description>
            Our Al Barsha-focused process ensures quality, efficiency, and
            measurable results:
            <ul tw="mt-4 list-disc pl-6">
              <li>
                Discovery & Research: Understand your business and technical
                requirements.
              </li>
              <li>
                Planning & Strategy: Match you with the most reliable hosting
                plan for your needs.
              </li>
              <li>
                Implementation: Deploy your website on secure, high-performance
                servers.
              </li>
              <li>
                Monitoring & Optimization: Continuously improve speed and
                resource allocation.
              </li>
              <li>
                Ongoing Support: Provide 24/7 assistance with a local touch.
              </li>
            </ul>
          </Description>
        }
        imageSrcs={[
          "https://images.unsplash.com/photo-1551033406-611cf9a28f67?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3", // IT professional configuring servers
          "https://images.unsplash.com/photo-1486312338219-ce68d2c6f44d?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3", // Person working on computer with technical diagrams
          "https://images.unsplash.com/photo-1573164713988-8665fc963095?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3", // Tech team meeting discussing solutions
        ]}
        widths={["60%", "55%", "70%"]}
        heights={["60%", "45%", "35%"]}
        imageColumnStyle="height: 400px;"
        imageStyle="width: 45%; height: 60%;"
        imagePositions={[
          "right: 30%; top: 10%;",
          "left: 35%; bottom: 20%;",
          "right: 32%; bottom: 10%;",
        ]}
        primaryButtonText="Learn More"
        buttonRounded={true}
        textOnLeft={true}
      />

      {/* Success Stories Section */}
      <Features
        subheading={<Subheading>Success Stories</Subheading>}
        heading="Results That Speak for Themselves"
        description="Here’s how our reliable hosting solutions have empowered Al Barsha businesses."
        cards={[
          {
            icon: <FaAward color="#ff5464" size={40} />,
            title: "Business Growth",
            description:
              "Helped a local Al Barsha restaurant maintain 100% uptime during peak seasons.",
          },
          {
            icon: <FaHandshake color="#ff5464" size={40} />,
            title: "Client Satisfaction",
            description:
              "98% retention rate from Al Barsha clients through consistent excellence.",
          },
          {
            icon: <FaCheckCircle color="#ff5464" size={40} />,
            title: "Zero Downtime Guarantee",
            description:
              "Enabled over 100 Al Barsha websites to operate without interruptions.",
          },
        ]}
      />

      {/* Call-to-Action Section */}
      <MainFeature1
        subheading={<Subheading>Ready for Reliable Hosting?</Subheading>}
        heading="Let’s Grow Your Al Barsha Business Together"
        description={
          <Description>
            Partner with Al Barsha’s leading reliable web hosting provider to
            unlock your website’s full potential. Whether you’re launching a new
            site, migrating, or optimizing performance, we’re here to help.
          </Description>
        }
        imageSrcs={[
          "https://images.unsplash.com/photo-1531482615713-2afd69097998?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3", // Business handshake in modern office
          "https://images.unsplash.com/photo-1504384308090-c894fdcc538d?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3", // Cityscape of Dubai
          "https://images.unsplash.com/photo-1581091226825-a6a2a5aee158?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3", // Tech professional working with client
        ]}
        widths={["55%", "60%", "70%"]}
        heights={["45%", "60%", "35%"]}
        imageColumnStyle="height: 400px;"
        imageStyle="width: 45%; height: 60%;"
        imagePositions={[
          "left: 35%; top: 20%;",
          "right: 30%; top: 10%;",
          "left: 32%; bottom: 20%;",
        ]}
        primaryButtonText="Contact Us Today"
        buttonRounded={true}
        textOnLeft={false}
      />
    </AnimationRevealPage>
  );
};
