import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import Header from "Components/headers/PageHeader";
import MainFeature1 from "Components/Features/TwoColWithButton.js"; // Reused component
import Features from "Components/Features/ThreeColumnPages"; // Reused component
import {
  FaSearch,
  FaChartBar,
  FaUsers,
  FaHandshake,
  FaAward,
  FaLightbulb,
} from "react-icons/fa";

// Styled Components
const Subheading = tw.span`uppercase tracking-wider text-sm`;
const Heading = tw.h1`text-2xl md:text-4xl lg:text-5xl font-bold`;
const Description = tw.p`text-base md:text-lg lg:text-xl`;

// Google Ranking SEO Company in DIFC Page Component
export const GoogleRankingSEOCompanyInDIFC = () => {
  return (
    <AnimationRevealPage>
      {/* Header Section */}
      <Header from="Google Ranking SEO Company in DIFC" />

      {/* Hero Section */}
      <MainFeature1
        subheading={<Subheading>Your Trusted Partner for Higher Google Rankings</Subheading>}
        heading={
          <Heading>
            Elevate Your Google Rankings with{" "}
            <span tw="text-primary-500">Google Ranking SEO Company in DIFC</span>
          </Heading>
        }
        description={
          <Description>
            At MilaajBrandset, we specialize in delivering expert SEO services tailored for businesses in the Dubai International Financial Centre (DIFC). Our team of seasoned specialists brings data-driven strategies, fresh ideas, and a client-centric approach to help businesses improve their Google rankings, drive organic traffic, and achieve measurable results.
          </Description>
        }
        imageSrcs={[
            "https://images.unsplash.com/photo-1460925895917-afdab827c52f?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTJ8fHNlbyUyMGdvb2dsZSUyMHJhbmtpbmd8ZW58MHx8MHx8fDA%3D&auto=format&fit=crop&w=800&q=60", // Google ranking analytics dashboard
            "https://images.unsplash.com/photo-1432888498266-38ffec3eaf0a?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTh8fHNlYXJjaCUyMGVuZ2luZSUyMG9wdGltaXphdGlvbnxlbnwwfHwwfHx8MA%3D%3D&auto=format&fit=crop&w=800&q=60", // SEO keyword research
            "https://images.unsplash.com/photo-1557804506-669a67965ba0?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTB8fGJ1c2luZXNzJTIwZ3Jvd3RofGVufDB8fDB8fHww&auto=format&fit=crop&w=800&q=60", // Business growth chart
          ]}
        widths={["55%", "60%", "70%"]}
        heights={["45%", "60%", "35%"]}
        imageColumnStyle="height: 400px;"
        imageStyle="width: 45%; height: 60%;"
        imagePositions={[
          "left: 35%; top: 20%;",
          "right: 30%; top: 10%;",
          "left: 32%; bottom: 20%;",
        ]}
        primaryButtonText="Get Started Today"
        buttonRounded={true}
        textOnLeft={false}
      />

      {/* Key Benefits Section */}
      <Features
        subheading={<Subheading>Why Choose Us?</Subheading>}
        heading="Your Trusted Experts at the Google Ranking SEO Company in DIFC"
        description="We combine specialization, innovation, and measurable results to deliver exceptional value."
        cards={[
          {
            icon: <FaSearch color="#ff5464" size={40} />,
            title: "Proven Strategies for Google Rankings",
            description:
              "Our data-driven approach ensures maximum visibility on Google.",
          },
          {
            icon: <FaChartBar color="#ff5464" size={40} />,
            title: "Data-Driven Insights",
            description:
              "Backed by research to ensure maximum impact and growth.",
          },
          {
            icon: <FaUsers color="#ff5464" size={40} />,
            title: "Audience-Centric Approach",
            description:
              "We prioritize the needs and behaviors of your target audience.",
          },
          {
            icon: <FaHandshake color="#ff5464" size={40} />,
            title: "Client-Centric Solutions",
            description:
              "Tailored strategies designed to meet your unique goals.",
          },
          {
            icon: <FaAward color="#ff5464" size={40} />,
            title: "Proven Expertise",
            description:
              "Years of experience helping businesses rank higher on Google.",
          },
          {
            icon: <FaLightbulb color="#ff5464" size={40} />,
            title: "Creative Excellence",
            description:
              "Fresh perspectives and innovative ideas for every project.",
          },
        ]}
      />

      {/* Our Process Section */}
      <MainFeature1
        subheading={<Subheading>How We Work</Subheading>}
        heading="Our Streamlined Google Ranking SEO Process"
        description={
          <Description>
            Our process ensures quality, efficiency, and measurable results:
            <ul tw="mt-4 list-disc pl-6">
              <li>Discovery & Research: Understand your business and target audience.</li>
              <li>Keyword Analysis: Identify high-value keywords for your niche.</li>
              <li>Strategy Development: Create tailored SEO plans for your goals.</li>
              <li>Implementation: Optimize your website and content for Google's algorithms.</li>
              <li>Monitoring & Reporting: Track performance and adjust strategies for maximum impact.</li>
            </ul>
          </Description>
        }
        imageSrcs={[
            "https://images.unsplash.com/photo-1517245386807-bb43f82c33c4?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8c2VvJTIwcHJvY2Vzc3xlbnwwfHwwfHx8MA%3D%3D&auto=format&fit=crop&w=800&q=60", // SEO process workflow
            "https://images.unsplash.com/photo-1553877522-43269d4ea984?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8N3x8d2Vic2l0ZSUyMGFuYWx5dGljc3xlbnwwfHwwfHx8MA%3D%3D&auto=format&fit=crop&w=800&q=60", // Website analytics on laptop
            "https://images.unsplash.com/photo-1542744173-8e7e53415bb0?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTZ8fHRlYW0lMjBwbGFubmluZ3xlbnwwfHwwfHx8MA%3D%3D&auto=format&fit=crop&w=800&q=60", // Team planning SEO strategy
          ]}
        widths={["60%", "55%", "70%"]}
        heights={["60%", "45%", "35%"]}
        imageColumnStyle="height: 400px;"
        imageStyle="width: 45%; height: 60%;"
        imagePositions={[
          "right: 30%; top: 10%;",
          "left: 35%; bottom: 20%;",
          "right: 32%; bottom: 10%;",
        ]}
        primaryButtonText="Learn More"
        buttonRounded={true}
        textOnLeft={true}
      />

      {/* Success Stories Section */}
      <Features
        subheading={<Subheading>Success Stories</Subheading>}
        heading="Results That Speak for Themselves"
        description="Here’s how our Google ranking SEO services have helped businesses grow in DIFC."
        cards={[
          {
            icon: <FaAward color="#ff5464" size={40} />,
            title: "Improved Rankings",
            description:
              "A financial consultancy ranked #1 on Google for their primary keyword within three months.",
          },
          {
            icon: <FaHandshake color="#ff5464" size={40} />,
            title: "Client Satisfaction",
            description:
              "Achieved a 98% client retention rate through consistent results.",
          },
          {
            icon: <FaLightbulb color="#ff5464" size={40} />,
            title: "Increased Traffic",
            description:
              "Boosted a retail client’s organic traffic by 150% in six months.",
          },
        ]}
      />

      {/* Call-to-Action Section */}
      <MainFeature1
        subheading={<Subheading>Ready to Boost Your Google Rankings?</Subheading>}
        heading="Let’s Grow Your Business Together"
        description={
          <Description>
            Partner with our expert Google ranking SEO team in DIFC to unlock the full potential of your online presence. Whether you’re looking to improve your rankings, increase traffic, or enhance your brand’s visibility, we’re here to help.
          </Description>
        }
        imageSrcs={[
            "https://images.unsplash.com/photo-1600880292203-757bb62b4baf?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTV8fGR1YmFpJTIwYnVzaW5lc3N8ZW58MHx8MHx8fDA%3D&auto=format&fit=crop&w=800&q=60", // Dubai business district
            "https://images.unsplash.com/photo-1639762681057-408e52192e55?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTB8fGdvb2dsZSUyMHNlYXJjaHxlbnwwfHwwfHx8MA%3D%3D&auto=format&fit=crop&w=800&q=60", // Google search results
            "https://images.unsplash.com/photo-1560438718-eb61ede255eb?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MXx8YWwlMjBiYXJzaGF8ZW58MHx8MHx8fDA%3D&auto=format&fit=crop&w=800&q=60", // Al Barsha area in Dubai
          ]}
        widths={["55%", "60%", "70%"]}
        heights={["45%", "60%", "35%"]}
        imageColumnStyle="height: 400px;"
        imageStyle="width: 45%; height: 60%;"
        imagePositions={[
          "left: 35%; top: 20%;",
          "right: 30%; top: 10%;",
          "left: 32%; bottom: 20%;",
        ]}
        primaryButtonText="Contact Us Today"
        buttonRounded={true}
        textOnLeft={false}
      />
    </AnimationRevealPage>
  );
};

