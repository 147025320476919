import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import Header from "Components/headers/PageHeader";
import MainFeature1 from "Components/Features/TwoColWithButton.js"; // Reused component
import Features from "Components/Features/ThreeColumnPages"; // Reused component
import {
  FaPenNib,
  FaChartLine,
  FaUsers,
  FaHandshake,
  FaAward,
  FaMapMarkerAlt,
} from "react-icons/fa";

// Styled Components
const Subheading = tw.span`uppercase tracking-wider text-sm`;
const Heading = tw.h1`text-2xl md:text-4xl lg:text-5xl font-bold`;
const Description = tw.p`text-base md:text-lg lg:text-xl`;

// SEO Content Writing in Bur Dubai Page Component
export const SEOContentWritingInBurDubai = () => {
  return (
    <AnimationRevealPage>
      {/* Header Section */}
      <Header from="SEO Content Writing in Bur Dubai" />

      {/* Hero Section */}
      <MainFeature1
        subheading={<Subheading>Boost Your Online Visibility</Subheading>}
        heading={
          <Heading>
            Unlock Your Potential with{" "}
            <span tw="text-primary-500">SEO Content Writing in Bur Dubai</span>
          </Heading>
        }
        description={
          <Description>
            At MilaajBrandset, we specialize in delivering professional and
            SEO-optimized content tailored for businesses and professionals in
            Bur Dubai. Our team combines creativity, keyword research, and local
            insights to craft content that drives organic traffic, improves
            rankings, and delivers measurable results.
          </Description>
        }
        imageSrcs={[
          "https://images.unsplash.com/photo-1522542550221-31fd19575a2d?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1920&q=80", // SEO content writing workspace
          "https://images.unsplash.com/photo-1534732806146-b3bf32171b48?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1920&q=80", // Dubai skyline view
          "https://images.unsplash.com/photo-1498050108023-c5249f4df085?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1920&q=80", // Content creation coding
        ]}
        widths={["55%", "60%", "70%"]}
        heights={["45%", "60%", "35%"]}
        imageColumnStyle="height: 400px;"
        imageStyle="width: 45%; height: 60%;"
        imagePositions={[
          "left: 35%; top: 20%;",
          "right: 30%; top: 10%;",
          "left: 32%; bottom: 20%;",
        ]}
        primaryButtonText="Get Started Today"
        buttonRounded={true}
        textOnLeft={false}
      />

      {/* Key Benefits Section */}
      <Features
        subheading={<Subheading>Why Choose Us?</Subheading>}
        heading="Your Trusted SEO Content Partner in Bur Dubai"
        description="We combine creativity, strategy, and innovation to deliver unmatched value."
        cards={[
          {
            icon: <FaPenNib color="#ff5464" size={40} />,
            title: "Creative Excellence",
            description:
              "Craft engaging and original content that resonates with your audience.",
          },
          {
            icon: <FaChartLine color="#ff5464" size={40} />,
            title: "SEO Optimization",
            description:
              "Boost search engine rankings with keyword-rich, optimized content.",
          },
          {
            icon: <FaMapMarkerAlt color="#ff5464" size={40} />,
            title: "Localized Expertise",
            description:
              "Understand Bur Dubai's unique market needs and cultural nuances.",
          },
          {
            icon: <FaUsers color="#ff5464" size={40} />,
            title: "Personalized Support",
            description:
              "Tailored solutions designed to meet your specific goals and challenges.",
          },
          {
            icon: <FaHandshake color="#ff5464" size={40} />,
            title: "Client-Centric Approach",
            description:
              "Collaborate closely with you to ensure alignment with your vision.",
          },
          {
            icon: <FaAward color="#ff5464" size={40} />,
            title: "Proven Track Record",
            description:
              "Delivering exceptional results for Bur Dubai clients since [Year].",
          },
        ]}
      />

      {/* Our Process Section */}
      <MainFeature1
        subheading={<Subheading>How We Work</Subheading>}
        heading="Our Streamlined Process for SEO Content Writing in Bur Dubai"
        description={
          <Description>
            Our Bur Dubai-focused process ensures quality, efficiency, and
            measurable results:
            <ul tw="mt-4 list-disc pl-6">
              <li>
                Discovery & Research: Understand your business, audience, and
                goals.
              </li>
              <li>
                Keyword Analysis: Identify high-value keywords for maximum
                impact.
              </li>
              <li>
                Planning & Strategy: Develop a customized content plan aligned
                with your objectives.
              </li>
              <li>
                Creation & Execution: Craft high-quality, SEO-optimized content
                (blogs, product descriptions, landing pages, etc.).
              </li>
              <li>
                Optimization & Analysis: Refine content based on performance
                metrics and analytics.
              </li>
              <li>
                Ongoing Collaboration: Provide consistent support and updates to
                ensure success.
              </li>
            </ul>
          </Description>
        }
        imageSrcs={[
          "https://images.unsplash.com/photo-1587614382346-4ec70e388b28?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1920&q=80", // SEO keyword research
          "https://images.unsplash.com/photo-1542744094-3a31f272c490?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1920&q=80", // Content strategy team
          "https://images.unsplash.com/photo-1566125882500-87e10f726cdc?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1920&q=80", // Dubai business district
        ]}
        widths={["60%", "55%", "70%"]}
        heights={["60%", "45%", "35%"]}
        imageColumnStyle="height: 400px;"
        imageStyle="width: 45%; height: 60%;"
        imagePositions={[
          "right: 30%; top: 10%;",
          "left: 35%; bottom: 20%;",
          "right: 32%; bottom: 10%;",
        ]}
        primaryButtonText="Learn More"
        buttonRounded={true}
        textOnLeft={true}
      />

      {/* Success Stories Section */}
      <Features
        subheading={<Subheading>Success Stories</Subheading>}
        heading="Results That Speak for Themselves"
        description="Here’s how our SEO content writing services have empowered Bur Dubai businesses."
        cards={[
          {
            icon: <FaAward color="#ff5464" size={40} />,
            title: "Business Growth",
            description:
              "Helped a Bur Dubai-based e-commerce store increase organic traffic by 200% through keyword-optimized product descriptions.",
          },
          {
            icon: <FaHandshake color="#ff5464" size={40} />,
            title: "Client Satisfaction",
            description:
              "98% client retention rate through consistent excellence and measurable results.",
          },
          {
            icon: <FaPenNib color="#ff5464" size={40} />,
            title: "Creative Excellence",
            description:
              "Produced over 500 high-quality SEO-optimized articles for Bur Dubai businesses in [Year].",
          },
        ]}
      />

      {/* Call-to-Action Section */}
      <MainFeature1
        subheading={<Subheading>Ready for Impactful SEO Content?</Subheading>}
        heading="Let’s Grow Your Bur Dubai Business Together"
        description={
          <Description>
            Partner with Bur Dubai’s leading SEO content writers to unlock your
            brand’s full potential. Whether you’re launching a blog, optimizing
            your website, or creating landing pages, we’re here to help.
          </Description>
        }
        imageSrcs={[
          "https://images.unsplash.com/photo-1600096194534-95cf5188de4f?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1920&q=80", // SEO results dashboard
          "https://images.unsplash.com/photo-1512453979798-5ea266f8880c?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1920&q=80", // Dubai modern architecture
          "https://images.unsplash.com/photo-1600880292089-90a7e086ee0c?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1920&q=80", // Content writing collaboration
        ]}
        widths={["55%", "60%", "70%"]}
        heights={["45%", "60%", "35%"]}
        imageColumnStyle="height: 400px;"
        imageStyle="width: 45%; height: 60%;"
        imagePositions={[
          "left: 35%; top: 20%;",
          "right: 30%; top: 10%;",
          "left: 32%; bottom: 20%;",
        ]}
        primaryButtonText="Contact Us Today"
        buttonRounded={true}
        textOnLeft={false}
      />
    </AnimationRevealPage>
  );
};
