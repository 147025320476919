import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import Header from "Components/headers/PageHeader";
import MainFeature1 from "Components/Features/TwoColWithButton.js"; // Reused component
import Features from "Components/Features/ThreeColumnPages"; // Reused component
import {
  FaCode,
  FaUsers,
  FaHandshake,
  FaAward,
  FaLightbulb,
} from "react-icons/fa";

// Styled Components
const Subheading = tw.span`uppercase tracking-wider text-sm`;
const Heading = tw.h1`text-2xl md:text-4xl lg:text-5xl font-bold`;
const Description = tw.p`text-base md:text-lg lg:text-xl`;

// Custom Website Development in DIFC Page Component
export const CustomWebsiteDevelopmentInDIFC = () => {
  return (
    <AnimationRevealPage>
      {/* Header Section */}
      <Header from="Custom Website Development in DIFC" />

      {/* Hero Section */}
      <MainFeature1
        subheading={
          <Subheading>
            Your Trusted Partner for Tailored Web Solutions
          </Subheading>
        }
        heading={
          <Heading>
            Elevate Your Online Presence with{" "}
            <span tw="text-primary-500">
              Custom Website Development in DIFC
            </span>
          </Heading>
        }
        description={
          <Description>
            At MilaajBrandset, we specialize in delivering expert custom website
            development services tailored for businesses in the Dubai
            International Financial Centre (DIFC). Our team of seasoned
            professionals combines technical expertise, creativity, and a
            client-centric approach to create websites that are unique,
            functional, and optimized for success, ensuring measurable results
            for your business.
          </Description>
        }
        imageSrcs={[
          "https://images.unsplash.com/photo-1461749280684-dccba630e2f6?w=1000", // Developer workspace
          "https://images.unsplash.com/photo-1542744173-8e7e53415bb0?w=1000", // Modern tech workspace
          "https://images.unsplash.com/photo-1519389950473-47ba0277781c?w=1000", // Collaborative tech environment
        ]}
        widths={["55%", "60%", "70%"]}
        heights={["45%", "60%", "35%"]}
        imageColumnStyle="height: 400px;"
        imageStyle="width: 45%; height: 60%;"
        imagePositions={[
          "left: 35%; top: 20%;",
          "right: 30%; top: 10%;",
          "left: 32%; bottom: 20%;",
        ]}
        primaryButtonText="Get Started Today"
        buttonRounded={true}
        textOnLeft={false}
      />

      {/* Key Benefits Section */}
      <Features
        subheading={<Subheading>Why Choose Us?</Subheading>}
        heading="Your Trusted Experts at Custom Website Development in DIFC"
        description="We combine customization, innovation, and measurable results to deliver exceptional value."
        cards={[
          {
            icon: <FaCode color="#ff5464" size={40} />,
            title: "Tailored Solutions",
            description:
              "Custom-built websites designed to meet your unique needs.",
          },
          {
            icon: <FaUsers color="#ff5464" size={40} />,
            title: "Audience-Centric Approach",
            description:
              "We prioritize user experience and seamless functionality.",
          },
          {
            icon: <FaHandshake color="#ff5464" size={40} />,
            title: "Client-Centric Solutions",
            description:
              "Tailored strategies designed to meet your unique goals.",
          },
          {
            icon: <FaAward color="#ff5464" size={40} />,
            title: "Proven Track Record",
            description:
              "Delivering exceptional results for businesses of all sizes.",
          },
          {
            icon: <FaLightbulb color="#ff5464" size={40} />,
            title: "Creative Excellence",
            description:
              "Fresh perspectives and innovative ideas for every project.",
          },
        ]}
      />

      {/* Our Process Section */}
      <MainFeature1
        subheading={<Subheading>How We Work</Subheading>}
        heading="Our Streamlined Custom Website Development Process"
        description={
          <Description>
            Our process ensures quality, efficiency, and measurable results:
            <ul tw="mt-4 list-disc pl-6">
              <li>
                Discovery & Research: Understand your business and target
                audience.
              </li>
              <li>
                Planning & Strategy: Define goals, features, and technical
                requirements.
              </li>
              <li>
                Design Development: Craft visually stunning and functional
                designs.
              </li>
              <li>
                Development & Testing: Build and rigorously test your website
                for performance.
              </li>
              <li>
                Launch & Support: Deploy your website and provide ongoing
                support.
              </li>
            </ul>
          </Description>
        }
        imageSrcs={[
          "https://images.unsplash.com/photo-1551288049-bebda4e38f71?w=1000", // Web development strategy
          "https://images.unsplash.com/photo-1516321497487-e288fb19713f?w=1000", // Team analyzing strategy
          "https://images.unsplash.com/photo-1587620962725-abab7fe55159?w=1000", // Coding on multiple screens
        ]}
        widths={["60%", "55%", "70%"]}
        heights={["60%", "45%", "35%"]}
        imageColumnStyle="height: 400px;"
        imageStyle="width: 45%; height: 60%;"
        imagePositions={[
          "right: 30%; top: 10%;",
          "left: 35%; bottom: 20%;",
          "right: 32%; bottom: 10%;",
        ]}
        primaryButtonText="Learn More"
        buttonRounded={true}
        textOnLeft={true}
      />

      {/* Success Stories Section */}
      <Features
        subheading={<Subheading>Success Stories</Subheading>}
        heading="Results That Speak for Themselves"
        description="Here’s how our custom website development services have helped businesses grow in DIFC."
        cards={[
          {
            icon: <FaAward color="#ff5464" size={40} />,
            title: "Improved Performance",
            description:
              "A local startup saw a 50% increase in website loading speeds within three months.",
          },
          {
            icon: <FaHandshake color="#ff5464" size={40} />,
            title: "Client Satisfaction",
            description:
              "Achieved a 98% client retention rate through consistent results.",
          },
          {
            icon: <FaLightbulb color="#ff5464" size={40} />,
            title: "Enhanced Functionality",
            description:
              "Implemented advanced features, improving user engagement significantly.",
          },
        ]}
      />

      {/* Call-to-Action Section */}
      <MainFeature1
        subheading={
          <Subheading>Ready for Custom Website Development?</Subheading>
        }
        heading="Let’s Grow Your Business Together"
        description={
          <Description>
            Partner with the leading custom website development experts in DIFC
            to unlock the full potential of your online presence. Whether you’re
            looking to create a new website, optimize an existing one, or
            enhance functionality, we’re here to help.
          </Description>
        }
        imageSrcs={[
          "https://images.unsplash.com/photo-1553877522-43269d4ea984?w=1000", // Diverse tech team
          "https://images.unsplash.com/photo-1560439514-4e9645039924?w=1000", // Business collaboration
          "https://images.unsplash.com/photo-1600880292089-90a7e086ee0c?w=1000", // Modern workspace
        ]}
        widths={["55%", "60%", "70%"]}
        heights={["45%", "60%", "35%"]}
        imageColumnStyle="height: 400px;"
        imageStyle="width: 45%; height: 60%;"
        imagePositions={[
          "left: 35%; top: 20%;",
          "right: 30%; top: 10%;",
          "left: 32%; bottom: 20%;",
        ]}
        primaryButtonText="Contact Us Today"
        buttonRounded={true}
        textOnLeft={false}
      />
    </AnimationRevealPage>
  );
};
