import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import Header from "Components/headers/PageHeader";
import MainFeature1 from "Components/Features/TwoColWithButton.js"; // Reused component
import Features from "Components/Features/ThreeColumnPages"; // Reused component
import {
  FaLaptopCode,
  FaChartBar,
  FaUsers,
  FaHandshake,
  FaAward,
  FaLightbulb,
} from "react-icons/fa";

// Styled Components
const Subheading = tw.span`uppercase tracking-wider text-sm`;
const Heading = tw.h1`text-2xl md:text-4xl lg:text-5xl font-bold`;
const Description = tw.p`text-base md:text-lg lg:text-xl`;

// Website Redesign Services in Bur Dubai Page Component
export const WebsiteRedesignServicesInBurDubai = () => {
  return (
    <AnimationRevealPage>
      {/* Header Section */}
      <Header from="Website Redesign Services in Bur Dubai" />

      {/* Hero Section */}
      <MainFeature1
        subheading={<Subheading>Your Trusted Partner for Website Revamps</Subheading>}
        heading={
          <Heading>
            Elevate Your Online Presence with{" "}
            <span tw="text-primary-500">Website Redesign Services in Bur Dubai</span>
          </Heading>
        }
        description={
          <Description>
            At MilaajBrandset, we specialize in delivering expert website redesign services tailored for businesses in Bur Dubai. Our team of seasoned designers brings fresh ideas, technical expertise, and a client-centric approach to help businesses transform outdated websites into visually stunning, functional, and high-performing platforms that drive results.
          </Description>
        }
        imageSrcs={[
            "https://images.pexels.com/photos/270637/pexels-photo-270637.jpeg?auto=compress&cs=tinysrgb&w=800&h=533", // Redesigned website on screen
            "https://images.pexels.com/photos/3184291/pexels-photo-3184291.jpeg?auto=compress&cs=tinysrgb&w=800&h=533", // Team collaborating on redesign
            "https://images.pexels.com/photos/4164418/pexels-photo-4164418.jpeg?auto=compress&cs=tinysrgb&w=800&h=533", // Creative redesign brainstorming
          ]}
        widths={["55%", "60%", "70%"]}
        heights={["45%", "60%", "35%"]}
        imageColumnStyle="height: 400px;"
        imageStyle="width: 45%; height: 60%;"
        imagePositions={[
          "left: 35%; top: 20%;",
          "right: 30%; top: 10%;",
          "left: 32%; bottom: 20%;",
        ]}
        primaryButtonText="Get Started Today"
        buttonRounded={true}
        textOnLeft={false}
      />

      {/* Key Benefits Section */}
      <Features
        subheading={<Subheading>Why Choose Us?</Subheading>}
        heading="Your Trusted Experts at Website Redesign Services in Bur Dubai"
        description="We combine transformation, innovation, and measurable results to deliver exceptional value."
        cards={[
          {
            icon: <FaLaptopCode color="#ff5464" size={40} />,
            title: "Modernized Designs",
            description:
              "Revamp outdated websites with fresh, modern, and visually appealing designs.",
          },
          {
            icon: <FaChartBar color="#ff5464" size={40} />,
            title: "Improved User Experience",
            description:
              "Enhance navigation, speed, and overall usability for better engagement.",
          },
          {
            icon: <FaUsers color="#ff5464" size={40} />,
            title: "Audience-Centric Approach",
            description:
              "We design with your target audience’s needs and behaviors in mind.",
          },
          {
            icon: <FaHandshake color="#ff5464" size={40} />,
            title: "Client-Centric Solutions",
            description:
              "Tailored strategies designed to meet your unique goals.",
          },
          {
            icon: <FaAward color="#ff5464" size={40} />,
            title: "Proven Expertise",
            description:
              "Years of experience helping businesses succeed online.",
          },
          {
            icon: <FaLightbulb color="#ff5464" size={40} />,
            title: "Creative Excellence",
            description:
              "Fresh perspectives and innovative ideas for every project.",
          },
        ]}
      />

      {/* Our Process Section */}
      <MainFeature1
        subheading={<Subheading>How We Work</Subheading>}
        heading="Our Streamlined Website Redesign Process"
        description={
          <Description>
            Our process ensures quality, efficiency, and measurable results:
            <ul tw="mt-4 list-disc pl-6">
              <li>Discovery & Research: Understand your business and target audience.</li>
              <li>Audit & Analysis: Evaluate your current website’s strengths and weaknesses.</li>
              <li>Wireframing & Prototyping: Create a blueprint for your redesigned website.</li>
              <li>Design Development: Craft visually stunning and functional designs.</li>
              <li>Development & Testing: Build and rigorously test your website for performance.</li>
              <li>Launch & Support: Deploy your website and provide ongoing support.</li>
            </ul>
          </Description>
        }
        imageSrcs={[
            "https://images.pexels.com/photos/1181467/pexels-photo-1181467.jpeg?auto=compress&cs=tinysrgb&w=800&h=533", // Redesign process on laptop
            "https://images.pexels.com/photos/3184325/pexels-photo-3184325.jpeg?auto=compress&cs=tinysrgb&w=800&h=533", // Team working on redesign project
            "https://images.pexels.com/photos/590041/pexels-photo-590041.jpeg?auto=compress&cs=tinysrgb&w=800&h=533", // Planning website revamp
          ]}
        widths={["60%", "55%", "70%"]}
        heights={["60%", "45%", "35%"]}
        imageColumnStyle="height: 400px;"
        imageStyle="width: 45%; height: 60%;"
        imagePositions={[
          "right: 30%; top: 10%;",
          "left: 35%; bottom: 20%;",
          "right: 32%; bottom: 10%;",
        ]}
        primaryButtonText="Learn More"
        buttonRounded={true}
        textOnLeft={true}
      />

      {/* Success Stories Section */}
      <Features
        subheading={<Subheading>Success Stories</Subheading>}
        heading="Results That Speak for Themselves"
        description="Here’s how our website redesign services have helped businesses grow in Bur Dubai."
        cards={[
          {
            icon: <FaAward color="#ff5464" size={40} />,
            title: "Improved User Engagement",
            description:
              "A boutique consultancy saw a 40% increase in user engagement within three months.",
          },
          {
            icon: <FaHandshake color="#ff5464" size={40} />,
            title: "Client Satisfaction",
            description:
              "Achieved a 98% client retention rate through consistent results.",
          },
          {
            icon: <FaLightbulb color="#ff5464" size={40} />,
            title: "Increased Conversions",
            description:
              "Boosted a retail client’s conversion rate by 150% in six months.",
          },
        ]}
      />

      {/* Call-to-Action Section */}
      <MainFeature1
        subheading={<Subheading>Ready to Transform Your Website?</Subheading>}
        heading="Let’s Grow Your Business Together"
        description={
          <Description>
            Partner with our expert website redesign team in Bur Dubai to unlock the full potential of your online presence. Whether you’re looking to revamp an outdated website, improve functionality, or enhance user experience, we’re here to help.
          </Description>
        }
        imageSrcs={[
            "https://images.pexels.com/photos/3182759/pexels-photo-3182759.jpeg?auto=compress&cs=tinysrgb&w=800&h=533", // Team celebrating redesign success
            "https://images.pexels.com/photos/577585/pexels-photo-577585.jpeg?auto=compress&cs=tinysrgb&w=800&h=533", // Redesigned website workspace
            "https://images.pexels.com/photos/269077/pexels-photo-269077.jpeg?auto=compress&cs=tinysrgb&w=800&h=533", // Handshake symbolizing partnership
          ]}
        widths={["55%", "60%", "70%"]}
        heights={["45%", "60%", "35%"]}
        imageColumnStyle="height: 400px;"
        imageStyle="width: 45%; height: 60%;"
        imagePositions={[
          "left: 35%; top: 20%;",
          "right: 30%; top: 10%;",
          "left: 32%; bottom: 20%;",
        ]}
        primaryButtonText="Contact Us Today"
        buttonRounded={true}
        textOnLeft={false}
      />
    </AnimationRevealPage>
  );
};

